import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from 'src/app/core/User';
import { loggedInUserSelector } from 'src/app/core/users';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { State } from '../rx';
import { noCloseModalOptions } from 'src/app/shared/utils';
import { CreatingEditUserComponentParams } from '../edit-user/edit-user-component-params/creating-edit-user-component-params';

@Component({
  selector: 'app-new-user-button',
  templateUrl: './new-user-button.component.html',
  styleUrls: ['./new-user-button.component.css']
})
export class NewUserButtonComponent {

  constructor(private readonly modalService: NgbModal,
    private readonly store: Store<State>
  ) { }

  public handleClick() {
    const helper = (parentUser: User) => {
      const modalRef = this.modalService.open(EditUserComponent, noCloseModalOptions);
      modalRef.componentInstance.params = new CreatingEditUserComponentParams(parentUser);
    };

    this.getParentUser$().pipe(first()).subscribe(helper);
  }

  private getParentUser$(): Observable<User> {
    return this.store.pipe(select(loggedInUserSelector));
  }
}
