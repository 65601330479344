import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Jobs from 'src/app/core/jobs';
import * as Stations from 'src/app/core/stations';
import { Station } from 'src/app/core/stations';
import { State } from 'src/app/reducers';
import { NotificationsService } from '../../core/services/notifications.service';
import { adminStationsSelector } from '../rx/admin-location-selectors';
import { StationSelector } from './station-selector';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.css']
})
export class EditJobComponent implements OnInit {

  public name = '';
  public readonly stations$ = this.makeStations$();
  public readonly stationSelector: StationSelector = new StationSelector();

  private errorMessage: string | undefined;

  constructor(
    private readonly notifications: NotificationsService,
    private readonly store: Store<State>,
    private readonly activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.dispatchLoadStations();
  }

  submit() {
    try {
      this.validateReady();
    } catch (e) {
      return this.alertView(e);
    }

    this.dispatchCreate();
    this.handleResponse();
  }

  public getErrorMessage(): string | undefined {
    return this.errorMessage;
  }

  private makeStations$(): Observable<Station[]> {
    return this.store.pipe(select(adminStationsSelector));
  }

  private handleResponse() {
    this.notifications.addNotification({
      message: `Successfully created ${this.name}`
    });
    this.activeModal.dismiss();
  }

  private dispatchLoadStations() {
    this.store.dispatch(new Stations.LoadAll());
  }

  private validateReady() {
    this.validateName();
    this.validateSelectedStations();
  }

  private alertView(error: Error) {
    this.tempSetErrorMessage(error.message, 3000);
  }

  private dispatchCreate() {
    const stationIds = this.stationSelector.selectedIds();
    this.store.dispatch(new Jobs.Actions.Create({ name: this.name, stationIds }));
  }

  private validateName() {
    if (!this.name) {
      throw new Error('Name required');
    }
  }

  private validateSelectedStations() {
    if (!this.stationSelector.atLeastOneSelected()) {
      throw new Error('At least one station is required');
    }
  }

  private tempSetErrorMessage(message: string, ms: number) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = undefined, ms);
  }
}
