import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable, timer } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { TimeoutService } from '../core/services/timeout.service'
import { User } from '../core/User'
import { loggedInUserSelector, Logout } from '../core/users'
import { State } from '../reducers'

@Component({
  selector: 'app-login-status',
  templateUrl: './login-status.component.html',
  styleUrls: ['./login-status.component.css']
})
export class LoginStatusComponent {
  public readonly displayElapsedTime = true;

  public readonly timeElapsed$ = timer(0, 500).pipe(map(() => {
    const totalSeconds = (environment.logoutTimeout - this.timeout.getTimeElapsed()) / 1000;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${minutes}'${seconds}''`;
  }));

  constructor(private readonly store: Store<State>,
    private readonly timeout: TimeoutService) { }

  public handleLogoutRequest() {
    this.store.dispatch(new Logout());
  }

  public getUserEmail$(): Observable<string> {
    const helper = (user: User | undefined) => user ? user.email : '';
    return this.store.pipe(select(loggedInUserSelector), map(helper));
  }

}
