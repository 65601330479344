import { GetLoginState } from './login-state-controller';
import { Organization } from '../../organizations/Organization';

export abstract class LoginState {
  protected email: string;
  protected password: string;
  protected organization: Organization;

  constructor(protected readonly getLoginState: GetLoginState) {
  }

  public back(): LoginState {
    return this;
  }

  public next(): LoginState {
    return this;
  }

  public getErrorMessage(): string | undefined {
    return '';
  }

  public canMoveNext(): boolean {
    return true;
  }

  public setEmail(email: string = '') {
    this.email = email.toLowerCase();
  }

  public setPassword(password: string) {
    this.password = password;
  }

  public setOrganization(organization: Organization) {
    this.organization = organization;
  }
}
