import { QuestionI, SectionI } from '../lib/models';
import { SurveyWrapper } from './model';
import { getQuestions } from 'src/app/shared/utils';

export class PositionInSurvey {

  private static readonly SUCCESSFUL_MOVE = true;
  private static readonly FAILED_MOVE = false;

  constructor(protected readonly surveyWrapper: SurveyWrapper) {
  }

  public next() {
    const currentSection = this.getCurrentSection();

    const currentQuestion = this.getCurrentQuestionForSection(currentSection);

    const couldMoveToNextQuestion =
      this.nextQuestion({ section: currentSection, question: currentQuestion });

    if (!couldMoveToNextQuestion) {
      this.nextSection();
    }
  }

  public previous() {
    const currentSection = this.getCurrentSection();

    const currentQuestion = this.getCurrentQuestionForSection(currentSection);

    if (currentQuestion) {

      const couldMoveToPreviousQuestion =
        this.previousQuestion({ section: currentSection, question: currentQuestion });

      if (!couldMoveToPreviousQuestion) {
        this.previousSection();
      }
    }
  }

  public getCurrentQuestionForCurrentSection(): QuestionI {
    return this.getCurrentQuestionForSection(this.getCurrentSection());
  }

  public getCurrentQuestionForSection(section: SectionI): QuestionI {
    const questions = getQuestions(section);
    const currentQuestion = questions.find(q => q.current) || questions[0];

    return currentQuestion;
  }

  public setCurrentQuestionForSection(question: QuestionI, section: SectionI) {
    const questions = getQuestions(section);

    questions.forEach(question_ => {
      if (question_ === question) {
        question_.current = true;
      } else {
        delete question_.current;
      }
    });
  }

  public getCurrentSection() {
    const sections = this.getSections();
    const defaultSection = sections[0];
    return this.getSections().find(section => section.current) || defaultSection;
  }

  public getQuestionsForCurrentSection() {
    return getQuestions(this.getCurrentSection());
  }

  public getSections(): SectionI[] {
    return Object.values(this.getSurvey().sections);
  }

  public isCurrentSection(section: SectionI) {
    return this.getCurrentSection() === section;
  }

  public setCurrentSection(section: SectionI) {
    const survey = this.getSurvey();

    Object.values(survey.sections).forEach(section_ => {
      if (section === section_) {
        section_.current = true;
      } else {
        delete section_.current;
      }
    });
  }

  private getSurvey() {
    return this.surveyWrapper.getSurvey();
  }

  private nextSection() {
    const sections = this.getSections();
    const index = sections.indexOf(this.getCurrentSection());

    if (index !== sections.length - 1) {
      this.setCurrentSection(sections[index + 1]);
    }
  }

  private previousSection() {
    const sections = this.getSections();
    const index = sections.indexOf(this.getCurrentSection());

    if (index !== 0) {
      this.setCurrentSection(sections[index - 1]);
    }
  }

  private nextQuestion({ section, question }: { section: SectionI, question: QuestionI }): boolean {
    const questions = getQuestions(section);

    const index = questions.indexOf(question);
    if (index !== questions.length - 1) {
      const newCurrentQuestion = questions[index + 1];
      this.setCurrentQuestionForSection(newCurrentQuestion, section);

      return PositionInSurvey.SUCCESSFUL_MOVE;
    } else {
      return PositionInSurvey.FAILED_MOVE;
    }
  }

  private previousQuestion({ section, question }: { section: SectionI, question: QuestionI }): boolean {
    const questions = getQuestions(section);
    const index = questions.indexOf(question);

    if (index !== 0) {

      const newCurrentQuestion = questions[index - 1];
      this.setCurrentQuestionForSection(newCurrentQuestion, section);

      return PositionInSurvey.SUCCESSFUL_MOVE;
    } else {
      return PositionInSurvey.FAILED_MOVE;
    }
  }

}

export class MarkAsCompleteOnNextPositionInSurvey extends PositionInSurvey {
  next() {
    const question = this.getCurrentQuestionForCurrentSection();
    this.surveyWrapper.markAsComplete(question);
    super.next();
  }
}
