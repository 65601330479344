import { QuestionI } from 'src/app/core/lib/models';
import { AnnotationSelectionRequestHandler } from './annotation-selection-request-handler';
import { SelectionRequestHandler, SelectionRequestHook } from './selection-request-handler';
import { Constructor } from 'src/app/shared/utils';

export class SelectionRequestHandlerFactory {
  constructor(private readonly makeSelection: SelectionRequestHook) {
  }

  get(question: QuestionI): SelectionRequestHandler {
    return new (this.getConstructor(question))(this.makeSelection, question);
  }

  private getConstructor(question: QuestionI): Constructor<SelectionRequestHandler> {
    return question.requiresAnnotation ?
      AnnotationSelectionRequestHandler :
      SelectionRequestHandler;
  }
}
