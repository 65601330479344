import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lib } from '../core/lib/models';
import { LibService } from '../core/lib/service';
import { AnalysisSelection } from '../core/models/analysis';
import { LibAnalysisSelection } from '../core/models/analysis/lib-analyis/lib-analysis-selection';
import { toReplaySubject } from '../shared/utils';
import { CachedCreator } from '../core/models/cached-creator';

@Injectable({
  providedIn: 'root'
})
export class AnalysisSelectionService extends CachedCreator<AnalysisSelection> {

  constructor(
    private readonly libService: LibService
  ) {
    super();
  }

  protected make$(): Observable<AnalysisSelection> {
    const helper = (lib: Lib) => new LibAnalysisSelection(lib.makeAnalysisOptions);
    const observable = this.libService.get().pipe(map(helper));
    return toReplaySubject(observable);
  }
}
