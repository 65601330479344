import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { Revision } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { State } from '../rx';
import { ViewResultsComponent } from '../view-results/view-results.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { DashboardPresenceNotificationManager } from './dashboard-presence-notification';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Component({
  selector: 'app-survey-header',
  templateUrl: './survey-header.component.html',
  styleUrls: ['./survey-header.component.css']
})
export class SurveyHeaderComponent extends ActiveSurveyDirective implements OnInit {

  private readonly dashboardPresenceNotification: DashboardPresenceNotificationManager | undefined;

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly modalService: NgbModal,
    private readonly notifications: NotificationsService,
  ) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);
    this.dashboardPresenceNotification = new DashboardPresenceNotificationManager(
      this.revision$,
      this.getTotalCompletion$.bind(this),
      this.notifications);
  }

  ngOnInit() {
    this.surveyStateUpdated();
  }

  protected surveyStateUpdated() {
    if (this.dashboardPresenceNotification) {
      this.dashboardPresenceNotification.updateNotification();
    }
  }

  public canViewResults$(): Observable<boolean> {
    const helper = (totalCompletion: CompletionResult) => totalCompletion.asNumber() === 1;
    return this.getTotalCompletion$().pipe(map(helper));
  }

  public titleText$(): Observable<string> {
    const helper = (revision: Revision, station: Station) => {
      if (revision && station) {
        return `${revision.name} - ${station.name}`;
      } else {
        return 'Loading';
      }
    };

    return combineLatest([
      this.revision$,
      this.station$
    ]).pipe(map(([revision, station]) => helper(revision, station)));
  }

  public viewResults() {
    const helper = (surveyWrapper: SurveyWrapper, station: Station) => {
      const modalRef = this.modalService.open(ViewResultsComponent);
      modalRef.componentInstance.survey = surveyWrapper.getSurvey();
      modalRef.componentInstance.station = station;
    };

    combineLatest([
      this.surveyWrapper$,
      this.station$
    ]).pipe(first()).subscribe(([surveyWrapper, station]) => helper(surveyWrapper, station));
  }
}
