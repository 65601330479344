import { Component } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Stations from 'src/app/core/stations';
import * as Jobs from 'src/app/core/jobs';
import * as Organizations from 'src/app/core/organizations';
import { GetReviews, Review } from '../core/reviews';
import { LoadFromReview } from '../core/revisions';
import { loggedInUserSelector } from '../core/users';
import { State } from '../reducers';
import { partial } from 'lodash';

const reviewsAssignedToCurrentUserSelector = createSelector(
  loggedInUserSelector,
  state => Object.values(state.reviews.entities),
  (user, reviews) => {
    return user ?
      reviews.filter(review => review.user_id === user.id) :
      [];
  }
);

export function displayReview$(store: Store<State>, review: Review): Observable<string> {
  return store.pipe(map(state => {
    const revision = state.revisions.entities[review.revision_id];
    const station = revision ?
      state.stations.entities[revision.station_id] :
      undefined;

    const reviewTypeName = review.review_type.name;

    if (revision && station) {
      return `${station.name} - ${revision.name} - ${reviewTypeName}`;
    } else if (revision) {
      return `${revision.name} - ${reviewTypeName}`;
    } else {
      return reviewTypeName;
    }
  }));
}

@Component({
  selector: 'app-review-nav-selection',
  templateUrl: './review-nav-selection.component.html',
  styleUrls: ['./review-nav-selection.component.css']
})
export class ReviewNavSelectionComponent {

  public readonly displayReview$ = partial(displayReview$, this.store);
  private readonly reviews$: Observable<Review[]> = this.makeReviews$();

  // HACK
  private readonly requestedReviews = {};

  constructor(private readonly store: Store<State>) {
    this.dispatchLoads();
  }

  public numberReviewsToComplete$(): Observable<number> {
    return this.reviewsToComplete$().pipe(map(reviews => reviews.length));
  }

  public reviewsToComplete$(): Observable<Review[]> {
    const helper = (reviews: Review[]) => reviews.filter(review => !review.complete_at);
    return this.reviews$.pipe(map(helper));
  }

  public reviewsReport(review: Review): boolean {
    const reviewTypeName = review.review_type.name;
    return reviewTypeName === 'Classification Review' ||
      reviewTypeName === 'Approval Review';
  }

  private makeReviews$(): Observable<Review[]> {
    return this.store.pipe(select(reviewsAssignedToCurrentUserSelector));
  }

  private dispatchLoads() {
    this.dispatchLoadReviews();
    this.dispatchLoadRevisions();
    this.dispatchLoadStations();
    this.dispatchLoadJobs();
    this.dispatchLoadOrganizations();
  }

  private dispatchLoadReviews() {
    this.store.dispatch(new GetReviews());
  }

  private dispatchLoadRevisions() {
    this.store.pipe(map(state => Object.values(state.reviews.entities))).subscribe(reviews => {
      reviews.forEach(review => {
        if (!this.requestedReviews[review.id]) {
          this.store.dispatch(new LoadFromReview({ review }));
          this.requestedReviews[review.id] = true;
        }
      });
    });
  }

  private dispatchLoadStations() {
    this.store.dispatch(new Stations.LoadAll());
  }

  private dispatchLoadJobs() {
    this.store.dispatch(new Jobs.Actions.LoadAll());
  }

  private dispatchLoadOrganizations() {
    this.store.dispatch(new Organizations.LoadAll());
  }
}
