import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-annotation-editor',
  templateUrl: './annotation-editor.component.html',
  styleUrls: ['./annotation-editor.component.css']
})
export class AnnotationEditorComponent implements OnChanges {
  @Input() public readonly isReadonly = false;
  @Input() public annotation = '';
  @Input() public readonly error: string | undefined;

  @Output() public annotationSubmissionRequested = new EventEmitter<string>();

  private unedittedAnnotation = '';

  ngOnChanges(changes: SimpleChanges): void {
    const { annotation: annotationChanges } = changes;
    if (annotationChanges) {
      this.unedittedAnnotation = annotationChanges.currentValue;
    }
  }

  public cancelChanges() {
    this.annotation = this.unedittedAnnotation;
  }

  public handleSubmissionRequested() {
    this.annotationSubmissionRequested.emit(this.annotation);
    this.unedittedAnnotation = this.annotation;
  }

  public maySubmit(): boolean {
    return this.annotation !== this.unedittedAnnotation;
  }
}
