import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../core/services/notifications.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(
    private readonly notifications: NotificationsService,
  ) { }

  public getNotifications(): Notification[] {
    return this.notifications.notifications;
  }
}
