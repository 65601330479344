import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManualComponent } from '../manual/manual.component';

@Component({
  selector: 'app-manual-button',
  templateUrl: './manual-button.component.html',
  styleUrls: ['./manual-button.component.css']
})
export class ManualButtonComponent {
  constructor(private readonly modalService: NgbModal) { }

  public handleClick() {
    this.openManual();
  }

  private openManual() {
    this.modalService.open(ManualComponent);
  }
}
