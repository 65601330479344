import { HttpClient, HttpParams } from '@angular/common/http';
import { } from 'jasmine';
import { snakeCase, MemoizedFunction, flatten } from 'lodash';
import { compose, every, get, isEqual, map, reduce, zip } from 'lodash/fp';
import { defer, Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { QuestionI, SectionI } from '../core/lib/models';
import { ApiTokenService } from '../core/services/api-token.service';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function snakeify(obj) {
  const rv = {};

  for (const k in obj) {
    rv[snakeCase(k)] = obj[k];
  }

  return rv;
}

export function makeSrc(args) {
  const { modelStr, defaultImageUri } = (args || <any>{});
  const apiUri = environment.apiUri.toString();

  return model => {
    const { image_uri, id } = (model || <any>{});

    if (image_uri) {
      return apiUri + '/uploaded_files/' + modelStr + '/' + id + '/' + image_uri;
    } else if (defaultImageUri) {
      return apiUri + defaultImageUri;
    }
  };
}

export const userSrc = makeSrc({
  modelStr: 'user',
  defaultImageUri: '/images/default-user.svg'
});

export const organizationSrc = makeSrc({
  modelStr: 'organization',
  defaultImageUri: '/images/default-organization.svg'
});

export const regionSrc = makeSrc({
  modelStr: 'region',
  defaultImageUri: '/images/default-region.svg'
});

export const stationSrc = makeSrc({
  modelStr: 'station',
  defaultImageUri: '/images/default-station.png'
});

export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/;

/**
 * Helper method to return an observable of the given data
 * @param data
 */
export function asyncData(data) {
  return defer(() => Promise.resolve(data));
}

// Try to convert each id into a non NaN int.
// If the id cannot be converted, then the non-converted id
// will remain in the returned array
export function attemptCoerceIdArray(ids: any[]) {
  return ids.map(id => {
    // Try to convert
    const asInt = parseInt(id);
    if (isNaN(asInt)) { // Could not successfully convert
      return id;
    } else { // Successfully converted
      return asInt;
    }
  });
}

/**
 * Utility function to run a test that getter returns
 * a truthy value
 * @param getter
 */
export function sanityCheck(getter: () => any) {
  it('should be created', () => {
    expect(getter()).toBeTruthy();
  });
}

/**
 * Make the given TestBed provide the ApiTokenService and
 * the given httpClient
 * @param testBed
 * @param httpClient
 */
export function talksToApi(testBed, httpClient) {
  provide(testBed, ApiTokenService,
    { provide: HttpClient, useValue: httpClient });
}

/**
 * Configure the testBed such that is provides services
 * on testBed
 * @param testBed
 * @param services
 */
export function provide(testBed, ...services) {
  testBed.configureTestingModule({
    providers: services
  });
}

export function truncate(value: string, limit: number, postfix: string = ''): string {
  value = value || '';
  return value.length > limit ? value.substring(0, limit) + postfix : value;
}

export function getQuestions(section: SectionI): QuestionI[] {
  const { question }: { question?: QuestionI } = section;
  let { questions }: { questions?: QuestionI[] } = section;

  if (question) {
    questions = [question];
  }

  return questions;
}

export function toReplaySubject<T>(observable: Observable<T>, numReplays = 1): ReplaySubject<T> {
  const subj = new ReplaySubject<T>(numReplays);
  observable.subscribe(subj.next.bind(subj));
  return subj;
}

export function urlEncodeArray(key: string, data: any[]): string {
  const addId = (httpParams: HttpParams, datum: number) => httpParams.append(`${key}[]`, String(datum));
  const params = reduce(addId, new HttpParams(), data);
  return params.toString();
}

export const Never = (message: string = 'Never') => { throw new Error(message); };

export function sameId<T extends { id: number }>(a: T | undefined, b: T | undefined): boolean {
  if (!a || !b) { return false; }
  return a.id === b.id;
}

export function sameIds<T extends { id: number }>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const id1 = map(get('id'), arr1);
  const id2 = map(get('id'), arr2);
  return compose(every(([a, b]) => a === b), zip(id1))(id2);
}

export function allSameIds(arrArr1: { id: number }[][], arrArr2: { id: number }[][]): boolean {
  if (arrArr1.length !== arrArr2.length) {
    return false;
  }

  const individualMatches = map(([a, b]) => sameIds(a, b), zip(arrArr1, arrArr2));
  return every(isEqual(true), individualMatches);
}

export type Constructor<T> = new (...args: any[]) => T;

export type MemoizedFunctionGeneric<T> = T & MemoizedFunction;

export const noCloseModalOptions: NgbModalOptions = {
  backdrop: 'static'
};


export const cartesianProduct = (...args) => args.reduce((a, b) => (
  flatten(a.map(x => b.map(y => x.concat([y]))))
), [[]]);

export const logRet = (func: (...args: any[]) => any, identifier?: string) => {
  const rv = func();
  if (identifier) {
    console.log(identifier);
  }
  console.log(rv);
  return rv;
};

export const logValues = (key: string, context: any, prefix = '') => {
  const original = context[key];
  context[key] = function() {
    const args = arguments;
    return logRet(() => {
      return original.bind(context)(...args);
    }, prefix + ': ' + key);
  };
};

export function assertTruthy<T>(item: T, errorMessage = ''): T {
  if (!item) {
    throw new Error(errorMessage);
  }

  return item;
}
