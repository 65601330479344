import { compose, filter, get, isEqual, last, overEvery, sortBy } from 'lodash/fp';
import { Review } from '../../reviews';
import { ReviewTypeTag, isApproved } from './review-type-tag';

export class GetApprovalDate {
  constructor(private readonly reviews: Review[]) {
  }

  get(): number | null {
    return this.pipeline()(this.reviews);
  }

  private pipeline(): (reviews: Review[]) => number | null {
    return compose(
      this.processApprovalReview.bind(this),
      this.getLastApprovalReview.bind(this),
      this.getApprovalReviews.bind(this)
    );
  }

  private getApprovalReviews(reviews: Review[]) {
    return filter(this.getApprovalReviewsFilterFunc(), reviews);
  }


  private getLastApprovalReview(approvalReviews: Review[]) {
    return last(sortBy(get('complete_at'), approvalReviews));
  }

  private processApprovalReview(approvalReview: Review | undefined) {
    return approvalReview ?
      approvalReview.complete_at :
      null;
  }

  private getApprovalReviewsFilterFunc() {
    return overEvery([
      this.isComplete(),
      this.hasCorrectResponse(),
      this.hasCorrectName()]);
  }

  private hasCorrectName() {
    return compose(
      isEqual(ReviewTypeTag.Approval),
      get('review_type.name'));
  }

  private hasCorrectResponse() {
    return isApproved;
  }

  private isComplete() {
    return get('complete_at');
  }
}
