import { ShouldWorkOn } from './should-work-on';
import { Observable, combineLatest } from 'rxjs';
import { ReviewCompletionManager } from 'src/app/core/models/review-completion-manager/review-completion-manager';
import { QuestionMetadata } from 'src/app/core/lib/models';
import { map } from 'rxjs/operators';

export class ReviewModeShouldWorkOn extends ShouldWorkOn {
  constructor(
    private readonly hasAccessToSection$: Observable<boolean>,
    private readonly reviewCompletionManager$: Observable<ReviewCompletionManager<QuestionMetadata>>) {
    super();
  }

  public should$(): Observable<boolean> {
    const helper = (hasAccessToSection: boolean, reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) =>
      hasAccessToSection && reviewCompletionManager.getTotalCompletion().asNumber() < 1;

    return combineLatest([
      this.hasAccessToSection$,
      this.reviewCompletionManager$,
    ]).pipe(map(([hasAccessToSection, completionResult]) => helper(hasAccessToSection, completionResult)));
  }
}
