import { flatten, get, maxBy } from 'lodash/fp';
import { SurveyUpdate } from './model';

export class DummyUpdate {
  constructor(private readonly userId: number,
    private readonly revisionId: number,
    private readonly data: any,
    private readonly cache: Map<string | number, SurveyUpdate[]>
  ) {
  }

  public get(): SurveyUpdate {
    return {
      id: this.getLastId() + 1,
      user_id: this.userId,
      revision_id: this.revisionId,
      timestamp: this.getLastTimestamp() + 1,
      data: this.data
    };
  }

  private getLastId(): number {
    const allSurveyUpdates = flatten(Array.from(this.cache.values()));
    return maxBy(get('id'), allSurveyUpdates) || 0;
  }

  private getLastTimestamp(): number {
    return maxBy(get('timestamp'), this.cache.get(String(this.revisionId))) || 0;
  }
}
