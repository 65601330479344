import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { assign } from 'lodash/fp';
import { combineLatest, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Revision } from 'src/app/core/revisions';
import { CreatingEditRevisionComponentParameters, EditRevisionComponentParameters, EdittingEditRevisionComponentParameters } from 'src/app/core/revisions/edit-revision-component-parameters';
import { noCloseModalOptions } from 'src/app/shared/utils';
import { EditRevisionComponent } from '../edit-revision/edit-revision.component';
import { ParameterizedRevisionDirective } from '../parameterized-revision.component';
import { RequestReviewComponent } from '../request-review/request-review.component';
import { State } from '../rx';

@Component({
  selector: 'app-revision-actions',
  templateUrl: './revision-actions.component.html',
  styleUrls: ['./revision-actions.component.css']
})
export class RevisionActionsComponent extends ParameterizedRevisionDirective {

  @Input() revision: Revision | undefined;
  @Output() onNewRevision = new EventEmitter();

  constructor(store: Store<State>,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly activeModal: NgbActiveModal
  ) {
    super(store);
  }

  public mayCreateNewRevision$(): Observable<boolean> {
    const helper = (noRevisions) => noRevisions || (this.revision && this.revision.active);

    return combineLatest(
      this.noRevisions$(),
    ).pipe(map(([noRevisions]) => helper(noRevisions)));
  }

  public viewSurvey() {
    this.activeModal.close();
    this.router.navigate(['/', 'survey', 'revision', this.revision.id]);
  }

  public requestReview() {
    const modalRef = this.modalService.open(RequestReviewComponent, assign(noCloseModalOptions, {
      size: 'lg',
    }));

    modalRef.componentInstance.revision = this.revision;
  }

  public async newRevision() {
    this.store.pipe(first()).subscribe(async state => {

      // Hold onto the old ids that existed before the action is dispatched
      const oldIds = <number[]>state.revisions.ids;

      const params = new CreatingEditRevisionComponentParameters(this.station, this.job, this.revision);

      try {
        await this.makeEditRevisionComponent(params);
      } catch (e) {
        // If the modal was dismissed, don't look for the newly created revision
        return;
      }

      let sub;

      sub = this.store.subscribe(state => {
        // Look for any new ids in the state that were not present before the action
        // was dispatched
        const newIds = (<number[]>state.revisions.ids)
          .filter(newId => !oldIds.includes(newId));

        // If there is a newId, assume it will give us the newly created revision
        const newId = newIds[0];

        if (newId) {
          this.onNewRevision.emit(newId);
          sub.unsubscribe();
        }
      });
    });
  }

  public editRevision() {
    const params = new EdittingEditRevisionComponentParameters(this.station, this.job, this.revision);
    this.makeEditRevisionComponent(params);
  }

  private makeEditRevisionComponent(params: EditRevisionComponentParameters) {
    const modalRef = this.modalService.open(
      EditRevisionComponent,
      assign(noCloseModalOptions, { size: 'lg' }));

    modalRef.componentInstance.params = params;

    return modalRef.result;
  }

  private noRevisions$(): Observable<boolean> {
    return this.revisions$.pipe(map(revision => revision.length === 0));
  }
}
