import { Job } from '..';
import { SimpleMockApiInterceptor } from '../../interceptors/simple-mock-api';

export class Get extends SimpleMockApiInterceptor<Job[]> {
  protected correctMethod: string = 'GET';
  protected correctExtension: string = '/apiv2/jobs'

  protected getBody(): Job[] {
    return [
      { id: 1, name: 'Foo', station_ids: [1, 2, 3, 4], user_id: 1 },
      { id: 2, name: 'Bar', station_ids: [5, 6, 7, 8, 9], user_id: 1 }
    ];
  }
}
