import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LoadEffect } from '../effects/load';
import { Region } from '../Region';
import { RegionsService } from './service';

export type RegionKindType = 'Region';
export const RegionKind: RegionKindType = 'Region';

export const regionAdapter: EntityAdapter<Region> = createEntityAdapter<Region>({
});

enum Types {
  LoadAll = '[Regions] Load All',
  AddAll = '[Regions] Add All',
  UpsertOne = '[Regions] Upsert One',
  RemoveOne = '[Regions] Remove One'
}

interface RegionAction extends Action {
  type: Types;
}

export class AddAll implements RegionAction {
  public readonly type = Types.AddAll;

  constructor(public readonly payload: { regions: Region[] }) {
  }
}

export class LoadAll implements RegionAction {
  public readonly type = Types.LoadAll;
}

export class UpsertOne implements RegionAction {
  public readonly type = Types.UpsertOne;

  constructor(public readonly payload: { region: Region }) {
  }
}

export class RemoveOne implements RegionAction {
  public readonly type = Types.RemoveOne;

  constructor(public readonly payload: { region: Region }) {
  }
}

export interface State extends EntityState<Region> {
}

const initial: State = regionAdapter.getInitialState({});

type Union = AddAll | LoadAll | UpsertOne | RemoveOne;

export function reducer(state = initial, action: Union): State {
  switch (action.type) {
    case Types.AddAll: {
      return regionAdapter.addMany(action.payload.regions, state);
    }
    case Types.UpsertOne: {
      return regionAdapter.upsertOne(action.payload.region, state);
    }
    case Types.RemoveOne: {
      return regionAdapter.removeOne(action.payload.region.id, state);
    }
    default: {
      return state;
    }
  }
}


@Injectable()
export class LoadAllEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly regionsService: RegionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAll];
  }

  protected handleResponse(regions: Region[]) {
    return new AddAll({ regions });
  }

  protected handleRequest(request: LoadAll) {
    return this.regionsService.get();
  }
}
