import { FromUpdates, PositionData, SetPositionData, SurveyI, Update } from '../lib/models';
import { TimestampSorter } from '../models/timestamp-sorter';
import { SurveyUpdate } from '../survey-update/model';

export class SurveyCreatorFromUpdates {
  constructor(private readonly fromUpdates: FromUpdates) {
  }

  public createSurvey(updates: Update[]): SurveyI {
    return this.fromUpdates({ updates });
  }
}

export class SurveyCreatorFromSurveyUpdates {

  private readonly surveyCreator: SurveyCreatorFromUpdates;

  constructor(fromUpdates: FromUpdates) {
    this.surveyCreator = new SurveyCreatorFromUpdates(fromUpdates);
  }

  public createSurvey(surveyUpdates: SurveyUpdate[]): SurveyI {
    const updates = this.createUpdates(surveyUpdates);
    return this.surveyCreator.createSurvey(updates);
  }

  private createUpdates(surveyUpdates: SurveyUpdate[]): Update[] {
    const sorted = this.sortOnTimestamp(surveyUpdates);
    const updates: Update[] = this.convertToUpdates(sorted);
    return updates;
  }

  private sortOnTimestamp(surveyUpdates: SurveyUpdate[]): SurveyUpdate[] {
    return (new TimestampSorter<SurveyUpdate>(surveyUpdates)).getSorted();
  }

  private convertToUpdates(surveyUpdates: SurveyUpdate[]): Update[] {
    return surveyUpdates.map(this.convertSurveyUpdateToUpdate.bind(this));
  }

  private convertSurveyUpdateToUpdate(surveyUpdate: SurveyUpdate): Update {
    const { data, timestamp, user_id } = surveyUpdate;
    return Object.assign({}, data, { timestamp, user_id });
  }
}

export class SurveyCreatorWithPositionData {

  private readonly surveyCreator: SurveyCreatorFromSurveyUpdates;

  constructor(fromUpdates: FromUpdates,
    private readonly setPositionData: SetPositionData
  ) {
    this.surveyCreator = new SurveyCreatorFromSurveyUpdates(fromUpdates);
  }

  public createSurvey(surveyUpdates: SurveyUpdate[], positionData: PositionData): SurveyI {
    const survey = this.surveyCreator.createSurvey(surveyUpdates);
    this.setPositionData({ survey: survey, positionData: positionData });
    return survey;
  }
}
