import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createSelector } from '@ngrx/store';
import { State as AppState } from 'src/app/reducers';
import { LoadEffect } from '../effects/load';
import { UserService } from '../services/user.service';
import { User } from '../User';
import { UserCopy } from './user-copy';
import { ApiTokenService } from '../services/api-token.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum UserRole {
  admin = 'admin',
  analyst = 'analyst',
  user = 'user',
}

export type UserKindType = 'User';
export const UserKind: UserKindType = 'User';

export interface UserOrganization {
  readonly user_id: number;
  readonly organization_id: number;
}

export interface UserRegion {
  readonly user_id: number;
  readonly region_id: number;
}

export interface UserStation {
  readonly user_id: number;
  readonly station_id: number;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
});

export enum Types {
  LoadLoggedIn = '[Users] Load Logged In',
  SetLoggedIn = '[Users] Set Logged In',
  LoadAll = '[Users] Load All',
  AddAll = '[Users] Add All',
  AddLoggedIn = '[Users] Add Logged In',
  LoadOne = '[Users] Load One',
  AddOne = '[Users] Add One',
  Create = '[Users] Create',
  Update = '[Users] Update',
  Delete = '[Users] Delete',
  RemoveOne = '[Users] Remove One',
  UpsertOne = '[Users] Upsert One',
  Logout = '[Users] Logout'
}

interface UserAction extends Action {
  type: Types;
}

export class LoadLoggedIn implements UserAction {
  type = Types.LoadLoggedIn;

  constructor(public readonly payload = { forceReload: false }) {
  }
}

export class Logout implements UserAction {
  type = Types.Logout;
}

export class LoadAll implements UserAction {
  type = Types.LoadAll;
}

export class AddAll implements UserAction {
  type = Types.AddAll;

  constructor(public readonly payload: {
    users: User[]
  }) {
  }
}

export class AddLoggedIn implements UserAction {
  type = Types.AddLoggedIn;

  constructor(public readonly payload: {
    user: User
  }) {
  }
}

export class SetLoggedIn implements UserAction {
  type = Types.SetLoggedIn;

  constructor(public readonly payload: {
    user: User
  }) {
  }
}

export class LoadOne implements UserAction {
  type = Types.LoadOne;

  constructor(public readonly payload: { id: number, forceReload?: boolean }) {
  }
}

export class AddOne implements UserAction {
  type = Types.AddOne;

  constructor(public readonly payload: { user: User }) {
  }
}

export class Create implements UserAction {
  type = Types.Create;

  constructor(public readonly payload: { copy: UserCopy }) {
  }
}

export class Update implements UserAction {
  type = Types.Update;

  constructor(public readonly payload: { copy: UserCopy, user: User }) {
  }
}

export class Delete implements UserAction {
  type = Types.Delete;

  constructor(public readonly payload: { user: User }) {
  }
}

export class RemoveOne implements UserAction {
  type = Types.RemoveOne;

  constructor(public readonly payload: { user: User }) {
  }
}

export class UpsertOne implements UserAction {
  type = Types.UpsertOne;

  constructor(public readonly payload: { user: User }) {
  }
}

export interface State extends EntityState<User> {
  loggedInUserId: number | null;
}

const initial: State = adapter.getInitialState({
  loggedInUserId: null
});

export function reducer(state = initial, action): State {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.addMany(action.payload.users, state);
    }
    case Types.AddLoggedIn: {
      return {
        ...adapter.upsertOne(action.payload.user, state),
        loggedInUserId: action.payload.user.id
      };
    }
    case Types.SetLoggedIn: {
      return {
        ...state,
        loggedInUserId: action.payload.user.id
      };
    }
    case Types.AddOne: {
      return adapter.upsertOne(action.payload.user, state);
    }
    case Types.RemoveOne: {
      return adapter.removeOne(action.payload.user.id, state);
    }
    case Types.UpsertOne: {
      return adapter.upsertOne(action.payload.user, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class LoadAllEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAll];
  }

  protected handleResponse(users: User[]) {
    return new AddAll({ users });
  }

  protected handleRequest() {
    return this.userService.get();
  }
}

@Injectable()
export class LoadLoggedInEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadLoggedIn];
  }

  protected handleResponse(user: User) {
    if (!user) {
      throw new Error('User was not loaded properly');
    }

    return new AddLoggedIn({ user });
  }

  protected handleRequest({ payload: { forceReload } }: LoadLoggedIn) {
    return this.userService.getLoggedIn(forceReload);
  }
}

@Injectable()
export class LoadOneEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadOne];
  }

  protected handleResponse(user: User) {
    return new AddOne({ user });
  }

  protected handleRequest({ payload: { id } }) {
    return this.userService.getOne(id);
  }
}

@Injectable()
export class CreateEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Create];
  }

  protected handleResponse(user: User) {
    return new AddOne({ user });
  }

  protected handleRequest({ payload: { copy } }) {
    return this.userService.create(copy);
  }
}


@Injectable()
export class UpdateEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Update];
  }

  protected handleResponse(user: User) {
    return new AddOne({ user });
  }

  protected handleRequest({ payload: { user, copy } }) {
    return this.userService.update(user, copy);
  }
}

@Injectable()
export class DeleteEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly userService: UserService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Delete];
  }

  protected handleResponse(user: User) {
    return new RemoveOne({ user });
  }

  protected handleRequest({ payload: { user } }) {
    return this.userService.delete(user);
  }
}

@Injectable()
export class LogoutEffect {
  @Effect({dispatch: false})
  observing$ = this.actions$
    .pipe(ofType(Types.Logout),
          map(this.doLogout.bind(this)));

  constructor(private readonly actions$: Actions,
              private readonly userService: UserService,
              private readonly apiTokenService: ApiTokenService
             ) {
  }

  private doLogout() {
    this.apiTokenService.logout();
    this.userService.clearCachedLoggedInUser();
  }
}

export const loggedInUserSelector = createSelector(
  (state: AppState) => state.users.entities,
  (state: AppState) => state.users.loggedInUserId,
  (users, loggedInUserId) => {
    return loggedInUserId ? users[loggedInUserId] : null;
  });
