import { Component, Input } from '@angular/core';
import { SurveyI } from 'src/app/core/lib/models';
import { AnalysisSelection } from 'src/app/core/models/analysis';
import { GraphData } from 'src/app/core/models/analysis/graph-data';
import { RankData } from 'src/app/core/models/analysis/rank-data';
import { Station } from 'src/app/core/stations';
import { stationSrc } from '../../utils';
import { ConstrainedReportGenerator } from 'src/app/core/models/reporting/constrained-report-generator';

@Component({
  selector: 'app-station-analysis',
  templateUrl: './station-analysis.component.html'
})
export class StationAnalysisComponent {
  @Input() private readonly station: Station;
  @Input() private readonly analysisSelection: AnalysisSelection | undefined;
  @Input() private readonly survey: SurveyI | undefined;
  @Input() private readonly reportGenerator: ConstrainedReportGenerator;

  public getSrc(): string {
    return stationSrc(this.station);
  }

  public getStationName(): string {
    return this.station.name;
  }

  public getGraphData(): GraphData {
    this.assertMayAnalyze();
    return this.analysisSelection.getGraphData(this.survey);
  }

  public getRankData(): RankData {
    this.assertMayAnalyze();
    return this.analysisSelection.getRankData(this.survey);
  }

  public mayAnalyze(): boolean {
    return this.analysisSelection &&
      this.survey &&
      this.analysisSelection.shouldShow(this.survey);
  }

  public mayGenerateReport(): boolean {
    return this.mayAnalyze() && !!this.reportGenerator;
  }

  public getReportGenerator(): ConstrainedReportGenerator {
    return this.reportGenerator;
  }

  private assertMayAnalyze() {
    if (!this.mayAnalyze()) {
      throw new Error('Can only analyze a Station if given both a SurveyI and an AnalysisSelection');
    }
  }
}
