import { QuestionI, SurveyI } from '../../lib/models';

export abstract class ReviewCompletionDataRepresentation<InternalRepresentation> {
  public abstract getInternalRepresentation(
    question: QuestionI, survey: SurveyI): InternalRepresentation;
  public abstract questionFromInternalRepresentation(
    internalRepresentation: InternalRepresentation, survey: SurveyI): QuestionI;
  public abstract areSameRepresentation(
    rep1: InternalRepresentation, rep2: InternalRepresentation): boolean;
}
