import { GraphData } from './graph-data';
import { InternalGraphOptions } from '../../lib/models';

export class LibGraphData extends GraphData {
  constructor(private readonly internalGraphOptions: InternalGraphOptions) {
    super();
  }

  public getChartType(): string {
    return this.internalGraphOptions.chartType;
  }

  public getDatasets() {
    return this.internalGraphOptions.datasets;
  }

  public getLabels(): string[] {
    return this.internalGraphOptions.labels;
  }

  public getOptions() {
    return this.internalGraphOptions.options;
  }
}
