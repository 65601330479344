import { Action, ActionReducerMap } from '@ngrx/store';
import * as Root from '../../reducers';
import * as Jobs from './jobs';
import * as SelectedRevision from './selected-revision';
import * as ViewSelections from './view-selections';
export * from './update-survey-state';

export interface SurveysState {
  jobs: Jobs.State;
  viewSelections: ViewSelections.State;
  selectedRevision: SelectedRevision.State;
  reviewMode: boolean;
  currentReviewId: number | undefined;
}

export interface State extends Root.State {
  surveys: SurveysState;
}

const SetReviewModeType = '[Survey] Set Review Mode';

export class SetReviewModeAction implements Action {
  type = SetReviewModeType;

  constructor(public readonly payload: { reviewMode: boolean }) {
  }
}

const SetCurrentReviewIdType = '[Survey] Set Current Review Id';

export class SetCurrentReviewIdAction implements Action {
  type = SetCurrentReviewIdType;

  constructor(public readonly payload: { currentReviewId: number }) {
  }
}

export function reviewModeReducer(state: boolean = false, action: SetReviewModeAction) {
  if (action.type === SetReviewModeType) {
    return action.payload.reviewMode;
  } else {
    return state;
  }
}

export function currentReviewIdReducer(state: number | undefined, action: SetCurrentReviewIdAction) {
  if (action.type === SetCurrentReviewIdType) {
    return action.payload.currentReviewId;
  } else {
    return state;
  }
}

export const reducers: ActionReducerMap<SurveysState, any> = {
  jobs: Jobs.reducer,
  viewSelections: ViewSelections.reducer,
  selectedRevision: SelectedRevision.reducer,
  reviewMode: reviewModeReducer,
  currentReviewId: currentReviewIdReducer,
};
