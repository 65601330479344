import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable, AsyncSubject, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LibService } from 'src/app/core/lib/service';
import * as ReviewTypes from 'src/app/core/review-types';
import { ReviewType } from 'src/app/core/review-types';
import { User } from 'src/app/core/User';
import { State } from '../rx';
import { Revision } from './../../core/revisions/index';
import { SectionSelector } from 'src/app/core/reviews/section-selector';
import { ReviewValidator } from 'src/app/core/reviews/review-validator';
import { CreateReview } from 'src/app/core/reviews';
import { UserRole } from 'src/app/core/users';
import { ReviewTypeTag } from 'src/app/core/models/reporting/review-type-tag';


@Component({
  selector: 'app-request-review',
  templateUrl: './request-review.component.html',
  styleUrls: ['./request-review.component.css']
})
export class RequestReviewComponent implements OnInit {

  @Input() public readonly revision: Revision;

  public purpose = '';
  public selectedUser: User;
  public selectedReviewType: ReviewType;

  public readonly users$: Observable<User[]> = this.makeUsers$();
  public readonly reviewTypes$: Observable<ReviewType[]> = this.makeReviewTypes$();
  public readonly selector$: Observable<SectionSelector> = this.makeSelector$();

  private readonly reviews$ = this.store.pipe(map(state => {
    if (this.revision) {
      return Object.values(state.reviews.entities)
        .filter(review => review.revision_id === this.revision.id);
    } else {
      return [];
    }
  }));

  public readonly availableReviewTypes$ = combineLatest([this.reviews$, this.reviewTypes$])
    .pipe(map(([reviews, reviewTypes]) => {
      return reviewTypes.filter(reviewType => {
        const previouslyRequestedClassification =
          reviews.find(review => review.review_type.name === ReviewTypeTag.Classification);

        switch (reviewType.name) {
          case (ReviewTypeTag.Classification): {
            // Requires no previously requested Classification review
            return !previouslyRequestedClassification;
          }

          case (ReviewTypeTag.Approval): {
            // The classification review must be completed for the approval review may
            // be requested.
            const previouslyFoundApprovalReview =
              reviews.find(r => r.review_type.name === ReviewTypeTag.Approval);

            return previouslyRequestedClassification &&
              previouslyRequestedClassification.response &&
              !previouslyFoundApprovalReview;
          }

          case (ReviewTypeTag.Review): {
            // The generic review type may have however many reviews requested
            return true;
          }

          default: {
            throw new Error(`${reviewType.name} does not have a case statement handler`);
          }
        }
      });
    }));

  private errorMessage: string | undefined;

  constructor(private readonly activeModal: NgbActiveModal,
              private readonly store: Store<State>,
              private readonly libService: LibService) {
  }

  public ngOnInit() {
    this.dispatchLoadReviewTypes();
  }

  public handleCancel() {
    this.activeModal.close();
  }

  public handleRequestSubmit() {
    this.makeValidator$().pipe(first()).subscribe(validator => {
      if (!validator.isValid()) {
        this.tempSetErrorMessage(validator.currentError());
        return;
      }

      this.dispatchCreate(validator);
      this.handleResult();
    });
  }

  public requiresSections() {
    if (!this.selectedReviewType) return false;

    const reviewTypeName = this.selectedReviewType &&
      this.selectedReviewType.name;

    return reviewTypeName !== 'Classification Review' &&
      reviewTypeName !== 'Approval Review';
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  private dispatchLoadReviewTypes() {
    this.store.dispatch(new ReviewTypes.LoadAll());
  }

  private makeUsers$(): Observable<User[]> {
    return this.store.pipe(
      map(state => Object.values(state.users.entities)),
      map(users => users.filter(user => user.role !== UserRole.user)));
  }

  private makeReviewTypes$(): Observable<ReviewType[]> {
    return this.store.pipe(map(state => Object.values(state.reviewTypes.entities)));
  }

  private makeSelector$(): AsyncSubject<SectionSelector> {
    const asyncSubject = new AsyncSubject<SectionSelector>();
    this.libService.get().subscribe(lib => {
      asyncSubject.next(new SectionSelector(lib.makeSurvey()));
      asyncSubject.complete();
    });
    return asyncSubject;
  }

  private makeValidator$(): Observable<ReviewValidator> {
    return this.selector$.pipe(map(selector => {
      return new ReviewValidator(
        this.selectedUser,
        this.selectedReviewType,
        this.purpose,
        selector,
        this.revision);
    }));
  }

  private tempSetErrorMessage(message: string) {
    this.errorMessage = message;
    this.removeErrorMessageIn(3000);
  }

  private removeErrorMessageIn(milliseconds: number) {
    setTimeout(() => {
      this.errorMessage = undefined;
    }, milliseconds);
  }

  private dispatchCreate(reviewValidator: ReviewValidator) {
    this.store.dispatch(new CreateReview({ reviewValidator }));
  }

  private handleResult() {
    this.activeModal.dismiss();
  }
}
