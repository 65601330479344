import { Organization } from '../../organizations/Organization';
import { LoginState } from './login-state';
import { GetLoginState } from './login-state-controller';
import { RequestAccessLoginState } from './request-access-login-state';
import { RequestAccessRequestMadeLoginState } from './request-access-request-made-login-state';

export class SelectOrganizationLoginState extends LoginState {
  constructor(getLoginState: GetLoginState,
    protected organization: Organization) {
    super(getLoginState);
  }

  previous() {
    return this.getLoginState(RequestAccessLoginState);
  }

  next() {
    return this.getLoginState(RequestAccessRequestMadeLoginState);
  }

  canMoveNext() {
    return !!this.organization;
  }

  getErrorMessage() {
    return 'Organization required';
  }
}
