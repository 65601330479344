import { memoize } from 'lodash';
import { Job } from '../jobs';
import { Revision } from '../revisions';
import { Station } from '../stations';
import { SurveyWrapper } from '../survey-wrapper';
import { SurveyI } from '../lib/models';

export abstract class FinalizedSurveys {
  public surveyFor(station: Station): SurveyI | undefined {
    const surveyWrapper = this.surveyWrapperFor(station);
    return surveyWrapper ?
      surveyWrapper.getSurvey() :
      undefined;
  }

  public abstract surveyWrapperFor(station: Station): SurveyWrapper | undefined;
  public abstract revisionFor(station: Station): Revision | undefined;
  public abstract addSurveyWrapper(revision: Revision, surveyWrapper: SurveyWrapper): void;

  // Returns all the revisions which may be returned from `this.revisionFor` for all possible `Station`s
  public abstract getAvailableRevisions(): Revision[];
}

export class FinalizedSurveysImpl extends FinalizedSurveys {
  private readonly surveyWrappers = new Map<number, SurveyWrapper>();

  constructor(
    private readonly revisions: Revision[],
    private readonly job: Job,
  ) {
    super();
  }

  public readonly surveyWrapperFor: (station: Station) => SurveyWrapper | undefined = memoize(function(station: Station) {
    const finalizedRevision = this.revisionFor(station);

    if (!finalizedRevision) {
      return undefined;
    }

    return this.surveyWrapperForRevision(finalizedRevision);
  });

  public getAvailableRevisions(): Revision[] {
    return this.revisions.filter(
      revision =>
        this.isRevisionForGivenJob(revision) &&
        this.isRevisionFinalized(revision));
  }

  public revisionFor(station: Station): Revision | undefined {
    return this.revisions.find(this.isRevisionFor(station));
  }

  public addSurveyWrapper(revision: Revision, surveyWrapper: SurveyWrapper) {
    this.surveyWrappers.set(revision.id, surveyWrapper);
    this.clearCache();
  }

  private surveyWrapperForRevision(revision: Revision): SurveyWrapper | undefined {
    return this.surveyWrappers.get(revision.id);
  }

  private isRevisionFor(station: Station): (revision: Revision) => boolean {
    return (revision: Revision) =>
      this.isRevisionForGivenJob(revision) &&
      this.revisionBelongsToStation(revision, station) &&
      this.isRevisionFinalized(revision);
  }


  private isRevisionForGivenJob(revision: Revision): boolean {
    return revision.job_id === this.job.id;
  }

  private revisionBelongsToStation(revision: Revision, station: Station): boolean {
    return station.id === revision.station_id;
  }

  private isRevisionFinalized(revision: Revision): boolean {
    return revision.final;
  }

  private clearCache() {
    // Must be indexed on string to maintain the correct type of
    // the memoized function
    this.surveyWrapperFor['cache'].clear();
  }
}
