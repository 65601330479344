import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ETag } from './e-tag';

class ReMakeRequestError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, ReMakeRequestError.prototype);
  }
}

export type GetImpl = <T>(
  url: string,
  { headers, observe }: { headers: HttpHeaders, observe: 'response' }
) => Observable<T>;

export type CacheableRequestOptions =
  { alwaysExpectETag?: boolean, onErrorTryPreviouslyStored?: boolean };

export abstract class CacheableRequest {

  private static readonly NOT_MODIFIED_STATUS = 304;
  private static readonly IF_NONE_MATCH_HEADER = 'If-None-Match';
  private static readonly E_TAG_HEADER = 'ETag';

  private readonly alwaysExpectETag: boolean = true;
  private readonly onErrorTryPreviouslyStored: boolean = false;

  protected abstract getImpl: GetImpl;
  protected abstract storeETag(url: string, eTag: ETag): void;
  protected abstract removeETag(url: string): void;
  protected abstract storeResponse<T>(url: string, body: T): void;
  protected abstract getETag(url: string): ETag;
  protected abstract getCachedResponse<T>(url: string): T;
  protected abstract hasCachedResponse(url: string): boolean;

  constructor(options: CacheableRequestOptions) {
    Object.entries(options).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  get<T>(url: string, headers: HttpHeaders = new HttpHeaders()): Observable<T> {
    return this.getImpl<T>(url, {
      headers: this.addHeaders(url, headers),
      observe: 'response'
    }).pipe(
      map(this.processResponse.bind(this)),
      catchError(this.processError.bind(this)(url)),
      catchError(this.processSecondaryError.bind(this)(url, headers)),
      catchError(this.processTertiaryError.bind(this)(url))
    );
  }

  private addHeaders(url: string, headers: HttpHeaders): HttpHeaders {
    const maybeEtag = this.getETag(url);

    if (maybeEtag) {
      headers = headers.append(CacheableRequest.IF_NONE_MATCH_HEADER, maybeEtag);
    }

    return headers;
  }

  private processResponse<T>(response: HttpResponse<T>): T {
    this.storeETag(response.url, this.extractETag(response.headers));
    this.storeResponse<T>(response.url, response.body);
    return response.body;
  }

  private processError<T>(url: string): (error: HttpErrorResponse) => Observable<T> {
    return (error: HttpErrorResponse) => {
      if (this.shouldUseCachedResponse(error, url)) {
        return of(this.getCachedResponse<T>(url));
      } else if (this.shouldReMakeRequest(error, url)) {
        return throwError(new ReMakeRequestError());
      } else {
        return throwError(error);
      }
    };
  }

  private processSecondaryError<T>(url: string, headers: HttpHeaders): (error: Error) => Observable<T> {
    return (error: Error) => {
      if (error instanceof ReMakeRequestError) {
        this.removeETag(url);
        return this.get(url, headers);
      } else {
        return throwError(error);
      }
    };
  }

  private processTertiaryError<T>(url: string): (error: Error) => Observable<T> {
    return (error: Error) => {
      console.warn(error);
      if (this.onErrorTryPreviouslyStored && this.hasCachedResponse(url)) {
        return of(this.getCachedResponse(url));
      } else {
        return throwError(error);
      }
    };
  }

  private extractETag(headers: HttpHeaders): ETag {
    const eTag = headers.get(CacheableRequest.E_TAG_HEADER);

    if (this.alwaysExpectETag && !eTag) {
      throw new Error('ETag was not present in response header');
    }

    return eTag;
  }

  private shouldUseCachedResponse(error: HttpErrorResponse, url: string) {
    return this.indicatesNotModified(error) && this.hasCachedResponse(url);
  }

  private shouldReMakeRequest(error: HttpErrorResponse, url: string) {
    return this.indicatesNotModified(error) && !this.hasCachedResponse(url);
  }

  private indicatesNotModified(error: HttpErrorResponse): boolean {
    return error.status === CacheableRequest.NOT_MODIFIED_STATUS;
  }
}
