import { SurveyWrapper, SetSurveyCache } from '.';
import { Lib, SurveyI, AddAdditionalInformation, RemoveAdditionalInformation } from '../lib/models';
import { Review } from '../reviews';
import { Revision } from '../revisions';

export class ReviewSurveyWrapper extends SurveyWrapper {
  public addAdditionalInformation: AddAdditionalInformation;
  public removeAdditionalInformation: RemoveAdditionalInformation;

  constructor(lib: Lib,
              survey: SurveyI,
              setSurveyCache: SetSurveyCache,
              private readonly review: Review
  ) {
    super(lib, survey, review.revision_id, setSurveyCache);
  }

  public makeSelection(data: any): void {
    // Do nothing
  }

  public belongsToRevision(revision: Revision): boolean {
    return this.review.revision_id === revision.id;
  }
}
