class Manual {
  constructor(private readonly topLevel: ManualNode) {
  }

  private current: ManualNode;
  private readonly nodes: ManualNode[] = [];

  public addNode(node: ManualNode) {
    this.nodes.push(node);
  }

  public visit(node: ManualNode) {
    this.current = node;
  }

  public getCurrent(): ManualNode {
    return this.current ?
      this.current :
      this.topLevel;
  }

  public getNext(): ManualNode[] {
    const current = this.getCurrent();
    return this.nodes.filter(node => node.prior === current);
  }

  public back() {
    this.current = this.getCurrent().prior;
  }
}

interface ManualNode {
  readonly title: string;
  readonly text?: ManualText;
  readonly prior?: ManualNode;
}

const TopLevel = {
  title: 'Manual (In Progress)'
};

// For now
type ManualText = string;

export const TheManual = new Manual(TopLevel);

export const ScenarioCodes = {
  prior: TopLevel,
  title: 'Scenario Codes'
};

TheManual.addNode(ScenarioCodes);

class ScenarioCodeSnippet implements ManualNode {
  public readonly prior = ScenarioCodes;
  public readonly text: ManualText;
  public readonly title: string;

  constructor(code: string, scenario: string) {
    this.title = code;
    this.text = `code: ${code} \n scenario: ${scenario}`;
  }
}

const scenarioCodeSnippets = [
  ["T1", "Theft of copper located in the yard that is not installed"],
  ["T2", "Theft of copper located in the Target Area #3 Facility"],
  ["T3",	"Theft of single line drawings from the Control Facility"],
  ["T4",	"Theft of PMA vehicles or equipment located in the yard"],
  ["T5", "Theft of PMA vehicles or equipment located in the Target Area #3 Facility"],

  ["S1" , "Standoff firearm attack on transformers or other critical equipment located in the yard"],
  ["S2" , "Arson attack on equipment or facilities located in the yard"],
  ["S3" , "VBIED attack on equipment or facilities located in the yard"],
  ["S4" , "IED attack on equipment, or facilities in the yard"],
  ["S5" , "Physical destruction of equipment located in the yard, e.g., smash with sledge hammer, vehicle ramming, cutting, etc."],
  ["S6" , "Physical destruction of Control Facility contents, e.g., smash with a sledge hammer"] ,
  ["S7" , "Switches in the yard are manually opened or closed"] ,
  ["S8" , "Equipment is switched from inside the Control Facility"] ,
  ["S9" , "Theft of copper in the yard that is installed, e.g., ground straps, potentially causing transmission issues"] ,
  ["S10", "Small arms ballistic attack on equipment in the yard, from within the yard"] ,

  ["P1", "Physical assault on personnel outside of the Control Facility"] ,
  ["P2",  "Physical assault on personnel within the Control Facility"] ,
  ["P3",  "Active shooter attack with a firearm on personnel in the yard from within the yard"] ,
  ["P4",  "Active shooter attack with a firearm on personnel within the Control Facility"] ,
  ["P5",  "Standoff active shooter attack with a firearm (from outside the yard) on personnel in the yard"] ,
  ["P6",  "VBIED attack on personnel in the yard"]
]
  .map(([code, scenario]) => new ScenarioCodeSnippet(code, scenario))
  .forEach(TheManual.addNode.bind(TheManual));

const Admin = {
  title: 'Administrator',
  prior: TopLevel
};

TheManual.addNode(Admin);

const RevisionProcess = {
  title: 'Revision Process',
  prior: Admin
};

TheManual.addNode(RevisionProcess);

[
  ['New Revision', 'Prevents any further changes on the current revision. Creates a new Revision in the same state as the current Revision with optionally changed permissions. This action is not reversible.'],
  ['Finalize', 'Prevents any further changes on the current revision. Once finalized, the Revision is considered complete and will appear in the dashboard. This action is reversible.']
]
  .map(([title, text]) => {
    return {
      title,
      text,
      prior: RevisionProcess
    }
  })
  .forEach(TheManual.addNode.bind(TheManual));
