import { Observable, forkJoin } from 'rxjs';
import { Toggler } from './Toggler';
import { first, map } from 'rxjs/operators';
import { LocationPermissionsDiff } from 'src/app/core/users/location-diff-actions';

export class LocationPermissionDiffer<T extends { id: number }> {
  public static Diff<T extends { id: number }>(
    availableModels$: Observable<T[]>,
    toggler: Toggler<T>,
    alreadyPermissioned: number[]
  ): Observable<LocationPermissionsDiff<T>> {

    const differ = new LocationPermissionDiffer(availableModels$, toggler, alreadyPermissioned);

    return forkJoin([differ.toCreate(), differ.toDelete()]).pipe(
      map(([toCreate, toDelete]) => ({ toCreate, toDelete }))
    );

  }

  private constructor(private readonly availableModels$: Observable<T[]>,
    private readonly toggler: Toggler<T>,
    private readonly alreadyPermissioned: number[]) {
  }

  private toCreate(): Observable<T[]> {
    return this.availableModels$.pipe(
      map(models => models.filter(this.shouldCreate.bind(this))),
      first());
  }

  private toDelete(): Observable<T[]> {
    return this.filterAvailableModels(this.shouldDelete.bind(this));
  }

  private filterAvailableModels(func: (T) => boolean): Observable<T[]> {
    return this.availableModels$.pipe(
      map(models => models.filter(func)),
      first());
  }

  private shouldDelete(model: T): boolean {
    return !this.toggler.isSelected(model) && this.alreadyPermissioned.includes(model.id);
  }

  private shouldCreate(model: T): boolean {
    return this.toggler.isSelected(model) && !this.alreadyPermissioned.includes(model.id);
  }
}
