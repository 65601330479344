import { ApplicationRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { LatLngBounds, MapOptions } from 'leaflet';
import { AnalysisSelection } from 'src/app/core/models/analysis';
import { FinalizedSurveys } from 'src/app/core/models/finalized-surveys';
import { LocationHierarchy } from 'src/app/core/models/location-hierarchy';
import { RankDataForSurveyImpl } from 'src/app/core/models/rank-data-for-survey';
import { LocationI } from 'src/app/core/locations';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnChanges {
  private map: L.Map | undefined;

  constructor(private readonly ref: ApplicationRef) {
  }

  @Input() private readonly locationHierarchy: LocationHierarchy;
  @Input() private readonly finalizedSurveys: FinalizedSurveys;
  @Input() private readonly analysisSelection: AnalysisSelection;

  // Input and is only used through the ngOnChanges method
  @Input() private readonly zoomToLocation: LocationI;

  private hasRegistered = new Map<AnalysisSelection, boolean>();

  public layers = [];

  ngOnInit() {
    this.setupRankData();

    this.locationHierarchy.onUpdate(this.updateLayers.bind(this));

    if (this.analysisSelection) {
      this.analysisSelection.onUpdate(() => {
        this.trySetRankData();
        this.updateLayers.bind(this);
      });
    }

    this.updateLayers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setupRankData();

    if (changes.zoomToLocation) {
      const { previousValue, currentValue } = changes.zoomToLocation;
      if (previousValue !== currentValue && currentValue && this.map) {
        this.map.setView([currentValue.latitude, currentValue.longitude], 100);
      }
    }

    this.locationHierarchy.onUpdate(this.updateLayers.bind(this));

    if (this.analysisSelection) {
      this.analysisSelection.onUpdate(() => {
        this.trySetRankData();
        this.updateLayers.bind(this);
      });
    }

    this.updateLayers();
  }

  private async updateLayers() {
    const tick = () => {
      try {
        this.ref.tick();
      } catch (e) {
        if (e.message !== "ApplicationRef.tick is called recursively") throw e;
      }
    };

    this.layers = this.locationHierarchy.getLayers(this.analysisSelection);
    tick();
    const overlay = await this.locationHierarchy.getOverlay();
    if (overlay) {
      this.layers.push(overlay);
      tick();
    }
  }

  private setupRankData() {
    this.trySetRankData();
    this.tryRegisterUpdate();
  }

  private trySetRankData() {
    if (this.finalizedSurveys && this.analysisSelection) {
      const rankDataForSurvey = new RankDataForSurveyImpl(
        this.finalizedSurveys, this.analysisSelection);
      this.locationHierarchy.setRankDataForSurvey(rankDataForSurvey);
    }
  }

  private tryRegisterUpdate() {
    if (this.analysisSelection && !this.hasRegistered.get(this.analysisSelection)) {
      this.hasRegistered.set(this.analysisSelection, true);

      this.analysisSelection.onUpdate(() => {
        this.trySetRankData.bind(this);
      });
    }
  }

  public getMapOptions(): MapOptions {
    return this.locationHierarchy.getMapOptions();
  }

  public getBounds(): LatLngBounds | undefined {
    return this.locationHierarchy.getBounds();
  }

  // Needs to detect changes for the whole application
  // in case `this.locationHierarchy` is used by other
  // components
  public onMapReady(map: L.Map) {
    map.on('click', () => this.ref.tick());
    this.map = map;
  }
}
