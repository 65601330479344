import { NotificationsService } from 'src/app/core/services/notifications.service';
import { first } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { Revision } from 'src/app/core/revisions';

// Not great, but works
// globally keep track of which revisions
// notifications have been displayed for such that
// a revision will only have a notification for it
// displayed one time per site load
const DisplayedNotificationsFor = {};

export class DashboardPresenceNotificationManager {
  constructor(
    private readonly revision$: Observable<Revision>,
    private readonly getTotalCompletion$: () => Observable<CompletionResult>,
    private readonly notifications: NotificationsService) {
  }

  public updateNotification() {
    combineLatest([
      this.revision$,
      this.getTotalCompletion$()
    ])
      .pipe(first())

      .subscribe(([revision, totalCompletion]) => {

        // Display notifications for numerically complete revisions
        // which have not been finalized and a notification has not
        // been previously displayed on this site load

        if (totalCompletion.asNumber() === 1 &&
            !revision.final &&
            !DisplayedNotificationsFor[revision.id]) {

          DisplayedNotificationsFor[revision.id] = true;

          this.notifications.addNotification({
            message: 'Revision will not be displayed in dashboard until finalized',
          });

        }

      });
  }
}
