import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { memoize } from 'lodash/fp'
import { Subject } from 'rxjs'
import { State } from 'src/app/reducers'
import { environment } from 'src/environments/environment'

import { clickTimeout } from '../models/timeouts/click'
import { keydownTimeout } from '../models/timeouts/keydown'
import { Logout } from '../users'

interface Hook {
  timeout: number;
  callback: (...args) => any;
}

// All timeouts are specified in ms
@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  private static CHECK_INTERVAL = 5000;
  private static SINCE_LAST_TIMEOUT_KEY = 'sinceLastTimeout';

  private readonly hooks: Hook[] = [];

  private get lastEvent() {
    const stored = localStorage.getItem(TimeoutService.SINCE_LAST_TIMEOUT_KEY);
    if (stored) {
      try {
        return Number(JSON.parse(stored));
      } catch (e) {
        return 0;
      }
    } else {
      return 0;
    }
  }

  private set lastEvent(n: number) {
    localStorage.setItem(TimeoutService.SINCE_LAST_TIMEOUT_KEY, JSON.stringify(n));
  }

  constructor(private readonly store: Store<State>) {
    this.setUpHooks();
  }

  public run = memoize(() => {
    this.startRunningChecks();
  });

  public getTimeElapsed() {
    return Date.now() - this.lastEvent;
  }

  private setUpHooks() {
    this.addResetTimeoutHook(clickTimeout());
    this.addResetTimeoutHook(keydownTimeout());
    if (environment.logoutTimeout) {
      this.addOnTimeoutHook(
        environment.logoutTimeout,
        this.doLogout.bind(this));
    }
  }

  private addOnTimeoutHook(timeout, callback) {
    this.hooks.push({ timeout, callback });
  }

  private addResetTimeoutHook(subject: Subject<any>) {
    const resetTimeout = () => this.lastEvent = Date.now();
    subject.subscribe(resetTimeout);
  }

  private startRunningChecks() {
    const checkInterval = TimeoutService.CHECK_INTERVAL;

    const helper = () => {
      this.hooks.forEach(({ timeout, callback }) => {
        if (timeout <= this.getTimeElapsed()) {
          callback();
        }
      });
    };

    helper();

    setInterval(() => {
      helper();
    }, checkInterval);
  }

  private doLogout() {
    this.store.dispatch(new Logout());
  }
}
