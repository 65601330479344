import { Component, OnInit } from '@angular/core';
import { createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/core/User';
import * as Users from 'src/app/core/users';
import { loggedInUserSelector, UserKind } from 'src/app/core/users';
import { UserCopy } from 'src/app/core/users/user-copy';
import { State } from 'src/app/reducers';
import { userSrc, noCloseModalOptions } from '../../shared/utils';
import { EditUserComponent } from '../edit-user/edit-user.component';
import { ModelsComponent } from '../models/models.component';
import { EdittingEditUserComponentParams } from '../edit-user/edit-user-component-params/editting-edit-user-component-params';

const nonLoggedInUsersSelector = createSelector(
  loggedInUserSelector,
  (state: State) => Object.values(state.users.entities),
  (loggedInUser, users) => users.filter(user => user !== loggedInUser)
);

@Component({
  selector: 'app-users',
  templateUrl: '../models/models.component.html',
})
export class UsersComponent extends ModelsComponent<User> implements OnInit {
  public hasLocationPermissions = true;
  public readonly src = userSrc;

  protected kind: string = UserKind;
  protected copyConstructor = UserCopy;

  ngOnInit(): void {
    this.dispatchLoadUsers();
  }

  public handleRequestModelEdit(user: User) {
    const modalRef = this.modalService.open(EditUserComponent, noCloseModalOptions);
    modalRef.componentInstance.params = new EdittingEditUserComponentParams(user);
  }

  protected makeModels$(): Observable<User[]> {
    return this.store.pipe(select(nonLoggedInUsersSelector));
  }

  private dispatchLoadUsers() {
    this.store.dispatch(new Users.LoadAll());
  }
}
