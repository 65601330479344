import { forkJoin, Observable } from 'rxjs';
import { Update } from '../lib/models';
import { Revision } from '../revisions';
import { ApiV2Service } from '../services/api-v2.service';
import { SurveyUpdate } from './model';
import { UpdatesQueue } from './updates-queue';

export class UpdatesQueueImpl extends UpdatesQueue {
  constructor(private readonly api: ApiV2Service,
    private readonly apiExtension: string
  ) {
    super();
  }

  protected onPop(revision: Revision, updates: Update[]): Observable<SurveyUpdate[]> {
    return forkJoin(updates.map(this.createOne(revision)));
  }

  private createOne(revision: Revision): (update: Update) => Observable<SurveyUpdate> {
    return (update: Update) => {
      console.log('Creating: ' + JSON.stringify(update));
      const createSurveyUpdateArgs = {
        revision: {
          job_id: revision.job_id,
          station_id: revision.station_id,
        },
        survey_update: {
          data: update
        }
      };

      return this.api.post(this.apiExtension, createSurveyUpdateArgs);
    };
  }
}
