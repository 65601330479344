import { CheckboxAnalysisSelectionParameter } from '..';
import { LibAnalysisParameter } from 'src/app/core/lib/models';

export class LibCheckboxAnalyisSelectionParameter extends CheckboxAnalysisSelectionParameter {
  constructor(private readonly libAnalysisParameter: LibAnalysisParameter) {
    super();
  }

  public isSelected(): boolean {
    if (typeof this.libAnalysisParameter.value === 'boolean') {
      return this.libAnalysisParameter.value;
    } else {
      throw new Error(`Incorrectly configured LibAnalysisParameter for type \'checkbox\': ${JSON.stringify(this.libAnalysisParameter)}`);
    }
  }

  public getStyle(): Object {
    return this.libAnalysisParameter.style;
  }

  public getName(): string {
    return this.libAnalysisParameter.label;
  }
}
