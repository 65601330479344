import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Review } from '.';
import { Revision } from '../revisions';
import { ApiV2Service } from '../services/api-v2.service';
import { ReviewValidator } from './review-validator';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  private readonly apiExtension = 'reviews';

  constructor(private readonly api: ApiV2Service,
  ) {
  }

  public get(): Observable<Review[]> {
    return this.api.get(this.apiExtension);
  }

  public read(revision: Revision): Observable<Review[]> {
    return this.api.get(
      `${this.apiExtension}?revision_id=${revision.id}`
    );
  }

  public create(reviewValidator: ReviewValidator): Observable<Review> {
    return this.api.post(
      this.apiExtension,
      reviewValidator.getCreateReviewArgs()
    );
  }

  public markAsComplete(review: Review, response: string): Observable<Review> {
    const params = {
      review: {
        id: review.id,
        response: response
      }
    };

    return this.api.put(this.makeMarkAsCompleteExtension(), params);
  }

  private makeMarkAsCompleteExtension(): string {
    return `${this.apiExtension}/mark_as_complete`;
  }
}
