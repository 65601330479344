import { Injectable } from '@angular/core';

export interface Notification {
  message: string;
  type?: string;
  spinner?: boolean;
}

export type AddNotification = (notification: Notification, timeout?: number | Promise<any>) => void;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notifications = [];

  public readonly addNotification: AddNotification = (notification: Notification, timeout: number | Promise<any> = 3000) => {
    if (this.notifications.length > 5) {
      return;
    }

    this.notifications.push(notification);

    const removeNotification = () => {
      const filtered = this.notifications.filter(
        notification_ => notification_ !== notification);
      this.notifications.splice(0, this.notifications.length, ...filtered);
    };

    if (typeof timeout === 'number') {
      setTimeout(removeNotification, timeout);
    } else {
      timeout.then(removeNotification);
    }

  }
}
