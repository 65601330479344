import { Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Update } from '../lib/models';
import { Revision } from '../revisions';
import { SurveyUpdate } from './model';

// https://stackoverflow.com/questions/46538484/rxjs-implementing-a-queue
export abstract class UpdatesQueue {
  private readonly queue = new Subject();

  constructor() {
    this.queue.pipe(this.queueTo()).subscribe();
  }

  add(revision: Revision, updates: Update[]) {
    this.queue.next([revision, updates]);
  }

  private queueTo() {
    return mergeMap(([revision, updates]) => {
      return this.onPop(revision, updates);
    }, null, 1);
  }

  protected abstract onPop(revision: Revision, updates: Update[]): Observable<SurveyUpdate[]>;
}
