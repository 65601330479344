import { GraphData } from './graph-data';
import { RankData } from './rank-data';
import { SurveyI } from '../../lib/models';
import { ReplaySubject } from 'rxjs';

export enum AnalysisSelectionParameterInputType {
  Checkbox = 'checkbox',
  Select = 'select'
}

export abstract class AnalysisSelectionParameter {
  public abstract getInputType(): AnalysisSelectionParameterInputType;
  public abstract getStyle(): Object;
  public abstract getName(): string;
}

export abstract class CheckboxAnalysisSelectionParameter extends AnalysisSelectionParameter {
  public getInputType(): AnalysisSelectionParameterInputType {
    return AnalysisSelectionParameterInputType.Checkbox;
  }

  public abstract isSelected(): boolean;
}

export abstract class SelectAnalysisSelectionParameter extends AnalysisSelectionParameter {
  public getInputType(): AnalysisSelectionParameterInputType {
    return AnalysisSelectionParameterInputType.Select;
  }

  public abstract getOptions(): string[];

  public abstract getValue(): string;
}

export abstract class AnalysisSelectionParameters {
  public abstract asRows(): AnalysisSelectionRow[];

  public abstract getParameters(): AnalysisSelectionParameter[];
}

export abstract class AnalysisSelectionRow extends AnalysisSelectionParameters {
  public asRows(): AnalysisSelectionRow[] {
    throw new Error('Already looking at analysis selection as a row');
  }

  public abstract getParameters(): AnalysisSelectionParameter[];
}

export abstract class AnalysisSelection {
  private readonly updateOccured = new ReplaySubject<any>(1);

  public abstract getParameters(): AnalysisSelectionParameters;
  public abstract getGraphData(survey: SurveyI): GraphData;
  public abstract getRankData(survey: SurveyI): RankData;

  public abstract updateParameters
    (parameter: AnalysisSelectionParameter,
     value: string | boolean): void;

  public abstract shouldShow(survey: SurveyI): boolean;

  public onUpdate(func: (...args: any[]) => any): void {
    this.updateOccured.subscribe(func);
  }

  protected notifyUpdateOccurred() {
    this.updateOccured.next();
  }
}
