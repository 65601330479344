import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegionKind } from '.';
import { Region } from "../Region";
import { LocationCopy, OrganizationParents } from '../locations/location-copy';
import { Organization, OrganizationKind } from '../organizations/Organization';

export class RegionCopy extends LocationCopy<Region> {

  public organization_id: number;
  public readonly hasParent: boolean = true;


  constructor(args) {
    super(args);
    this.validators.push(this.validator(() => !!this.organization_id, 'Organization is not valid'));
  }

  protected copyOverFields(region: Region) {
    super.copyOverFields(region);
    this.organization_id = region.organization_id;
  }

  protected createJSON() {
    return Object.assign(
      super.createJSON(),
      {
        organization_id: this.organization_id
      },
    );
  }

  public setParent(model: Region | Organization) {
    if (model.kind === RegionKind) {
      throw new Error('The parent of a region must be an organiztion');
    } else if (model.kind === OrganizationKind) {
      this.organization_id = model.id;
    }
  }

  public getAvailableParents(
    organizations$: Observable<Organization[]>,
    regions$: Observable<Region[]>
  ): OrganizationParents {
    return new OrganizationParents(organizations$);
  }

  public getParent(organizations$: Observable<Organization[]>, regions$: Observable<Region[]>
  ): Observable<Organization | null> {
    return organizations$.pipe(
      map(this.getParentHelper.bind(this)));
  }

  private getParentHelper(organizations: Organization[]): Organization | null {
    return organizations.find(organization => organization.id === this.organization_id) || null;
  }
}
