import { Station } from '../../stations';
import { ReportOrganization, ReportStation } from '.';
import { Organization } from '../../organizations/Organization';
import { Region } from '../../Region';
import { organizationSrc, stationSrc } from 'src/app/shared/utils';

export abstract class ReportLocationsCreator {
  abstract createReportOrganization(station: Station): ReportOrganization;
  abstract createReportStation(station: Station): ReportStation;
}

export class ReportLocationsCreatorImpl extends ReportLocationsCreator {

  constructor(private readonly regions: Region[],
    private readonly organizations: Organization[]) {
    super();
  }

  createReportOrganization(station: Station): ReportOrganization {
    return this.convertOrganizationToReportOrganization(this.getOrganization(station));
  }

  createReportStation(station: Station): ReportStation {
    return Object.assign({}, station, { img: stationSrc(station) });
  }

  private convertOrganizationToReportOrganization(organization: Organization): ReportOrganization {
    return Object.assign({}, organization, { img: organizationSrc(organization) });
  }

  private getOrganization(station: Station): Organization {
    const { region_id, organization_id } = station;

    let organization: Organization;

    if (region_id) {
      const foundRegion = this.regions.find(region => region.id === region_id);
      if (foundRegion) {
        organization = this.organizations.find(organization_ => organization_.id === foundRegion.organization_id);
      }
    } else {
      organization = this.organizations.find(
        organization_ => organization_.id === organization_id);
    }

    if (!organization) {
      throw new Error(`Could not find organization for: ${JSON.stringify(station)}`);
    }

    return organization;
  }

}
