import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonadicAction } from 'src/app/core/effects/monadic-action';
import { LocationI } from 'src/app/core/locations';
import { CreateModelAction, UpdateModelAction } from 'src/app/core/models';
import * as Organizations from 'src/app/core/organizations';
import { Organization } from 'src/app/core/organizations/Organization';
import { Region } from 'src/app/core/Region';
import * as Regions from 'src/app/core/regions';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoadLoggedIn } from 'src/app/core/users';
import { State } from 'src/app/reducers';
import { LocationCopy, LocationParents } from '../../core/locations/location-copy';
import { EditLocationText } from './edit-location-text';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent implements OnInit {

  @Input() public readonly editLocationText: EditLocationText;
  @Input() public readonly copy: LocationCopy<LocationI>;

  public parents: LocationParents | undefined;
  public errorMessage: string;

  private readonly organizations$: Observable<Organization[]> = this.makeOrganizations$();
  private readonly regions$: Observable<Region[]> = this.makeRegions$();
  private parent_: Organization | Region | undefined;

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>,
    private readonly notificationsService: NotificationsService
  ) {
    this.dispatchLoads();
  }

  ngOnInit() {
    this.validateInput();
    this.setupParents();
  }

  get parent(): Region | Organization {
    return this.parent_;
  }

  set parent(parent: Region | Organization) {
    this.copy.setParent(parent);
    this.parent_ = parent;
  }

  public handleSaveRequest() {
    if (!this.copy.isValid()) {
      this.temporarilyDisplayError(this.copy.currentError());
      return;
    }

    this.save();
    this.handleResults();
  }

  public handleCancel() {
    this.activeModal.close();
  }

  private validateInput() {
    if (!this.editLocationText) {
      throw new Error('Input editLocationText required');
    }

    if (!this.copy) {
      throw new Error('Input copy is required');
    }
  }

  private setupParents() {
    if (this.copy.hasParent) {
      this.parents = this.copy.getAvailableParents(this.organizations$, this.regions$);
      this.trySetInitialParent();
    }
  }

  private trySetInitialParent() {
    this.copy.getParent(this.organizations$, this.regions$).subscribe(parent => {
      // Only set the parent if a model was received and the current parent has
      // not been set yet
      if (parent && !this.parent) {
        this.parent = parent;
      }
    });
  }

  private makeOrganizations$(): Observable<Organization[]> {
    return this.store.pipe(
      map(state => Object.values(state.organizations.entities)));
  }

  private makeRegions$(): Observable<Region[]> {
    return this.store.pipe(
      map(state => Object.values(state.regions.entities)));
  }

  private dispatchLoads() {
    this.store.dispatch(new Regions.LoadAll());
    this.store.dispatch(new Organizations.LoadAll());
  }

  private temporarilyDisplayError(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.removeErrorIn(3000);
  }

  private removeErrorIn(milliseconds: number) {
    setTimeout(() => {
      this.errorMessage = undefined;
    }, milliseconds);
  }

  private save() {
    if (this.copy.isDerivedFromModel()) {
      this.dispatchUpdate();
    } else {
      this.dispatchCreate();
    }
  }

  private handleResults() {
    this.notificationsService.addNotification({
      message: this.editLocationText.getSuccessNotification(this.copy.name)
    });
    this.activeModal.dismiss();
  }

  private dispatchUpdate() {
    this.store.dispatch(new UpdateModelAction({ copy: this.copy, model: this.copy.getModel() }));
  }

  private dispatchCreate() {
    this.store.dispatch(
      new MonadicAction<CreateModelAction>(
        new CreateModelAction({ copy: this.copy }),
        this.dispatchLoadLoggedInUser.bind(this)));
  }

  private dispatchLoadLoggedInUser() {
    this.store.dispatch(new LoadLoggedIn({ forceReload: true }));
  }
}
