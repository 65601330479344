import { Action } from '@ngrx/store';
import { Revision } from '.';
import { Job } from '../jobs';
import { SurveyI } from '../lib/models';
import { Station } from '../stations';
import { User } from '../User';
import * as Revisions from './';
import { PostRevisionParams } from './PostRevisionParams';
import { UserSectionAssigner } from './user-section-assigner';
import { UserRole } from '../users';

export abstract class EditRevisionComponentParameters {
  public name: string;
  public assigner: UserSectionAssigner = UserSectionAssigner.Empty();

  public abstract getTitle(): string;
  protected abstract getActionToDispatchHelper(params: PostRevisionParams): Action;

  constructor(private readonly station: Station,
    private job: Job,
    protected readonly revision?: Revision) {
  }

  public getAvailableUsers(): User[] {
    return this.assigner.users
      .filter(user => user.role !== UserRole.user)
      .filter(user => user.station_ids.includes(this.station.id));
  }

  public getActionToDispatch(): Action {
    if (!this.isValid()) {
      throw new Error('May not get action of non valid parameters');
    }

    return this.getActionToDispatchHelper(this.makeParams());
  }

  private makeParams(): PostRevisionParams {
    return {
      revision: {
        station_id: this.station.id,
        job_id: this.job.id,
        name: this.name
      },

      user_revisions: this.assigner.userRevisions()
    };
  }

  public setInitialAssignments(survey: SurveyI, users: User[]) {
    this.assigner = new UserSectionAssigner(survey.sections, users, this.revision);
  }

  public isValid(): boolean {
    return !this.currentErrorMessage();
  }

  public currentErrorMessage(): string | undefined {
    try {
      this.validateName();
      this.validateAssigner();
    } catch (e) {
      return e.message;
    }
  }

  private validateName(): void {
    if (!this.name || this.name.length === 0) {
      throw new Error('Name required');
    }
  }

  private validateAssigner(): void {
    if (!this.assigner.allSectionsAssigned()) {
      throw new Error('Assign a user to each section');
    }
  }
}

export class EdittingEditRevisionComponentParameters extends EditRevisionComponentParameters {
  public name = this.revision.name;

  public getTitle(): string {
    return `Editting - ${this.revision.name}`;
  }

  protected getActionToDispatchHelper(params: PostRevisionParams): Action {
    return new Revisions.Update({ postRevisionParams: params });
  }
}

export class CreatingEditRevisionComponentParameters extends EditRevisionComponentParameters {

  public getTitle(): string {
    return 'New Revision';
  }

  protected getActionToDispatchHelper(params: PostRevisionParams): Action {
    return new Revisions.Create({ postRevisionParams: params });
  }
}
