import { SurveyAccessControl, AdditionalAccessControlModels } from './survey-access-control';
import { Review } from '../reviews';
import { SurveyWrapper } from '.';
import { SectionI, QuestionI } from '../lib/models';
import { User } from '../User';

export class ReviewAccessControl extends SurveyAccessControl {
  constructor(surveyWrapper: SurveyWrapper,
	      user: User,
	      private readonly review: Review
	     ) {
    super(surveyWrapper, user);
  }

  public hasAccessToSection(section: SectionI): boolean {
    const sectionKey = this.sectionKeyFor(section);
    return this.review.section_keys.includes(sectionKey);
  }

  public mayProvideFeedback(question: QuestionI, section: SectionI): boolean {
    return this.hasAccessToSection(section);
  }

  public mayReview(question: QuestionI, section: SectionI): boolean {
    return this.hasAccessToSection(section);
  }
}
