import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RemoveOne, Station, StationKind, UpsertOne } from '.';
import { Job } from '../jobs';
import { LocationService } from '../services/location.service';
import { StationCopy } from './station-copy';

@Injectable({
  providedIn: 'root'
})
export class StationsService extends LocationService<Station> {
  protected readonly uploadExtensionName = 'station';
  protected readonly mapFileExtensionName = 'station';
  protected readonly urlExtension = '/api/stations';
  protected readonly kind: string = StationKind;
  protected readonly copyConstructor = StationCopy;

  public getFromJob(job: Job): Observable<Station[]> {
    return this.makeRequest(this.makeJobUrlExtension(job));
  }

  public makeUpsertAction(station: Station): UpsertOne {
    return new UpsertOne({ station });
  }

  public makeRemoveOneAction(station: Station): RemoveOne {
    return new RemoveOne({ station });
  }

  private makeJobUrlExtension(job: Job): string {
    return `${this.getBaseUrl()}?ids=${job.station_ids.join(',')}`;
  }

  private futureMakeJobUrlExtension(job: Job): string {
    return `jobs/${job.id}/stations`; // To apiv2
  }
}
