import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/core/organizations/Organization';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-request-access-request-made-state',
  templateUrl: './request-access-request-made-state.component.html',
  styleUrls: ['./request-access-request-made-state.component.css']
})
export class RequestAccessRequestMadeStateComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) { }

  ngOnInit() {
  }

  getOrganization(): Organization {
    return this.loginStateService.getOrganization();
  }

}
