import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { gen } from 'testcheck';
import { LoadEffect } from '../effects/load';
import { Job } from '../jobs';
import { Review } from '../reviews';
import { Station } from '../stations';
import { RevisionSurveyActionsService } from '../survey-wrapper/revision-survey-wrapper-service';
import { PostRevisionParams } from './PostRevisionParams';
import { RevisionsService } from './service';

export interface Revision {
  id: number;
  job_id: number;
  station_id: number;
  revision_id: number;
  name: string;
  no_change: boolean;
  final: boolean;
  active: boolean;
  user_sections: { [key: string]: number };
}

export const revisionGen = gen.object({
  id: gen.sPosInt,
  job_id: gen.sPosInt,
  station_id: gen.sPosInt,
  revision_id: gen.sPosInt,
  name: gen.asciiString,
  no_change: gen.return(false),
  final: gen.return(false),
  active: gen.return(true),
  userSections: gen.return({})
});

export const adapter: EntityAdapter<Revision> = createEntityAdapter<Revision>({
});

enum Types {
  AddAll = '[Revision] Add All',
  LoadFromReview = '[Revision] Load From Review',
  LoadFromJobStation = '[Revision] Load From Job Station',
  LoadFromJobStations = '[Revision] Load From Job Stations',
  LoadFromId = '[Revision] Load From Id',
  ReloadChain = '[Revision] Reload Chain',
  Create = '[Revision] Create',
  Update = '[Revision] Update',
  UpsertOne = '[Revision] Upsert One',
  Finalize = '[Revision] Finalize',
  UnFinalize = '[Revision] Un-Finalize'
}

interface RevisionAction extends Action {
  type: Types;
}

export class AddAll implements RevisionAction {
  type = Types.AddAll;

  constructor(public readonly payload: { revisions: Revision[] }) { }
}

export class LoadFromJobStation implements RevisionAction {
  type = Types.LoadFromJobStation;

  constructor(public readonly payload: { station: Station, job: Job }) {
  }
}

export class LoadFromJobStations implements RevisionAction {
  type = Types.LoadFromJobStations;

  constructor(public readonly payload: { job: Job, stations: Station[] }) {
  }
}

export class ReloadChain implements RevisionAction {
  type = Types.ReloadChain;

  constructor(public readonly payload: { revision: Revision }) {
  }
}

export class Create implements RevisionAction {
  type = Types.Create;

  constructor(public readonly payload: {
    postRevisionParams: PostRevisionParams
  }) {
  }
}

export class Update implements RevisionAction {
  type = Types.Update;

  constructor(public readonly payload: {
    postRevisionParams: PostRevisionParams
  }) {
  }
}

export class UpsertOne implements RevisionAction {
  type = Types.UpsertOne;

  constructor(public readonly payload: {
    revision: Revision
  }) {
  }
}

export class FinalizeRevisionAction implements RevisionAction {
  type = Types.Finalize;

  constructor(public readonly payload: { revision: Revision }) {
  }
}

export class UnFinalizeRevisionAction implements RevisionAction {
  type = Types.UnFinalize;

  constructor(public readonly payload: { revision: Revision }) {
  }
}

export class LoadFromReview implements RevisionAction {
  type = Types.LoadFromReview;

  constructor(public readonly payload: { review: Review }) {
  }
}

export class LoadFromId implements RevisionAction {
  public readonly type = Types.LoadFromId;

  constructor(public readonly payload: { id: number }) {
  }
}

type Union = AddAll | LoadFromJobStation | Create | Update | UpsertOne | LoadFromId;

export interface State extends EntityState<Revision> {
}

const initial: State = adapter.getInitialState({});

export function reducer(state: State = initial, action: Union): State {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.upsertMany((<AddAll>action).payload.revisions, state);
    }
    case Types.UpsertOne: {
      return adapter.upsertOne((<UpsertOne>action).payload.revision, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class LoadFromReviewEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadFromReview];
  }

  protected handleResponse(revisions: Revision[]) {
    return new AddAll({ revisions });
  }

  protected handleRequest({ payload: { review } }: LoadFromReview) {
    return this.revisionsService.getFromReview(review);
  }
}

@Injectable()
export class LoadFromJobStationEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadFromJobStation, Types.ReloadChain];
  }

  protected handleResponse(revisions: Revision[]) {
    return new AddAll({ revisions });
  }

  protected handleRequest(action: LoadFromJobStation | ReloadChain) {
    if (action.constructor === LoadFromJobStation) {
      const { payload: { job, station } } = <LoadFromJobStation>action;
      return this.revisionsService.getFromJobStation(job, station);
    } else if (action.constructor === ReloadChain) {
      const { payload: { revision } } = <ReloadChain>action;
      return this.revisionsService.getChain(revision);
    } else {
      throw new Error('Did not properly handle request: ' + JSON.stringify(action));
    }
  }
}

@Injectable()
export class LoadFromJobStationsEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadFromJobStations];
  }

  protected handleResponse(revisions: Revision[]) {
    return new AddAll({ revisions });
  }

  protected handleRequest({ payload: { job, stations } }: LoadFromJobStations) {
    return this.revisionsService.getFromJobStations(job, stations);
  }
}

@Injectable()
export class LoadFromIdEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly revisionsService: RevisionsService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadFromId];
  }

  protected handleResponse(revision: Revision) {
    return new AddAll({ revisions: [revision] });
  }

  protected handleRequest({ payload: { id } }: LoadFromId) {
    return this.revisionsService.getFromId(id);
  }
}

@Injectable()
export class CreateRevisionEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Create];
  }

  protected handleResponse(revision: Revision) {
    return new ReloadChain({ revision });
    // return new UpsertOne({ revision });
  }

  protected handleRequest({ payload: { postRevisionParams } }: Create) {
    return this.revisionsService.create(postRevisionParams);
  }
}

@Injectable()
export class UpdateRevisionEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Update];
  }

  protected handleResponse(revision: Revision) {
    return new UpsertOne({ revision });
  }

  protected handleRequest({ payload: { postRevisionParams } }: Update) {
    return this.revisionsService.update(postRevisionParams);
  }
}

@Injectable()
export class FinalizeRevisionEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService,
    private readonly surveyActionsService: RevisionSurveyActionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Finalize];
  }

  protected handleResponse(revision: Revision) {
    this.surveyActionsService.removeFromCache(revision);
    return new UpsertOne({ revision });
  }

  protected handleRequest({ payload: { revision } }: FinalizeRevisionAction) {
    return this.revisionsService.finalize(revision);
  }
}

@Injectable()
export class UnFinalizeRevisionEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly revisionsService: RevisionsService,
    private readonly surveyActionsService: RevisionSurveyActionsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.UnFinalize];
  }

  protected handleResponse(revision: Revision) {
    this.surveyActionsService.removeFromCache(revision);
    return new UpsertOne({ revision });
  }

  protected handleRequest({ payload: { revision } }: FinalizeRevisionAction) {
    return this.revisionsService.unFinalize(revision);
  }
}
