import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { LoadOne } from '.';
import { LoadEffect } from '../effects/load';
import { Organization } from '../organizations/Organization';
import { Region } from '../Region';
import { Station } from '../stations';
import { User } from '../User';
import { LocationDiffService } from './location-diff.service';

export interface LocationPermissionsDiff<T> { toCreate: T[], toDelete: T[] }

enum UpdateLocationPermissionTypes {
  Organizations = '[UpdateLocationPermissions] Organizations',
  Regions = '[UpdateLocationPermissions] Regions',
  Stations = '[UpdateLocationPermissions] Stations',
  Success = '[UpdateLocationPermissions] Success',
  Failure = '[UpdateLocationPermissions] Failure',
}

export interface UpdateLocationPermissionsAction<T> extends Action {
  readonly type: UpdateLocationPermissionTypes;
  readonly payload: { user: User, diff: LocationPermissionsDiff<T> };
}

abstract class UpdateLocationPermissionsActionC<T> implements UpdateLocationPermissionsAction<T> {
  abstract readonly type: UpdateLocationPermissionTypes;
  constructor(public readonly payload: { user: User, diff: LocationPermissionsDiff<T> }) {
  }
}

export class UpdateOrganizationPermissionsAction extends UpdateLocationPermissionsActionC<Organization> {
  type = UpdateLocationPermissionTypes.Organizations;
}

export class UpdateRegionPermissionsAction extends UpdateLocationPermissionsActionC<Region> {
  type = UpdateLocationPermissionTypes.Regions;
}

export class UpdateStationPermissionsAction extends UpdateLocationPermissionsActionC<Station> {
  type = UpdateLocationPermissionTypes.Stations;
}

export class SuccessfulUpdateAction implements Action {
  type = UpdateLocationPermissionTypes.Success;

  constructor(public readonly payload: { user: User }) {
  }
}

export class FailedUpdateAction implements Action {
  type = UpdateLocationPermissionTypes.Failure;

  constructor(public readonly payload: { user: User }) {
  }
}

@Injectable()
abstract class UpdateLocationDiffEffect extends LoadEffect {
  constructor(actions: Actions, protected readonly locationDiffService: LocationDiffService) {
    super(actions);
  }

  protected handleResponse(user: User) {
    return new LoadOne({ id: user.id });
  }
}

@Injectable()
export class UpdateOrganizationsDiffEffect extends UpdateLocationDiffEffect {
  @Effect()
  observing$ = this.parentObserver$;

  protected typeFilters() {
    return [UpdateLocationPermissionTypes.Organizations];
  }

  protected handleRequest({ payload: { user, diff } }:
    { payload: { user: User, diff: LocationPermissionsDiff<Organization> } }) {
    return this.locationDiffService.updateOrganizationPermissions(user, diff);
  }
}

@Injectable()
export class UpdateRegionsDiffEffect extends UpdateLocationDiffEffect {
  @Effect()
  observing$ = this.parentObserver$;

  protected typeFilters() {
    return [UpdateLocationPermissionTypes.Regions];
  }

  protected handleRequest({ payload: { user, diff } }:
    { payload: { user: User, diff: LocationPermissionsDiff<Region> } }) {
    return this.locationDiffService.updateRegionPermissions(user, diff);
  }
}

@Injectable()
export class UpdateStationsDiffEffect extends UpdateLocationDiffEffect {
  @Effect()
  observing$ = this.parentObserver$;

  protected typeFilters() {
    return [UpdateLocationPermissionTypes.Stations];
  }

  protected handleRequest({ payload: { user, diff } }:
    { payload: { user: User, diff: LocationPermissionsDiff<Station> } }) {
    return this.locationDiffService.updateStationPermissions(user, diff);
  }
}
