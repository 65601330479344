import { Injectable } from '@angular/core';
import { ReportLocationsCreator, ReportLocationsCreatorImpl } from '../core/models/reporting/report-locations-creator';
import { Observable, combineLatest } from 'rxjs';
import { State } from '../reducers';
import { Store } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Organization } from '../core/organizations/Organization';
import { Region } from '../core/Region';
import { sameIds } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportLocationsCreatorService {
  constructor(private readonly store: Store<State>) {
  }

  get$(): Observable<ReportLocationsCreator> {
    const helper = (regions: Region[], organizations: Organization[]) =>
      new ReportLocationsCreatorImpl(regions, organizations);

    const regions$ = this.store.pipe(
      map(state => Object.values(state.regions.entities)),
      distinctUntilChanged(sameIds)
    );

    const organizations$ = this.store.pipe(
      map(state => Object.values(state.organizations.entities)),
      distinctUntilChanged(sameIds)
    );

    return combineLatest([
      regions$,
      organizations$
    ]).pipe(map(([regions, organizations]) => helper(regions, organizations)));
  }
}
