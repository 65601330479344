import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Review } from 'src/app/core/reviews';
import { QuestionI, SurveyI } from 'src/app/core/lib/models';
import { State } from '../rx';
import { Store } from '@ngrx/store';
import { CreateFeedback, Feedback } from 'src/app/core/feedbacks';
import { FeedbackManager } from '../question/feedback-manager';

@Component({
  selector: 'app-create-feedback',
  templateUrl: './create-feedback.component.html',
  styleUrls: ['./create-feedback.component.css']
})
export class CreateFeedbackComponent {

  @Input() feedbackManager: FeedbackManager;
  @Input() review: Review;
  @Input() question: QuestionI;
  @Input() survey: SurveyI;

  public text = '';

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>
  ) { }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  public handleSubmitRequest() {
    this.dispatchCreateFeedback();
  }

  public valid(): boolean {
    return !!this.text;
  }

  private dispatchCreateFeedback() {
    const feedback = this.createFeedback();

    this.store.dispatch(new CreateFeedback({
      feedback: feedback
    }));

    this.activeModal.dismiss();
  }

  private createFeedback(): Feedback {
    return this.feedbackManager.createFeedback(this.text, this.review, this.question, this.survey);
  }

}
