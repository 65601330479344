import { User } from '../User';
import { UserKind, UserKindType, UserRole, UserOrganization, UserRegion, UserStation } from '.';
import { emailRegex } from 'src/app/shared/utils';
import { ModelCopy } from '../models/model-copy';

export class UserCopy extends ModelCopy<User> implements User {
  public readonly kind: UserKindType = UserKind;

  name: string;
  nick: string;
  role: UserRole;
  job_title: string;

  private email_: string;

  get email(): string {
    return this.email_;
  }

  set email(email: string) {
    this.email_ = email.toLowerCase();
  }


  id: number;
  user_id: number;
  image_uri: string;
  readonly user_organizations: UserOrganization[];
  readonly user_regions: UserRegion[];
  readonly user_stations: UserStation[];
  readonly user_ids: number[];
  readonly organization_ids: number[];
  readonly station_ids: number[];
  readonly region_ids: number[];
  readonly edit_organization_ids: number[];
  readonly edit_region_ids: number[];
  readonly edit_station_ids: number[];

  protected readonly validators = [
    this.validator(() => !!this.name, 'Name is not valid'),
    this.validator(() => !!this.nick, 'Nick is not valid'),
    this.validator(() => emailRegex.test(this.email), 'Email is not valid'),
    this.validator(() => !!this.role, 'Role is not valid'),
    this.validator(() => !!this.job_title, 'Job Title is not valid')
  ];

  protected copyOverFields(model: User) {
    this.id = model.id;
    this.user_id = model.user_id;
    this.name = model.name;
    this.nick = model.nick;
    this.email = model.email;
    this.role = model.role;
    this.image_uri = model.image_uri;
    this.job_title = model.job_title;
  }

  protected createJSON() {
    return {
      id: this.id,
      name: this.name,
      nick: this.nick,
      email: this.email,
      role: this.role,
      user_id: this.user_id,
      image_uri: this.image_uri,
      job_title: this.job_title
    };
  }
}
