import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash/fp';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, flatMap, map } from 'rxjs/operators';
import { Job } from '../core/jobs';
import { selectedJob } from '../core/jobs/selectors';
import { CachedCreator } from '../core/models/cached-creator';
import { FinalizedSurveys, FinalizedSurveysImpl } from '../core/models/finalized-surveys';
import { Revision } from '../core/revisions';
import { State } from '../reducers';
import { sameId, toReplaySubject } from '../shared/utils';
import { AddSurveyWrappersToFinalizedSurveysService } from './add-survey-wrappers-to-finalized-surveys/add-survey-wrappers-to-finalized-surveys.service';

@Injectable({
  providedIn: 'root'
})
export class FinalizedSurveysService extends CachedCreator<FinalizedSurveys> {
  constructor(
    private readonly store: Store<State>,
    private readonly addSurveyWrappersToFinalizedSurveys: AddSurveyWrappersToFinalizedSurveysService
  ) {
    super();
  }

  protected make$(): Observable<FinalizedSurveys> {
    const revisions$ = this.store.pipe(
      map(state => Object.values(state.revisions.entities)),
      distinctUntilChanged(isEqual));

    const job$ = this.store.pipe(
      select(selectedJob),
      distinctUntilChanged(sameId));

    const observable = combineLatest([
      revisions$,
      job$,
    ]).pipe(flatMap(([revisions, job]) => this.makeFinalizedSurveys(revisions, job)));

    return toReplaySubject(observable);
  }

  private makeFinalizedSurveys(revisions: Revision[], job: Job | undefined): Observable<FinalizedSurveys | null> {
    if (job) {
      const finalizedSurveys = new FinalizedSurveysImpl(revisions, job);
      return this.addAllSurveyWrappers(finalizedSurveys).pipe(map(() => finalizedSurveys));
    } else {
      return of(null);
    }
  }

  private addAllSurveyWrappers(finalizedSurveys: FinalizedSurveys): Observable<any> {
    return this.addSurveyWrappersToFinalizedSurveys.addSurveyWrappers(finalizedSurveys);
  }
}
