import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BackgroundComponent } from './background/background.component';
import { CardComponent } from './card/card.component';
import { LoginWarningTextComponent } from './login-warning-text/login-warning-text.component';
import { LoginComponent } from './login/login.component';
import { WarningComponent } from './warning/warning.component';
import { NextLoginStateButtonComponent } from './next-login-state-button/next-login-state-button.component';
import { LoginEmailFormComponent } from './login-email-form/login-email-form.component';
import { EmailStateComponent } from './email-state/email-state.component';
import { RequestAccessLinkComponent } from './request-access-link/request-access-link.component';
import { PasswordStateComponent } from './password-state/password-state.component';
import { LoginPasswordFormComponent } from './login-password-form/login-password-form.component';
import { BackLoginStateLinkComponent } from './back-login-state-link/back-login-state-link.component';
import { SelectOrganizationStateComponent } from './select-organization-state/select-organization-state.component';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';
import { SamlStateComponent } from './saml-state/saml-state.component';
import { SamlRedirectComponent } from './saml-redirect/saml-redirect.component';
import { RequestAccessRequestMadeStateComponent } from './request-access-request-made-state/request-access-request-made-state.component';
import { SharedModule } from '../shared/shared.module';
import { CurrentLoginStateComponent } from './current-login-state/current-login-state.component';
import { RequestAccessStateComponent } from './request-access-state/request-access-state.component';
import { AuthenticationFailedComponent } from './authentication-failed/authentication-failed.component';
import { DeclineComponent } from './decline/decline.component';

@NgModule({
  declarations: [WarningComponent, BackgroundComponent, CardComponent, LoginWarningTextComponent, LoginComponent, NextLoginStateButtonComponent, LoginEmailFormComponent, EmailStateComponent, RequestAccessLinkComponent, PasswordStateComponent, LoginPasswordFormComponent, BackLoginStateLinkComponent, SelectOrganizationStateComponent, SelectOrganizationComponent, SamlStateComponent, SamlRedirectComponent, RequestAccessRequestMadeStateComponent, CurrentLoginStateComponent, RequestAccessStateComponent, AuthenticationFailedComponent, DeclineComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    SharedModule
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
