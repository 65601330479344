import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { LoadAllForRevision } from 'src/app/core/feedbacks';
import { LoadAllReviewCompletion } from 'src/app/core/review-completion/review-completion';
import { Revision } from 'src/app/core/revisions';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { State } from '../rx';
import { currentRevisionSelector } from '../rx/selectors';
import { combineLatest } from 'rxjs';
import { RevisionSurveyWrapper } from 'src/app/core/survey-wrapper/revision-survey-wrapper';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent extends ActiveSurveyDirective implements OnInit {
  // TODO there's probably something we can add to the pipe to get this functionality
  private prevRev;

  // TODO same as prevRev
  private prevSW;

  public loadingRevision = false;

  constructor(activeSurveyFactoryService: ActiveSurveyFactoryService,
              store: Store<State>,
              changeDetectorRef: ChangeDetectorRef,
              reviewSurveyActionsService: ReviewSurveyActionsService,
             ) {
    super(activeSurveyFactoryService, store, changeDetectorRef, reviewSurveyActionsService);

    combineLatest([
      this.surveyWrapper$,
      this.store.pipe(select(currentRevisionSelector))
    ]).subscribe(([sw, rev]) => {
      if (rev && this.prevRev && rev.id !== this.prevRev.id) {
        this.loadingRevision = true;
      }

      this.prevRev = rev;

      if (sw instanceof RevisionSurveyWrapper) {
        if ((<RevisionSurveyWrapper>sw).revision.id === this.prevRev.id) {
          this.loadingRevision = false;
        }
      }
    });
  }

  // HACK
  private readonly requestedRevisions = {};

  // HACK
  private readonly requestedReviews = {};

  ngOnInit() {
    this.dispatchLoadFeedbacks();
    this.dispatchLoadReviewCompletions();
  }

  private dispatchLoadFeedbacks() {
    const helper = (revision: Revision) => {
      this.requestedRevisions[revision.id] = true;
      this.store.dispatch(new LoadAllForRevision({ revision }));
    };

    this.revision$.pipe(filter(revision => revision && !this.requestedRevisions[revision.id])).subscribe(helper);
  }

  private dispatchLoadReviewCompletions() {
    const helper = (review) => {
      this.requestedReviews[review.id] = true;
      this.store.dispatch(new LoadAllReviewCompletion({ review }));
    };

    this.getReview$().pipe(filter(review => review && !this.requestedReviews[review.id])).subscribe(helper);
  }
}
