import * as _ from 'lodash';
import { partial } from 'lodash';
import { compose, find, get, isEqual, assign } from 'lodash/fp';
import { assertTruthy, userSrc } from 'src/app/shared/utils';
import { ReportReview, ReportRevision, ReportUser, ReportOrganization } from '.';
import { Review } from '../../reviews';
import { Revision } from '../../revisions';
import { GetApprovalDate } from './get-approval-date';
import { RevisionChainCreator } from './revision-chain-creator';
import { User } from '../../User';

export abstract class ReportRevisionsCreator {
  abstract createRevisions(lastRevision: Revision, organization: ReportOrganization): ReportRevision[];
}

export class ReportRevisionCreatorImpl extends ReportRevisionsCreator {
  constructor(private readonly revisions: Revision[],
    private readonly reviews: Review[],
    private readonly users: User[]) {
    super();
  }

  public createRevisions(
    lastRevision: Revision,
    organization: ReportOrganization
  ): ReportRevision[] {
    return this.getRevisionChain(lastRevision)
      .map(partial(this.convertRevisionToReportRevision.bind(this), _, lastRevision, organization));
  }

  private getRevisionChain(lastRevision: Revision): Revision[] {
    return (new RevisionChainCreator(this.revisions)).create(lastRevision);
  }

  private convertRevisionToReportRevision(
    revision: Revision,
    lastRevision: Revision,
    organization: ReportOrganization
  ): ReportRevision {
    const name = revision.name;
    const reviews = this.getReviewsFor(revision)
      .map((review: Review) => this.convertReviewToReportReview(review, organization));
    const approval_date = this.getApprovalDate(this.getReviewsFor(revision));
    const generated_report = revision === lastRevision;

    return {
      name,
      approval_date,
      reviews,
      generated_report
    };
  }

  private getApprovalDate(reviews: Review[]): number | null {
    return (new GetApprovalDate(reviews)).get();
  }

  private getReviewsFor(revision: Revision): Review[] {
    return this.reviews.filter(review => review.revision_id === revision.id);
  }

  private convertReviewToReportReview(review: Review, organization: ReportOrganization): ReportReview {
    return {
      name: review.review_type.name,
      purpose: review.purpose,
      result: review.response,
      complete_at: review.complete_at,
      person: this.getPerson(review, organization)
    };
  }

  private getPerson(review: Review, organization: ReportOrganization): ReportUser {
    const helper = compose(isEqual(review.user_id), get('id'));
    const user: User = assertTruthy(find(helper, this.users), `No user found for ${review.id}`);
    return this.convertToReportUser(user, organization);
  }

  private convertToReportUser(user: User, organization: ReportOrganization): ReportUser {
    return assign(user, {
      img: userSrc(user),
      organization: this.getOrganization(user, organization),
      sections: null
    })
  }

  // HACK. This does not actually get the organization that the user belongs to.
  private getOrganization(user: User, organization: ReportOrganization): ReportOrganization {
    return organization;
  }
}
