import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { LoadEffect } from '../effects/load';
import { ModelServiceDispatcherService } from '../services/model-service-dispatcher.service';
import { PictureModelService } from '../services/picture-model.service';
import { ModelCopy } from './model-copy';

export interface Model {
  readonly id: number;
  readonly kind: string;
}

export interface PictureModel extends Model {
  image_uri?: string;
}

enum Types {
  UpdateModel = '[Models] Update',
  CreateModel = '[Models] Create',
  DeleteModel = '[Models] Delete',
  UploadPicture = '[Models] Upload Picture',
  RemovePicture = '[Models] Remove Picture',
}

interface ModelAction extends Action {
  type: Types;
}

export class CreateModelAction implements ModelAction {
  type = Types.CreateModel;

  constructor(public readonly payload: { copy: ModelCopy<Model> }) {
  }
}

export class UpdateModelAction implements ModelAction {
  type = Types.UpdateModel;

  constructor(public readonly payload: { copy: ModelCopy<Model>, model: Model }) {
  }
}

export class DeleteModelAction implements ModelAction {
  type = Types.DeleteModel;

  constructor(public readonly payload: { model: Model }) {
  }
}

export class UploadPictureAction implements ModelAction {
  type = Types.UploadPicture;

  constructor(public readonly payload: { model: Model, file: any }) {
  }
}

export class RemovePictureAction implements ModelAction {
  type = Types.RemovePicture;

  constructor(public readonly payload: { model: Model }) {
  }
}

@Injectable()
export class UpdateModelEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly modelServices: ModelServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.UpdateModel];
  }

  protected handleResponse(model: Model): Action {
    const service = this.modelServices.getService(model);
    return service.makeUpsertAction(model);
  }

  protected handleRequest({ payload: { model, copy } }) {
    const service = this.modelServices.getService(model);
    return service.update(copy, model);
  }
}

@Injectable()
export class CreateModelEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly modelServices: ModelServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.CreateModel];
  }

  protected handleResponse(model: Model) {
    return this.modelServices.getService(model).makeUpsertAction(model);
  }

  protected handleRequest({ payload: { copy } }) {
    return this.modelServices.getService(copy).create(copy);
  }
}



@Injectable()
export class DeleteModelEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly modelServices: ModelServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.DeleteModel];
  }

  protected handleResponse(model: Model) {
    return this.modelServices.getService(model).makeRemoveOneAction(model);
  }

  protected handleRequest({ payload: { model } }: { payload: { model: Model } }) {
    return this.modelServices.getService(model).delete(model);
  }
}

@Injectable()
export class UploadPictureEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly modelServices: ModelServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.UploadPicture];
  }

  protected handleResponse(model: Model) {
    return this.modelServices.getService(model).makeUpsertAction(model);
  }

  protected handleRequest({ payload: { model, file } }: { payload: { model: Model, file: any } }) {
    return (<PictureModelService<any>>this.modelServices.getService(model)).addPicture(file, model);
  }
}

@Injectable()
export class RemovePictureEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly modelServices: ModelServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.RemovePicture];
  }

  protected handleResponse(model: Model) {
    return this.modelServices.getService(model).makeUpsertAction(model);
  }

  protected handleRequest({ payload: { model } }: { payload: { model: Model } }) {
    return (<PictureModelService<any>>this.modelServices.getService(model)).removePicture(model);
  }
}
