import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService
       } from '../../core/services/notifications.service';
import { ApiTokenService
       } from '../../core/services/api-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as camelize from 'camelize';

@Component({
  templateUrl: './new-survey-attempt.component.html'
})
export class NewSurveyAttemptComponent {
  @Input() station;
  @Input() stations;

  name;
  copy;
  copyFromStation;
  copyFromSurveyAttempt

  constructor(public activeModal: NgbActiveModal,
              public notifications : NotificationsService,
              public apiTokenService : ApiTokenService,
              public http : HttpClient
             ) { }

  submit() {

    const surveyUpdates = [];

    let surveyUpdatesPromise;

    if (this.copy && this.copyFromSurveyAttempt) {
      surveyUpdatesPromise = this.http.get(
        environment.apiUri.toString() + '/api/survey_update?survey_attempt_id=' + this.copyFromSurveyAttempt.id,
        { headers: this.apiTokenService.headers() }
      ).toPromise();
    } else {
      surveyUpdatesPromise = new Promise((res, rej) => res([]));
    }

    return surveyUpdatesPromise.then(surveyUpdates => {
      const newSurveyAttemptData : any = { name: this.name, station_id: this.station.id };

      if (surveyUpdates.length > 0) {
        surveyUpdates.forEach(surveyUpdate => {
          delete surveyUpdate.id;
          delete surveyUpdate.surveyAttemptId
        });

        newSurveyAttemptData.survey_updates = surveyUpdates;
      }

      return this.http.post(
        environment.apiUri.toString() + '/api/survey_attempt',
        newSurveyAttemptData,
        { headers: this.apiTokenService.headers() }
      ).toPromise()
    })
      .then((surveyAttempt : any) => {
        surveyAttempt = camelize(surveyAttempt);

        this.station.surveyAttemptIds.push(surveyAttempt.id);
        this.station.surveyAttempts.push(surveyAttempt);

        return surveyAttempt;
      })
      .then((surveyAttempt) => {
        this.notifications.addNotification(
          {message: `Successfully created: ${surveyAttempt.name}`});
        this.activeModal.dismiss();
      }).catch(e => {
        console.warn(e);
        this.notifications.addNotification(
          {message: `Failed to create survey attempt: ${this.name}`, type: 'warning'})
      });
  }
}
