import { Injectable } from '@angular/core';
import { RegionKind } from '.';
import { Region } from '../Region';
import { LocationService } from '../services/location.service';
import { RemoveOne, UpsertOne } from './';
import { RegionCopy } from './region-copy';

@Injectable({
  providedIn: 'root'
})
export class RegionsService extends LocationService<Region> {
  protected readonly uploadExtensionName = 'region';
  protected readonly mapFileExtensionName = 'region';
  protected readonly urlExtension = '/api/regions';
  protected readonly kind = RegionKind;
  protected readonly copyConstructor = RegionCopy;

  public makeUpsertAction(region: Region): UpsertOne {
    return new UpsertOne({ region });
  }

  public makeRemoveOneAction(region: Region): RemoveOne {
    return new RemoveOne({ region });
  }
}
