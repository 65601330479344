import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotificationsService } from '../../core/services/notifications.service';
import { ApiTokenService } from '../../core/services/api-token.service';

@Component({
  templateUrl: './edit-survey-attempt.component.html'
})
export class EditSurveyAttemptComponent {
  @Input() station = {};
  surveyAttempt_ = {};
  name = "";

  @Input() get surveyAttempt() {
    return this.surveyAttempt_;
  }

  set surveyAttempt(surveyAttempt : any) {
    surveyAttempt = surveyAttempt || {};
    this.name = surveyAttempt.name
    this.surveyAttempt_ = surveyAttempt;
  }

  constructor(public activeModal : NgbActiveModal,
              public http : HttpClient,
              public apiTokenService : ApiTokenService,
              public notifications : NotificationsService) {
  }

  submit() {
    this.http.post(
      environment.apiUri.toString() + '/api/survey_attempts/' + this.surveyAttempt.id,
      { name: this.name },
      { headers: this.apiTokenService.headers() })
      .toPromise()
      .then((res : any) => {
        this.surveyAttempt.name = res.name;
        this.notifications.addNotification(
          {message: `Successfully updated: ${this.surveyAttempt.name}`});
        this.activeModal.dismiss();
      })
      .catch(e => {
        this.notifications.addNotification(
          {message: `Error updating: ${this.surveyAttempt.name}`, type: 'warning'});
        console.warn(e);
      });
  }
}
