import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-warning-text',
  templateUrl: './login-warning-text.component.html',
  styleUrls: ['./login-warning-text.component.css']
})
export class LoginWarningTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
