import { QuestionMetadata, MetadataKeyFor, QuestionFor, MetadataCompare, QuestionI, SurveyI } from 'src/app/core/lib/models';
import { ReviewCompletionDataRepresentation } from 'src/app/core/models/review-completion-manager/review-completion-data-representation';

export class LibReviewCompletion extends ReviewCompletionDataRepresentation<QuestionMetadata> {

  constructor(
    private readonly metadataKeyFor: MetadataKeyFor,
    private readonly questionFor: QuestionFor,
    private readonly metadataCompare: MetadataCompare,
  ) {
    super();
  }

  public getInternalRepresentation(question: QuestionI, survey: SurveyI): any {
    return this.metadataKeyFor({ question, survey });
  }

  public questionFromInternalRepresentation(internalRepresentation: any, survey: SurveyI): QuestionI {
    return this.questionFor({ survey, metadataKey: internalRepresentation });
  }

  public areSameRepresentation(rep1: any, rep2: any): boolean {
    return this.metadataCompare(rep1, rep2);
  }
}
