import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { toReplaySubject } from 'src/app/shared/utils';
import { environment } from '../../../environments/environment';
import { CacheableRequest } from '../http/cacheable-request';
import { CacheableRequestImpl } from '../http/cacheable-request-impl';
import { LibLoader } from './lib-loader';
import { Lib } from './models';

@Injectable({
  providedIn: 'root'
})
export class LibService {
  private readonly apiExtension = '/lib/1';

  private readonly lib$: Observable<Lib>;

  constructor(http: HttpClient) {
    if (environment.loadLibLocally) {
      require('../../lib/built/index');
      this.lib$ = of(window['lib']);
    } else {
      this.lib$ = this.makeLib$(http);
    }
  }

  get(): Observable<Lib> {
    return this.lib$;
  }

  private makeLib$(http: HttpClient): Observable<Lib> {
    const libLoader = new LibLoader(this.makeCacheableRequest(http),
      environment.apiUri.toString() + this.apiExtension);
    return toReplaySubject<Lib>(libLoader.loadLib$());
  }

  private makeCacheableRequest(http: HttpClient): CacheableRequest {
    return new CacheableRequestImpl({
      alwaysExpectETag: true,
      onErrorTryPreviouslyStored: true
    }, http.get.bind(http));
  }

}
