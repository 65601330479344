import { EditUserComponentParams } from './edit-user-component-params';
import { UserKind } from 'src/app/core/users';
import { UserCopy } from 'src/app/core/users/user-copy';
import { User } from 'src/app/core/User';
import { Action } from '@ngrx/store';
import { UpdateModelAction } from 'src/app/core/models';

export class EdittingEditUserComponentParams extends EditUserComponentParams {
  private readonly copy = new UserCopy({ model: this.user, kind: UserKind });

  constructor(protected readonly user: User) {
    super();
  }

  public getSuccessNotificationMessage(copy: UserCopy): string {
    return `Successfully updated ${copy.name}`;
  }

  public getTitle(): string {
    return `Edit ${this.user.name}`;
  }

  public getCopy(): UserCopy {
    return this.copy;
  }

  public getAction(): Action {
    return new UpdateModelAction({ model: this.user, copy: this.copy });
  }
}
