import { LoginState } from './login-state';
import { EmailLoginState } from './email-login-state';
import { SamlLoginState } from './saml-login-state';
import { GetLoginState } from './login-state-controller';

export class WarningLoginState extends LoginState {
  constructor(getLoginState: GetLoginState,
    private readonly useSaml: boolean) {
    super(getLoginState);
  }

  next() {
    const constructor_ = this.useSaml ?
      SamlLoginState :
      EmailLoginState;
    return this.getLoginState(constructor_);
  }
}
