import { CacheableRequest } from '../http/cacheable-request';
import { Lib } from './models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class LibLoader {
  private static readonly LIB_KEY: string = 'lib';

  constructor(private readonly cacheableRequest: CacheableRequest,
    private readonly url: string) {
  }

  loadLib$(): Observable<Lib> {
    return this.loadRemoteLib().pipe(
      map(({ lib }) => this.evalLib(lib)),
      map(() => this.assertLibExistsOnWindow()),
      map(() => this.libOnWindow())
    );
  }

  private loadRemoteLib() {
    return this.cacheableRequest.get<{ lib: string }>(this.url);
  }

  // http://perfectionkills.com/global-eval-what-are-the-options/#indirect_eval_call_theory
  // (1, eval)(' ... ') escapes strict mode and executes the given string of code in
  // the global scope
  private evalLib(libString: string) {
    // tslint:disable-next-line:no-unused-expression
    (1, eval)(libString);
  }

  private assertLibExistsOnWindow() {
    if (!this.libOnWindow) {
      throw new Error('Lib does not exist on the window');
    }
  }

  private libOnWindow(): Lib {
    return window[LibLoader.LIB_KEY];
  }
}
