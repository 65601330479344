import { Revision } from '../../revisions';
import { Station } from '../../stations';
import { SurveyWrapper } from '../../survey-wrapper';
import { ReportGenerator } from './report-generator';

export class ConstrainedReportGeneratorFactory {
  constructor(
    private readonly reportGenerator: ReportGenerator,
    private readonly reportArgs: any) {
  }

  public getConstrainedReportGenerator(
    station: Station,
    revision: Revision,
    surveyWrapper: SurveyWrapper
  ): ConstrainedReportGenerator {
    return new ConstrainedReportGenerator(
      this.reportGenerator,
      station,
      revision,
      surveyWrapper,
      this.reportArgs,
    );
  }
}

export class ConstrainedReportGenerator {
  constructor(private readonly reportGenerator: ReportGenerator,
              private readonly station: Station,
              private readonly revision: Revision,
              private readonly surveyWrapper: SurveyWrapper,
              private readonly reportArgs: any) {
  }

  public generateReport() {
    this.reportGenerator.generateReport(this.station, this.revision, this.surveyWrapper, this.reportArgs);
  }
}
