import { Component } from '@angular/core';
import { AvailableModelTypes, State } from '../rx';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-admin',
  templateUrl: './admin-module.component.html',
})
export class AdminModuleComponent {
  public searchText = '';
  public readonly AvailableModelTypes = AvailableModelTypes;

  constructor(private readonly store: Store<State>) {
  }

  public handleTabChange(modelType: AvailableModelTypes) {
    this.dispatchModelType(modelType);
    this.resetSearchText();
  }

  private resetSearchText() {
    this.searchText = '';
  }

  private dispatchModelType(modelType: AvailableModelTypes) {
    this.store.dispatch({ type: modelType });
  }
}
