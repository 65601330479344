import { ShouldWorkOn } from './should-work-on';
import { Observable } from 'rxjs';
import { ReviewCompletionManager } from 'src/app/core/models/review-completion-manager/review-completion-manager';
import { QuestionMetadata } from 'src/app/core/lib/models';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { invoke } from 'lodash/fp';
import { flatMap, map } from 'rxjs/operators';
import { RevisionModeShouldWorkOn } from './revision-mode-should-work-on';
import { ReviewModeShouldWorkOn } from './review-mode-should-work-on';

export class ShouldWorkOnWrapper extends ShouldWorkOn {
  constructor(
    private readonly hasAccessToSection$: Observable<boolean>,
    private readonly isInReviewMode$: Observable<boolean>,
    private readonly reviewCompletionManager$: Observable<ReviewCompletionManager<QuestionMetadata>>,
    private readonly completionResult$: Observable<CompletionResult>) {
    super();
  }

  public should$(): Observable<boolean> {
    return <Observable<boolean>>this.delegate$().pipe(flatMap(invoke('should$')));
  }

  private delegate$(): Observable<ShouldWorkOn> {
    return this.isInReviewMode$.pipe(map(isInReviewMode => {
      if (isInReviewMode) {
        return this.inReviewMode();
      } else {
        return this.inRevisionMode();
      }
    }));
  }

  private inRevisionMode(): RevisionModeShouldWorkOn {
    return new RevisionModeShouldWorkOn(this.hasAccessToSection$, this.completionResult$);
  }

  private inReviewMode(): ReviewModeShouldWorkOn {
    return new ReviewModeShouldWorkOn(this.hasAccessToSection$, this.reviewCompletionManager$);
  }
}
