import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Model } from '../models';
import { ModelCopy } from '../models/model-copy';
import { ApiTokenService } from './api-token.service';

export function getBaseUrl(urlExtension: string) {
  return environment.apiUri.toString() + urlExtension;
}

export function makeUrlForInstance(urlExtension: string, id: number): string {
  return getBaseUrl(urlExtension) + `/${id}`;
}

export function processDeleteResponse<T>(model: T): ({ success }: { success: boolean }) => T {
  return ({ success }: { success: boolean }) => {
    if (success) {
      return model;
    } else {
      console.warn(model);
      throw new Error('Delete failed');
    }
  };
}


@Injectable()
export abstract class ModelService<T extends Model> {
  constructor(protected readonly http: HttpClient,
    protected readonly apiTokenService: ApiTokenService) {
  }

  protected abstract urlExtension: string;
  protected abstract kind: string;
  public abstract makeUpsertAction(model: T): Action;
  public abstract makeRemoveOneAction(model: T): Action;

  public get(): Observable<T[]> {
    return this.makeRequest(this.getBaseUrl());
  }

  public getOne(id: number): Observable<T[]> {
    return this.makeRequest(this.makeUrlForInstance(id));
  }

  public create(copy: ModelCopy<T>): Observable<T> {
    return this.http.post<T>(
      this.getBaseUrl(),
      copy.asJSON(),
      { headers: this.apiTokenService.headers() }
    ).pipe(
      map(this.addKind.bind(this))
    );
  }

  public update(copy: ModelCopy<T>, model: T): Observable<T> {
    return this.http.post<T>(
      this.makeUrlForInstance(model.id),
      copy.asJSON(),
      { headers: this.apiTokenService.headers() }
    ).pipe(
      map(this.addKind.bind(this))
    );
  }

  public delete(model: T): Observable<T> {
    return this.http.delete<{ success: boolean }>(
      this.makeUrlForInstance(model.id),
      { headers: this.apiTokenService.headers() }
    ).pipe(
      map(processDeleteResponse(model)));
  }

  protected getBaseUrl = () => getBaseUrl(this.urlExtension);

  private makeUrlForInstance(id: number): string {
    return makeUrlForInstance(this.urlExtension, id);
  }

  protected makeRequest(url: string): Observable<T[]> {
    return this.http.get<T[]>(url, {
      headers: this.apiTokenService.headers()
    }).pipe(
      map(this.processModels.bind(this))
    );
  }

  private processModels(modelsOrModel: any[] | any): Model | Model[] {
    if (Array.isArray(modelsOrModel)) {
      return modelsOrModel.map(model => this.addKind(model));
    } else {
      return this.addKind(modelsOrModel);
    }
  }

  protected addKind(model: any): T {
    model.kind = this.kind;
    return model;
  }
}
