import { Component, OnInit } from '@angular/core';
import { State } from '../reducers';
import { Store, select } from '@ngrx/store';
import { User } from '../core/User';
import { Observable, of } from 'rxjs';
import { loggedInUserSelector, UserRole } from '../core/users';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-site-nav-selection',
  templateUrl: './site-nav-selection.component.html',
  styleUrls: ['./site-nav-selection.component.css']
})
export class SiteNavSelectionComponent implements OnInit {

  private readonly user$ = this.makeUser$();
  private activeUrl: string;


  constructor(private readonly store: Store<State>,
              private readonly router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event.url) {
        this.activeUrl = event.url;
      }
    });
  }

  public displayJobSelection() {
    if (this.activeUrl) {
      return !this.activeUrl.startsWith('/admin');
    } else {
      return false;
    }
  }

  public displayToggleViewSelections() {
    if (this.activeUrl) {
      return this.activeUrl.startsWith('/survey');
    } else {
      return false;
    }
  }

  public mayAccessDashboard$(): Observable<boolean> {
    return of(true);
  }

  public mayAccessSurvey$(): Observable<boolean> {
    return this.accessByRole(UserRole.admin, UserRole.analyst);
  }

  public mayAccessAdmin$(): Observable<boolean> {
    return this.accessByRole(UserRole.admin);
  }

  private accessByRole(...roles: UserRole[]): Observable<boolean> {
    const helper = (user: User | undefined) => user ? roles.includes(user.role) : false;
    return this.user$.pipe(map(helper));
  }

  private makeUser$(): Observable<User> {
    return this.store.pipe(select(loggedInUserSelector));
  }
}
