import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { LocationI } from '.';
import { LoadEffect } from '../effects/load';
import { LocationServiceDispatcherService } from '../services/location-service-dispatcher.service';

enum Types {
  RemoveMapFile = '[Location] Remove Map File',
  AddMapFile = '[Location] Add Map File',
}

interface MapFileAction extends Action {
  type: Types;
}

export class RemoveMapFileAction implements MapFileAction {
  type = Types.RemoveMapFile;

  constructor(public readonly payload: { model }) {
  }
}

export class AddMapfileAction implements MapFileAction {
  type = Types.AddMapFile;

  constructor(public readonly payload: { file, fileType, model }) {
  }
}

@Injectable()
export class AddMapFileEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly locationServices: LocationServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.AddMapFile];
  }

  protected handleResponse(model: LocationI): Action {
    return this.locationServices.getService(model).makeUpsertAction(model);
  }

  protected handleRequest({ payload: { file, fileType, model } }: { payload: { file: any, fileType: string, model: LocationI } }) {
    return this.locationServices.getService(model).addMapFile(file, fileType, model);
  }
}

@Injectable()
export class RemoveMapFileEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly locationServices: LocationServiceDispatcherService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.RemoveMapFile];
  }

  protected handleResponse(model: LocationI): Action {
    return this.locationServices.getService(model).makeUpsertAction(model);
  }

  protected handleRequest({ payload: { model } }: { payload: { model: LocationI } }) {
    return this.locationServices.getService(model).removeMapFile(model);
  }
}
