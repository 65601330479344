import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) { }

  ngOnInit() {
  }

  getErrorMessage(): string {
    return this.loginStateService.getErrorMessage();
  }

  getSuccessMessage(): string {
    return this.loginStateService.getSuccessMessage();
  }

}
