import { Observable } from 'rxjs';

export abstract class CachedCreator<T> {
  private instance$: Observable<T>;

  protected abstract make$(): Observable<T>;

  public get$() {
    this.ensureInstance();
    return this.instance$;
  }

  private ensureInstance() {
    if (!this.instance$) {
      this.instance$ = this.make$();
    }
  }
}
