import { Component, Input } from '@angular/core';
import { AnalysisSelection, AnalysisSelectionParameter, AnalysisSelectionParameterInputType, AnalysisSelectionRow } from 'src/app/core/models/analysis';

@Component({
  selector: 'app-analysis-selection',
  templateUrl: './analysis-selection.component.html',
  styleUrls: ['./analysis-selection.component.css']
})
export class AnalysisSelectionComponent {

  @Input() private readonly analysisSelection: AnalysisSelection | null;

  public getRows(): AnalysisSelectionRow[] {
    return this.analysisSelection ?
      this.analysisSelection.getParameters().asRows() :
      [];
  }

  public isSelectionParameter(parameter: AnalysisSelectionParameter) {
    return parameter.getInputType() === AnalysisSelectionParameterInputType.Select;
  }

  public isCheckboxParameter(parameter: AnalysisSelectionParameter) {
    return parameter.getInputType() === AnalysisSelectionParameterInputType.Checkbox;
  }

  public handleUpdateParametersRequested(parameter: AnalysisSelectionParameter, value: string | boolean) {
    this.analysisSelection.updateParameters(parameter, value);
  }
}
