import { SelectAnalysisSelectionParameter } from '..';
import { LibAnalysisParameter } from 'src/app/core/lib/models';

export class LibSelectAnalysisSelectionParameter extends SelectAnalysisSelectionParameter {
  constructor(private readonly libAnalysisParameter: LibAnalysisParameter) {
    super();
  }

  public getOptions(): string[] {
    return this.libAnalysisParameter.options;
  }

  public getStyle(): Object {
    return this.libAnalysisParameter.style;
  }

  public getName(): string {
    return this.libAnalysisParameter.label;
  }

  public getValue(): string {
    if (typeof this.libAnalysisParameter.value === 'string') {
      return this.libAnalysisParameter.value;
    } else {
      throw new Error(`Incorrectly configured LibAnalysisParameter for type \'select\': ${JSON.stringify(this.libAnalysisParameter)}`);
    }
  }
}
