export abstract class NewTabOpener {
  public abstract openTab(url: string): void;
}

export class NewTabOpenerImpl extends NewTabOpener {
  constructor(
    private readonly successfullyOpened: (url: string) => void,
    private readonly failedToOpen: (url: string) => void) {
    super();
  }

  public openTab(url: string) {
    const opened = window.open(url, '_blank');
    if (opened && opened.focus) {
      opened.focus();
      this.successfullyOpened(url);
    } else {
      this.failedToOpen(url);
    }
  }
}
