import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MarkAsComplete, Review } from 'src/app/core/reviews';
import { State } from '../rx';
import { MonadicAction } from 'src/app/core/effects/monadic-action';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complete-review',
  templateUrl: './complete-review.component.html',
  styleUrls: ['./complete-review.component.css']
})
export class CompleteReviewComponent {
  @Input() review: Review;
  public response: string | undefined;

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>,
    private readonly notifications: NotificationsService,
    private readonly router: Router) {
  }

  public availableResponses(): string[] {
    return this.review.review_type.available_responses;
  }

  public handleSubmitRequest() {
    const action = new MonadicAction(
      new MarkAsComplete({ review: this.review, response: this.response }),
      this.successfullyCompleted.bind(this)
    );
    this.store.dispatch(action);
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  public valid(): boolean {
    return !!this.response;
  }

  private successfullyCompleted() {
    this.activeModal.dismiss();
    this.notifications.addNotification({ message: `Successfully completed review` });
    this.router.navigate(['/', 'survey']);
  }
}
