import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent {
  @Input() public readonly name: string;
  @Input() private readonly onConfirm: () => any;
  @Input() private readonly activeModal: NgbActiveModal

  private confirmationClicked: boolean;

  public confirm() {
    if (this.confirmationClicked) {
      this.onConfirm();
      this.activeModal.dismiss();
    } else {
      this.confirmationClicked = true;
    }
  }

  public isConfirmed(): boolean {
    return this.confirmationClicked;
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }
}
