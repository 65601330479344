import { Component, OnInit, Input } from '@angular/core';
import { Job } from 'src/app/core/jobs';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {

  @Input() public readonly searchText = '';
  public job: Job;

  constructor() { }

  ngOnInit() {
  }
}
