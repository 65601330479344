import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { first, flatMap, map } from 'rxjs/operators';
import { SurveyUpdate } from '../survey-update/model';
import { SurveyUpdateService } from '../survey-update/service';
import { SurveyCreatorFromSurveyUpdates, SurveyCreatorFromUpdates } from '../survey-wrapper/survey-creator';
import { Lib, SurveyI, Update } from './models';
import { LibService } from './service';
import { toReplaySubject } from 'src/app/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private readonly cache = new Map<number, Observable<Update[]>>();

  constructor(private readonly surveyUpdateService: SurveyUpdateService,
    private readonly libService: LibService
             ) {
  }

  get(revisionId: number): Observable<SurveyI> {
    this.ensureCache(revisionId);
    return this.buildSurveyFromCache(revisionId);
  }

  // This function is not getting called when there is an update
  // to a survey
  set(revisionId: number, survey: SurveyI): Observable<any> {
    const subj = new ReplaySubject(1);

    this.makeUpdates(survey).pipe(first()).subscribe(updates => {
      this.cache.set(revisionId, of(updates));
      subj.next();
    });

    return subj;
  }

  private ensureCache(revisionId: number) {
    if (!this.cache.has(revisionId)) {
      const updates$: Observable<Update[]> = this.makeNewSurvey(revisionId).pipe(flatMap(this.makeUpdates.bind(this)));
      this.cache.set(revisionId, updates$);
    }
  }

  private makeUpdates(survey: SurveyI): Observable<Update[]> {
    const helper = (lib: Lib) => {
      const { updates } = lib.makeUpdates({ survey });
      return updates;
    };

    return this.libService.get().pipe(map(helper));
  }

  private makeNewSurvey(revisionId: number): Observable<SurveyI> {
    const helper = (lib: Lib, surveyUpdates: SurveyUpdate[]) => {
      const surveyCreator = new SurveyCreatorFromSurveyUpdates(lib.fromUpdates);
      return surveyCreator.createSurvey(surveyUpdates);
    };

    const observable = combineLatest([
      this.libService.get(),
      this.surveyUpdateService.read(revisionId)
    ]).pipe(map(([lib, surveyUpdates]) => helper(lib, surveyUpdates)));

    return toReplaySubject<SurveyI>(observable);
  }

  private buildSurveyFromCache(revisionId: number): Observable<SurveyI> {
    if (!this.cache.get(revisionId)) {
      throw new Error('Attempting to build survey from cache without revisionId present: ' + revisionId);
    }

    const helper = (lib: Lib, updates: Update[]) => {
      const surveyCreator = new SurveyCreatorFromUpdates(lib.fromUpdates);
      return surveyCreator.createSurvey(updates);
    };

    const lib$ = this.libService.get();
    const updates$ = this.cache.get(revisionId);

    return combineLatest([
      lib$,
      updates$
    ]).pipe(map(([lib, updates]) => helper(lib, updates)));
  }
}
