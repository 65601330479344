import { forkJoin, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Station } from '.';
import { LocationCopy, OrganizationsAndRegionParents } from '../locations/location-copy';
import { Organization, OrganizationKind } from '../organizations/Organization';
import { RegionKind } from '../regions';
import { Region } from "../Region";

export class StationCopy extends LocationCopy<Station> {
  public organization_id?: number;
  public region_id?: number;
  public readonly hasParent: boolean = true;

  constructor(args) {
    super(args);
    this.validators.push(
      this.validator(
        () => !!this.organization_id || !!this.region_id,
        'Requires a Region or an Organization'));
  }

  protected copyOverFields(station: Station) {
    super.copyOverFields(station);
    this.organization_id = station.organization_id;
    this.region_id = station.region_id;
  }

  protected createJSON() {
    return Object.assign(
      super.createJSON(),
      {
        organization_id: this.organization_id,
        region_id: this.region_id
      }
    );
  }

  public getAvailableParents(
    organizations$: Observable<Organization[]>,
    regions$: Observable<Region[]>
  ): OrganizationsAndRegionParents {
    return new OrganizationsAndRegionParents(organizations$, regions$);
  }

  public setParent(model: Region | Organization) {
    if (model.kind === RegionKind) {
      this.region_id = model.id;
      this.organization_id = null;
    } else if (model.kind === OrganizationKind) {
      this.organization_id = model.id;
      this.region_id = null;
    }
  }

  public getParent(
    organizations$: Observable<Organization[]>,
    regions$: Observable<Region[]>
  ): Observable<Organization | Region | null> {
    return combineLatest(
      organizations$,
      regions$
    ).pipe(map(([organizations, regions]) => this.getParentHelper(organizations, regions)));
  }

  private getParentHelper(organizations: Organization[], regions: Region[]): Organization | Region | null {
    const organization = organizations.find(organization => organization.id === this.organization_id);
    const region = regions.find(region => region.id === this.region_id);
    return organization || region || null;
  }
}
