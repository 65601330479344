import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiTokenService } from './api-token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiV2Service {

  private readonly url = `${environment.apiUri.toString()}/apiv2`;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTokenService: ApiTokenService
  ) {
  }

  get(urlExtension: string, options: any = {}): Observable<any> {
    return this.http.get(this.makeUrl(urlExtension), this.addApiToken(options));
  }

  post(urlExtension: string, args: any, options: any = {}): Observable<any> {
    return this.http.post(this.makeUrl(urlExtension), args, this.addApiToken(options));
  }

  put(urlExtension: string, args: any, options: any = {}): Observable<any> {
    return this.http.put(this.makeUrl(urlExtension), args, this.addApiToken(options));
  }

  private makeUrl(urlExtension: string): string {
    return this.url + '/' + urlExtension;
  }

  private addApiToken(options: any) {
    options.headers = options.headers || new HttpHeaders();
    options.headers = this.apiTokenService.addToHeaders(options.headers);
    return options;
  }
}
