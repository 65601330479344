import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { GraphData } from 'src/app/core/models/analysis/graph-data';

@Component({
  selector: 'app-analysis-graph',
  templateUrl: './analysis-graph.component.html',
  styleUrls: ['./analysis-graph.component.css']
})
export class AnalysisGraphComponent implements OnChanges {
  @Input() private readonly graphData: GraphData;
  @ViewChild('baseChart') chart: BaseChartDirective;

  constructor() { }

  ngOnChanges() {
    this.reloadChart();
  }

  private reloadChart() {
    if (this.chart && this.chart.chart) {
      this.chart.chart.destroy();
      this.chart.chart = 0;
      this.chart.chartType = this.getChartType();
      this.chart.datasets = this.getDatasets();
      this.chart.labels = this.getLabels();
      this.chart.options = this.getOptions();
      this.chart.legend = true;
      this.chart.ngOnInit();
    }
  }

  public getChartType() {
    return this.graphData.getChartType();
  }

  public getDatasets() {
    return this.graphData.getDatasets();
  }

  public getLabels() {
    return this.graphData.getLabels();
  }

  public getOptions() {
    return this.graphData.getOptions();
  }
}
