import { circle, Circle, latLng, LatLngBounds, latLngBounds, Layer, MapOptions, tileLayer } from 'leaflet';
import { LocationI } from '../locations';
import { CurrentLocationType } from './location-hierarchy';
import { MapDataColor } from './map-data-color';
import { RankDataForSurvey } from './rank-data-for-survey';
import { OverlayMaker } from './overlay-maker';
import { AnalysisSelection } from './analysis';

const mapTileServerUrl = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';

export abstract class MapData {
  public abstract getLayers(analysisSelection: AnalysisSelection): Layer[];
  public abstract getBounds(): LatLngBounds | undefined;
  public abstract getMapOptions(): MapOptions;
  public abstract getOverlay(): Promise<Layer | undefined>;
}

export class MapDataImpl extends MapData {
  private readonly markerRadius = 20000;
  private readonly bounds: LatLngBounds | undefined;
  private overlayPromise: Promise<Layer | undefined>;

  constructor(private readonly locations: LocationI[],
    private readonly overlayLocation: CurrentLocationType,
    private readonly locationClicked: (location: LocationI) => any,
    private readonly rankDataForSurvey: RankDataForSurvey | null,
  ) {
    super();
    this.bounds = this.makeBounds();
  }

  public getLayers(analysisSelection: AnalysisSelection): Layer[] {
    return this.locations.map(location => {
      const { latitude, longitude } = location;
      const color = (new MapDataColor(location, this.rankDataForSurvey, analysisSelection)).get();
      const marker = circle([latitude, longitude], { radius: this.markerRadius, color });
      this.addClickListener(marker, location);
      this.addTooltip(marker, location);
      return marker;
    });
  }

  public getBounds(): LatLngBounds | undefined {
    return this.bounds;
  }

  public getMapOptions(): MapOptions {
    return {
      layers: [
        tileLayer(mapTileServerUrl, {
          attribution: 'Security Risk Assessment Overview',
          maxZoom: 10
        })
      ],
      zoom: 4,
      center: latLng(39.8283, -98.5795)
    };
  }

  public getOverlay(): Promise<Layer | undefined> {
    if (!this.overlayPromise) {
      this.overlayPromise = new OverlayMaker(this.overlayLocation).makeOverlay();
    }

    return this.overlayPromise;
  }

  private makeBounds(): LatLngBounds | undefined {
    // Using latLngBounds with an empty array will cause an invalid bounds area
    if (this.locations.length > 0) {
      return latLngBounds(this.locations.map(location => this.makeLatLng(location)));
    } else {
      return undefined;
    }
  }

  private addClickListener(marker: Circle, location: LocationI) {
    marker.on('click', () => this.locationClicked(location));
  }

  private addTooltip(locationMarker: Layer, location: LocationI): void {
    const { nick } = location;
    locationMarker.bindTooltip(nick.substring(0, 4), { permanent: true }).openTooltip();
  }

  private makeLatLng(location: LocationI) {
    const { latitude, longitude } = location;
    return latLng(latitude, longitude);
  }
}
