import { createSelector } from '@ngrx/store';
import { selectedJob } from 'src/app/core/jobs/selectors';
import { Station } from 'src/app/core/stations';
import { State } from '.';
import { Job } from '../../core/jobs';
import * as Revisions from '../../core/revisions';

export const stationsForSelectedJob = createSelector(
  state => {
    return state.stations.entities;
  },
  selectedJob,
  (stations, selectedJob) => {
    if (selectedJob) {
      return selectedJob.station_ids
        .map(stationId => stations[stationId])
        .filter(station => station);
    } else {
      return [];
    }
  }
);

function findRevisionsFor(job: Job, stationId: number, revisions: Revisions.Revision[]) {
  return revisions.filter(revision =>
    revision.station_id === stationId &&
    revision.job_id === job.id);
}

const revisionsSelectorHelper =
  (job: Job, revisions: Revisions.Revision[]) => (stationId: number) => findRevisionsFor(job, stationId, revisions);

export const jobStationRevisionsSelector = createSelector(
  selectedJob,
  state => Object.values(state.revisions.entities),
  revisionsSelectorHelper
);

export const currentRevisionSelector = (state: State) => {
  const revisionId = state.surveys.selectedRevision.selectedRevisionId;

  return revisionId ?
    state.revisions.entities[revisionId] :
    null;
};

export const currentStationSelector = createSelector(
  currentRevisionSelector,
  (state: State) => state.stations.entities,
  (revision: Revisions.Revision, stations) => revision ? stations[revision.station_id] : null
);

export const currentReviewSelector = createSelector(
  (state: State) => state.surveys.currentReviewId,
  (state: State) => state.reviews.entities,
  (reviewId, reviews) => reviewId ? reviews[reviewId] : null
);

export const revisionForCurrentReviewSelector = createSelector(
  currentReviewSelector,
  (state: State) => state.revisions.entities,
  (currentReview, revisions) => currentReview ? revisions[currentReview.revision_id] : null
);

export const jobForCurrentReviewSelector = createSelector(
  revisionForCurrentReviewSelector,
  (state: State) => state.jobs.entities,
  (revision, jobs) => revision ? jobs[revision.job_id] : null
);

export const stationForCurrentReviewSelector = createSelector(
  revisionForCurrentReviewSelector,
  (state: State) => state.stations.entities,
  (revision, stations) => revision ? stations[revision.station_id] : null
);

export const revisionsForStationJobSelector = () => createSelector(
  (state: State, props: { job: Job, station: Station }) =>
    Object.values(state.revisions.entities)
      .filter((revision: Revisions.Revision) => revision.job_id === props.job.id &&
        revision.station_id === props.station.id));
