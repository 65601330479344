import { Action } from '@ngrx/store';

export interface State {
  viewSelections: boolean;
}

const initial = {
  viewSelections: false
};

enum Types {
  Toggle = '[View Selections] Toggle',
  Show = '[View Selections] Show',
  Hide = '[View Selections] Hide'
}

interface ViewSelectionAction extends Action {
  type: Types;
}

export class Toggle implements ViewSelectionAction {
  type = Types.Toggle;
}

export class Show implements ViewSelectionAction {
  type = Types.Show;
}

export class Hide implements ViewSelectionAction {
  type = Types.Hide;
}

type Union = Toggle | Show | Hide;

export function reducer(state = initial, action: ViewSelectionAction) {
  switch (action.type) {
    case Types.Toggle: {
      return {
        ...state,
        viewSelections: !state.viewSelections
      };
    }
    case Types.Show: {
      return {
        ...state,
        viewSelections: true
      };
    }
    case Types.Hide: {
      return {
        ...state,
        viewSelections: false
      };
    }
    default: {
      return state;
    }
  }
}
