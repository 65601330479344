import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class UnsubscribeOnDestroyComponent implements OnDestroy {
  protected ngUnsubscribe = new Subject();
  protected takeUntilDestroy = takeUntil(this.ngUnsubscribe);

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
