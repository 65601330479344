import { Component, Input } from '@angular/core';
import { ConstrainedReportGenerator } from 'src/app/core/models/reporting/constrained-report-generator';

@Component({
  selector: 'app-gen-report',
  templateUrl: './gen-report.component.html',
  styleUrls: ['./gen-report.component.css']
})
export class GenReportComponent {

  @Input() private readonly reportGenerator: ConstrainedReportGenerator;

  constructor(
  ) { }

  public generateReport() {
    this.reportGenerator.generateReport();
  }
}
