import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Job } from '../core/jobs';
import { CachedCreator } from '../core/models/cached-creator';
import { ReportPeopleCreator, ReportPeopleCreatorImpl } from '../core/models/reporting/report-people-creator';
import { RevisionAccessControlFactory } from '../core/survey-wrapper/revision-access-control';
import { User } from '../core/User';
import { State } from '../reducers';
import { sameIds } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportPeopleCreatorService extends CachedCreator<ReportPeopleCreator> {
  constructor(private readonly store: Store<State>) {
    super();
  }

  protected make$(): Observable<ReportPeopleCreator> {
    const helper = (users: User[], revisionAccessControlFactory: RevisionAccessControlFactory) =>
      new ReportPeopleCreatorImpl(users, revisionAccessControlFactory);

    const users$ = this.store.pipe(
      map(state => Object.values(state.users.entities)),
      distinctUntilChanged(sameIds)
    );

    const revisionAccessControlFactory$ = this.makeRevisionAccessControlFactory$();

    return combineLatest([
      users$,
      revisionAccessControlFactory$
    ]).pipe(map(([users, revisionAccessControlFactory]) => helper(users, revisionAccessControlFactory)));
  }

  private makeRevisionAccessControlFactory$(): Observable<RevisionAccessControlFactory> {
    const helper = (jobs: Job[]) => new RevisionAccessControlFactory(jobs);

    const jobs$ = this.store.pipe(
      map(state => Object.values(state.jobs.entities)),
      distinctUntilChanged(sameIds)
    );

    return combineLatest([
      jobs$,
    ]).pipe(map(([jobs]) => helper(jobs)));
  }
}
