import { Component } from '@angular/core';
import { ApiTokenService } from '../core/services/api-token.service';
import { ApplicationSetupService } from '../core/services/application-setup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-module.component.html',
  styleUrls: ['./app-module.component.css']
})
export class AppModuleComponent {
  constructor(
    private readonly applicationSetupService: ApplicationSetupService,
    private readonly apiTokenService: ApiTokenService
  ) {
    this.applicationSetup();
  }

  public isLoggedIn(): boolean {
    return this.apiTokenService.isLoggedIn();
  }

  private applicationSetup() {
    this.applicationSetupService.setupApplication();
  }
}
