import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';
import { environment } from 'src/environments/environment';
import { SamlRedirect } from './saml-redirect';

@Component({
  selector: 'app-saml-redirect',
  templateUrl: './saml-redirect.component.html',
  styleUrls: ['./saml-redirect.component.css']
})
export class SamlRedirectComponent {
  private readonly samlRedirect = new SamlRedirect(
    environment.apiUri,
    this.doRedirect.bind(this)
  );

  public getRedirectCountdown() {
    return this.samlRedirect.getRedirectCountdown();
  }

  private doRedirect() {
    const samlUrl = environment.apiUri + '/users/saml/auth';
    window.location.replace(samlUrl);
  }
}
