import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionI } from 'src/app/core/lib/models';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent {
  @Input() public readonly options: OptionI[] = [];
  @Input() public readonly isReadonly: boolean = false;
  // If set, will display as an option that has the intent to be
  // selected but isn't yet
  @Input() private readonly transientOption: OptionI | undefined;

  @Output() public readonly selectionRequested = new EventEmitter<OptionI>();

  public handleSelectionRequested(option: OptionI, event = null) {
    this.emitSelectionRequested(option);

    if (event) {
      event.preventDefault();
    }
  }

  public shouldDisplayAsSelected(option: OptionI): boolean {
    return this.transientOption ?
      option === this.transientOption :
      option.selected;
  }

  private emitSelectionRequested(option: OptionI) {
    this.selectionRequested.emit(option);
  }
}
