import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Action, Store } from '@ngrx/store';
import { State } from 'src/app/reducers';

@Component({
  templateUrl: './delete-model.component.html'
})
export class DeleteModelComponent {

  @Input() private readonly actionToDispatch: Action;
  @Input() public readonly model: { name: string };

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>
  ) {
  }

  public dispatchAction = () => {
    this.store.dispatch(this.actionToDispatch);
  }
}
