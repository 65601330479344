import { Action } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

export const SurveyStateUpdatedType = '[Surveys] Survey State Updated';

export class SurveyStateUpdatedAction implements Action {
    type = SurveyStateUpdatedType;
}

export function listenForSurveyStateUpdated(updates$, callback) {
    updates$.pipe(
        ofType(SurveyStateUpdatedType)
      ).subscribe(() => {
        callback();
      });
}