import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { toReplaySubject } from 'src/app/shared/utils';
import { Completion } from './completion';
import { SurveyWrapper } from './model';
import { PositionInSurvey } from './position-in-survey';
import { AdditionalAccessControlModels, SurveyAccessControl } from './survey-access-control';
import { SurveyActions } from './survey-actions';
import { SurveyWrapperService } from './survey-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export abstract class SurveyActionsService<T extends { id: number }> {
  public readonly savePositionData =
    this.surveyWrapperService.savePositionData.bind(this.surveyWrapperService);
  private readonly cache = new Map<number, Observable<SurveyActions>>();

  constructor(private readonly surveyWrapperService: SurveyWrapperService<T>) {
  }

  protected abstract createAccessControl(surveyWrapper: SurveyWrapper, models: AdditionalAccessControlModels): SurveyAccessControl;
  protected abstract createPositionInSurvey(surveyWrapper: SurveyWrapper): PositionInSurvey;

  public get(models: AdditionalAccessControlModels, model: T): Observable<SurveyActions> {
    this.ensureInCache(models, model);
    return this.cache.get(model.id);
  }

  public removeFromCache(modelOrId: T | number) {
    const id = typeof modelOrId === 'number' ?
      modelOrId :
      modelOrId.id;

    this.cache.delete(id);
  }

  private ensureInCache(models: AdditionalAccessControlModels, model: T) {
    if (!this.cache.has(model.id)) {
      const surveyAction$ = this.getNewSurveyAction$(models, model);
      this.cache.set(model.id, surveyAction$);
    }
  }

  private getNewSurveyAction$(models: AdditionalAccessControlModels, model: T): Observable<SurveyActions> {
    const helper = (surveyWrapper: SurveyWrapper) => {
      return this.createActions(surveyWrapper, models);
    };

    const surveyWrapper$ = this.surveyWrapperService.get(model);

    const observable = combineLatest([
      surveyWrapper$
    ]).pipe(map(([surveyWrapper]) => helper(surveyWrapper)));

    return toReplaySubject(observable);
  }

  private createActions(surveyWrapper: SurveyWrapper, models: AdditionalAccessControlModels): SurveyActions {
    const accessControl = this.createAccessControl(surveyWrapper, models);
    const positionInSurvey = this.createPositionInSurvey(surveyWrapper);
    const completion = this.createCompletion(surveyWrapper);

    return {
      surveyWrapper,
      accessControl,
      positionInSurvey,
      completion
    };
  }

  private createCompletion(surveyWrapper: SurveyWrapper): Completion {
    return new Completion(surveyWrapper);
  }

}
