import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PictureModel, RemovePictureAction, UploadPictureAction } from 'src/app/core/models';
import { NotificationsService } from '../../core/services/notifications.service';
import { State } from '../rx';

@Component({
  templateUrl: './picture-upload.component.html'
})
export class PictureUploadComponent {

  private file: any;
  @Input() private readonly model: PictureModel & { name: string };

  constructor(
    private readonly notifications: NotificationsService,
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>
  ) { }

  public handleFileChange(event) {
    this.file = event.target.files[0];
  }

  public hasFile(): boolean {
    return !!this.file;
  }

  public currentlyHasImage(): boolean {
    return !!this.model.image_uri;
  }

  public handleRemoveCurrentPicture() {
    this.dispatchRemovePicture();
    this.handleResponse();
  }

  public handleSubmit() {
    this.dispatchAddPicture();
    this.handleResponse();
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  private dispatchRemovePicture() {
    this.store.dispatch(new RemovePictureAction({ model: this.model }));
  }

  private dispatchAddPicture() {
    this.store.dispatch(new UploadPictureAction({ model: this.model, file: this.file }));
  }

  private handleResponse() {
    this.notifications.addNotification({
      message: `Successfully updated picture for ${this.model.name}`
    });
    this.activeModal.dismiss();
  }
}
