import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyWrapper } from '.';
import { Lib, SurveyI } from '../lib/models';
import { LibService } from '../lib/service';
import { SurveyService } from '../lib/survey-service';
import { Review } from '../reviews';
import { Revision } from '../revisions';
import { PositionDataSerializer } from './position-data-serializer/position-data-serializer';
import { ReviewSurveyWrapper } from './review-survey-wrapper';
import { RevisionSurveyWrapper } from './revision-survey-wrapper';
import { ReviewPositionDataSerializer } from './position-data-serializer/review-position-data-serializer';
import { RevisionPositionDataSerializer } from './position-data-serializer/revision-position-data-serializer';

@Injectable({
  providedIn: 'root'
})
export abstract class SurveyWrapperService<T extends { id: number }> {
  protected abstract createWrapper(lib: Lib, survey: SurveyI, model: T): SurveyWrapper;
  protected abstract getSurvey(model: T): Observable<SurveyI>;

  constructor(private readonly libService: LibService,
    private readonly positionDataSerializer: PositionDataSerializer<T>
  ) {
  }

  public get(model: T): Observable<SurveyWrapper> {
    const helper = (lib: Lib, survey: SurveyI) => {
      this.setPositionData(lib, survey, model);
      const wrapper = this.createWrapper(lib, survey, model);
      return wrapper;
    };

    const lib$ = this.libService.get();
    const survey$ = this.getSurvey(model);

    return combineLatest([
      lib$,
      survey$
    ]).pipe(map(([lib, survey]) => helper(lib, survey)));
  }

  public savePositionData(model: T, surveyWrapper: SurveyWrapper) {
    this.positionDataSerializer.set(model, surveyWrapper.getPositionData());
  }

  private setPositionData(lib: Lib, survey: SurveyI, model: T) {
    const positionData = this.positionDataSerializer.get(model);
    lib.setPositionData({ survey, positionData });
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewSurveyWrapperService extends SurveyWrapperService<Review> {
  constructor(libService: LibService,
    private readonly surveyService: SurveyService) {
    super(libService, new ReviewPositionDataSerializer());
  }

  protected createWrapper(lib: Lib, survey: SurveyI, review: Review): SurveyWrapper {
    return new ReviewSurveyWrapper(lib,
                                   survey,
                                   this.surveyService.set.bind(this.surveyService),
                                   review);
  }

  protected getSurvey(review: Review): Observable<SurveyI> {
    return this.surveyService.get(review.revision_id);
  }
}

@Injectable({
  providedIn: 'root'
})
export class RevisionSurveyWrapperService extends SurveyWrapperService<Revision> {
  constructor(libService: LibService,
    private readonly surveyService: SurveyService) {
    super(libService, new RevisionPositionDataSerializer());
  }

  protected createWrapper(lib: Lib, survey: SurveyI, revision: Revision): SurveyWrapper {
    return new RevisionSurveyWrapper(lib,
                                     survey,
                                     this.surveyService.set.bind(this.surveyService),
                                     revision);
  }

  protected getSurvey(revision: Revision): Observable<SurveyI> {
    return this.surveyService.get(revision.id);
  }
}
