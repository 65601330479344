import { Pipe, PipeTransform } from '@angular/core';
import * as Fuse from 'fuse.js';
import { truncate } from 'src/app/shared/utils';

@Pipe({
  name: 'filterModels'
})
export class FilterModelsPipe implements PipeTransform {
  transform(models, searchText) {

    if (!searchText) {
      return models;
    }

    const fuse = new Fuse(models, <any>{ keys: ['name', 'nick', 'title'], threshold: 0.4 });
    return fuse.search(searchText);
  }
}

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform = truncate;
}
