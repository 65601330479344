import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Jobs from 'src/app/core/jobs';
import { Job } from 'src/app/core/jobs';
import { State } from 'src/app/reducers';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-admin-job-selection',
  templateUrl: './admin-job-selection.component.html',
  styleUrls: ['./admin-job-selection.component.css']
})
export class AdminJobSelectionComponent implements OnInit {
  @Input() public readonly searchText: string;
  @Output() public readonly job = new EventEmitter();
  public readonly jobs$: Observable<Job[]> = this.makeJobs$();

  private currentJob: Job | undefined;

  constructor(
    private readonly store: Store<State>,
    private readonly userService: UserService
  ) {
  }

  ngOnInit() {
    this.dispatchLoadJobs();
  }

  public setCurrentJob(job: Job) {
    this.currentJob = job;
    this.job.emit(job);
  }

  public getCurrentJob(): Job | undefined {
    return this.currentJob;
  }

  private makeJobs$(): Observable<Job[]> {
    return combineLatest([
      this.store,
      this.userService.getLoggedIn()
    ]).pipe(map(([store, user]) => {
      return Object.values(store.jobs.entities)
        .filter(job => job.user_id === user.id);
    }));
  }

  private dispatchLoadJobs() {
    this.store.dispatch(new Jobs.Actions.LoadAll());
  }
}
