import { Types } from './actions/types';
import { adapter } from './adapter';
import { initial, State } from './state';
import { Union, AddAll } from './actions';
import { SelectJob } from './actions/select';

export function reducer(state = initial, action: Union): State {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.addMany((<AddAll>action).payload.jobs, state);
    }
    case Types.Select: {
      return {
        ...state,
        selectedId: (<SelectJob>action).payload.job.id
      };
    }
    default: {
      return state;
    }
  }
}
