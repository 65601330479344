import { Revision } from '.';
import { SectionI } from '../lib/models';
import { User } from '../User';

export class UserSectionAssigner {

  private readonly store: { [key: string]: User } = {};

  static Empty(): UserSectionAssigner {
    return new UserSectionAssigner({}, []);
  }

  constructor(private readonly sections: { [key: string]: SectionI },
    public readonly users: User[],
    revision?: Revision) {

    if (revision) {
      this.assignInitialSections(revision);
    }
  }

  public add(user: User, section: SectionI) {
    const sectionKey = this.getSectionKey(section);
    this.store[sectionKey] = user;
  }

  public remove(section: SectionI) {
    const sectionKey = this.getSectionKey(section);
    delete this.store[sectionKey];
  }

  public userFor(section: SectionI) {
    const sectionKey = this.getSectionKey(section);
    if (sectionKey) {
      return this.store[sectionKey];
    }
  }

  public isSectionSelected(section: SectionI): boolean {
    return !!this.userFor(section);
  }

  public getUserForId(id: number): User | undefined {
    return this.users.find(user => user.id === id);
  }

  public availableSections(): SectionI[] {
    return Object.values(this.sections);
  }

  public allSectionsAssigned(): boolean {
    return this.availableSections().length === this.numSectionsAssigned();
  }

  public userRevisions(): { user_id: number, section_key: string }[] {
    if (!this.allSectionsAssigned()) {
      throw new Error('Did not assign all sections');
    }

    return Object.keys(this.store).map(sectionKey => {
      return { section_key: sectionKey, user_id: this.store[sectionKey].id };
    });
  }

  private assignInitialSections(revision: Revision) {
    Object.entries(revision.user_sections).forEach(([sectionKey, userId]) => {
      const foundUser = this.users.find(user => user.id === userId);
      const section = this.sections[sectionKey];
      this.add(foundUser, section);
    });
  }

  private numSectionsAssigned(): number {
    return Object.keys(this.store).length;
  }

  private getSectionKey(section: SectionI) {
    const foundSectionKey = Object.keys(this.sections).find(sectionKey => {
      return this.sections[sectionKey] === section;
    });

    if (!foundSectionKey) {
      throw new Error('The given section was not produced by this assigner');
    }

    return foundSectionKey;
  }
}
