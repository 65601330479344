import { Feedback } from 'src/app/core/feedbacks';
import { User } from 'src/app/core/User';
import { Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { SurveyI, QuestionI, MetadataKeyFor, QuestionFor } from 'src/app/core/lib/models';

export class ReviewNotFoundError extends Error {
  constructor(feedback: Feedback) {
    super(`Review not found for: ${JSON.stringify(feedback)}`);
  }
}

export class UserNotFoundError extends Error {
  constructor(review: Review) {
    super(`User not found for: ${JSON.stringify(review)}`);
  }
}

export enum FeedbackState {
  ADDED = 'ADDED',
  STAGED = 'STAGED',
  FAILED_TO_SAVE = 'FAILED_TO_SAVE'
}

export class FeedbackManager {

  private readonly reviews: Map<number, Review> = new Map<number, Review>();
  private readonly feedbacks: Map<number | Symbol, Feedback> = new Map<number | Symbol, Feedback>();
  private readonly stagedFeedbacks: Map<number | Symbol, Feedback> = new Map<number | Symbol, Feedback>();
  private readonly failedToSaveFeedbacks: Map<number | Symbol, Feedback> = new Map<number | Symbol, Feedback>();

  constructor(
    private readonly revision: Revision,
    private readonly metadataKeyFor: MetadataKeyFor,
    private readonly questionFor: QuestionFor
  ) {
  }

  addReview(review: Review): void {
    this.reviews.set(review.id, review);
  }

  createFeedback(text: string, review: Review, question: QuestionI, survey: SurveyI): Feedback {
    return {
      id: Symbol(),
      text: text,
      review_id: review.id,
      revision_id: this.revision.id,
      metadata_key: this.metadataKeyFor({ question, survey })
    };
  }

  removeFeedback(id: number | Symbol) {
    this.feedbacks.delete(id);
  }

  addFeedback(feedback: Feedback) {
    this.stagedFeedbacks.delete(feedback.id);
    this.failedToSaveFeedbacks.delete(feedback.id);
    this.feedbacks.set(feedback.id, feedback);
  }

  addFeedbackIfBelongsToRevision(feedback: Feedback) {
    if (feedback.revision_id === this.revision.id) {
      this.addFeedback(feedback);
    }
  }

  stageFeedback(feedback: Feedback) {
    this.stagedFeedbacks.set(feedback.id, feedback);
  }

  failedToSave(feedback: Feedback) {
    if (this.stagedFeedbacks.has(feedback.id)) {
      this.stagedFeedbacks.delete(feedback.id);
      this.failedToSaveFeedbacks.set(feedback.id, feedback);
    } else {
      throw new Error('The given feedback was never staged and therefore cannot be failed to save: ' +
        JSON.stringify(feedback));
    }
  }

  updateFeedback(oldFeedback: Feedback, newFeedback: Feedback) {
    if (this.stagedFeedbacks.has(oldFeedback.id)) {
      this.stagedFeedbacks.delete(oldFeedback.id);
      this.stagedFeedbacks.set(newFeedback.id, newFeedback);
    } else {
      throw new Error('Cannot update a not present feedback');
    }
  }

  // This is a hacky function. It will look for a feedback that looks
  // similar enough to the one given one and update it to the parameter.
  // The intent is to always use the updateFeedback function, but the architecture
  // of the application doesn't permit for that yet
  looseUpdateFeedback(feedback: Feedback) {
    const foundFeedback = this.looseFinder(this.stagedFeedbacks, feedback);

    if (foundFeedback) {
      this.updateFeedback(foundFeedback, feedback);
    } else {
      throw new Error(`Could not loosely find feedback: ${JSON.stringify(feedback)}`);
    }
  }

  getFeedbacks(): Feedback[] {
    return Array.from(this.feedbacks.values())
      .concat(Array.from(this.stagedFeedbacks.values()))
      .concat(Array.from(this.failedToSaveFeedbacks.values()));
  }

  getFeedbacksFor(question: QuestionI, survey: SurveyI): Feedback[] {
    return this.getFeedbacks()
      .filter(feedback => {
        return this.questionFor({ survey, metadataKey: feedback.metadata_key }) === question;
      });
  }

  getUpdateDiff(): Feedback[] {
    return Array.from(this.stagedFeedbacks.values())
      .concat(Array.from(this.failedToSaveFeedbacks.values()));
  }

  hasFeedback(feedback: Feedback): boolean {
    return !!this.getFeedbacks().find(feedback_ => feedback.id === feedback_.id);
  }

  isStaged(feedback: Feedback): boolean {
    return this.stagedFeedbacks.has(feedback.id);
  }

  feedbackState(feedback: Feedback): FeedbackState {
    this.assertOnlyPresentInOneStore(feedback);
    if (this.feedbacks.has(feedback.id)) {
      return FeedbackState.ADDED;
    } else if (this.stagedFeedbacks.has(feedback.id)) {
      return FeedbackState.STAGED;
    } else if (this.failedToSaveFeedbacks.has(feedback.id)) {
      return FeedbackState.FAILED_TO_SAVE;
    } else {
      throw new Error('Manager has no knowledge of: ' + JSON.stringify(feedback));
    }
  }

  userNameForFeedback(feedback: Feedback): string {
    const { review_id } = feedback;
    const review = this.reviews.get(review_id);
    return review ? review.user.name : '';
  }

  private assertOnlyPresentInOneStore(feedback: Feedback) {
    const counter = (map: Map<number | Symbol, Feedback>): number => {
      return map.has(feedback.id) ? 1 : 0;
    };

    const total = [this.feedbacks, this.stagedFeedbacks, this.failedToSaveFeedbacks]
      .map(counter)
      .reduce((a, b) => a + b);

    if (total > 1) {
      throw new Error('Feedback present in more than one state: ' + JSON.stringify(feedback));
    }
  }

  private looseFinder(store: Map<number | Symbol, Feedback>, lookingFor: Feedback): Feedback | undefined {
    return Array.from(store.values()).find(feedback => {
      return feedback.review_id === lookingFor.review_id &&
        feedback.revision_id === lookingFor.revision_id &&
        feedback.text === lookingFor.text &&
        this.allSameKeys(feedback.metadata_key, lookingFor.metadata_key); // Heuristic check for same metadata
    });
  }

  private allSameKeys(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    const allKeysFound = keys1.reduce((prev, key) => {
      return prev && keys2.includes(key);
    }, true);

    return keys1.length === keys2.length && allKeysFound;
  }

  private tryGetReview(feedback: Feedback): Review {
    const review = this.reviews.get(feedback.review_id);

    if (!review) {
      throw new ReviewNotFoundError(feedback);
    }

    return review;
  }

}
