import { Component, OnInit } from '@angular/core';
import { TheManual } from 'src/app/core/manual';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})
export class ManualComponent {
  public readonly manual = TheManual;

  public getCurrent() {
    return this.manual.getCurrent();
  }

  public visit(node) {
    this.manual.visit(node);
  }

  public getNext() {
    return this.manual.getNext();
  }

  public back() {
    this.manual.back();
  }

}
