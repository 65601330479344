import { ETag } from './e-tag';

export abstract class URLToETag {
  abstract get(url: string): ETag;
  abstract set(url: string, eTag: ETag): void;
  abstract remove(url: string): void;
}

export class URLToETagImpl extends URLToETag {
  get(url: string): string {
    const cached = localStorage.getItem(this.makeLocalStorageString(url));
    return cached;
  }

  set(url: string, eTag: string): void {
    localStorage.setItem(this.makeLocalStorageString(url), eTag);
  }

  remove(url: string) {
    localStorage.removeItem(this.makeLocalStorageString(url));
  }

  // HACK
  private makeLocalStorageString(url: string): string {
    return `ETAG_FOR: ${url}`;
  }
}
