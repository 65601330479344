import { get } from 'lodash/fp';
import { OptionI, QuestionI } from 'src/app/core/lib/models';
import { SelectionRequestHandler, SelectionRequestHook } from './selection-request-handler';

export class AnnotationSelectionRequestHandler extends SelectionRequestHandler {
  private stagedSelection: OptionI | undefined;
  private stagedAnnotation: string | undefined;
  private errorMessage: string | undefined;

  constructor(makeSelection: SelectionRequestHook,
    question: QuestionI) {
    super(makeSelection, question);
    this.stagedSelection = this.getSelection();
    this.stagedAnnotation = this.getAnnotation();
  }

  public handleSelectionRequest(selection: OptionI): void {
    this.stagedSelection = selection;
    this.trySubmitStagedParameters();
  }

  public handleAnnotationRequest(annotation: string) {
    this.stagedAnnotation = annotation;
    this.trySubmitStagedParameters();
  }

  public getTransientState() {
    return {
      errorMessage: this.errorMessage,
      transientOption: this.stagedSelection
    };
  }

  private trySubmitStagedParameters() {
    if (this.maySubmit()) {
      super.handleSelectionRequest(this.stagedSelection, this.stagedAnnotation);
      this.errorMessage = undefined;
    } else {
      this.errorMessage = 'Reason required to complete selection';
    }
  }

  private maySubmit(): boolean {
    return this.stagedSelection &&
      this.stagedAnnotation &&
      this.stagedAnnotation.length > 0;
  }

  private getSelection(): OptionI | undefined {
    return this.question.options.find(get('selected'));
  }

  private getAnnotation(): string | undefined {
    const selection = this.getSelection();
    return selection ? selection.annotation : undefined;
  }
}
