import { isEqual, compose, get, overSome } from 'lodash/fp';
import { Review } from '../../reviews';
import { ReportReview } from '.';

type AReview = Review | ReportReview;

export enum ReviewTypeTag {
  Approval = 'Approval Review',
  Classification = 'Classification Review',
  Review = 'Review'
}

const APPROVAL_REVIEW_RESPONSE = 'Approve';

// TODO. These are bad abstractions. The two separate interfaces for dealing with
// Reviews should not be handled the same way.
export function findByTypeTag<T extends AReview>(reviewTypeTag: ReviewTypeTag, reviews: T[]): T | undefined {
  const helperForReportReview = compose(isEqual(reviewTypeTag), get('name'));
  const helperForReview = compose(isEqual(reviewTypeTag), get('review_type.name'));
  const helper = overSome([helperForReportReview, helperForReview]);
  return reviews.find(helper);
}

export function isApproved<T extends AReview>(review: T): boolean {
  return ((<Review>review).response === APPROVAL_REVIEW_RESPONSE) ||
    ((<ReportReview>review).result === APPROVAL_REVIEW_RESPONSE);
}
