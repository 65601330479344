import { memoize } from 'lodash';
import { AnalysisOptionSelections, AnalysisOptionsResult, MakeAnalysisOptions, SurveyI } from 'src/app/core/lib/models';
import { AnalysisSelection, AnalysisSelectionParameter, AnalysisSelectionParameters } from '..';
import { GraphData } from '../graph-data';
import { LibGraphData } from '../lib-graph-data';
import { LibRankData } from '../lib-rank-data';
import { RankData } from '../rank-data';
import { LibAnalysisSelectionParameters } from './selection-parameters';

export class LibAnalysisSelection extends AnalysisSelection {

  constructor(private readonly makeAnalysisOptions: MakeAnalysisOptions) {
    super();
    this.update();
  }

  private analysisOptionsResult: AnalysisOptionsResult;
  private parameters: AnalysisSelectionParameters;

  // Must be cached for a given Survey
  public readonly getGraphData: (survey: SurveyI) => GraphData = memoize(function(survey: SurveyI) {
    const internalGraphOptions = this.analysisOptionsResult.makeGraphOptions(survey);
    return new LibGraphData(internalGraphOptions);
  });

  public getParameters(): AnalysisSelectionParameters {
    return this.parameters;
  }

  public updateParameters(parameter: AnalysisSelectionParameter, value: string | boolean) {
    const key = parameter.getName();
    const newSelections = Object.assign({}, this.analysisOptionsResult.selections, { [key]: value });
    this.update(newSelections);
    this.notifyUpdateOccurred();
  }

  public getRankData(survey: SurveyI): RankData {
    const internalRankData = this.analysisOptionsResult.makeRankData(survey);
    return new LibRankData(internalRankData);
  }

  public shouldShow(survey: SurveyI): boolean {
    return this.analysisOptionsResult.shouldShow(survey);
  }

  private update(selections?: AnalysisOptionSelections) {
    this.internalUpdateParameters(selections);
    this.clearCaches();
  }

  private internalUpdateParameters(selections?: AnalysisOptionSelections) {
    this.analysisOptionsResult = this.makeAnalysisOptions({
      selections: selections || {}
    });

    this.parameters = new LibAnalysisSelectionParameters(this.analysisOptionsResult.options);
  }

  private clearCaches() {
    // Index on the string 'cache' because we still declare the type of the
    // function in the super class
    this.getGraphData['cache'].clear();
  }
}
