import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.css']
})
export class LoginEmailFormComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) { }

  ngOnInit() {
  }

  getEmail(): string {
    return this.loginStateService.getEmail();
  }

  setEmail(email: string) {
    this.loginStateService.setEmail(email);
  }

}
