import { Injectable } from '@angular/core';
import { Model } from '../models';
import { Organization, OrganizationKind } from '../organizations/Organization';
import { OrganizationsService } from '../organizations/service';
import { Region } from '../Region';
import { RegionKind } from '../regions';
import { RegionsService } from '../regions/service';
import { ReviewCompletion, ReviewCompletionKind } from '../review-completion/review-completion';
import { ReviewCompletionService } from '../review-completion/service';
import { Station, StationKind } from '../stations';
import { StationsService } from '../stations/service';
import { User } from '../User';
import { UserKind } from '../users';
import { ModelService } from './model-service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ModelServiceDispatcherService {

  private readonly checks: ((model: Model) => ModelService<any> | null)[] = [
    this.checker<Organization>(OrganizationKind, this.organizationsService),
    this.checker<Region>(RegionKind, this.regionsService),
    this.checker<Station>(StationKind, this.stationsService),
    this.checker<User>(UserKind, this.usersService),
    this.checker<ReviewCompletion>(ReviewCompletionKind, this.reviewCompletionService)
  ];

  constructor(private readonly organizationsService: OrganizationsService,
    private readonly regionsService: RegionsService,
    private readonly stationsService: StationsService,
    private readonly usersService: UserService,
    private readonly reviewCompletionService: ReviewCompletionService
  ) {
  }

  public getService<T extends Model>(model: T): ModelService<T> {
    const maybeModelService = this.tryGetModelService<T>(model);

    if (maybeModelService) {
      return maybeModelService;
    }

    throw new Error(`No service found for \n ${JSON.stringify(model)}`);
  }

  private tryGetModelService<T extends Model>(model: T): ModelService<T> | null {
    let modelService: ModelService<T>;

    this.checks.forEach(checker => {
      const maybeModelService = checker(model);
      if (maybeModelService) {
        modelService = maybeModelService;
      }
    });

    return modelService;
  }

  private checker<T extends Model>(kind: string, modelService: ModelService<T>):
    (model: Model) => ModelService<T> | null {
    return (model: Model) => {
      return model.kind === kind ? modelService : null;
    };
  }
}
