import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { every, get } from 'lodash/fp';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, flatMap, map } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import { findByTypeTag, isApproved, ReviewTypeTag } from 'src/app/core/models/reporting/review-type-tag';
import { Review } from 'src/app/core/reviews';
import { FinalizeRevisionAction, Revision, UnFinalizeRevisionAction } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { ParameterizedRevisionDirective } from '../parameterized-revision.component';
import { selectReviewsForRevision, State } from '../rx';

@Component({
  selector: 'app-revisions',
  templateUrl: './revisions.component.html',
  styleUrls: ['./revisions.component.css']
})
export class RevisionsComponent extends ParameterizedRevisionDirective {
  public currentRevision$: Observable<Revision | undefined> = this.makeCurrentRevision$();
  private currentRevisionId: number | undefined;
  private errorMessage: string | undefined;

  constructor(store: Store<State>) {
    super(store);
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  public getTitle(): string {
    return `${this.job.name} - ${this.station.name}`;
  }

  public getRevisions$(): Observable<Revision[]> {
    return this.revisions$;
  }

  public getJob(): Job {
    return this.job;
  }

  public getStation(): Station {
    return this.station;
  }

  public handleRevisionChange(revisionId: number | undefined) {
    this.currentRevisionId = revisionId ?
      Number(revisionId) :
      undefined;

    this.currentRevision$ = this.makeCurrentRevision$();
  }

  public isSelectedRevision(revision: Revision) {
    return revision.id === this.currentRevisionId;
  }

  public mayFinalize$(): Observable<boolean> {
    return this.currentRevision$.pipe(
      map(currentRevision =>
        currentRevision &&
        (currentRevision.active || currentRevision.final)));
  }

  public isFinal$(): Observable<boolean> {
    return this.currentRevision$.pipe(
      map(currentRevision => currentRevision && currentRevision.final));
  }

  public handleFinalizeToggleRequest() {
    this.isFinal$().pipe(first()).subscribe(isFinal => {
      if (isFinal) {
        this.dispatchUnFinalize();
      } else {
        this.handleFinalizeRequest();
      }
    });
  }

  private makeCurrentRevision$(): Observable<Revision | undefined> {
    return this.revisions$
      .pipe(map(revisions => revisions
        .find(revision => revision.id === this.currentRevisionId)));
  }

  private handleFinalizeRequest() {
    const helper = (reviews: Review[]) => {
      if (!this.hasAppropriateReviewsToFinalize(reviews)) {
        this.tempSetError('Finalization requires both a Classification and an Approval review');
      } else if (!this.isApproved(reviews)) {
        this.tempSetError('Finalization requires that the Revision has been Approved');
      } else if (!this.allReviewsComplete(reviews)) {
        this.tempSetError('Finalization requires all reviews be complete');
      } else {
        this.dispatchFinalize();
      }
    };

    combineLatest([
      this.getReviewsForCurrentRevision$()
    ]).pipe(first())
      .subscribe(([reviews]) => helper(reviews));
  }

  private hasAppropriateReviewsToFinalize(reviews: Review[]): boolean {
    const approvalReview = findByTypeTag(ReviewTypeTag.Approval, reviews);
    const classificationReview = findByTypeTag(ReviewTypeTag.Classification, reviews);
    return !!(approvalReview && classificationReview);
  }

  private isApproved(reviews: Review[]): boolean {
    const approvalReview = findByTypeTag(ReviewTypeTag.Approval, reviews);
    return approvalReview && isApproved(approvalReview);
  }

  private allReviewsComplete(reviews: Review[]): boolean {
    return every(get('complete_at'), reviews);
  }

  private getReviewsForCurrentRevision$(): Observable<Review[]> {
    return this.currentRevision$.pipe(
      filter(revision => !!revision),
      flatMap(revision =>
        this.store.pipe(
          select(selectReviewsForRevision(),
            { revision }))));
  }

  private dispatchFinalize() {
    this.currentRevision$.pipe(first()).subscribe(revision => {
      const action = new FinalizeRevisionAction({ revision });
      this.store.dispatch(action);
    });
  }

  private dispatchUnFinalize() {
    this.currentRevision$.pipe(first()).subscribe(revision => {
      const action = new UnFinalizeRevisionAction({ revision });
      this.store.dispatch(action);
    });
  }

  private tempSetError(errorMessage: string, ms = 3000) {
    this.errorMessage = errorMessage;
    setTimeout(() => this.errorMessage = undefined, ms);
  }
}
