import { adapter } from './adapter';
import { EntityState } from '@ngrx/entity';
import { Job } from './model';

export interface State extends EntityState<Job> {
  selectedId: number | null;
}

export const initial: State = adapter.getInitialState({
  selectedId: null,
});
