import { LocationCopy, LocationParents } from '../locations/location-copy';
import { Region } from "../Region";
import { Organization } from './Organization';
import { Observable } from 'rxjs';

export class OrganizationCopy extends LocationCopy<Organization> {
  public readonly hasParent: boolean = false;

  public setParent(model: Organization | Region) {
    throw new Error('Can\'t set parent of organization');
  }

  public getAvailableParents(organizations$, regions$): LocationParents {
    throw new Error('Can\'t get parents of organization');
  }

  public getParent(organizations$, regions$): Observable<Organization | Region | null> {
    throw new Error('Can\'t get parents of organization');
  }
}
