import { LocationI } from '../locations';
import { Station, StationKind } from '../stations';
import { RankData } from './analysis/rank-data';
import { RankDataForSurvey } from './rank-data-for-survey';
import { AnalysisSelection } from './analysis';

export class MapDataColor {
  private static DEFAULT_COLOR = 'blue';

  constructor(private readonly location: LocationI,
              private readonly rankDataForSurvey: RankDataForSurvey | null,
              private readonly analysisSelection: AnalysisSelection) {
  }

  get(): string {
    if (this.location.kind === StationKind) {
      return this.getStationColor();
    } else {
      return MapDataColor.DEFAULT_COLOR;
    }
  }

  private getStationColor(): string {
    const rankData = this.getRankData();

    if (rankData) {
      const survey = this.rankDataForSurvey.getSurvey(<Station>this.location);

      if (survey && !this.analysisSelection.shouldShow(survey)) {
        return MapDataColor.DEFAULT_COLOR;
      } else {
        return rankData.getColor();
      }
    } else {
      return MapDataColor.DEFAULT_COLOR;
    }
  }

  private getRankData(): RankData | null {
    return this.rankDataForSurvey ?
      this.rankDataForSurvey.get(<Station>this.location) :
      null;
  }
}
