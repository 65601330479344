import { Component, OnInit, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { OptionI, QuestionI } from 'src/app/core/lib/models';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { SelectionRequestHandlerFactory } from './selection-request-handler-factory';
import { SelectionRequestHandler } from './selection-request-handler';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent extends ActiveSurveyDirective implements OnInit {
  @Input() public readonly overflow: boolean;
  private readonly selectionRequestHandlerFactory =
    new SelectionRequestHandlerFactory(this.makeSelection.bind(this));
  private selectionRequestHandler: SelectionRequestHandler | undefined;

  ngOnInit() {
    this.currentQuestion$.pipe(this.takeUntilDestroy).subscribe((question: QuestionI) => {
      if (question) {
        this.selectionRequestHandler = this.selectionRequestHandlerFactory.get(question);
      }
    });
  }

  public handleSelectionRequest(selection: OptionI) {
    if (this.selectionRequestHandler) {
      this.selectionRequestHandler.handleSelectionRequest(selection);
    }
  }

  public handleAnnotationRequest(annotation: string) {
    if (this.selectionRequestHandler) {
      this.selectionRequestHandler.handleAnnotationRequest(annotation);
    }
  }

  public getTransientOption(): OptionI | undefined {
    if (this.selectionRequestHandler) {
      return this.selectionRequestHandler.getTransientState().transientOption;
    }
  }

  public getError(): string | undefined {
    if (this.selectionRequestHandler) {
      return this.selectionRequestHandler.getTransientState().errorMessage;
    }
  }

  private makeSelection(question: QuestionI, selection: OptionI, annotation?: string) {
    this.surveyWrapper$.pipe(first()).subscribe(surveyWrapper => {
      surveyWrapper.makeSelection({
        question,
        annotation,
        selection
      });

      this.dispatchSurveyStateUpdate();
    });
  }
}
