import { Injectable } from '@angular/core';
import { SurveyWrapper } from '.';
import { Review } from '../reviews';
import { PositionInSurvey } from './position-in-survey';
import { ReviewAccessControl } from './review-access-control';
import { SurveyActionsService } from './service';
import { AdditionalAccessControlModels } from './survey-access-control';
import { ReviewSurveyWrapperService } from './survey-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewSurveyActionsService extends SurveyActionsService<Review> {
  constructor(reviewSurveyWrapperService: ReviewSurveyWrapperService) {
    super(reviewSurveyWrapperService);
  }

  protected createAccessControl(
    surveyWrapper: SurveyWrapper,
    models: AdditionalAccessControlModels
  ): ReviewAccessControl {
    return new ReviewAccessControl(surveyWrapper, models.user, models.review);
  }

  protected createPositionInSurvey(surveyWrapper: SurveyWrapper): PositionInSurvey {
    return new PositionInSurvey(surveyWrapper);
  }
}
