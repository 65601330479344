import { Observable } from 'rxjs';
import { Omit } from 'src/app/shared/utils';
import { LocationI } from '.';
import { ModelCopy } from '../models/model-copy';
import { Organization } from '../organizations/Organization';
import { Region } from "../Region";

interface LocationCopyConstructorArguments {
  model?: LocationI;
  kind?: string;
}

export type LocationCopyConstructor<T extends LocationI> =
  new (args: LocationCopyConstructorArguments) => LocationCopy<T>;

export abstract class LocationParents {
  organizations$?: Observable<Organization[]>;
  regions$?: Observable<Region[]>;
}

export class OrganizationParents extends LocationParents {
  constructor(public readonly organizations$: Observable<Organization[]>) {
    super();
  }
}

export class OrganizationsAndRegionParents extends LocationParents {
  constructor(public readonly organizations$: Observable<Organization[]>,
    public readonly regions$: Observable<Region[]>) {
    super();
  }
}

export abstract class LocationCopy<T extends LocationI> extends ModelCopy<T> implements LocationI {
  public id: number;
  public name: string;
  public latitude: number;
  public longitude: number;
  public city: string;
  public state_abbrev: string;
  public zip: string;
  public street_address: string;
  public nick: string;
  public map_file_uri: string;
  public image_uri: string;
  public kind: string;

  public abstract readonly hasParent: boolean;

  protected readonly validators = [
    this.validator(() => !!this.name, 'Name is not valid'),
    this.validator(() => !!this.nick, 'Shorthand is not valid'),
    this.validator(() => !!this.latitude && this.latitude >= -90 && this.latitude <= 90, 'Latitude is not valid'),
    this.validator(() => !!this.longitude && this.longitude >= -180 && this.longitude <= 180, 'Longitude is not valid'),
    this.validator(() => !!this.state_abbrev, 'Address is not valid'),
    this.validator(() => !!this.city, 'City is not valid'),
    this.validator(() => !!this.state_abbrev, 'State is not valid'),
    this.validator(() => !!this.zip, 'Zip code is not valid')
  ];

  public abstract getAvailableParents(
    organizations: Observable<Organization[]>,
    regions: Observable<Region[]>
  ): LocationParents;

  public abstract getParent(
    organizations$: Observable<Organization[]>,
    regions$: Observable<Region[]>): Observable<Organization | Region | null>;

  public abstract setParent(model: Organization | Region): void;

  protected createJSON(): Omit<T, 'kind'> {
    const rv: Omit<LocationI, 'kind'> = {
      id: this.id,
      name: this.name,
      nick: this.nick,
      latitude: this.latitude,
      longitude: this.longitude,
      city: this.city,
      state_abbrev: this.state_abbrev,
      zip: this.zip,
      street_address: this.street_address,
      map_file_uri: this.map_file_uri,
      image_uri: this.image_uri,
    };

    return <Omit<T, 'kind'>><unknown>rv;
  }

  protected copyOverFields(location: T) {
    this.id = location.id;
    this.name = location.name;
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.city = location.city;
    this.state_abbrev = location.state_abbrev;
    this.zip = location.zip;
    this.street_address = location.street_address;
    this.nick = location.nick;
    this.map_file_uri = location.map_file_uri;
    this.image_uri = location.image_uri;
    this.kind = location.kind;
  }
}
