import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lib, QuestionMetadata } from 'src/app/core/lib/models';
import { LibService } from 'src/app/core/lib/service';
import { ReviewCompletionManager } from 'src/app/core/models/review-completion-manager/review-completion-manager';
import { LibReviewCompletion } from '../review-actions/lib-review-completion';

@Injectable({
  providedIn: 'root'
})
export class ReviewCompletionManagerService {
  constructor(private readonly libService: LibService) {
  }

  public get$(totalQuestionsNeeded: number): Observable<ReviewCompletionManager<QuestionMetadata>> {
    const helper = (lib: Lib) => {

      const libReviewCompletion = new LibReviewCompletion(
        lib.metadataKeyFor,
        lib.questionFor,
        lib.metadataCompare);

      const reviewCompletionManager = new ReviewCompletionManager(libReviewCompletion);

      reviewCompletionManager.setTotalQuestionsNeeded(totalQuestionsNeeded);
      return reviewCompletionManager;
    };

    const lib$ = this.libService.get();

    return lib$.pipe(map(lib => helper(lib)));
  }
}
