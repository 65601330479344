import { Injectable } from '@angular/core';
import { Organization } from "../organizations/Organization";
import { LocationPermissionsDiff } from './location-diff-actions';
import { User } from "../User";
import { ApiTokenService } from '../services/api-token.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Region } from "../Region";
import { Station } from '../stations';
import { forkJoin, Observable } from 'rxjs';

class JoinRequestCreator<T extends { id: number }> {

  public constructor(private readonly joinName: string, private readonly locationForeignKey: string) {
  }

  public createRequests(user: User, diff: LocationPermissionsDiff<T>): { createRequests: any[], destroyRequests: any[] } {
    const createRequests = this.createRequestParameters(
      user,
      diff.toCreate,
      this.createCreateExtension.bind(this),
      this.createCreateParams.bind(this)
    );

    const destroyRequests = this.createRequestParameters(
      user,
      diff.toDelete,
      this.createDestroyExtension.bind(this),
    );

    return { createRequests, destroyRequests };
  }

  private prepareRequests(user: User, models: T[], extensionFunc) {
    this.createRequestParameters(user, models, extensionFunc);
  }

  private createDestroyExtension(user: User, model: T) {
    const join = user[this.joinName].find(join => join[this.locationForeignKey] === model.id);

    if (!join) {
      console.warn(user);
      console.warn(model);
      throw new Error('Location join not found');
    }

    return `/${this.joinName}/${join.id}`;
  }

  private createCreateExtension(user: User, model: T): string {
    return `/${this.joinName}`;
  }

  private createCreateParams(user, model: T): any {
    return { user_id: user.id, [this.locationForeignKey]: model.id };
  }

  private createRequestParameters(
    user: User, models: T[],
    extensionFunc: (user: User, model: T) => string,
    paramsFunc?: (user: User, model: T) => any): { urlExtension: string, params?: any }[] {

    return models.map(model => {
      const urlExtension = `/api${extensionFunc(user, model)}`;

      let params: any;
      if (paramsFunc) {
        params = paramsFunc(user, model);
      }

      return { urlExtension, params };
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocationDiffService {

  private readonly organizationRequestCreator =
    new JoinRequestCreator<Organization>('user_organizations', 'organization_id');
  private readonly regionRequestCreator =
    new JoinRequestCreator<Region>('user_regions', 'region_id');
  private readonly stationRequestCreator =
    new JoinRequestCreator<Station>('user_stations', 'station_id');

  public readonly updateOrganizationPermissions = this.updatePermissions<Organization>(this.organizationRequestCreator);
  public readonly updateRegionPermissions = this.updatePermissions<Region>(this.regionRequestCreator);
  public readonly updateStationPermissions = this.updatePermissions<Station>(this.stationRequestCreator);

  constructor(private apiTokenService: ApiTokenService, private http: HttpClient) {
  }

  private updatePermissions<T extends { id: number }>(joinRequestCreator: JoinRequestCreator<T>) {
    return (user: User, diff: LocationPermissionsDiff<T>): Observable<User> => {

      const { createRequests, destroyRequests } = joinRequestCreator.createRequests(user, diff);

      const createdCreateRequests = createRequests.map(
        ({ urlExtension, params }) => this.makeCreateRequest(urlExtension, params));
      const createdDestroyRequests = destroyRequests.map(
        ({ urlExtension }) => this.makeDestroyRequest(urlExtension));

      return forkJoin(createdCreateRequests.concat(createdDestroyRequests)).pipe(map(() => user));
    };
  }

  private makeCreateRequest(urlExtension: string, params) {
    return this.http.post<{ id?: number }>
      (this.makeFullUrl(urlExtension), params, { headers: this.apiTokenService.headers() }).pipe(
        map(response => {
          if (!response.id) {
            throw new Error('Could not successfully create location join');
          }
        })
      );
  }

  private makeDestroyRequest(urlExtension: string) {
    return this.http.delete<{ success: boolean }>(
      this.makeFullUrl(urlExtension), { headers: this.apiTokenService.headers() }).pipe(
        map(response => {
          if (!response.success) {
            throw new Error('Could not successfully destroy request');
          }
        })
      );
  }

  private makeFullUrl(urlExtension: string): string {
    return environment.apiUri.toString() + urlExtension;
  }
}
