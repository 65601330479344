import { MockApi } from './mock-api';

import { HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

export abstract class SimpleMockApiInterceptor<T> extends MockApi<T> {
  protected abstract correctExtension: string;
  protected abstract getBody(): T;
  protected correctMethod: string = 'GET';

  protected shouldHandle(request: HttpRequest<any>): boolean {
    return this.isCorrectExtension(request.url) && this.isCorrectMethod(request.method);
  }

  protected handle(request: HttpRequest<any>): Observable<HttpResponse<T>> {
    return of(new HttpResponse({ status: 200, body: this.getBody() }));
  }

  private isCorrectExtension(url: string): boolean {
    return url.endsWith(this.correctExtension);
  }

  private isCorrectMethod(method: string): boolean {
    return method === this.correctMethod;
  }
}
