import { LoginState } from './login-state';
import { EmailLoginState } from './email-login-state';
import { GetLoginState } from './login-state-controller';

export class PasswordLoginState extends LoginState {
  constructor(getLoginState: GetLoginState) {
    super(getLoginState);
  }

  back() {
    return this.getLoginState(EmailLoginState);
  }
}
