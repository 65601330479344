import { RankData } from './analysis/rank-data';
import { Station } from '../stations';
import { FinalizedSurveys } from './finalized-surveys';
import { AnalysisSelection } from './analysis';
import { SurveyI } from '../lib/models';

export abstract class RankDataForSurvey {
  public abstract get(station: Station): RankData | null;
  public abstract getSurvey(station: Station): SurveyI | null;
}

export class RankDataForSurveyImpl extends RankDataForSurvey {
  constructor(private readonly finalizedSurveys: FinalizedSurveys,
    private readonly analysisSelection: AnalysisSelection
  ) {
    super();
  }

  public get(station: Station): RankData {
    const maybeSurvey = this.getSurvey(station);
    return maybeSurvey ?
      this.analysisSelection.getRankData(maybeSurvey) :
      null;
  }

  public getSurvey(station: Station): SurveyI | null {
    return this.finalizedSurveys.surveyFor(station);
  }
}
