import { CacheableRequest, GetImpl, CacheableRequestOptions } from './cacheable-request';
import { URLToCachedResponseImpl } from './url-to-cached-response';
import { URLToETagImpl } from './url-to-etag';

export class CacheableRequestImpl extends CacheableRequest {
  private readonly urlToResponse = new URLToCachedResponseImpl();
  private readonly urlToETag = new URLToETagImpl();

  constructor(options: CacheableRequestOptions, protected readonly getImpl: GetImpl) {
    super(options);
  }

  protected storeETag(url: string, eTag: string): void {
    this.urlToETag.set(url, eTag);
  }

  protected storeResponse<T>(url: string, body: T): void {
    this.urlToResponse.set<T>(url, body);
  }

  protected getETag(url: string): string {
    return this.urlToETag.get(url);
  }

  protected getCachedResponse<T>(url: string): T {
    return this.urlToResponse.get<T>(url);
  }

  protected removeETag(url: string) {
    this.urlToETag.remove(url);
  }

  protected hasCachedResponse(url: string): boolean {
    return this.urlToResponse.has(url);
  }
}
