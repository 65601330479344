import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Organizations from 'src/app/core/organizations';
import { Organization } from 'src/app/core/organizations/Organization';
import { State } from 'src/app/reducers';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.css']
})
export class SelectOrganizationComponent implements OnInit {

  public readonly organizations$: Observable<Organization[]> = this.makeOrganizations$();

  constructor(private readonly store: Store<State>,
    private readonly loginStateService: LoginStateService
  ) {
  }

  ngOnInit() {
    this.loadOrganizations();
  }

  selectOrganization(organization: Organization) {
    this.loginStateService.setOrganization(organization);
  }

  isSelectedOrganization(organization: Organization): boolean {
    return organization === this.loginStateService.getOrganization();
  }

  private makeOrganizations$(): Observable<Organization[]> {
    const helper = (state: State) => Object.values(state.organizations.entities);
    return this.store.pipe(map(helper));
  }

  private loadOrganizations() {
    this.store.dispatch(new Organizations.LoadAll());
  }
}
