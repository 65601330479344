import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { State } from '../rx';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Component({
  selector: 'app-survey-footer',
  templateUrl: './survey-footer.component.html',
  styleUrls: ['./survey-footer.component.css']
})
export class SurveyFooterComponent extends ActiveSurveyDirective {

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
  ) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);
  }

  handleNext() {
    this.activeSurveyFactoryService.next();
  }

  handlePrevious() {
    this.activeSurveyFactoryService.previous();
  }
}
