import { ApiV2Service } from './../services/api-v2.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewType } from '.';

@Injectable({
    providedIn: 'root'
})
export class ReviewTypeService {

    private readonly urlExtension: string = 'review_types';

    constructor(private readonly api : ApiV2Service) {
    }

    get(): Observable<ReviewType[]> {
        return this.api.get(this.urlExtension);
    }
}