import { SurveyUpdate } from './model';
import { Observable } from 'rxjs';
import { ApiV2Service } from '../services/api-v2.service';
import { first } from 'rxjs/operators';

export class SurveyUpdatesShim {

  constructor(protected readonly api: ApiV2Service,
    protected readonly apiExtension: string) {
  }

  get(revisionId: number): Observable<SurveyUpdate[]> {
    return this.makeRequest(revisionId);
  }

  addData(userId: number, revisionId: number, data: any): void {
    // Do nothing
  }

  protected makeRequest(revisionId: number): Observable<SurveyUpdate[]> {
    return this.api.get(this.makeRequestUrl(revisionId)).pipe(first());
  }

  protected makeRequestUrl(revisionId: number): string {
    return `${this.apiExtension}?revision_id=${revisionId}`;
  }
}
