import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LoadEffect } from '../effects/load';
import { ReviewTypeService } from './service';

export interface ReviewType {
  readonly id: number;
  readonly name: string;
  readonly available_responses: string[];
}

export enum Types {
  AddAll = '[Review Types] Add All',
  LoadAll = '[Review Types] Load All'
}

interface ReviewTypeAction extends Action {
  type: Types;
}

export class AddAll implements ReviewTypeAction {
  type = Types.AddAll;

  constructor(public readonly payload: { reviewTypes: ReviewType[] }) {

  }
}

export class LoadAll implements ReviewTypeAction {
  type = Types.LoadAll;
}

const adapter: EntityAdapter<ReviewType> = createEntityAdapter<ReviewType>({

});

export interface State extends EntityState<ReviewType> {
}

const initial: State = adapter.getInitialState({});

type Union = AddAll | LoadAll;

export function reducer(state = initial, action: Union) {
  switch (action.type) {
    case (Types.AddAll): {
      return adapter.addMany((<AddAll>action).payload.reviewTypes, state);
    }

    default: {
      return state;
    }
  }
}

@Injectable()
export class LoadAllEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions,
    private readonly reviewTypeService: ReviewTypeService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAll];
  }

  protected handleResponse(reviewTypes: ReviewType[]) {
    return new AddAll({ reviewTypes });
  }

  protected handleRequest(action: any) {
    return this.reviewTypeService.get();
  }
}
