import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuestionMetadata } from 'src/app/core/lib/models';
import { ReviewCompletionManager } from 'src/app/core/models/review-completion-manager/review-completion-manager';
import { ReviewCompletion } from 'src/app/core/review-completion/review-completion';
import { State } from '../rx';
import { ReviewCompletionManagerService } from '../services/review-completion-manger.service';

@Injectable({
  providedIn: 'root'
})
export class UpdatingReviewCompletionMangerService {
  constructor(
    private readonly store: Store<State>,
    private readonly reviewCompletionManagerService: ReviewCompletionManagerService
  ) {
  }

  public get$(totalQuestionsNeeded: number): Observable<ReviewCompletionManager<QuestionMetadata>> {
    return this.reviewCompletionManagerService.get$(totalQuestionsNeeded)
      .pipe(tap(this.setReviewCompletionsWhenReceived.bind(this)));
  }

  private setReviewCompletionsWhenReceived(reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) {
    const currentReviewCompletionsSelector = createSelector(
      (state: State) => state.surveys.currentReviewId,
      (state: State) => Object.values(state.reviewCompletions.entities),
      (currentReviewId, reviewCompletions) => {
        if (currentReviewId) {
          return reviewCompletions
            .filter(reviewCompletion => reviewCompletion.review_id === currentReviewId);
        } else {
          return [];
        }
      }
    );

    const helper = (
      reviewCompletions: ReviewCompletion[],
    ) => {
      const metadata = reviewCompletions
        .map(reviewCompletion => reviewCompletion.metadata_key);
      reviewCompletionManager
        .setCompletionsByInternalRepresentations(metadata);
    };

    this.store.pipe(select(currentReviewCompletionsSelector)).subscribe(helper);
  }

}
