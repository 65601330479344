import { LocalStorageItem, LocalStorageKey } from './local-storage-item';
import { toPairs, forEach } from 'lodash/fp';
import { ExtendableMap } from '../extendable-map';

export class LocalStorageMap<V> extends ExtendableMap<string, V> {
  constructor(private readonly localStorageKey: LocalStorageKey,
    entries?: ReadonlyArray<[string, V]> | null) {
    super(entries);
    this.doInitialSync(entries);
  }

  set(key: string, value: V, skipLocalStorage = false): this {
    super.set(key, value);

    if (skipLocalStorage === false) {
      this.internalSet(key, value);
    }

    return this;
  }

  clear() {
    super.clear();
    this.getLocalStorageItem().clear();
  }

  private doInitialSync(entries: ReadonlyArray<[string, V]> | null) {
    if (entries) {
      this.writeInitialEntriesToLocalStorage(entries);
    }

    this.updateThisFromLocalStorage();
  }

  private writeInitialEntriesToLocalStorage(entries: ReadonlyArray<[string, V]>) {
    entries.forEach((args) => this.internalSet(...args));
  }

  private updateThisFromLocalStorage() {
    forEach(([key, value]: [any, any]) => super.set(key, value), toPairs(this.getLocalStorageItem().get()));
  }

  private internalSet(key: string, value: V) {
    this.getLocalStorageItem().update((obj: any) => {
      obj[key] = value;
      return obj;
    });
  }

  private getLocalStorageItem(): LocalStorageItem<any> {
    return new LocalStorageItem<any>(this.localStorageKey, {});
  }
}

export class LocalStorageMapParseStrings<V> extends LocalStorageMap<V> {
  get(key: number | string): V {
    return super.get(String(key));
  }

  set(key: number | string, value: V, skipLocalStorage = false): this {
    return super.set(String(key), value, skipLocalStorage);
  }

  has(key: number | string): boolean {
    return super.has(String(key));
  }
}
