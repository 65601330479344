import { MockApi } from '../interceptors/mock-api';
import { Station, stationGen } from '.';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SimpleMockApiInterceptor } from '../interceptors/simple-mock-api';
import { gen, sampleOne } from 'testcheck';

abstract class StationsInterceptor extends SimpleMockApiInterceptor<Station[]> {
  protected correctMethod: string = 'GET';
  protected abstract stationIds: number[];

  private stations;

  protected getBody(): Station[] {
    if (!this.stations) {
      this.setStations();
    }

    return this.stations
  }

  private setStations() {
    this.stations = this.stationIds.map(stationId => {
      return this.createStation(stationId);
    })
  }

  private createStation(stationId) {
    const station = sampleOne(stationGen);
    station.id = stationId;
    return station;
  }
}

export class FirstJobStationsInterceptor extends StationsInterceptor {
  protected size = 5
  protected correctExtension: string = '/apiv2/jobs/1/stations';
  protected stationIds = [1, 2, 3, 4];
}

export class SecondJobStationsInterceptor extends StationsInterceptor {
  protected size = 3
  protected correctExtension: string = '/apiv2/jobs/2/stations';
  protected stationIds = [5, 6, 7, 8, 9];
}
