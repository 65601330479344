import { Region } from "src/app/core/Region";
import { Organization } from "src/app/core/organizations/Organization";
import { Station } from 'src/app/core/stations';
import { Observable, forkJoin, Subject, BehaviorSubject, of, combineLatest } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { Toggler } from './Toggler';
import { LocationPermissionDiffer } from './LocationPermissionDiffer';
import { User } from "src/app/core/User";
import { LocationPermissionsDiff } from 'src/app/core/users/location-diff-actions';

export interface AllLocationPermissionsDifferences {
  stations: LocationPermissionsDiff<Station>;
  regions: LocationPermissionsDiff<Region>;
  organizations: LocationPermissionsDiff<Organization>;
}

export class LocationPermissionSelections {

  public readonly availableOrganizations$: Observable<Organization[]>;
  public readonly availableRegions$: Observable<Region[]>;
  public readonly availableStations$: Observable<Station[]>;

  public organizationToggler: Toggler<Organization> = Toggler.Empty();
  public regionToggler: Toggler<Region> = Toggler.Empty();
  public stationToggler: Toggler<Station> = Toggler.Empty();

  private readonly organizationChanged = new BehaviorSubject(true);
  private readonly regionChanged = new BehaviorSubject(true);
  private readonly stationChanged = new BehaviorSubject(true);

  constructor(
    private readonly organizations$: Observable<Organization[]>,
    private readonly regions$: Observable<Region[]>,
    private readonly stations$: Observable<Station[]>
  ) {
    this.availableOrganizations$ = this.makeAvailableOrganizations$();
    this.availableRegions$ = this.makeAvailableRegions$();
    this.availableStations$ = this.makeAvailableStations$();

    this.setupUnselectListeners();
  }

  public setSelectedOrganizationIds(organizationIds: number[]) {
    this.organizationToggler = new Toggler<Organization>(organizationIds, () => {
      this.organizationChanged.next(true);
    });
  }

  public setSelectedRegionIds(regionIds: number[]) {
    this.regionToggler = new Toggler<Region>(regionIds, () => {
      this.regionChanged.next(true);
    });
  }

  public setSelectedStationIds(stationIds: number[]) {
    this.stationToggler = new Toggler<Station>(stationIds, () => {
      this.stationChanged.next(true);
    });
  }

  public getDifferences(user: User): Observable<AllLocationPermissionsDifferences> {

    return forkJoin(
      [LocationPermissionDiffer.Diff(this.organizations$, this.organizationToggler, user.organization_ids),
      LocationPermissionDiffer.Diff(this.regions$, this.regionToggler, user.region_ids),
      LocationPermissionDiffer.Diff(this.stations$, this.stationToggler, user.station_ids)
      ]).pipe(
        map(([organizations, regions, stations]) => ({ organizations, regions, stations }))
      );

  }

  private makeAvailableOrganizations$(): Observable<Organization[]> {
    return this.organizations$; // All organizations are always available
  }

  private makeAvailableRegions$(): Observable<Region[]> {
    return combineLatest([
      this.availableOrganizations$,
      this.regions$,
      this.organizationChanged
    ]).pipe(
      map(([organizations, regions, ...ignore]) => this.availableRegions(organizations, regions)));
  }

  private makeAvailableStations$(): Observable<Station[]> {
    return combineLatest([
      this.availableOrganizations$,
      this.availableRegions$,
      this.stations$,
      this.organizationChanged,
      this.regionChanged
    ]).pipe(
      map(([organizations, regions, stations, ...ignore]) =>
        this.availableStations(organizations, regions, stations)));
  }

  private setupUnselectListeners() {
    this.onOrganizationChangeUnselectAllRegionsForOrganization();
    this.onOrganizationChangeUnselectAllStationsForOrganization();
    this.onRegionChangeUnselectAllStationsForRegion();
  }

  private onOrganizationChangeUnselectAllRegionsForOrganization() {
    combineLatest([
      this.organizations$,
      this.regions$,
      this.organizationChanged
    ]).subscribe(([organizations, regions, ...ignore]) => {
      this.ensureRegionSelectionStateForOrganization(organizations, regions);
    });
  }

  private onOrganizationChangeUnselectAllStationsForOrganization() {
    combineLatest([
      this.organizations$,
      this.stations$,
      this.organizationChanged,
    ]).subscribe(([organizations, stations, ...ignore]) => {
      this.ensureStationSelectionStateForOrganizations(organizations, stations);
    });
  }

  private onRegionChangeUnselectAllStationsForRegion() {
    combineLatest([
      this.regions$,
      this.stations$,
      this.regionChanged
    ]).subscribe(([regions, stations, ...ignore]) => {
      this.ensureStationSelectionStateForRegions(regions, stations);
    });
  }

  private ensureStationSelectionStateForRegions(regions: Region[], stations: Station[]) {
    regions.forEach(region => {
      if (!this.regionToggler.isSelected(region)) {
        this.ensureStationsNotSelectedForRegion(region, stations);
      }
    });
  }

  private ensureStationsNotSelectedForRegion(region: Region, stations: Station[]) {
    const notSelected = stations
      .filter(station => station.region_id === region.id)
      .map(station => station.id);

    this.stationToggler.unselectIf(id => notSelected.includes(id));
  }

  private ensureRegionSelectionStateForOrganization(organizations: Organization[], regions: Region[]) {
    organizations.forEach(organization => {
      if (!this.organizationToggler.isSelected(organization)) {
        this.ensureRegionsNotSelectedForOrganization(organization, regions);
      }
    });
  }

  private ensureRegionsNotSelectedForOrganization(organization: Organization, regions: Region[]) {
    const notSelected = regions
      .filter(region => region.organization_id === organization.id)
      .map(region => region.id);

    this.regionToggler.unselectIf(id => notSelected.includes(id));
  }

  private ensureStationSelectionStateForOrganizations(organizations: Organization[], stations: Station[]) {
    organizations.forEach(organization => {
      if (!this.organizationToggler.isSelected(organization)) {
        this.ensureStationsNotSelectedForOrganization(organization, stations);
      }
    });
  }

  private ensureStationsNotSelectedForOrganization(organization: Organization, stations: Station[]) {
    const notSelected = stations
      .filter(station => station.organization_id === organization.id)
      .map(station => station.id);

    this.stationToggler.unselectIf(id => notSelected.includes(id));
  }

  private availableRegions(organizations: Organization[], regions: Region[]): Region[] {
    return regions.filter(region =>
      this.regionIsAvailableOnSelectedOrganization(organizations, region));
  }

  private regionIsAvailableOnSelectedOrganization(organizations: Organization[], region: Region): boolean {
    const found = organizations.find(organization => organization.id === region.organization_id);
    return found && this.organizationToggler.isSelected(found);
  }

  private availableStations(organizations: Organization[], regions: Region[], stations: Station[]): Station[] {
    return stations.filter(station =>
      this.stationIsAvailableOnSelectedRegion(regions, station) ||
      this.stationIsAvailableOnSelectedOrganization(organizations, station));
  }

  private stationIsAvailableOnSelectedRegion(regions: Region[], station: Station): boolean {
    const found = regions.find(region => region.id === station.region_id);
    return found && this.regionToggler.isSelected(found);
  }

  private stationIsAvailableOnSelectedOrganization(organizations: Organization[], station: Station): boolean {
    const found = organizations.find(organization => organization.id === station.organization_id);
    return found && this.organizationToggler.isSelected(found);
  }
}
