import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { selectedJob } from 'src/app/core/jobs/selectors';
import { RevisionsLoader } from 'src/app/core/models/revisions-loader/revisions-loader';
import { Revision } from 'src/app/core/revisions';
import { UnsubscribeOnDestroyComponent } from 'src/app/shared/components/unsubscribe-on-destroy.component';
import { Job } from '../../core/jobs';
import { LoadFromJob, Station } from '../../core/stations';
import { State } from '../rx';
import { revisionsForStationJobSelector, stationsForSelectedJob } from '../rx/selectors';

@Component({
  selector: 'app-selections',
  templateUrl: './selections.component.html',
  styleUrls: ['./selections.component.css']
})
export class SelectionsComponent extends UnsubscribeOnDestroyComponent {

  public readonly viewSelections$: Observable<boolean>
    = this.store.pipe(select(state => state.surveys.viewSelections.viewSelections));

  public readonly stations$: Observable<Station[]>
    = this.store.pipe(select(stationsForSelectedJob));

  private revisionsLoader = new RevisionsLoader(this.store, this.ngUnsubscribe);

  constructor(private readonly store: Store<State>) {
    super();
    this.subscribeToJobSelection();
    this.revisionsLoader.startSubscription();
  }

  public revisionsForStation$(station: Station): Observable<Revision[]> {
    return this.store.pipe(
      select(selectedJob)
    ).pipe(
      flatMap(
        (job: Job) => this.store.pipe(
          select(revisionsForStationJobSelector(), { station, job }))));
  }

  private subscribeToJobSelection() {
    this.store.pipe(
      select(selectedJob)
    ).subscribe((job: Job) => {
      if (selectedJob) {
        this.handleSelectedJob(job);
      }
    });
  }

  private handleSelectedJob(job: Job): void {
    if (job) {
      this.store.dispatch(new LoadFromJob({ job: job }));
    }
  }
}
