import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'src/app/core/User';
import { RemoveOne, UpsertOne, UserKind } from 'src/app/core/users';
import { environment } from '../../../environments/environment';
import { UserCopy } from '../users/user-copy';
import { PictureModelService } from './picture-model.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends PictureModelService<User> {
  protected uploadExtensionName = 'user';
  protected copyConstructor = UserCopy;
  protected urlExtension = '/api/users';
  protected kind: string = UserKind;
  private readonly whoAmIUrl: string = environment.apiUri.toString() + '/api/whoami';
  private cachedLoggedInUser: User | undefined;

  public makeUpsertAction(user: User): Action {
    return new UpsertOne({ user });
  }

  public makeRemoveOneAction(user: User): Action {
    return new RemoveOne({ user });
  }

  public getLoggedIn(forceReload = false): Observable<User> {
    if (forceReload) {
      this.clearCachedLoggedInUser();
    }

    if (this.cachedLoggedInUser) {
      return of(this.cachedLoggedInUser);
    } else {
      return this.http.get<User>(
        this.whoAmIUrl,
        { headers: this.apiTokenService.headers() })
        .pipe(
          map(this.addKind.bind(this)),
          tap((user: User) => this.cachedLoggedInUser = user)
        );
    }
  }

  public clearCachedLoggedInUser() {
    this.cachedLoggedInUser = undefined;
  }
}
