import { ChangeDetectorRef, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, first, flatMap, map } from 'rxjs/operators';
import { QuestionI, SectionI, SurveyI } from 'src/app/core/lib/models';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { Completion } from 'src/app/core/survey-wrapper/completion';
import { PositionInSurvey } from 'src/app/core/survey-wrapper/position-in-survey';
import { SurveyAccessControl } from 'src/app/core/survey-wrapper/survey-access-control';
import { SurveyActions } from 'src/app/core/survey-wrapper/survey-actions';
import { UnsubscribeOnDestroyComponent } from 'src/app/shared/components/unsubscribe-on-destroy.component';
import { toReplaySubject } from 'src/app/shared/utils';
import { State, SurveyStateUpdatedAction } from '../rx';
import { ActiveSurveyFactoryService } from './active-survey-factory.service';
import { ActiveSurveyService } from './active-survey.service';
import { ReviewActiveSurveyService } from './review-active-survey.service';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Directive({
  selector: 'only-extend-active-survey'
})
export class ActiveSurveyDirective extends UnsubscribeOnDestroyComponent {
  private readonly surveyActions$: Observable<SurveyActions | null> = this.makeSurveyActions$();

  public readonly surveyWrapper$: Observable<SurveyWrapper | null> = this.makeSurveyWrapper$();
  public readonly accessControl$: Observable<SurveyAccessControl | null> = this.makeAccessControl$();
  public readonly positionInSurvey$: Observable<PositionInSurvey | null> = this.makePositionInSurvey$();
  public readonly completion$: Observable<Completion | null> = this.makeCompletion$();
  public readonly questions$: Observable<QuestionI[]> = this.makeQuestions$();
  public readonly currentQuestion$: Observable<QuestionI | null> = this.makeCurrentQuestion$();
  public readonly revision$: Observable<Revision | null> = this.makeRevision$();
  public readonly station$: Observable<Station | null> = this.makeStation$();
  public readonly isInReviewMode$: Observable<boolean> = this.makeIsInReviewMode$();

  public currentSection$: Observable<SectionI> = this.makeCurrentSection$();

  private makeCurrentSection$(): Observable<SectionI> {
    const helper = (positionInSurvey: PositionInSurvey) =>
      positionInSurvey.getCurrentSection();
    return this.positionInSurvey$.pipe(map(helper));
  }

  constructor(protected readonly activeSurveyFactoryService: ActiveSurveyFactoryService,
              protected readonly store: Store<State>,
              protected readonly ref: ChangeDetectorRef,
              private readonly reviewSurveyActionsService: ReviewSurveyActionsService
  ) {
    super();
    this.initListenForUpdates();
  }

  public mayAnswerCurrentQuestion$(): Observable<boolean> {
    const helper = (question: QuestionI, accessControl: SurveyAccessControl, positionInSurvey: PositionInSurvey): boolean =>
      accessControl.mayAnswer(question, positionInSurvey.getCurrentSection());

    return combineLatest([
      this.currentQuestion$,
      this.accessControl$,
      this.positionInSurvey$
    ]).pipe(map(([question, accessControl, positionInSurvey]) => helper(question, accessControl, positionInSurvey)));
  }

  public hasAccessToSection(section: SectionI): Observable<boolean> {
    const helper = (accessControl: SurveyAccessControl): boolean => accessControl.hasAccessToSection(section);
    return this.accessControl$.pipe(map(helper));
  }

  public getTotalCompletion$(): Observable<CompletionResult> {
    return this.completion$.pipe(
      map(completion => completion.total()));
  }

  protected getSurvey$(): Observable<SurveyI> {
    return this.surveyWrapper$.pipe(first(), map(surveyWrapper => surveyWrapper.getSurvey()));
  }

  protected getReview$(): Observable<Review | undefined> {
    const helper = (activeSurveyService: ReviewActiveSurveyService) => {
      const { review$ } = activeSurveyService;

      if (!review$) {
        return of(undefined);
      } else {
        return review$;
      }
    };

    return this.activeSurveyFactoryService.get$().pipe(flatMap(helper));
  }

  private initListenForUpdates() {
    this.activeSurveyFactoryService.onUpdate(this.handleSurveyStateUpdated.bind(this));
  }

  private handleSurveyStateUpdated() {
    // Put updates that are required to happen in this
    // method instead of in `this.surveyStateUpdated`
    // incase `super` isn't called
    this.currentSection$ = this.makeCurrentSection$();

    combineLatest([
      this.isInReviewMode$,
      this.revision$,
      this.store
    ]).pipe(first()).subscribe(([isInReviewMode, revision, state]) => {
      if (!isInReviewMode && revision) {
        const reviewIds = Object.values(state.reviews.entities)
          .filter(review => review.revision_id === revision.id)
          .map(review => review.id);

        reviewIds.forEach(id => this.reviewSurveyActionsService.removeFromCache(id));
      }
    });

    this.surveyStateUpdated();

    try {
      this.ref.detectChanges();
    } catch (e) {
      // This sometimes throws an error. I believe it has to
      // do with the fact that this is in a super class and angular
      // possibly hasn't completely set up the template yet.
      // Regardless, just catch and ignore the error
    }
  }

  // Override this method in the subclass if you want to take an action when the
  // survey state is updated
  protected surveyStateUpdated() {
    // Nothing by default
  }

  protected dispatchSurveyStateUpdate() {
    this.store.dispatch(new SurveyStateUpdatedAction());
  }

  private makeIsInReviewMode$(): Observable<boolean> {
    return this.activeSurveyFactoryService.get$()
      .pipe(map(activeSurveyService => activeSurveyService.isInReviewMode()));
  }

  private makeSurveyActions$(): Observable<SurveyActions | null> {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.surveyActions$;
    return toReplaySubject(this.extractFromCurrentActiveSurveyService$(helper));
  }

  private makeSurveyWrapper$(): Observable<SurveyWrapper | null> {
    const helper = this.checkNull<SurveyWrapper>(surveyActions => surveyActions.surveyWrapper);
    return this.surveyActions$.pipe(map(helper));
  }

  private makePositionInSurvey$(): Observable<PositionInSurvey | null> {
    const helper = this.checkNull<PositionInSurvey>(surveyActions => surveyActions.positionInSurvey);
    return this.surveyActions$.pipe(map(helper));
  }

  private makeAccessControl$(): Observable<SurveyAccessControl | null> {
    const helper = this.checkNull<SurveyAccessControl>(surveyActions => surveyActions.accessControl);
    return this.surveyActions$.pipe(map(helper));
  }

  private makeCompletion$(): Observable<Completion | null> {
    const helper = this.checkNull<Completion>(surveyActions => surveyActions.completion);
    return this.surveyActions$.pipe(map(helper));
  }

  private checkNull<T>(callback: (surveyActions: SurveyActions) => T): (surveyActions: SurveyActions | null) => T | null {
    return (surveyActions: SurveyActions | null) => {
      if (surveyActions) {
        return callback(surveyActions);
      } else {
        return null;
      }
    };
  }

  private makeCurrentQuestion$(): Observable<QuestionI | null> {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.currentQuestion$;
    return this.extractFromCurrentActiveSurveyService$(helper);
  }

  private makeRevision$(): Observable<Revision | null> {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.revision$;
    return this.extractFromCurrentActiveSurveyService$(helper);
  }

  private makeStation$(): Observable<Station | null> {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.station$;
    return this.extractFromCurrentActiveSurveyService$(helper);
  }

  private makeQuestions$(): Observable<QuestionI[]> {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.questions$;
    return this.extractFromCurrentActiveSurveyService$(helper);
  }

  private extractFromCurrentActiveSurveyService$<T>(callback: (arg0: ActiveSurveyService) => Observable<T>): Observable<T> {
    return this.activeSurveyFactoryService.get$()
      .pipe(flatMap(callback),
        distinctUntilChanged((a, b) => a === b));
  }
}
