import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Revisions from '../../core/revisions';
import { Station } from '../../core/stations';
import { makeSrc } from '../../shared/utils';
import { State } from '../rx';
import { currentRevisionSelector, currentStationSelector } from '../rx/selectors';
import * as ViewSelections from '../rx/view-selections';

@Component({
  selector: 'app-station-survey-selection',
  templateUrl: './station-survey-selection.component.html',
  styleUrls: ['./station-survey-selection.component.css']
})
export class StationSurveySelectionComponent {
  constructor(private readonly store: Store<State>,
    private readonly router: Router) {
  }

  @Input() public station: Station;
  @Input() public revisions$: Observable<Revisions.Revision[]>;

  public readonly currentStation$: Observable<Station | null> =
    this.store.pipe(select(currentStationSelector));

  public readonly currentRevision$: Observable<Revisions.Revision | null> =
    this.store.pipe(select(currentRevisionSelector));

  public readonly src = makeSrc({
    modelStr: 'station',
    defaultImageUri: '/images/default-station.png'
  });

  public handleRevisionClicked(revision: Revisions.Revision) {
    this.selectRevision(revision);
    this.hideSelections();
  }

  private selectRevision(revision: Revisions.Revision) {
    this.router.navigate(['/', 'survey', 'revision', revision.id]);
  }

  private hideSelections() {
    this.store.dispatch(new ViewSelections.Hide());
  }
}
