import { Organization, OrganizationKind } from '../organizations/Organization';
import { Region } from '../Region';
import { Station, StationKind } from '../stations';
import { MapData, MapDataImpl } from './map-data';
import { LocationI } from '../locations';
import { Layer, MapOptions, LatLngBounds } from 'leaflet';
import { RankDataForSurvey } from './rank-data-for-survey';
import { RegionKind } from '../regions';
import { AnalysisSelection } from './analysis';

export type CurrentLocationType = LocationI | undefined;

export function isOrganization(location: CurrentLocationType): boolean {
  if (!location) {
    return false;
  } else {
    return location.kind === OrganizationKind;
  }
}

export function isRegion(location: CurrentLocationType): boolean {
  if (!location) {
    return false;
  } else {
    return location.kind === RegionKind;
  }
}

export function isStation(location: CurrentLocationType): boolean {
  if (!location) {
    return false;
  } else {
    return location.kind === StationKind;
  }
}


export class LocationHierarchy {
  private currentLocation: CurrentLocationType;
  private mapData: MapData;
  private rankDataForSurvey: RankDataForSurvey | null = null;

  private readonly cbs = [];

  constructor(
    private readonly organizations: Organization[],
    private readonly regions: Region[],
    private readonly stations: Station[],
  ) {
    this.setCurrentLocation(undefined);
  }

  public onUpdate(cb: () => any) {
    this.cbs.push(cb);
  }

  private fireUpdateCbs() {
    this.cbs.forEach(cb => cb());
  }

  public getLayers(analysisSelection: AnalysisSelection): Layer[] {
    const layers = this.mapData.getLayers(analysisSelection);
    return layers;
  }

  public getOverlay() {
    return this.mapData.getOverlay();
  }

  public getMapOptions(): MapOptions {
    return this.mapData.getMapOptions();
  }

  public getBounds(): LatLngBounds | undefined {
    return this.mapData.getBounds();
  }

  public shouldListOrganizations(): boolean {
    // At the top level
    return !this.canGoBack();
  }

  public shouldListRegions(): boolean {
    // When listing organizations, only list organizations
    return !this.shouldListOrganizations() && this.currentRegions().length > 0;
  }

  public shouldListStations(): boolean {
    // See shouldListRegions
    return !this.shouldListOrganizations() && this.currentStations().length > 0;
  }

  public canGoBack(): boolean {
    return !!this.currentLocation;
  }

  public back(): void {
    if (!this.currentLocation) {
      throw new Error('Cannot go back with no current location');
    } else if (isStation(this.currentLocation)) {

      const station = <Station>this.currentLocation;
      const region = this.regions.find(r => r.id === station.region_id);
      const organization = this.organizations.find(o => o.id === station.organization_id);
      this.setCurrentLocation(region || organization);

    } else if (isRegion(this.currentLocation)) {

      const region = <Region>this.currentLocation;
      const organization = this.findOrganizationForRegion(region);
      if (!organization) {
        throw new Error(`Could not find organization for region: ${JSON.stringify(region)}`);
      } else {
        this.setCurrentLocation(organization);
      }

    } else {
      this.setCurrentLocation(undefined);
    }
  }

  public setCurrentLocation(location: CurrentLocationType) {
    this.currentLocation = location;
    this.setMapData();
    this.fireUpdateCbs();
  }


  public setRankDataForSurvey(rankDataForSurvey: RankDataForSurvey | null) {
    this.rankDataForSurvey = rankDataForSurvey;
    this.setMapData();
    this.fireUpdateCbs();
  }

  public currentOrganizations(): Organization[] {
    if (this.shouldListOrganizations()) {
      return this.organizations;
    } else {
      return [];
    }
  }

  public currentRegions(): Region[] {
    if (isOrganization(this.currentLocation)) {
      return this.findRegionsForOrganization(<Organization>this.currentLocation);
    } else {
      return [];
    }
  }

  public currentStations(): Station[] {
    if (isOrganization(this.currentLocation)) {
      return this.findStationsForOrganization(<Organization>this.currentLocation);
    } else if (isRegion(this.currentLocation)) {
      return this.findStationsForRegion(<Region>this.currentLocation);
    } else if (isStation(this.currentLocation)) {
      return [<Station>this.currentLocation];
    } else {
      return [];
    }
  }

  private findOrganizationForRegion(region: Region): Organization | undefined {
    return this.organizations.find(organization => organization.id === region.organization_id);
  }

  private findRegionsForOrganization(organization: Organization): Region[] {
    return this.regions.filter(region => region.organization_id === organization.id);
  }

  private findStationsForOrganization(organization: Organization): Station[] {
    return this.stations.filter(station => organization.id === station.organization_id);
  }

  private findStationsForRegion(region: Region): Station[] {
    return this.stations.filter(station => region.id === station.region_id);
  }

  private setMapData() {
    this.mapData = new MapDataImpl(
      this.getAllCurrentlyListedLocations(),
      this.currentLocation,
      this.onLocationClicked.bind(this),
      this.rankDataForSurvey);
    this.fireUpdateCbs();
  }

  private onLocationClicked(location: LocationI) {
    this.setCurrentLocation(location);
  }

  private getAllCurrentlyListedLocations(): LocationI[] {
    // <any> cast to satisfy type checker, idk why
    return this.currentOrganizations().concat(
      <any>this.currentRegions()).concat(
        <any>this.currentStations());
  }
}
