import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditRegionTextCreating } from '../edit-location/edit-location-text';
import { EditLocationComponent } from '../edit-location/edit-location.component';
import { RegionKind } from 'src/app/core/regions';
import { RegionCopy } from 'src/app/core/regions/region-copy';
import { noCloseModalOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-new-region-button',
  templateUrl: './new-region-button.component.html',
  styleUrls: ['./new-region-button.component.css']
})
export class NewRegionButtonComponent {
  constructor(private readonly modalService: NgbModal) { }

  public handleClick() {
    const modalRef = this.modalService.open(EditLocationComponent, noCloseModalOptions);
    modalRef.componentInstance.editLocationText = new EditRegionTextCreating();
    modalRef.componentInstance.copy = new RegionCopy({ kind: RegionKind });
  }
}
