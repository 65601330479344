import { Component, HostListener } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(private readonly loginStateService: LoginStateService) {
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.loginStateService.nextLoginState();
    }
  }
}
