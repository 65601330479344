import { getQuestions } from 'src/app/shared/utils';
import { SurveyWrapper } from '.';
import { Job } from '../jobs';
import { QuestionI, SectionI } from '../lib/models';
import { Review } from '../reviews';
import { Revision } from '../revisions';
import { User } from '../User';

export interface AdditionalAccessControlModels {
  user: User;
  job: Job;
  revision: Revision;
  review?: Review;
}

export abstract class SurveyAccessControl {
  // has access means may make changes to the section
  public abstract hasAccessToSection(section: SectionI): boolean;

  constructor(
    private readonly surveyWrapper: SurveyWrapper,
    protected readonly user: User
  ) {
  }

  public getSectionsWithAccess(): SectionI[] {
    return Object.values(this.getSurvey().sections)
      .filter(this.hasAccessToSection.bind(this));
  }

  public mayAnswer(question: QuestionI, section: SectionI): boolean {
    return false;
  }

  public mayProvideFeedback(question: QuestionI, section: SectionI): boolean {
    return false;
  }

  public mayReview(question: QuestionI, section: SectionI): boolean {
    return false;
  }

  public getTotalQuestionsNeededToReview(): number {
    const sections = Object.values(this.getSurvey().sections);
    const reducer = (total: number, section: SectionI) => total + this.questionsNeededToReviewForSection(section);
    return sections.reduce(reducer, 0);
  }

  protected getSections(): SectionI[] {
    return Object.values(this.getSurvey().sections);
  }

  protected sectionKeyFor(section: SectionI): string | null {
    const { sections } = this.getSurvey();

    let foundSectionKey: string | null = null;

    Object.entries(sections).forEach(([sectionKey, possibleMatch]) => {
      if (section === possibleMatch) {
        foundSectionKey = sectionKey;
      }
    });

    return foundSectionKey;
  }

  private getSurvey() {
    return this.surveyWrapper.getSurvey();
  }

  private questionsNeededToReviewForSection(section: SectionI): number {
    const questions = getQuestions(section);

    return questions.reduce((questionTotals, question) => {
      const add = this.mayProvideFeedback(question, section) ? 1 : 0;
      return questionTotals + add;
    }, 0);
  }
}
