import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as Feedbacks from '../core/feedbacks';
import * as Jobs from '../core/jobs';
import * as Organizations from '../core/organizations';
import * as Regions from '../core/regions';
import * as ReviewTypes from '../core/review-types';
import * as Revisions from '../core/revisions';
import * as Stations from '../core/stations';
import * as Users from '../core/users';
import * as Reviews from '../core/reviews';
import * as ReviewCompletions from '../core/review-completion/review-completion';

export interface State {
  jobs: Jobs.State;
  organizations: Organizations.State;
  stations: Stations.State;
  revisions: Revisions.State;
  users: Users.State;
  reviewTypes: ReviewTypes.State;
  feedbacks: Feedbacks.State;
  regions: Regions.State;
  reviews: Reviews.State;
  reviewCompletions: ReviewCompletions.State;
}

export const reducers: ActionReducerMap<State> = {
  jobs: Jobs.reducer,
  organizations: Organizations.reducer,
  stations: Stations.reducer,
  revisions: Revisions.reducer,
  users: Users.reducer,
  reviewTypes: ReviewTypes.reducer,
  feedbacks: Feedbacks.reducer,
  regions: Regions.reducer,
  reviews: Reviews.reducer,
  reviewCompletions: ReviewCompletions.reducer
};

function clearState(reducer) {
  return (state, action) => {

    if (action.type === Users.Types.Logout) {
      state = undefined;
    }


    return reducer(state, action);
  };
}

function makeMetaReducers() {
  const alwaysMetaReducers = [clearState];
  const environmentMetaReducers = environment.production ?
    [] :
    [
      // storeFreeze
    ];

  return alwaysMetaReducers.concat(environmentMetaReducers);
}

export const metaReducers: MetaReducer<State>[] = makeMetaReducers();
