import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-login-password-form',
  templateUrl: './login-password-form.component.html',
  styleUrls: ['./login-password-form.component.css']
})
export class LoginPasswordFormComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) { }

  ngOnInit() {
  }

  getPassword(): string {
    return this.loginStateService.getPassword();
  }

  setPassword(password: string) {
    this.loginStateService.setPassword(password);
  }
}
