export abstract class PositionDataSerializer<T> {
  protected abstract localStorageKey: string;
  protected abstract extractKey(model: T): number;

  constructor() {
  }

  public set(model: T, positionData: any) {
    const id = this.extractKey(model);
    const store = this.getFullStore();
    store[id] = positionData;
    this.save(store);
  }

  public get(model: T) {
    const id = this.extractKey(model);
    const store = this.getFullStore();
    return store[id];
  }

  private getFullStore() {
    let store;
    try {
      store = JSON.parse(localStorage[this.localStorageKey]);
    } catch (e) {
      store = {};
    }

    return store;
  }

  private save(store) {
    localStorage[this.localStorageKey] = JSON.stringify(store);
  }
}
