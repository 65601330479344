import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditStationTextCreating } from '../edit-location/edit-location-text';
import { EditLocationComponent } from '../edit-location/edit-location.component';
import { StationKind } from 'src/app/core/stations';
import { StationCopy } from 'src/app/core/stations/station-copy';
import { noCloseModalOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-new-station-button',
  templateUrl: './new-station-button.component.html',
  styleUrls: ['./new-station-button.component.css']
})
export class NewStationButtonComponent {
  constructor(private readonly modalService: NgbModal) { }

  public handleClick() {
    const modalRef = this.modalService.open(EditLocationComponent, noCloseModalOptions);
    modalRef.componentInstance.editLocationText = new EditStationTextCreating();
    modalRef.componentInstance.copy = new StationCopy({ kind: StationKind });
  }
}
