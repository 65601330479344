export class Sorter<T> {
  constructor(private readonly unsorted: T[],
    private readonly getSortValue: (arg: T) => number) {
  }

  public getSorted(): T[] {
    const copy = this.unsorted.concat();
    copy.sort(this.ascendingSorter.bind(this));
    return copy;
  }

  private ascendingSorter(a: T, b: T): number {
    // https://www.w3schools.com/jsref/jsref_sort.asp
    return this.getSortValue(a) - this.getSortValue(b);
  }
}
