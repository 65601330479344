export class SamlRedirect {
  private redirectCountdown = 3;

  constructor(private readonly apiUri: string, private readonly doRedirect: () => any) {
    this.doCountDown();
  }

  getRedirectCountdown(): number {
    return this.redirectCountdown;
  }

  private doCountDown() {
    const decCountDown = () => {
      if (this.redirectCountdown === 1) {
        return this.doRedirect();
      }

      --this.redirectCountdown;

      setTimeout(decCountDown, 1000);
    };

    setTimeout(decCountDown, 1000);
  }
}
