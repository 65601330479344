import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Observer } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { QuestionI, QuestionMetadata, SurveyI } from 'src/app/core/lib/models';
import { CreateModelAction, DeleteModelAction } from 'src/app/core/models';
import { ReviewCompletion, ReviewCompletionCopy, ReviewCompletionKind } from 'src/app/core/review-completion/review-completion';
import { PositionInSurvey } from 'src/app/core/survey-wrapper/position-in-survey';
import { SurveyAccessControl } from 'src/app/core/survey-wrapper/survey-access-control';
import { ReviewCompletionManager } from '../../core/models/review-completion-manager/review-completion-manager';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { CompleteReviewComponent } from '../complete-review/complete-review.component';
import { State } from '../rx';
import { UpdatingReviewCompletionManagerDirective } from '../services/updating-review-completion-manager.directive';
import { UpdatingReviewCompletionMangerService } from '../services/updating-review-completion-manager.service';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Component({
  selector: 'app-review-actions',
  templateUrl: './review-actions.component.html',
  styleUrls: ['./review-actions.component.css']
})
export class ReviewActionsComponent extends UpdatingReviewCompletionManagerDirective {

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    updatingReviewCompletionManagerService: UpdatingReviewCompletionMangerService,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly modalService: NgbModal,
  ) {
    super(
      activeSurveyFactoryService,
      store,
      ref,
      reviewSurveyActionsService,
      updatingReviewCompletionManagerService,
    );
  }

  public shouldReviewCurrentQuestion$(): Observable<boolean> {
    const helper = (question: QuestionI, accessControl: SurveyAccessControl, positionInSurvey: PositionInSurvey): boolean =>
      accessControl.mayReview(question, positionInSurvey.getCurrentSection());

    return combineLatest([
      this.currentQuestion$,
      this.accessControl$,
      this.positionInSurvey$
    ]).pipe(map(([question, accessControl, positionInSurvey]) => helper(question, accessControl, positionInSurvey)));
  }

  public isCompletedOnReview$(): Observable<boolean> {
    const observable = Observable.create((observer: Observer<boolean>) => {
      const helper = (question: QuestionI, survey: SurveyI,
        reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) => {
        observer.next(reviewCompletionManager.isCompleted(question, survey));
        observer.complete();
      };

      this.reviewCompletionManagerOnCurrentQuestion(helper);
    });

    return observable;
  }

  public mayCompleteCurrentReview$(): Observable<boolean> {
    const helper = (isInReviewMode: boolean, reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) =>
      isInReviewMode && reviewCompletionManager.mayCompleteReview();

    return combineLatest([
      this.isInReviewMode$,
      this.reviewCompletionManager$
    ]).pipe(map(([isInReviewMode, reviewCompletionManager]) => helper(isInReviewMode, reviewCompletionManager)));
  }

  public mayViewReviewCompletion$(): Observable<boolean> {
    return this.isInReviewMode$;
  }

  public handleToggleReviewedRequested() {
    const helper = (question: QuestionI, survey: SurveyI,
      reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) => {
      reviewCompletionManager.toggleCompletion(question, survey);

      this.updateReviewCompletion(question, survey, reviewCompletionManager);
    };

    this.reviewCompletionManagerOnCurrentQuestion(helper);
  }

  public handleCompleteReviewRequested() {
    this.getReview$().pipe(first()).subscribe(review => {
      const activeModal = this.modalService.open(CompleteReviewComponent);
      activeModal.componentInstance.review = review;
    });
  }

  private reviewCompletionManagerOnCurrentQuestion<T>(callback: (question: QuestionI, survey: SurveyI,
    manager: ReviewCompletionManager<QuestionMetadata>) => T) {
    const reviewCompletionManager$ = this.reviewCompletionManager$;
    const question$ = this.currentQuestion$;
    const survey$ = this.getSurvey$();

    combineLatest([
      question$,
      survey$,
      reviewCompletionManager$,
    ]).pipe(first()).subscribe(([question, survey, reviewCompletionManager]) =>
      callback(question, survey, reviewCompletionManager));
  }

  private updateReviewCompletion(question: QuestionI, survey: SurveyI, reviewCompletionManager: ReviewCompletionManager<QuestionMetadata>) {
    if (reviewCompletionManager.isCompleted(question, survey)) {
      this.dispatchCreateReviewCompletion(question, survey, reviewCompletionManager);
    } else {
      this.dispatchDeleteReviewCompletion(question, survey, reviewCompletionManager);
    }
  }

  private dispatchCreateReviewCompletion(question: QuestionI, survey: SurveyI,
    manager: ReviewCompletionManager<QuestionMetadata>) {
    const review$ = this.getReview$().pipe(first());
    const metadata: QuestionMetadata = manager.getRepresentation(question, survey);

    review$.pipe(first()).subscribe(review => {
      const copy = new ReviewCompletionCopy({ kind: ReviewCompletionKind });
      copy.review_id = review.id;
      copy.metadata_key = metadata;

      this.store.dispatch(new CreateModelAction({ copy }));
    });
  }

  private dispatchDeleteReviewCompletion(
    question: QuestionI,
    survey: SurveyI,
    manager: ReviewCompletionManager<QuestionMetadata>) {
    this.getReview$().pipe(first()).subscribe(review => {
      const metadataKey: QuestionMetadata = manager.getRepresentation(question, survey);

      const findCoorespondingCompletion = (reviewCompletions: ReviewCompletion[]) => {
        return reviewCompletions.find(reviewCompletion =>
          reviewCompletion.review_id === review.id &&
          manager.areSameRepresentation(metadataKey, reviewCompletion.metadata_key));
      };

      this.store.pipe(
        map(state => findCoorespondingCompletion(Object.values(state.reviewCompletions.entities))),
        first()
      ).subscribe(reviewCompletion => {
        this.store.dispatch(new DeleteModelAction({ model: reviewCompletion }));
      });
    });
  }

}
