import { ShouldWorkOn } from './should-work-on';
import { Observable, combineLatest } from 'rxjs';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { map } from 'rxjs/operators';

export class RevisionModeShouldWorkOn extends ShouldWorkOn {
  constructor(
    private readonly hasAccessToSection$: Observable<boolean>,
    private readonly completionResult$: Observable<CompletionResult>) {
    super();
  }

  public should$(): Observable<boolean> {
    const helper = (hasAccessToSection: boolean, completionResult: CompletionResult) =>
      hasAccessToSection && completionResult.asNumber() < 1;

    return combineLatest([
      this.hasAccessToSection$,
      this.completionResult$,
    ]).pipe(map(([hasAccessToSection, completionResult]) => helper(hasAccessToSection, completionResult)));
  }
}
