import { ChangeDetectorRef, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Lib, QuestionI, SurveyI } from 'src/app/core/lib/models';
import { LibService } from 'src/app/core/lib/service';
import { Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { CreateFeedbackComponent } from '../create-feedback/create-feedback.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { FeedbackManager } from '../question/feedback-manager';
import { State } from '../rx';
import { SurveyAccessControl } from 'src/app/core/survey-wrapper/survey-access-control';
import { PositionInSurvey } from 'src/app/core/survey-wrapper/position-in-survey';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Component({
  selector: 'app-feedback-actions',
  templateUrl: './feedback-actions.component.html',
  styleUrls: ['./feedback-actions.component.css']
})
export class FeedbackActionsComponent extends ActiveSurveyDirective {

  private readonly feedbackManager$: Observable<FeedbackManager> = this.makeFeedbackManager$();

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly modalService: NgbModal,
    private readonly libService: LibService
  ) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);

    this.initListenForFeedbacks();
    this.initListenForReviews();
  }

  public canViewFeedbackButton$(): Observable<boolean> {
    return of(true);
  }

  public handleProvideFeedbackRequested() {
    const helper = ({ feedbackManager, review, survey, question }:
      { feedbackManager: FeedbackManager, review: Review, survey: SurveyI, question: QuestionI }) => {
      const modalRef = this.modalService.open(CreateFeedbackComponent);
      modalRef.componentInstance.feedbackManager = feedbackManager;
      modalRef.componentInstance.review = review;
      modalRef.componentInstance.survey = survey;
      modalRef.componentInstance.question = question;
    };

    this.createSurveyPrereqs$().pipe(first()).subscribe(helper);
  }

  public viewFeedback() {
    const feedbackManager$: Observable<FeedbackManager> = this.feedbackManager$;
    const question$: Observable<QuestionI> = this.currentQuestion$;
    const survey$: Observable<SurveyI> = this.getSurvey$();

    const helper = (feedbackManager, question, survey) => {
      const modalRef = this.modalService.open(FeedbackComponent);
      const { componentInstance } = modalRef;
      componentInstance.feedbackManager = feedbackManager;
      componentInstance.question = question;
      componentInstance.survey = survey;
    };

    combineLatest([
      feedbackManager$,
      question$,
      survey$
    ]).pipe(first())
      .subscribe(([feedbackManager, question, survey]) =>
        helper(feedbackManager, question, survey));
  }

  public isThereAnyFeedbackToView$(): Observable<boolean> {
    const helper = (manager: FeedbackManager, question: QuestionI, survey: SurveyI) => {
      return manager.getFeedbacksFor(question, survey).length > 0;
    };

    const feedbacks$ = this.store.pipe(map(state => state.feedbacks));
    const feedbackManager$ = this.feedbackManager$;
    const question$ = this.currentQuestion$;
    const survey$ = this.getSurvey$();

    return combineLatest([
      feedbacks$,
      feedbackManager$,
      question$,
      survey$
    ]).pipe(map(([_, manager, question, survey]) => helper(manager, question, survey)));
  }

  public mayProvideFeedbackOnCurrentQuestion$(): Observable<boolean> {
    const helper = (question: QuestionI, accessControl: SurveyAccessControl, positionInSurvey: PositionInSurvey): boolean =>
      accessControl.mayProvideFeedback(question, positionInSurvey.getCurrentSection());

    return combineLatest([
      this.currentQuestion$,
      this.accessControl$,
      this.positionInSurvey$
    ]).pipe(map(([question, accessControl, positionInSurvey]) => helper(question, accessControl, positionInSurvey)));
  }

  private makeFeedbackManager$(): Observable<FeedbackManager> {
    const subject = new BehaviorSubject<FeedbackManager>(undefined);

    const lib$ = this.libService.get();
    const revision$ = this.revision$;
    const helper = (revision: Revision, lib: Lib) => new FeedbackManager(revision, lib.metadataKeyFor, lib.questionFor);

    combineLatest(
      revision$,
      lib$
    ).pipe(first(), map(([revision, lib]) => helper(revision, lib)))
      .subscribe(subject.next.bind(subject));

    return subject;
  }

  private createSurveyPrereqs$(): Observable<{ review: Review, survey: SurveyI, question: QuestionI, feedbackManager: FeedbackManager }> {
    const survey$ = this.getSurvey$();
    const question$ = this.currentQuestion$.pipe(first());
    const review$ = this.getReview$().pipe(first());
    const feedbackManager$ = this.feedbackManager$;

    const helper = (feedbackManager: FeedbackManager, survey: SurveyI, question: QuestionI, review: Review) => {
      return {
        feedbackManager,
        survey,
        question,
        review,
      };
    };

    return combineLatest([
      feedbackManager$,
      survey$,
      question$,
      review$
    ]).pipe(map(([feedbackManager, survey, question, review]) => helper(feedbackManager, survey, question, review)));
  }

  private initListenForFeedbacks() {
    this.store.pipe(select(state => Object.values(state.feedbacks.entities))).subscribe((feedbacks) => {
      this.feedbackManager$.pipe(first()).subscribe((feedbackManager) => {
        feedbacks.forEach(feedback => {
          feedbackManager.addFeedbackIfBelongsToRevision(feedback);
        });
      });
    });
  }

  private initListenForReviews() {
    this.store.pipe(select(state => Object.values(state.reviews.entities))).subscribe((reviews) => {
      this.feedbackManager$.pipe(first()).subscribe((feedbackManager) => {
        reviews.forEach(review => {
          feedbackManager.addReview(review);
        });
      });
    });
  }

}
