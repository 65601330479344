import { EmailLoginState } from './email-login-state';
import { LoginState } from './login-state';
import { PasswordLoginState } from './password-login-state';
import { RequestAccessLoginState } from './request-access-login-state';
import { RequestAccessRequestMadeLoginState } from './request-access-request-made-login-state';
import { SamlLoginState } from './saml-login-state';
import { SelectOrganizationLoginState } from './select-organization-login-state';
import { WarningLoginState } from './warning-login-state';
import { Organization } from '../../organizations/Organization';
import { AuthenticationFailedLoginState } from './authentication-failed-login-state';
import { DeclineLoginState } from './decline-login-state';

export type GetLoginState = (loginStateConstructor: LoginStateConstructor) => LoginState;
export type LoginStateConstructor = new (...args: any[]) => LoginState;

export class LoginStateController {
  private static readonly MESSAGE_TIMEOUT = 3000;

  constructor(private readonly useSaml: boolean) {
  }

  private email: string;
  private password: string;
  private organization: Organization;
  private successMessage: string;
  private errorMessage: string;

  private readonly initialState = WarningLoginState;

  protected readonly getLoginState: GetLoginState =
    (loginStateConstructor: LoginStateConstructor) => {
      switch (loginStateConstructor) {
        case (DeclineLoginState): {
          return new DeclineLoginState(this.getLoginState);
        }
        case (AuthenticationFailedLoginState): {
          return new AuthenticationFailedLoginState(this.getLoginState);
        }
        case (WarningLoginState): {
          return new WarningLoginState(this.getLoginState, this.useSaml);
        }
        case (EmailLoginState): {
          return new EmailLoginState(this.getLoginState, this.email);
        }
        case (SamlLoginState): {
          return new SamlLoginState(this.getLoginState);
        }
        case (PasswordLoginState): {
          return new PasswordLoginState(this.getLoginState);
        }
        case (RequestAccessLoginState): {
          return new RequestAccessLoginState(this.getLoginState, this.email, <LoginStateConstructor>this.currentLoginState.constructor);
        }
        case (SelectOrganizationLoginState): {
          return new SelectOrganizationLoginState(this.getLoginState, this.organization);
        }
        case (RequestAccessRequestMadeLoginState): {
          return new RequestAccessRequestMadeLoginState(this.getLoginState);
        }
        default: {
          throw new Error(`Given constructor not handled in LoginStateController`);
        }
      }
    }

  protected currentLoginState: LoginState = this.getLoginState(this.initialState);

  public isCurrentLoginState(constructor_: LoginStateConstructor): boolean {
    return this.currentLoginState.constructor === constructor_;
  }

  public decline() {
    this.currentLoginState = this.getLoginState(DeclineLoginState);
  }

  public nextLoginState() {
    if (this.currentLoginState.canMoveNext()) {
      this.currentLoginState = this.currentLoginState.next();
    } else {
      this.setErrorMessage(this.currentLoginState.getErrorMessage());
    }
  }

  public setStateToRequestAccess() {
    this.currentLoginState = this.getLoginState(RequestAccessLoginState);
  }

  public backLoginState() {
    this.currentLoginState = this.currentLoginState.back();
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string) {
    this.email = email;
    this.currentLoginState.setEmail(email);
  }

  public getPassword(): string {
    return this.password;
  }

  public setPassword(password: string) {
    this.password = password;
    this.currentLoginState.setPassword(password);
  }

  public getOrganization(): Organization {
    return this.organization;
  }

  public setOrganization(organization: Organization) {
    this.organization = organization;
    this.currentLoginState.setOrganization(organization);
  }

  public getSuccessMessage(): string | undefined {
    return this.successMessage;
  }

  public setSuccessMessage(message: string) {
    this.successMessage = message;
    setTimeout(() => this.successMessage = undefined, LoginStateController.MESSAGE_TIMEOUT);
  }

  public getErrorMessage(): string | undefined {
    return this.errorMessage;
  }

  protected setErrorMessage(message: string) {
    this.errorMessage = message;
    setTimeout(() => this.errorMessage = undefined, LoginStateController.MESSAGE_TIMEOUT);
  }

  protected reset() {
    this.email = undefined;
    this.password = undefined;
    this.currentLoginState = this.getLoginState(this.initialState);
  }
}
