import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, merge, Observable } from 'rxjs';
import { catchError, filter, flatMap } from 'rxjs/operators';
import { ActionProcessorFactory } from './action-processor';
import { MonadicAction, MonadicActionType } from './monadic-action';

@Injectable()
export abstract class LoadEffect {

  // In child, must set the effect as so
  // @Effect()
  // <instance variable name> = this.parentObserver$
  // This is due to limitations in ngrx
  public readonly parentObserver$ = this.makeObservable$();

  private readonly actionProcessorFactory = new ActionProcessorFactory(
    this.handleRequest.bind(this), this.handleResponse.bind(this));

  constructor(
    private readonly actions$: Actions) {
  }

  protected abstract typeFilters(): string[]; // OperatorFunction<any, Actions<any>>;
  protected abstract handleResponse(action: Action & any, extra?: any): Action;
  protected abstract handleRequest(action: Action & any): Observable<any>;

  private makeObservable$(): Observable<any> {
    return this.makeCombinedObservable().pipe(
      flatMap(this.processAction.bind(this)));
  }

  private makeCombinedObservable(): Observable<any> {
    return merge(this.makeMonadicObservable(), this.makeNonMonadicObservable());
  }

  private makeMonadicObservable(): Observable<any> {
    return this.actions$.pipe(filter(this.monadicTypeFilter.bind(this)));
  }

  private makeNonMonadicObservable(): Observable<any> {
    return this.actions$.pipe(ofType(... this.typeFilters()));
  }

  private processAction(action: Action): Observable<Action> {
    return this.actionProcessorFactory
      .get(action)
      .processAction(action)
      .pipe(catchError(this.handleError.bind(this)));
  }

  private monadicTypeFilter(action: Action): boolean {
    return action.type === MonadicActionType &&
      this.typeFilters().includes((<MonadicAction<any>>action).action.type);
  }

  private handleError(e: Error) {
    console.warn(e);
    return EMPTY;
  }
}
