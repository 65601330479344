import { Model } from '.';
import { Omit } from 'src/app/shared/utils';

interface ModelCopyConstructorArguments {
  model?: Model;
  kind?: string;
}

export type ModelCopyConstructor<T extends Model> =
  new (args: ModelCopyConstructorArguments) => ModelCopy<T>;

export abstract class ModelCopy<T extends Model> {
  public readonly kind?: string;
  public readonly model?: T;

  protected abstract validators: (() => any)[];
  protected abstract copyOverFields(model: T): void;
  protected abstract createJSON(): any;

  constructor({ model, kind }: { model?: T, kind?: string }) {
    if (model) {
      this.model = model;
      this.copyOverFields(this.model);
    } else if (kind) {
      this.kind = kind;
    } else {
      throw new Error('Must provide either model or kind');
    }
  }

  public getModel(): T {
    if (!this.isDerivedFromModel()) {
      throw new Error('This copy was not derived from a model');
    } else {
      return this.model;
    }
  }

  public isDerivedFromModel(): boolean {
    return !!this.model;
  }

  public currentError(): string | null {
    try {
      this.validate();
    } catch (e) {
      return e.message;
    }
    return null;
  }

  public isValid(): boolean {
    return this.currentError() === null;
  }

  public asJSON(): any {
    if (!this.isValid()) {
      throw new Error('Can not request a JSON representation of a non valid copy');
    }
    return this.createJSON();
  }

  private validate() {
    this.validators.forEach(validator => validator());
  }

  protected validator(validIfTrue: () => boolean, errorMessage: string): () => any {
    return () => {
      if (!validIfTrue()) {
        throw new Error(errorMessage);
      }
    };
  }
}
