import { FullReportArgs } from '.';
import { GenReport } from '../../lib/models';
import { Revision } from '../../revisions';
import { Station } from '../../stations';
import { SurveyWrapper } from '../../survey-wrapper';
import { ReportLocationsCreator } from './report-locations-creator';
import { ReportPeopleCreator } from './report-people-creator';
import { RevisionChainProcessor } from './report-revision-chain-processor';
import { ReportRevisionsCreator } from './report-revisions-creator';
import { assign } from 'lodash/fp';

export const DRAFT_TEXT = 'DRAFT - May Contain Official Use Only Information';

export class ReportArgsCreator {
  constructor(
    private readonly libGenReport: GenReport,
    private readonly reportPeopleCreator: ReportPeopleCreator,
    private readonly reportRevisionsCreator: ReportRevisionsCreator,
    private readonly reportLocationsCreator: ReportLocationsCreator,
  ) {
  }

  // Use additionalReportArgs to overwrite any of the returned report args
  public async createReportArgs(station: Station, revision: Revision,
                                surveyWrapper: SurveyWrapper, additionalReportArgs: any): Promise<FullReportArgs> {

    // TODO this will not be a promise in the future
    const reportArgs = await this.libGenReport({ survey: surveyWrapper.getSurvey() });

    const reportOrganization = this.reportLocationsCreator.createReportOrganization(station);
    const reportStation = this.reportLocationsCreator.createReportStation(station);
    const people = this.reportPeopleCreator.createReportPeople(revision, surveyWrapper, reportOrganization);
    const revisions = this.reportRevisionsCreator.createRevisions(revision, reportOrganization);

    const revisionChainProcessor = new RevisionChainProcessor(revisions);
    const classification = revisionChainProcessor.getClassification(true);
    const approval = revisionChainProcessor.getApproval(true);

    let header;
    if (!classification || !classification.result) {
      header = DRAFT_TEXT;
    } else if (classification.result === 'OUO') {
      header = 'OFFICIAL USE ONLY';
    }

    const is_ouo = classification && classification.result === 'OUO' ? true : false;

    const reportArgs_: FullReportArgs = {
      organization: reportOrganization,
      station: reportStation,
      revisions,
      people,
      asset_groups: reportArgs.assetGroups,
      scenarios: reportArgs.scenarios,
      questions: reportArgs.questions,
      graphs: reportArgs.graphs,
      mitigations: reportArgs.mitigations,
      classification,
      approval,
      additional_information: reportArgs.additionalInformation,
      header,
      is_ouo
    };

    return assign(reportArgs_, additionalReportArgs);
  }
}
