import { Union } from './actions/union';
import * as State from './state';
import { Types } from './actions/types';

export function reducer(state = State.initial, action: Union): State.State {
  switch (action.type) {
    case Types.Select: {
      return {
        ...state,
        selectedId: action.payload.job.id
      }
    }
    default: {
      return state;
    }
  }
}
