import { Component, Input } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibService } from 'src/app/core/lib/service';
import { AnalysisSelection } from 'src/app/core/models/analysis';
import { Station } from 'src/app/core/stations';
import { SurveyI, Lib } from 'src/app/core/lib/models';
import { LibAnalysisSelection } from 'src/app/core/models/analysis/lib-analyis/lib-analysis-selection';

@Component({
  templateUrl: './view-results.component.html'
})
export class ViewResultsComponent {
  @Input() public readonly station: Station;
  @Input() public readonly survey: SurveyI;

  public readonly analysisSelection$ = this.makeAnalysisSelection$();

  constructor(private readonly libService: LibService) {
  }

  private makeAnalysisSelection$(): Observable<AnalysisSelection> {
    const behaviorSubject = new BehaviorSubject<AnalysisSelection>(undefined);
    const helper = (lib: Lib) => new LibAnalysisSelection(lib.makeAnalysisOptions);
    this.libService.get().pipe(map(helper)).subscribe(behaviorSubject.next.bind(behaviorSubject));
    return behaviorSubject;
  }
}
