import { Api } from './api';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export abstract class MockApi<T> extends Api<T> {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (this.isMockingApi()) {
      return super.intercept(request, next);
    } else {
      return this.continue(request, next);
    }
  }

  private isMockingApi(): boolean {
    return environment.mockApi
  }
}
