import { AnalysisSelectionRow, AnalysisSelectionParameter, AnalysisSelectionParameterInputType } from '..';
import { LibAnalysisParameter } from 'src/app/core/lib/models';
import { LibCheckboxAnalyisSelectionParameter } from './checkbox-selection-paramter';
import { LibSelectAnalysisSelectionParameter } from './select-analysis-parameter';

export class LibAnalysisSelectionRow extends AnalysisSelectionRow {
  private readonly parameters: AnalysisSelectionParameter[];

  constructor(parameters: LibAnalysisParameter[]) {
    super();
    this.parameters = parameters.map(this.makeFormalParameter.bind(this));
  }

  public getParameters(): AnalysisSelectionParameter[] {
    return this.parameters;
  }

  private makeFormalParameter(unformalParameter: LibAnalysisParameter): AnalysisSelectionParameter {
    if (unformalParameter.type === AnalysisSelectionParameterInputType.Checkbox) {
      return new LibCheckboxAnalyisSelectionParameter(unformalParameter);
    } else if (unformalParameter.type === AnalysisSelectionParameterInputType.Select) {
      return new LibSelectAnalysisSelectionParameter(unformalParameter);
    } else {
      throw new Error(`Could not convert parameter: ${JSON.stringify(unformalParameter)}`);
    }
  }
}
