import { Component, Input } from '@angular/core';
import { RankData } from 'src/app/core/models/analysis/rank-data';
import { truncate } from '../../utils';

@Component({
  selector: 'app-analysis-rank-data',
  templateUrl: './analysis-rank-data.component.html',
  styleUrls: ['./analysis-rank-data.component.css']
})
export class AnalysisRankDataComponent {

  @Input() private readonly rankData: RankData;

  constructor() {
  }

  public getColor(): string {
    return this.rankData.getColor();
  }

  public getTooltipTitle(): string {
    return this.rankData.getName() || this.rankData.getNick() || '';
  }

  public getFullDisplayText(): string {
    return [
      this.getDisplayName(),
      '-',
      this.rankData.getResult().asLevel(),
      this.rankData.getResult().asNumber().toFixed(2)
    ].join(' ');
  }

  private getDisplayName(): string {
    const nickOrName = this.rankData.getNick() || this.rankData.getName() || '';
    return truncate(nickOrName, 5, '...');
  }
}
