import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Organization } from './Organization';

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
});

export enum Types {
  LoadAll = '[Organizations] Load All',
  AddAll = '[Organizations] Add All',
  UpsertOne = '[Organization] Upsert One',
  RemoveOne = '[Organization] Remove One'
}

interface OrganizationAction extends Action {
  type: Types;
}

export class LoadAll implements OrganizationAction {
  public readonly type = Types.LoadAll;
}

export class AddAll implements OrganizationAction {
  public readonly type = Types.AddAll;

  constructor(public readonly payload: { organizations: Organization[] }) {
  }
}

export class UpsertOne implements OrganizationAction {
  public readonly type = Types.UpsertOne;

  constructor(public readonly payload: { organization: Organization }) {
  }
}

export class RemoveOne implements OrganizationAction {
  public readonly type = Types.RemoveOne;

  constructor(public readonly payload: { organization: Organization }) {
  }
}

export interface State extends EntityState<Organization> {
}

const initial: State = adapter.getInitialState({});

type Union = LoadAll | AddAll | UpsertOne | RemoveOne;

export function reducer(state = initial, action: Union) {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.addMany((<AddAll>action).payload.organizations, state);
    }
    case Types.UpsertOne: {
      return adapter.upsertOne(action.payload.organization, state);
    }
    case Types.RemoveOne: {
      return adapter.removeOne(action.payload.organization.id, state);
    }
    default: {
      return state;
    }
  }
}

