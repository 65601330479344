import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createSelector, select, Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import { Revision } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { SurveyUpdateService } from 'src/app/core/survey-update/service';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { AdditionalAccessControlModels } from 'src/app/core/survey-wrapper/survey-access-control';
import { State } from '../rx';
import { currentRevisionSelector, currentStationSelector } from '../rx/selectors';
import { ActiveSurveyService } from './active-survey.service';
import { RevisionSurveyActionsService } from 'src/app/core/survey-wrapper/revision-survey-wrapper-service';
import { SurveyActions } from 'src/app/core/survey-wrapper/survey-actions';

@Injectable({
  providedIn: 'root'
})
export class RevisionActiveSurveyService extends ActiveSurveyService {
  constructor(
    store: Store<State>,
    updates$: Actions,
    private readonly revisionSurveyWrapperService: RevisionSurveyActionsService,
    private readonly surveyUpdateService: SurveyUpdateService
  ) {
    super(store, updates$);
  }

  public isInReviewMode(): boolean {
    return false;
  }

  protected makeStation$(): Observable<Station | null> {
    return this.store.pipe(select(currentStationSelector));
  }

  protected makeRevision$(): Observable<Revision | null> {
    return this.store.pipe(select(currentRevisionSelector));
  }

  protected makeJob$(): Observable<Job | null> {
    const jobForRevision = (revision: Revision | null, jobs: { [id: string]: Job }) => {
      if (revision) {
        return jobs[revision.job_id];
      } else {
        return null;
      }
    };

    const selector = createSelector(
      currentRevisionSelector,
      (state) => state.jobs.entities,
      jobForRevision
    );

    return this.store.pipe(select(selector));
  }

  protected makeSurveyActions$(): Observable<SurveyActions | null> {
    const getActions = (additionalModels: AdditionalAccessControlModels) => {
      return this.revisionSurveyWrapperService.get(additionalModels, additionalModels.revision);
    };

    return this.makeSurveyActionsHelper$(this.revision$, of(undefined), getActions);
  }

  protected onSurveyStateUpdate() {
    super.onSurveyStateUpdate();
    this.saveUpdates();
  }

  private saveUpdates() {
    const helper = (revision: Revision, surveyWrapper: SurveyWrapper) =>
      this.surveyUpdateService.queueUpdates(revision, surveyWrapper.drainUpdates());
    this.onRevisionAndSurveyWrapper(helper);
  }

  protected savePositionData() {
    const helper = (revision: Revision, surveyWrapper: SurveyWrapper) =>
      this.revisionSurveyWrapperService.savePositionData(revision, surveyWrapper);
    this.onRevisionAndSurveyWrapper(helper);
  }

  private onRevisionAndSurveyWrapper(callback: (arg0: Revision, arg1: SurveyWrapper) => any) {
    const revision$ = this.revision$.pipe(first());
    const surveyActions$ = this.surveyActions$.pipe(first());
    forkJoin(revision$, surveyActions$).subscribe(([revision, surveyActions]) => callback(revision, surveyActions.surveyWrapper));
  }
}
