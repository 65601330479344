import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { first, map, flatMap, filter } from 'rxjs/operators';
import { QuestionI } from 'src/app/core/lib/models';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { State } from '../rx';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { PositionInSurvey } from 'src/app/core/survey-wrapper/position-in-survey';
import { UserService } from 'src/app/core/services/user.service';
import { EditAdditionalInformationComponent } from '../edit-additional-information/edit-additional-information.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';
import { LibService } from 'src/app/core/lib/service';
import { find } from 'lodash';

@Component({
  selector: 'app-question-selection',
  templateUrl: './question-selection.component.html',
  styleUrls: ['./question-selection.component.css']
})
export class QuestionSelectionComponent extends ActiveSurveyDirective {
  public searchText = '';
  public onlyDisplayRequired = false;

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly userService: UserService,
    private readonly modalService: NgbModal,
    private readonly libService: LibService
  ) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);
  }

  shouldDisplayQuestion$(question: QuestionI) {
    return this.isInReviewMode$.pipe(flatMap(isInReviewMode => {
      if (isInReviewMode) {
        return this.shouldDisplayReviewQuestion$(question);
      } else {
        return of(this.shouldDisplayRevisionQuestion(question));
      }
    }));
  }

  private shouldDisplayReviewQuestion$(question: QuestionI) {
    if (this.onlyDisplayRequired) {
      return this.hasBeenReviewed$(question).pipe(map(res => !res));
    } else {
      return of(true);
    }
  }

  private shouldDisplayRevisionQuestion(question: QuestionI) {
    if (this.onlyDisplayRequired) {
      return !question.completed;
    } else {
      return true;
    }
  }

  public activeClass(question: QuestionI): Observable<boolean> {
    return this.currentQuestion$.pipe(
      first(),
      map(currentQuestion => currentQuestion === question));
  }

  hasBeenReviewed$(question: QuestionI) {
    return combineLatest([
      this.libService.get(),
      this.getReview$(),
      this.surveyWrapper$,
      this.store
    ]).pipe(map(([lib, review, surveyWrapper, state]) => {
      if (!review) {
        return false;
      }

      return find(Object.values(state.reviewCompletions.entities).map(rc => {
        if (rc.review_id !== review.id) {
          return false;
        }

        const survey = surveyWrapper.getSurvey();
        const questionMetadataKey = lib.metadataKeyFor({ question, survey });

        return lib.metadataCompare(questionMetadataKey, rc.metadata_key);
      }));
    }));
  }

  shouldReview$(question: QuestionI) {
    return combineLatest([this.getReview$(), this.surveyWrapper$]).pipe(map(([review, surveyWrapper]) => {
      if (!review) {
        return false;
      } else {
        const survey = surveyWrapper.getSurvey();

        const sectionKey = Object.keys(survey.sections).find(sk => {
          const { question: question_ } = survey.sections[sk];

          let { questions } = survey.sections[sk];
          if (!questions) {
            questions = [question_];
          }

          return !!questions.find(q => q === question);
        });

        return review.section_keys.includes(sectionKey);
      }
    }));
  }

  public questionBadgeStyle$(question: QuestionI) {
    return combineLatest([
      this.isInReviewMode$,
      this.shouldReview$(question)
    ]).pipe(flatMap(([
      isInReviewMode,
      shouldReview
    ]) => {
      if (isInReviewMode && shouldReview) {
        return this.hasBeenReviewed$(question).pipe(map(reviewed => {
          return { 'background': reviewed ? '#33cd5f' : '#ffc900' };
        }));
      } else {
        return of({ 'background': question.completed ? '#33cd5f' : '#ffc900' });
      }
    }));
  }

  public handleQuestionClick(question: QuestionI) {
    this.positionInSurvey$.pipe(first()).subscribe((positionInSurvey: PositionInSurvey) => {
      const currentSection = positionInSurvey.getCurrentSection();
      positionInSurvey.setCurrentQuestionForSection(question, currentSection);
      this.dispatchSurveyStateUpdate();
    });
  }

  public getCurrentQuestion$(): Observable<QuestionI> {
    return this.positionInSurvey$.pipe(
      map(positionInSurvey => positionInSurvey.getCurrentQuestionForCurrentSection()));
  }

  public trackQuestion(index: number): number {
    return index;
  }

  protected surveyStateUpdated() {
    this.searchText = '';
  }

  public canEditAdditionalInformation$() {
    return combineLatest([
      this.revision$,
      this.userService.getLoggedIn(),
      this.currentSection$,
      this.store
    ]).pipe(map(([revision, user, section, state]) => {
      // The revision is editable, we are in the additional information section, and
      // the user has either been assigned the additional information section or
      // the user created the job this revision is on
      return !revision.final &&
        section.title === 'Additional Information' &&
        (revision.user_sections.additionalInformation === user.id ||
         state.jobs.entities[revision.job_id].user_id === user.id
        );
    }));
  }

  public editAdditionalInformation() {
    const modalRef = this.modalService.open(EditAdditionalInformationComponent, {
      backdrop: 'static'
    });

    this.getSurvey$()
      .pipe(first())
      .subscribe(survey => modalRef.componentInstance.survey = survey);
  }
}
