import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-back-login-state-link',
  templateUrl: './back-login-state-link.component.html',
  styleUrls: ['./back-login-state-link.component.css']
})
export class BackLoginStateLinkComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) {
  }

  ngOnInit() {
  }

  handleBack() {
    this.loginStateService.backLoginState();
  }
}
