import { Pipe } from '@angular/core';
import { OptionI, QuestionI } from 'src/app/core/lib/models';

@Pipe({
  name: 'extractAnnotation'
})
export class ExtractAnnotationPipe {
  transform(question: QuestionI): string {
    const helper = (cur: string, option: OptionI) => cur || option.annotation;
    return question.options.reduce(helper, '');
  }
}
