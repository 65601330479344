// This is used in order to partially enforce uniqueness
// of localstorage keys
export type LocalStorageKey = 'cached-survey-updates';

export class LocalStorageItem<T> {
  constructor(private readonly key: LocalStorageKey,
    private readonly defaultValue?: T) {
  }

  public get(): T {
    return this.orDefault(JSON.parse(localStorage.getItem(this.key)));
  }

  public set(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  public update(func: (arg: T) => T) {
    this.set(func(this.get()));
  }

  public clear() {
    localStorage.removeItem(this.key);
  }

  private orDefault(storedValue: T | null): T {
    return storedValue === null ? this.defaultValue : storedValue;
  }
}
