import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { State } from '../rx';
import { Store } from '@ngrx/store';
import { first, map, tap } from 'rxjs/operators';
import { DeleteComponent } from 'src/app/shared/components/delete/delete.component';
import { QuestionI } from 'src/app/core/lib/models';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

interface UpdateArgs {
  title: string;
  oldTitle?: string;
}

@Component({
  selector: 'app-additional-information-title-editor',
  templateUrl: 'additional-information-title-editor.component.html'
})
export class AdditionalInformationTitleEditorComponent {
  @Output() updateRequested = new EventEmitter<UpdateArgs>();

  @Input() removeAdditionalInformation : (title: string) => void;
  @Input() get title() { return this.title_; }

  set title(title) {
    this.titleCopy = title;
    this.title_ = title;
  }

  public titleCopy: string;
  private title_: string;

  constructor(private readonly modalService: NgbModal) {
  }

  handleDeleteRequest() {
    const modalRef = this.modalService.open(DeleteComponent);
    const { componentInstance } = modalRef;
    componentInstance.name = this.titleCopy;
    componentInstance.activeModal = modalRef;
    componentInstance.onConfirm =
      () => this.removeAdditionalInformation(this.titleCopy);
  }

  canSubmit() {
    return this.title !== this.titleCopy;
  }

  submit() {
    this.updateRequested.emit({
      title: this.titleCopy,
      oldTitle: this.title
    });
  }

  canDelete() {
    // Ensure that the diffs with the previous additional information questions
    // occurs successfully
    return this.title === this.titleCopy;
  }
}


@Component({
  selector: 'app-edit-additional-information',
  templateUrl: './edit-additional-information.component.html',
  styleUrls: ['./edit-additional-information.component.css']
})
export class EditAdditionalInformationComponent extends ActiveSurveyDirective {
  questions$ = this.getQuestions$();

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly activeModal: NgbActiveModal) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);
  }

  protected surveyStateUpdated() {
    this.questions$ = this.getQuestions$();
  }

  addAdditionalInformation({ title, oldTitle }: UpdateArgs, question?: QuestionI) {
    this.surveyWrapper$.pipe(first()).subscribe(surveyWrapper => {

      surveyWrapper.addAdditionalInformation({
        title,
        oldTitle,
        answer: question && question.answer
      });

      this.dispatchSurveyStateUpdate();
    });
  }

  removeAdditionalInformation = (title: string) => {
    this.surveyWrapper$.pipe(first()).subscribe(surveyWrapper => {
      surveyWrapper.removeAdditionalInformation({title});

      this.dispatchSurveyStateUpdate();
    });
  }

  handleDismiss() {
    this.activeModal.dismiss();
  }

  private getQuestions$() {
    return this.surveyWrapper$.pipe(map(surveyWrapper => {
      if (surveyWrapper) {
        return surveyWrapper.getSurvey().sections.additionalInformation.questions;
      } else {
        return [];
      }
    }));
  }
}
