import { Component, HostListener } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SectionI } from 'src/app/core/lib/models';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { Completion } from 'src/app/core/survey-wrapper/completion';
import { PositionInSurvey } from 'src/app/core/survey-wrapper/position-in-survey';
import { UpdatingReviewCompletionManagerDirective } from '../services/updating-review-completion-manager.directive';
import { ShouldWorkOnWrapper } from './should-work-on/should-work-on-wrapper';

@Component({
  selector: 'app-survey-body',
  templateUrl: './survey-body.component.html',
  styleUrls: ['./survey-body.component.css']
})
export class SurveyBodyComponent extends UpdatingReviewCompletionManagerDirective {
  public sectionTitleColor$(section: SectionI): Observable<string | null> {
    const helper = (positionInSurvey: PositionInSurvey, isSectionComplete: boolean, isInReviewMode: boolean) => {
      // Always set as blue if is the current section
      if (positionInSurvey.isCurrentSection(section)) {
        return 'blue';
      } else if (!isInReviewMode) {
        if (isSectionComplete) {
          // bootstrap success color
          return '#28a745';
        } else {
          return null;
        }
      } else {
        // When in review mode, don't provide any extra coloring because
        // it will override the class being set to indicate that the section
        // should be worked on
        return null;
      }
    };

    return combineLatest([
      this.positionInSurvey$,
      this.isSectionComplete$(section),
      this.isInReviewMode$
    ]).pipe(map(([positionInSurvey, isSectionComplete, isInReviewMode]) =>
      helper(positionInSurvey, isSectionComplete, isInReviewMode)));
  }

  public shouldWorkOn$(section: SectionI): Observable<boolean> {
    return new ShouldWorkOnWrapper(
      this.hasAccessToSection(section),
      this.isInReviewMode$,
      this.reviewCompletionManager$,
      this.completionResultForSection$(section)
    ).should$();
  }

  public getSections$(): Observable<SectionI[]> {
    const helper = (surveyWrapper: SurveyWrapper) => Object.values(surveyWrapper.getSurvey().sections);
    return this.surveyWrapper$.pipe(map(helper));
  }

  public isSectionComplete$(section: SectionI): Observable<boolean> {
    return this.completionResultForSection$(section)
      .pipe(map(completion => completion.asNumber() === 1));
  }

  public completionResultForSection$(section: SectionI): Observable<CompletionResult> {
    const helper = (completion: Completion) => completion.forSection(section);
    return this.completion$.pipe(map(helper));
  }

  public handleSectionClick(section: SectionI) {
    this.positionInSurvey$.pipe(first()).subscribe((positionInSurvey: PositionInSurvey) => {
      positionInSurvey.setCurrentSection(section);
      this.dispatchSurveyStateUpdate();
    });
  }

  @HostListener('document:keydown', ['$event'])
  private async handleKeyPress(event: KeyboardEvent) {
    this.currentSection$.pipe(first()).subscribe(section => {
      // This section has a text box which users might expect to be able
      // to use arrow keys to navigate while editting.
      // TODO probably want some way to ask if in any text input
      if (section.title === 'Additional Information') return;

      if (event.key === 'ArrowLeft') {
        this.activeSurveyFactoryService.previous();
      } else if (event.key === 'ArrowRight') {
        this.activeSurveyFactoryService.next();
      }
    });
  }
}
