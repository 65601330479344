import { Action, ActionReducerMap, createSelector } from '@ngrx/store';
import { Revision } from 'src/app/core/revisions';
import * as Root from '../../reducers';

export enum AvailableModelTypes {
  User = '[Admin] User',
  Organization = '[Admin] Organization',
  Region = '[Admin] Region',
  Station = '[Admin] Station',
  Job = '[Admin] Job'
}

export interface AdministrateModel extends Action {
  type: AvailableModelTypes;
}

export class AdministrateUsers implements AdministrateModel {
  type = AvailableModelTypes.User;
}

export class AdministrateOrganizations implements AdministrateModel {
  type = AvailableModelTypes.Organization;
}

export class AdministrateRegions implements AdministrateModel {
  type = AvailableModelTypes.Region;
}

export class AdministrateStations implements AdministrateModel {
  type = AvailableModelTypes.Station;
}

export class AdministrateJobs implements AdministrateModel {
  type = AvailableModelTypes.Job;
}

type AdministrationModels = AdministrateUsers | AdministrateOrganizations | AdministrateOrganizations |
  AdministrateRegions | AdministrateStations | AdministrateJobs;

function isModelTypeAction(action: Action): boolean {
  return Object.values(AvailableModelTypes).includes(action.type);
}

export function availableModelTypeReducer(state: AvailableModelTypes = AvailableModelTypes.User,
  action: AdministrationModels): AvailableModelTypes {
  if (isModelTypeAction(action)) {
    return action.type;
  } else {
    return state;
  }
}

export const selectReviewsForRevision = () => createSelector(
  (state: State, props: { revision: Revision }) =>
    Object.values(state.reviews.entities)
      .filter(review => review.revision_id === props.revision.id),
);

export interface AdminState {
  currentModelType: AvailableModelTypes;
}

export interface State extends Root.State {
  admin: AdminState;
}

export const reducers: ActionReducerMap<AdminState, any> = {
  currentModelType: availableModelTypeReducer,
};
