import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocationI } from '../locations';
import { LocationCopyConstructor } from '../locations/location-copy';
import { PictureModelService } from './picture-model.service';

@Injectable()
export abstract class LocationService<T extends LocationI> extends PictureModelService<T> {

  protected abstract readonly mapFileExtensionName: string;
  protected abstract readonly copyConstructor: LocationCopyConstructor<T>;

  public addMapFile(file: any, fileType: string, model: T): Observable<T> {
    const url = this.makeMapFileUrl(fileType, model);
    return this.http.post<T>(
      url,
      file,
      {
        headers: this.apiTokenService.headers()
      }
    )
      .pipe(map(this.addKind.bind(this)));
  }

  public removeMapFile(model: T): Observable<T> {
    const copy = new this.copyConstructor({ model });
    copy.map_file_uri = null;
    return this.update(copy, model);
  }

  private makeMapFileUrl(fileType: string, model: T): string {
    const url = [
      environment.apiUri.toString(),
      'upload',
      'map_file',
      this.mapFileExtensionName,
      model.id,
      fileType
    ].join('/');

    return url;
  }
}
