import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LoadEffect } from '../effects/load';
import { FeedbacksService } from './service';
import { Revision } from '../revisions';

export interface Feedback {
  id: number | Symbol;
  revision_id: number;
  review_id: number;
  text: string;
  metadata_key: any;
}

export enum Types {
  AddAll = '[Feedbacks] Add All',
  LoadAllForRevision = '[Feedbacks] Load All For Revision',
  Create = '[Feedbacks] Create',
  Update = '[Feedbacks] Update',
  Delete = '[Feedbacks] Delete',
  UpsertOne = '[Feedbacks] UsertOne',
  RemoveOne = '[Feedbacks] RemoveOne'
}

interface FeedbackAction extends Action {
  type: Types;
}

export class AddAll implements FeedbackAction {
  type = Types.AddAll;

  constructor(public readonly payload: { feedbacks: Feedback[] }) {
  }
}

export class LoadAllForRevision implements FeedbackAction {
  type = Types.LoadAllForRevision;

  constructor(public readonly payload: { revision: Revision }) {
  }
}

export class CreateFeedback implements FeedbackAction {
  type = Types.Create;

  constructor(public readonly payload: { feedback: Feedback }) {
  }
}

export class UpdateFeedback implements FeedbackAction {
  type = Types.Update;

  constructor(public readonly payload: { feedback: Feedback }) {
  }
}

export class DeleteFeedback implements FeedbackAction {
  type = Types.Delete;

  constructor(public readonly payload: { feedback: Feedback }) {
  }
}

export class UpsertOne implements FeedbackAction {
  type = Types.UpsertOne;

  constructor(public readonly payload: { feedback: Feedback }) {
  }
}


export class RemoveOne implements FeedbackAction {
  type = Types.RemoveOne;

  constructor(public readonly payload: { feedback: Feedback }) {
  }
}

const adapter: EntityAdapter<Feedback> = createEntityAdapter<Feedback>({});

export interface State extends EntityState<Feedback> { }

const initial: State = adapter.getInitialState({});

type Union = AddAll | LoadAllForRevision | UpsertOne | RemoveOne;

export function reducer(state = initial, action: Union) {
  switch (action.type) {
    case (Types.AddAll): {
      return adapter.addMany((<AddAll>action).payload.feedbacks, state);
    }
    case (Types.UpsertOne): {
      return adapter.upsertOne((<UpsertOne>action).payload.feedback, state);
    }
    case (Types.RemoveOne): {
      return adapter.removeOne(<number>(<RemoveOne>action).payload.feedback.id, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class CreateFeedbackEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly feedbacksService: FeedbacksService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Create];
  }

  protected handleResponse({ oldFeedback, newFeedback }) {
    return new AddAll({ feedbacks: [newFeedback] });
  }

  protected handleRequest({ payload: { feedback } }: CreateFeedback) {
    return this.feedbacksService.create(feedback);
  }
}


@Injectable()
export class UpdateFeedbackEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly feedbacksService: FeedbacksService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Update];
  }

  protected handleResponse(feedback: Feedback) {
    return new UpsertOne({ feedback });
  }

  protected handleRequest({ payload: { feedback } }: CreateFeedback) {
    return this.feedbacksService.update(feedback);
  }
}


@Injectable()
export class DeleteFeedbackEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly feedbacksService: FeedbacksService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Delete];
  }

  protected handleResponse(feedback: Feedback) {
    return new RemoveOne({ feedback });
  }

  protected handleRequest({ payload: { feedback } }: CreateFeedback) {
    return this.feedbacksService.delete(feedback);
  }
}


@Injectable()
export class LoadAllForRevisionEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly feedbacksService: FeedbacksService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAllForRevision];
  }

  protected handleResponse(feedbacks: Feedback[]) {
    return new AddAll({ feedbacks });
  }

  protected handleRequest({ payload: { revision } }: LoadAllForRevision) {
    return this.feedbacksService.getForRevision(revision);
  }
}
