import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Jobs from 'src/app/core/jobs';
import { Job } from 'src/app/core/jobs';
import { SelectJob } from 'src/app/core/jobs/actions/select';
import { allFromApplicationState, selectedJob } from 'src/app/core/jobs/selectors';
import * as ViewSelections from 'src/app/opensource-survey/rx/view-selections';
import { State } from 'src/app/reducers';

@Component({
  selector: 'app-job-selection',
  templateUrl: './job-selection.component.html',
  styleUrls: ['./job-selection.component.css']
})
export class JobSelectionComponent implements OnInit {

  public readonly jobs$: Observable<Job[]> =
    this.store.pipe(
      select(allFromApplicationState),
    );

  public readonly selectedJob$: Observable<any> =
    this.store.pipe(
      select(selectedJob)
    );

  constructor(
    private readonly store: Store<State>
  ) { }

  ngOnInit() {
    this.store.dispatch(new Jobs.Actions.LoadAll());
  }

  handleClick(job: Jobs.Job): void {
    this.selectJob(job);
    this.viewSelections();
  }

  private selectJob(job: Jobs.Job): void {
    this.store.dispatch(new SelectJob({ job: job }));
  }

  private viewSelections(): void {
    this.store.dispatch(new ViewSelections.Show());
  }
}
