export enum Types {
  LoadAll = '[Jobs] Load All',
  AddAll = '[Jobs] Add All',
  Create = '[Jobs] Create',
  Select = '[Jobs] Select'
}




