import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class Api<T> implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (this.shouldHandle(request)) {
      return this.handle(request);
    } else {
      return this.continue(request, next);
    }
  }

  protected continue(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request);
  }

  protected abstract shouldHandle(request: HttpRequest<any>): boolean;

  protected abstract handle(request: HttpRequest<any>): Observable<HttpResponse<T>>;
}
