import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { TruncatePipe, FilterModelsPipe } from './pipes';
import { DeleteModelComponent } from './components/delete-model/delete-model.component';
import { AnalysisSelectionComponent } from './components/analysis-selection/analysis-selection.component';
import { StationAnalysisComponent } from './components/station-analysis/station-analysis.component';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GenReportComponent } from './components/gen-report/gen-report.component';
import { AnalysisGraphComponent } from './components/analysis-graph/analysis-graph.component';
import { AnalysisRankDataComponent } from './components/analysis-rank-data/analysis-rank-data.component';
import { JobSelectionComponent } from './components/job-selection/job-selection.component';
import { CenteredComponent } from './centered/centered.component';
import { ManualButtonComponent } from './components/manual-button/manual-button.component';
import { ManualComponent } from './components/manual/manual.component';
import { DeleteComponent } from './components/delete/delete.component';
import { ToggleViewSelectionsComponent } from './components/toggle-view-selections/toggle-view-selections.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFontAwesomeModule,
    ChartsModule,
    NgbModule
  ],
  declarations: [
    FilterModelsPipe,
    TruncatePipe,
    DeleteModelComponent,
    AnalysisSelectionComponent,
    StationAnalysisComponent,
    GenReportComponent,
    AnalysisGraphComponent,
    AnalysisRankDataComponent,
    JobSelectionComponent,
    CenteredComponent,
    ManualButtonComponent,
    ManualComponent,
    DeleteComponent,
    ToggleViewSelectionsComponent
  ],
  exports: [
    FilterModelsPipe,
    TruncatePipe,
    DeleteModelComponent,
    AnalysisSelectionComponent,
    StationAnalysisComponent,
    JobSelectionComponent,
    CenteredComponent,
    ManualButtonComponent,
    ToggleViewSelectionsComponent
  ],
  providers: [
  ],
  entryComponents: [
    DeleteModelComponent,
    DeleteComponent,
    ManualComponent
  ]
})
export class SharedModule { }
