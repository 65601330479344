import { Station } from 'src/app/core/stations';

export class StationSelector {
  private readonly selected = {};

  toggle(station: Station) {
    this.selected[station.id] = !this.selected[station.id];
  }

  selectedIds(): number[] {
    const ids = [];

    Object.keys(this.selected).forEach(id => {
      if (this.selected[id]) {
        ids.push(id);
      }
    });

    return ids;
  }

  isSelected(station: Station): boolean {
    return this.selected[station.id];
  }

  atLeastOneSelected(): boolean {
    return this.selectedIds().length >= 1;
  }
}
