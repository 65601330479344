import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-request-access-link',
  templateUrl: './request-access-link.component.html',
  styleUrls: ['./request-access-link.component.css']
})
export class RequestAccessLinkComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) {
  }

  ngOnInit() {
  }

  handleRequestAccess() {
    this.loginStateService.setStateToRequestAccess();
  }

}
