import { ReviewType } from 'src/app/core/review-types';
import { User } from 'src/app/core/User';
import { Revision } from '../revisions';
import { SectionSelector } from './section-selector';
import { ReviewTypeTag } from '../models/reporting/review-type-tag';

interface CreateReviewArgs {
  user_id: number;
  revision_id: number;
  review_type_id: number;
  purpose: string;
  section_keys: string[];
}

export class ReviewValidator {
  constructor(private readonly user: User,
    private readonly reviewType: ReviewType,
    private readonly purpose: string,
    private readonly selector: SectionSelector,
    private readonly revision: Revision) {
  }

  public getCreateReviewArgs(): CreateReviewArgs {
    if (!this.isValid()) {
      throw new Error('Cannot request arguments from a non valid validator');
    }

    return {
      user_id: this.user.id,
      revision_id: this.revision.id,
      review_type_id: this.reviewType.id,
      purpose: this.purpose,
      section_keys: this.selector.sectionKeys()
    };
  }

  public isValid(): boolean {
    return !this.currentError();
  }

  public currentError(): string | undefined {
    try {
      this.validate();
    } catch (e) {
      return e.message;
    }

    return undefined;
  }

  private validate(): void {
    this.validateUser();
    this.validateReviewType();
    this.validatePurpose();
    this.validateSelector();
  }

  private validateUser(): void {
    if (!this.user) {
      throw new Error('User Required');
    }
  }

  private validateReviewType(): void {
    if (!this.reviewType) {
      throw new Error('Review Type Required');
    }
  }

  private validatePurpose() {
    if (!this.purpose || this.purpose.length === 0) {
      throw new Error('Purpose Required');
    }
  }

  private validateSelector(): void {
    if (this.reviewType.name !== ReviewTypeTag.Classification &&
        this.reviewType.name !== ReviewTypeTag.Approval &&
        !this.selector.atLeastOneSelected()) {
      throw new Error('Select at least one section');
    }
  }
}
