import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from 'src/app/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private apiToken_: string;

  set apiToken(apiToken) {
    this.apiToken_ = apiToken;
    localStorage.apiToken = JSON.stringify(apiToken);
  }

  get apiToken() {
    return this.apiToken_;
  }

  constructor(private readonly store: Store<State>) {
    try {
      this.apiToken = JSON.parse(localStorage.apiToken);
    } catch (e) { }
  }

  public hasToken(): boolean {
    return !!this.apiToken;
  }

  public headersWithOptionalToken() {
    return this.hasToken() ? this.headers() : new HttpHeaders();
  }

  public headers() {
    const headers = this.addToHeaders(new HttpHeaders());
    return headers;
  }

  public addToHeaders(headers: HttpHeaders) {
    return headers.append('api-token', this.apiToken);
  }

  public logout() {
    this.apiToken = undefined;
  }

  public isLoggedIn(): boolean {
    return !!this.apiToken;
  }
}
