import { Component, OnInit } from '@angular/core';
import { EditJobComponent } from '../edit-job/edit-job.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-job-button',
  templateUrl: './new-job-button.component.html',
  styleUrls: ['./new-job-button.component.css']
})
export class NewJobButtonComponent implements OnInit {

  constructor(private readonly modalService: NgbModal) { }

  ngOnInit() {
  }

  handleClick() {
    this.modalService.open(EditJobComponent);
  }

}
