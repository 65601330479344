import { EditUserComponentParams } from './edit-user-component-params';
import { User } from 'src/app/core/User';
import { UserCopy } from 'src/app/core/users/user-copy';
import { Action } from '@ngrx/store';
import { CreateModelAction } from 'src/app/core/models';
import { UserKind } from 'src/app/core/users';

export class CreatingEditUserComponentParams extends EditUserComponentParams {
  private readonly copy = this.makeCopy();

  constructor(protected readonly parentUser: User) {
    super();

  }

  public getTitle(): string {
    return 'New User';
  }

  public getSuccessNotificationMessage(copy: UserCopy): string {
    return `Successfully created ${copy.name}`;
  }

  public getCopy(): UserCopy {
    return this.copy;
  }

  public getAction(): Action {
    return new CreateModelAction({ copy: this.copy });
  }

  private makeCopy(): UserCopy {
    const copy = new UserCopy({ kind: UserKind });
    copy.user_id = this.parentUser.id;
    return copy;
  }
}
