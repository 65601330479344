import { EmailLoginState } from './email-login-state';
import { GetLoginState, LoginStateConstructor } from './login-state-controller';
import { RequestAccessRequestMadeLoginState } from './request-access-request-made-login-state';

export class RequestAccessLoginState extends EmailLoginState {
  constructor(
    getLoginState: GetLoginState,
    email: string,
    private readonly previousLoginStateConstructor: LoginStateConstructor
  ) {
    super(getLoginState, email);
  }

  back() {
    return this.getLoginState(this.previousLoginStateConstructor);
  }

  next() {
    return this.getLoginState(RequestAccessRequestMadeLoginState);
  }
}
