import { Pipe } from '@angular/core';

@Pipe({
  name: 'sortStations'
})
export class SortStationsPipe {
  transform(stations, analysis) {
    const ranked = (analysis || {}).ranked || [];
    const sorted : any = [];

    ranked.forEach(stationId => {
      const station = stations.find(station => station.id == stationId);
      if (station) {
        sorted.push(station);
      }
    });

    stations.forEach(station => {
      if (!sorted.includes(station)) {
        sorted.push(station);
      }
    });

    return sorted;
  }
}
