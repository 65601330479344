import { LibAnalysisParameter } from 'src/app/core/lib/models';
import { AnalysisSelectionParameter, AnalysisSelectionParameters, AnalysisSelectionRow } from '..';
import { LibAnalysisSelectionRow } from './selection-row';

export class LibAnalysisSelectionParameters extends AnalysisSelectionParameters {

  private readonly rows: AnalysisSelectionRow[];

  constructor(private readonly parameters: LibAnalysisParameter[][]) {
    super();
    this.rows = this.parameters.map(someParameters => new LibAnalysisSelectionRow(someParameters));
  }

  public asRows(): AnalysisSelectionRow[] {
    return this.rows;
  }

  public getParameters(): AnalysisSelectionParameter[] {
    const allParameters = [];
    this.rows.forEach(row => allParameters.push(row.getParameters()));
    return allParameters;
  }
}
