import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { SharedModule } from '../shared/shared.module';
import { ActiveSurveyDirective } from './active-survey/active-survey.component';
import { AnnotationEditorComponent } from './annotation-editor/annotation-editor.component';
import { ExtractAnnotationPipe } from './annotation-editor/extract-annotation.pipe';
import { CompleteReviewComponent } from './complete-review/complete-review.component';
import { CreateFeedbackComponent } from './create-feedback/create-feedback.component';
import { EditSurveyAttemptComponent } from './edit-survey-attempt/edit-survey-attempt.component';
import { FeedbackActionsComponent } from './feedback-actions/feedback-actions.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NewSurveyAttemptComponent } from './new-survey-attempt/new-survey-attempt.component';
import { OpensourceSurveyModuleComponent } from './opensource-survey-module/opensource-survey-module.component';
import { OptionsComponent } from './options/options.component';
import { QuestionSelectionComponent } from './question-selection/question-selection.component';
import { QuestionComponent } from './question/question.component';
import { ReviewActionsComponent } from './review-actions/review-actions.component';
import { reducers } from './rx';
import { SectionComponent } from './section/section.component';
import { SelectionsComponent } from './selections/selections.component';
import { StationSurveySelectionComponent } from './station-survey-selection/station-survey-selection.component';
import { SurveyBodyComponent } from './survey-body/survey-body.component';
import { SurveyFooterComponent } from './survey-footer/survey-footer.component';
import { SurveyHeaderComponent } from './survey-header/survey-header.component';
import { SurveyComponent } from './survey/survey.component';
import { TextQuestionComponent } from './text-question/text-question.component';
import { ViewResultsComponent } from './view-results/view-results.component';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { EditAdditionalInformationComponent, AdditionalInformationTitleEditorComponent } from './edit-additional-information/edit-additional-information.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularFontAwesomeModule,
    NgbModule,
    SharedModule,
    StoreModule.forFeature('surveys', reducers),
  ],
  declarations: [
    ScrollIntoViewDirective,
    ExtractAnnotationPipe,
    OpensourceSurveyModuleComponent,
    SurveyComponent,
    SectionComponent,
    QuestionComponent,
    NewSurveyAttemptComponent,
    EditSurveyAttemptComponent,
    ViewResultsComponent,
    TextQuestionComponent,
    FeedbackComponent,
    SelectionsComponent,
    StationSurveySelectionComponent,
    SurveyHeaderComponent,
    SurveyBodyComponent,
    SurveyFooterComponent,
    QuestionSelectionComponent,
    CreateFeedbackComponent,
    ReviewActionsComponent,
    CompleteReviewComponent,
    FeedbackActionsComponent,
    ActiveSurveyDirective,
    OptionsComponent,
    AnnotationEditorComponent,
    EditAdditionalInformationComponent,
    AdditionalInformationTitleEditorComponent
  ],
  entryComponents: [
    NewSurveyAttemptComponent,
    EditSurveyAttemptComponent,
    ViewResultsComponent,
    FeedbackComponent,
    CreateFeedbackComponent,
    CompleteReviewComponent,
    EditAdditionalInformationComponent
  ],
  exports: [
    OpensourceSurveyModuleComponent
  ]
})
export class OpensourceSurveyModule { }
