import { last } from 'lodash/fp';
import { ReportReview, ReportRevision } from '.';
import { findByTypeTag, ReviewTypeTag } from './review-type-tag';

export class RevisionChainProcessor {
  protected readonly revision: ReportRevision;

  constructor(reportRevisionChain: ReportRevision[]) {
    this.revision = last(reportRevisionChain);
    this.assertRevision();
  }

  getClassification(optional = false): ReportReview | null {
    return this.findBy(ReviewTypeTag.Classification, optional);
  }

  getApproval(optional = false): ReportReview {
    return this.findBy(ReviewTypeTag.Approval, optional);
  }

  private findBy(reviewTypeTag: ReviewTypeTag, optional: boolean) {
    const review = findByTypeTag(reviewTypeTag, this.revision.reviews);
    if (!optional) {
      this.assertReview(review, reviewTypeTag);
    }

    if (review && !review.complete_at) {
      return undefined;
    } else {
      return review;
    }
  }

  private assertRevision() {
    if (!this.revision) {
      throw new Error('Cannot process an empty Revision Chain');
    }
  }

  private assertReview(review: ReportReview | undefined, reviewTypeTag: ReviewTypeTag) {
    if (!review) {
      throw new Error(`No review found for ${reviewTypeTag}`);
    }
  }
}
