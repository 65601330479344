import { JobAction } from './job';
import { Types } from './types';
import { Job } from '../model';

export class AddAll implements JobAction {
  public readonly type = Types.AddAll

  constructor(public readonly payload: { jobs: Job[] }) {
  }
}
