import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, empty, Observable } from 'rxjs';
import { first, flatMap, map } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import { QuestionI, SectionI } from 'src/app/core/lib/models';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { AdditionalAccessControlModels } from 'src/app/core/survey-wrapper/survey-access-control';
import { SurveyActions } from 'src/app/core/survey-wrapper/survey-actions';
import { User } from 'src/app/core/User';
import { loggedInUserSelector } from 'src/app/core/users';
import { listenForSurveyStateUpdated, State, SurveyStateUpdatedAction } from '../rx';

export abstract class ActiveSurveyService {
  public readonly surveyStateUpdated = new BehaviorSubject<any>(true);

  public station$: Observable<Station | null>;
  public revision$: Observable<Revision | null>;
  public job$: Observable<Job | null>;
  public surveyActions$: Observable<SurveyActions | null>;
  public questions$: Observable<QuestionI[]>;
  public currentQuestion$: Observable<QuestionI>;
  public totalCompletion$: Observable<CompletionResult>;

  protected abstract makeStation$(): Observable<Station | null>;
  protected abstract makeRevision$(): Observable<Revision | null>;
  protected abstract makeJob$(): Observable<Job | null>;
  protected abstract makeSurveyActions$(): Observable<SurveyActions | null>;
  protected abstract savePositionData(): void;
  public abstract isInReviewMode(): boolean;

  constructor(
    protected readonly store: Store<State>,
    private readonly updates$: Actions,
  ) {
    this.assignModels();
    this.initListenForUpdates();
  }

  public next() {
    this.withSurveyActions(surveyActions => {
      surveyActions.positionInSurvey.next();
      this.dispatchSurveyStateUpdate();
    });
  }

  public previous() {
    this.withSurveyActions(surveyActions => {
      surveyActions.positionInSurvey.previous();
      this.dispatchSurveyStateUpdate();
    });
  }

  public mayAnswerCurrentQuestion$(): Observable<boolean> {
    const helper = (question: QuestionI, surveyActions: SurveyActions): boolean =>
      surveyActions.accessControl.mayAnswer(question, surveyActions.positionInSurvey.getCurrentSection());

    return combineLatest([
      this.currentQuestion$,
      this.surveyActions$
    ]).pipe(map(([question, surveyActions]) => helper(question, surveyActions)));
  }

  public hasAccessToSection(section: SectionI): Observable<boolean> {
    const helper = (surveyActions: SurveyActions): boolean => surveyActions.accessControl.hasAccessToSection(section);
    return this.surveyActions$.pipe(map(helper));
  }

  private assignModels() {
    this.station$ = this.makeStation$();
    this.revision$ = this.makeRevision$();
    this.job$ = this.makeJob$();
    this.surveyActions$ = this.makeSurveyActions$();
    this.questions$ = this.makeQuestionsObservable();
    this.currentQuestion$ = this.makeCurrentQuestionObservable();
    this.totalCompletion$ = this.makeTotalCompletionObservable();
  }

  private initListenForUpdates() {
    listenForSurveyStateUpdated(this.updates$, this.onSurveyStateUpdate.bind(this));
  }

  protected onSurveyStateUpdate() {
    this.updateDisplayedSurveyState();
    this.savePositionData();
  }

  protected onSurveyStateUpdated() {
    this.updateDisplayedSurveyState();
  }

  protected dispatchSurveyStateUpdate() {
    this.store.dispatch(new SurveyStateUpdatedAction());
  }

  protected updateDisplayedSurveyState() {
    this.assignModels();
    this.emitSurveyStateUpdated();
  }

  private emitSurveyStateUpdated() {
    this.surveyStateUpdated.next(true);
  }

  protected makeSurveyActionsHelper$(
    revision$: Observable<Revision>,
    review$: Observable<Review | undefined>,
    getActions: (arg0: AdditionalAccessControlModels) => Observable<SurveyActions>
  ): Observable<SurveyActions> {
    const user$ = this.store.pipe(select(loggedInUserSelector));

    const helper = (
      user: User | undefined,
      job: Job | undefined,
      revision: Revision | undefined,
      review: Review | undefined
    ): Observable<SurveyActions | null> => {
      if (user && job && revision) {

        const additionalAccessControlModels: AdditionalAccessControlModels = {
          user,
          job,
          revision,
          review
        };

        const actions = getActions(additionalAccessControlModels);
        return actions ? actions : empty();
      } else {
        return empty();
      }
    };

    return combineLatest([
      user$,
      this.job$,
      revision$,
      review$
    ]).pipe(flatMap(([user, job, revision, review]) => helper(user, job, revision, review)));
  }

  protected withSurveyActions(func: (surveyActions: SurveyActions) => any) {
    this.surveyActions$.pipe(first()).subscribe(surveyActions => {
      func(surveyActions);
    });
  }

  private makeQuestionsObservable() {
    return this.surveyActions$.pipe(
      map((surveyActions: SurveyActions) =>
        surveyActions.positionInSurvey.getQuestionsForCurrentSection()));
  }

  private makeCurrentQuestionObservable() {
    return this.surveyActions$.pipe(
      map(surveyActions => surveyActions.positionInSurvey.getCurrentQuestionForCurrentSection()));
  }

  private makeTotalCompletionObservable() {
    return this.surveyActions$.pipe(
      map(surveyActions => surveyActions.completion.total()));
  }

}
