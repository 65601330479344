import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { gen } from 'testcheck';
import { LoadEffect } from '../effects/load';
import { Revision } from '../revisions';
import { ReviewValidator } from './review-validator';
import { ReviewsService } from './service';
import { ReviewType } from '../review-types';

export interface Review {
  id: number;
  section_keys: string[];
  response?: string;
  purpose?: string;
  revision_id: number;
  user_id: number;
  complete_at?: number;
  review_type: ReviewType;
  user: {
    name: string;
  };
}

export const reviewGen = gen.object({
  id: gen.sPosInt,
  revision_id: gen.sPosInt,
  user_id: gen.sPosInt,
  section_keys: gen.array(gen.string),
  result: gen.string.nullable(),
  purpose: gen.string.nullable(),
  finished: gen.sPosInt.nullable(),
});

export const adapter: EntityAdapter<Review> = createEntityAdapter<Review>({
});

enum Types {
  Get = '[Reviews] Get',
  Create = '[Reviews] Create',
  ReadFromRevision = '[Reviews] Read From Revision',
  AddAll = '[Reviews] Add All',
  MarkAsComplete = '[Reviews] Mark as complete'
}

interface ReviewAction extends Action {
  readonly type: Types;
}

export class ReadReviewFromRevisionAction implements ReviewAction {
  type = Types.ReadFromRevision;

  constructor(public readonly payload: { revision: Revision }) {
  }
}

export class AddAllReviews implements ReviewAction {
  type = Types.AddAll;

  constructor(public readonly payload: { reviews: Review[] }) {
  }
}

export class CreateReview implements ReviewAction {
  type = Types.Create;

  constructor(public readonly payload: { reviewValidator: ReviewValidator }) {
  }
}

export class MarkAsComplete implements ReviewAction {
  type = Types.MarkAsComplete;

  constructor(public readonly payload: { review: Review, response: string }) {
  }
}

export class GetReviews implements ReviewAction {
  type = Types.Get;
}

export interface State extends EntityState<Review> {
}

const initial = adapter.getInitialState({});

type Union = ReadReviewFromRevisionAction | AddAllReviews;

export function reducer(state: State = initial, action: Union): State {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.upsertMany((<AddAllReviews>action).payload.reviews, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class GetReviewsEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly reviewsService: ReviewsService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Get];
  }

  protected handleResponse(reviews: Review[]): ReviewAction {
    return new AddAllReviews({ reviews });
  }

  protected handleRequest() {
    return this.reviewsService.get();
  }

}

@Injectable()
export class ReadReviewFromRevisionEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly reviewsService: ReviewsService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.ReadFromRevision];
  }

  protected handleResponse(reviews: Review[]): ReviewAction {
    return new AddAllReviews({ reviews });
  }

  protected handleRequest({ payload: { revision } }: ReadReviewFromRevisionAction) {
    return this.reviewsService.read(revision);
  }
}

@Injectable()
export class CreateReviewEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly reviewsService: ReviewsService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.Create];
  }

  protected handleResponse(review: Review) {
    return new AddAllReviews({ reviews: [review] });
  }

  protected handleRequest({ payload: { reviewValidator } }: CreateReview) {
    return this.reviewsService.create(reviewValidator);
  }
}

@Injectable()
export class MarkAsCompleteEffect extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly reviewsService: ReviewsService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.MarkAsComplete];
  }

  protected handleResponse(review: Review) {
    return new AddAllReviews({ reviews: [review] });
  }

  protected handleRequest({ payload: { review, response } }: MarkAsComplete) {
    return this.reviewsService.markAsComplete(review, response);
  }
}
