import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CachedCreator } from '../core/models/cached-creator';
import { LocationHierarchy } from '../core/models/location-hierarchy';
import { Organization } from '../core/organizations/Organization';
import { Region } from '../core/Region';
import { Station } from '../core/stations';
import { State } from '../reducers';
import { sameIds, toReplaySubject } from '../shared/utils';
import { selectedJob } from '../core/jobs/selectors';
import { Job } from '../core/jobs';

const selectOrganizations = createSelector(
  (state: State) => Object.values(state.organizations.entities)
);

const selectRegions = createSelector(
  (state: State) => Object.values(state.regions.entities)
);

const selectStations = createSelector(
  (state: State) => Object.values(state.stations.entities)
);

@Injectable({
  providedIn: 'root'
})
export class LocationHierarchyService extends CachedCreator<LocationHierarchy> {
  constructor(private readonly store: Store<State>) {
    super();
  }

  protected make$(): Observable<LocationHierarchy> {
    // For some reason pasing an empty props object to the selector is necessary
    const organizations$ = this.store.pipe(select(selectOrganizations, {}), distinctUntilChanged(sameIds));
    const regions$ = this.store.pipe(select(selectRegions, {}), distinctUntilChanged(sameIds));
    const stations$ = this.store.pipe(select(selectStations, {}), distinctUntilChanged(sameIds));

    const job$ = this.store.pipe(select(selectedJob));

    const helper = (
      organizations: Organization[],
      regions: Region[],
      stations: Station[],
      job: Job,
    ) => {
      if (!job) {
        return new LocationHierarchy(organizations, regions, stations);
      }

      const { station_ids } = job;

      const useStations = stations.filter(station => station_ids.includes(station.id));
      const useRegions = [];
      const useOrganizations = [];

      stations.forEach(station => {
        const { region_id, organization_id } = station;

        let findIn, findBy, putIn;

        if (region_id) {
          findIn = regions;
          findBy = (region: Region) => region.id === region_id;
          putIn = useRegions;
        } else if (organization_id) {
          findIn = organizations;
          findBy = (organization: Organization) => organization.id === organization_id;
          putIn = useOrganizations;
        }

        if (findIn && findBy && putIn) {
          const found = findIn.find(findBy);
          if (found && !putIn.find(it => it === found)) {
            putIn.push(found);
          }
        }
      });

      return new LocationHierarchy(useOrganizations, useRegions, useStations);
    };

    const observable = combineLatest([
      organizations$,
      regions$,
      stations$,
      job$
    ])
      .pipe(
        map(([organizations, regions, stations, job]) =>
            helper(organizations, regions, stations, job)));

    return toReplaySubject(observable);
  }
}
