import { Component, OnChanges, OnInit } from '@angular/core';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  templateUrl: './text-question.component.html',
  selector: 'app-text-question'
})
export class TextQuestionComponent extends ActiveSurveyDirective implements OnChanges, OnInit {

  public answerCopy: string;

  private answer_: string;

  public set answer(answer) {
    this.answer_ = answer;
    this.answerCopy = answer;
  }

  public get answer() { return this.answer_; }

  ngOnInit() {
    this.updateAnswer();
  }

  ngOnChanges() {
    this.updateAnswer();
  }

  canSubmit() {
    return this.answerCopy !== this.answer;
  }

  submit() {
    combineLatest([
      this.surveyWrapper$,
      this.currentQuestion$
    ]).pipe(first()).subscribe(([surveyWrapper, question ]) => {
      surveyWrapper.makeSelection({
        question,
        selection: this.answerCopy
      });
      this.dispatchSurveyStateUpdate();
    });
  }

  cancel() {
    this.answerCopy = this.answer;
  }

  canCancel() {
    return this.answerCopy !== this.answer;
  }

  protected surveyStateUpdated() {
    super.surveyStateUpdated();
    this.updateAnswer();
  }

  private updateAnswer() {
    this.currentQuestion$.pipe(first()).subscribe(question => {
      if (question) {
        this.answer = question.answer;
      }
    });
  }
}
