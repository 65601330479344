import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { State } from '../rx';
import { ActiveSurveyService } from './active-survey.service';
import { ReviewActiveSurveyService } from './review-active-survey.service';
import { RevisionActiveSurveyService } from './revision-active-survey.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveSurveyFactoryService {
  private readonly surveyStateUpdated = new BehaviorSubject(true);

  constructor(
    private readonly store: Store<State>,
    private readonly reviewActiveSurveyService: ReviewActiveSurveyService,
    private readonly revisionActiveSurveyService: RevisionActiveSurveyService
  ) {
    this.initListenForUpdates();
  }

  public get$(): Observable<ActiveSurveyService> {
    return this.store.pipe(map(state => {
      if (state.surveys.reviewMode) {
        return this.reviewActiveSurveyService;
      } else {
        return this.revisionActiveSurveyService;
      }
    }));
  }

  public onActiveSurveyService(callback: (arg0: ActiveSurveyService) => any) {
    this.get$().pipe(first()).subscribe(callback);
  }

  public next() {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.next();
    this.onActiveSurveyService(helper);
  }

  public previous() {
    const helper = (activeSurveyService: ActiveSurveyService) => activeSurveyService.previous();
    this.onActiveSurveyService(helper);
  }

  public onUpdate(callback: () => any) {
    this.surveyStateUpdated.subscribe(callback);
  }

  private initListenForUpdates() {
    const emit = () => this.surveyStateUpdated.next(true);

    this.reviewActiveSurveyService.surveyStateUpdated.subscribe(emit);
    this.revisionActiveSurveyService.surveyStateUpdated.subscribe(emit);
  }
}
