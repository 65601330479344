import { Injectable } from '@angular/core';
import { LocationCopyConstructor } from 'src/app/core/locations/location-copy';
import { LocationI } from '../../core/locations';
import { EditLocationTextEditting } from '../edit-location/edit-location-text';
import { ModelsComponent } from '../models/models.component';
import { EditLocationComponent } from './../edit-location/edit-location.component';
import { noCloseModalOptions } from 'src/app/shared/utils';

@Injectable()
export abstract class LocationsComponent<T extends LocationI> extends ModelsComponent<T> {
  public hasMapFiles = true;
  protected abstract readonly copyConstructor: LocationCopyConstructor<T>;

  public handleRequestModelEdit(model: T) {
    const modalRef = this.modalService
      .open(EditLocationComponent, noCloseModalOptions);
    modalRef.componentInstance.copy =
      new this.copyConstructor({ kind: this.kind, model });
    modalRef.componentInstance.editLocationText =
      new EditLocationTextEditting<T>(model);
  }
}
