import { ReviewCompletion, ReviewCompletionKind, ReviewCompletionKindType, UpsertOneReviewCompletion, RemoveOneReviewCompletion } from './review-completion';
import { ModelService } from '../services/model-service';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Review } from '../reviews';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReviewCompletionService extends ModelService<ReviewCompletion> {
  protected urlExtension: string = '/api/review_completions';
  protected kind: ReviewCompletionKindType = ReviewCompletionKind;

  public getFromReview(review: Review): Observable<ReviewCompletion[]> {
    return this.makeRequest(`${this.getBaseUrl()}?review_id=${review.id}`);
  }

  public makeUpsertAction(reviewCompletion: ReviewCompletion): Action {
    return new UpsertOneReviewCompletion({ reviewCompletion });
  }

  public makeRemoveOneAction(reviewCompletion: ReviewCompletion): Action {
    return new RemoveOneReviewCompletion({ reviewCompletion });
  }
}
