import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { REVIEW_ID_KEY, REVISION_ID_KEY } from 'src/app/app.module';
import { SetCurrentReviewIdAction, SetReviewModeAction, State } from '../rx';
import { SelectRevisionById } from '../rx/selected-revision';
import { NumericParamMapParser } from './numeric-param-map-parser';
import { LoadFromId } from 'src/app/core/revisions';

@Component({
  selector: 'app-opensource-survey-module',
  templateUrl: './opensource-survey-module.component.html',
  styleUrls: ['./opensource-survey-module.component.css']
})
export class OpensourceSurveyModuleComponent {

  private readonly parseRevisionId = new NumericParamMapParser(REVISION_ID_KEY);
  private readonly parseReviewId = new NumericParamMapParser(REVIEW_ID_KEY);

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<State>) {
    this.setRouteListeners();
  }

  private setRouteListeners() {
    this.activatedRoute.data.subscribe(this.handleRouteData.bind(this));
    this.activatedRoute.paramMap.subscribe(this.handleParamMap.bind(this));
  }

  private handleParamMap(paramMap: ParamMap) {
    this.handleRevisionId(paramMap);
    this.dispatchSetReviewId(this.parseReviewId.parse(paramMap));
  }

  private handleRouteData(data: any) {
    const { reviewMode } = data;
    this.dispatchSetReviewMode(reviewMode || false);
  }

  private handleRevisionId(paramMap: ParamMap) {
    const revisionId = this.parseRevisionId.parse(paramMap);
    if (revisionId) {
      this.dispatchSetRevisionId(revisionId);
      this.dispatchLoadRevision(revisionId);
    }
  }

  private dispatchSetReviewMode(reviewMode: boolean) {
    this.store.dispatch(new SetReviewModeAction({ reviewMode }));
  }

  private dispatchSetReviewId(reviewId: number) {
    this.store.dispatch(new SetCurrentReviewIdAction({ currentReviewId: reviewId }));
  }

  private dispatchSetRevisionId(revisionId: number) {
    this.store.dispatch(new SelectRevisionById({ id: revisionId }));
  }

  private dispatchLoadRevision(revisionId: number) {
    this.store.dispatch(new LoadFromId({ id: revisionId }));
  }
}
