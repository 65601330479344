import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PictureModel } from '../models';
import { ModelCopyConstructor } from '../models/model-copy';
import { ModelService } from './model-service';

@Injectable()
export abstract class PictureModelService<T extends PictureModel> extends ModelService<T> {
  protected abstract uploadExtensionName: string;
  protected abstract copyConstructor: ModelCopyConstructor<T>;

  public addPicture(file: any, model: T): Observable<T> {
    const url = this.makePictureUrl(model);
    return this.http.post<T>(
      url,
      file,
      { headers: this.apiTokenService.headers() }
    ).pipe(map(this.addKind.bind(this)));
  }

  public removePicture(model: T): Observable<T> {
    const copy = new this.copyConstructor({ model });
    (<any>copy).image_uri = null;
    return this.update(copy, model);
  }

  private makePictureUrl(model: T): string {
    const url = environment.apiUri +
      '/upload/image/' +
      this.uploadExtensionName +
      '/' +
      model.id;

    return url;
  }
}
