import { QuestionI, OptionI } from 'src/app/core/lib/models';

export type SelectionRequestHook =
  (question: QuestionI, selection: OptionI, annotation?: string) => void;

export class SelectionRequestHandler {
  constructor(private readonly makeSelection: SelectionRequestHook,
    protected readonly question: QuestionI
  ) {
  }

  public handleSelectionRequest(selection: OptionI, annotation?: string): void {
    this.makeSelection(this.question, selection, annotation);
  }

  public handleAnnotationRequest(annotation: string) {
    throw new Error('Requesting an annotation from a non annotation requiring question');
  }

  public getTransientState(): { errorMessage?: string, transientOption?: OptionI } {
    return {};
  }
}
