import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../../core/services/notifications.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ApiTokenService } from '../../core/services/api-token.service';
import * as camelize from 'camelize';
import { LocationI } from 'src/app/core/locations';
import { State } from '../rx';
import { Store } from '@ngrx/store';
import { AddMapfileAction, RemoveMapFileAction } from 'src/app/core/locations/map-file';

@Component({
  templateUrl: './map-file-upload.component.html'
})
export class MapFileUploadComponent {

  public errorMessage: string;

  @Input() private readonly model: LocationI;

  private file: any;
  private fileType: string;

  private readonly fileTypeErrorMessage = 'Only .kml and .kmz files are accepted';

  constructor(
    private readonly notifications: NotificationsService,
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>
  ) {
  }

  public handleRemoveMapFileRequest() {
    this.dispatchRemoveMapFile();
    this.handleResponse();
  }

  public handleSubmitRequest() {
    this.dispatchAddMapFile();
    this.handleResponse();
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  public canRemoveUri(): boolean {
    return !!this.model.map_file_uri;
  }

  public canUpdate(): boolean {
    return !!this.file;
  }

  public handleFileChange(event) {
    this.trySetFile(this.extractFileFromEvent(event));
  }

  private handleResponse() {
    this.notifications.addNotification({ message: `Successfully updated map file for ${this.model.name}` });
    this.activeModal.dismiss();
  }

  private extractFileFromEvent(event) {
    return event.target.files[0];
  }

  private trySetFile(file) {
    const fileType = this.getFileType(file);

    if (!this.isValidFileType(fileType)) {
      this.temporarilyDisplayError(this.fileTypeErrorMessage);
      return;
    }

    this.file = file;
    this.fileType = fileType;
  }

  private getFileType(file): string {
    const split = file.name.split(".");
    const fileType = split[split.length - 1];
    return fileType;
  }

  private isValidFileType(fileType: string): boolean {
    const kmlFileType = 'kml';
    const kmzFileType = 'kmz';
    return fileType === kmlFileType || fileType === kmzFileType;
  }

  private temporarilyDisplayError(message: string) {
    this.errorMessage = message;
    this.removeErrorMessageIn(3000);
  }

  private removeErrorMessageIn(milliseconds: number) {
    setTimeout(() => {
      this.errorMessage = undefined;
    }, milliseconds);
  }

  private dispatchRemoveMapFile() {
    this.store.dispatch(new RemoveMapFileAction({ model: this.model }));
  }

  private dispatchAddMapFile() {
    this.store.dispatch(new AddMapfileAction({ file: this.file, fileType: this.fileType, model: this.model }));
  }

}
