import { Lib, PositionData, QuestionI, SectionI, SurveyI, Update } from '../lib/models';
import { Revision } from '../revisions';
import { User } from '../User';
import { LastModificationToSection } from './last-modification-to-section';

export type SetSurveyCache = (revisionId: number, survey: SurveyI) => any;

export abstract class SurveyWrapper {
  private readonly updates: Update[] = [];

  constructor(
    protected readonly lib: Lib,
    protected survey: SurveyI,
    private readonly revisionId: number,
    private readonly setSurveyCache: SetSurveyCache) {
  }

  public abstract addAdditionalInformation(args: any): void;
  public abstract removeAdditionalInformation(args: any): void;
  public abstract makeSelection(data: any): void;
  public abstract belongsToRevision(revision: Revision): boolean;

  public getSurvey() {
    return this.survey;
  }

  public markAsComplete(question: QuestionI) {
    this.doSurveyTransition(this.lib.markAsComplete({ survey: this.survey, question }));
  }

  public getLastModificationToSection(section: SectionI, user: User): number | null {
    return (new LastModificationToSection(this.lib.updateBelongsToSection, this.updates)).get(section, user);
  }

  public drainUpdates(): Update[] {
    const updates = this.getUpdatesCopy();
    this.emptyUpdates();
    return updates;
  }

  public getPositionData(): PositionData {
    return this.lib.extractPositionData(this.survey);
  }

  protected doSurveyTransition(survey: SurveyI) {
    const newUpdates = this.extractUpdates(this.survey, survey);
    this.addUpdates(newUpdates);
    this.survey = survey;
    this.setSurveyCache(this.revisionId, this.survey);
  }

  private extractUpdates(oldSurvey: SurveyI, newSurvey: SurveyI): Update[] {
    const { updates } = this.lib.makeUpdates({ oldSurvey, survey: newSurvey });
    return updates;
  }

  private addUpdates(updates: Update[]) {
    updates.forEach(update => this.updates.push(update));
  }

  // get updates and empty updates could be done
  // more succinctly, but the implementations are
  // as so for clarity

  private getUpdatesCopy(): Update[] {
    return this.updates.map(update => update);
  }

  private emptyUpdates() {
    for (let i = 0; i < this.updates.length; ++i) {
      this.updates.pop();
    }
  }
}
