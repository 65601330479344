import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { get } from 'lodash/fp';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import scrollIntoView from 'scroll-into-view-if-needed';
import { QuestionI } from 'src/app/core/lib/models';

@Directive({
  selector: '[samScrollIntoView]'
})
export class ScrollIntoViewDirective implements OnInit, OnChanges {
  @Input() private readonly currentQuestion$: Observable<QuestionI>;
  @Input() private readonly question: QuestionI;
  private previousQuestion: QuestionI;

  private currentSubscription: Subscription;

  constructor(private readonly element: ElementRef) {
  }

  ngOnInit() {
    this.updateCurrentSubscription();
  }

  ngOnChanges() {
    this.updateCurrentSubscription();
  }

  private updateCurrentSubscription() {
    if (!this.verifyInputs()) {
      // Don't throw an error. Hope that eventually
      // the correct inputs are passed in
      return;
    }

    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
    this.currentSubscription = this.listenForPositionChange();
  }

  private verifyInputs(): boolean {
    return !!this.currentQuestion$ && !!this.question;
  }

  private listenForPositionChange(): Subscription {
    return this.currentQuestion$
      .pipe(
        filter(nextQuestion => {
          // If the titles are the same, it is unlikely that the
          // next question is the same as the previous question
          // and should not be scrolled to.
          // The previousQuestion must be tracked as an instance variable
          // to be tracked across subscriptions.
          const likelySameQuestion = get('title', this.previousQuestion) === nextQuestion.title;
          this.previousQuestion = nextQuestion;
          return !likelySameQuestion;
        }))
      .subscribe((currentQuestion: QuestionI) => {
        this.tryScrollIntoView(currentQuestion);
      });
  }

  private tryScrollIntoView(currentQuestion: QuestionI) {
    if (this.shouldScroll(currentQuestion)) {
      this.doScroll();
    }
  }

  private shouldScroll(currentQuestion: QuestionI): boolean {
    return this.question === currentQuestion;
  }

  private doScroll() {
    scrollIntoView(
      this.element.nativeElement, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'center'
      });
  }
}
