import { Revision } from '../../revisions';
import { flow } from 'lodash';

export class RevisionChainCreator {
  private static EMPTY_CHAIN: Revision[] = [];

  constructor(private readonly revisions: Revision[]) {
  }

  create(lastRevisionInChain: Revision): Revision[] {
    return this.internalCreate(lastRevisionInChain);
  }

  private internalCreate(lastRevisionInChain: Revision | undefined): Revision[] {
    return lastRevisionInChain ?

      flow([
        this.getPriorRevision.bind(this),
        this.internalCreate.bind(this),
        this.addToChain(lastRevisionInChain)
      ])(lastRevisionInChain) :

      RevisionChainCreator.EMPTY_CHAIN;
  }

  private getPriorRevision(revision: Revision): Revision | undefined {
    const { revision_id } = revision;

    return revision_id ?
      this.revisions.find(maybeRevision => maybeRevision.id === revision_id) :
      undefined;
  }

  private addToChain(nextRevision: Revision): (chain: Revision[]) => Revision[] {
    return (chain: Revision[]) => {
      return chain.concat([nextRevision]);
    };
  }
}
