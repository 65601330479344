import { Job } from 'src/app/core/jobs';
import { Station } from 'src/app/core/stations';

// HACK
export class LoadRevisionsCache {
  private readonly cache = {};

  public doRequest<T>(job: Job, stations: Station[], requestFunc: (stations: Station[]) => T): T {
    this.ensureJob(job);
    stations = this.getStationsNeededForRequest(job, stations);
    this.setRequested(job, stations);
    return requestFunc(stations);
  }

  private setRequested(job: Job, stations: Station[]) {
    stations.forEach(station => this.cache[job.id][station.id] = true);
  }

  private getStationsNeededForRequest(job: Job, stations: Station[]): Station[] {
    return stations.filter(station => !this.hasBeenRequested(job, station));
  }

  private ensureJob(job: Job) {
    this.cache[job.id] = this.cache[job.id] || {};
  }

  private hasBeenRequested(job: Job, station: Station): boolean {
    return !!this.cache[job.id][station.id];
  }
}
