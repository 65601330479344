export class CompletionResult {
  private readonly completion: number;

  constructor(numerator: number, denominator: number) {
    this.completion = denominator === 0 ? 0 : (numerator / denominator);
  }

  asPercentString(): string {
    return String((this.completion * 100).toFixed(2)) + '%';
  }

  asNumber(): number {
    return this.completion;
  }
}
