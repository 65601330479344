import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Lib, SectionI } from 'src/app/core/lib/models';
import { LibService } from 'src/app/core/lib/service';
import { EditRevisionComponentParameters } from 'src/app/core/revisions/edit-revision-component-parameters';
import { User } from 'src/app/core/User';
import { State } from '../rx';

@Component({
  selector: 'app-edit-revision',
  templateUrl: './edit-revision.component.html',
  styleUrls: ['./edit-revision.component.css']
})
export class EditRevisionComponent implements OnInit {
  @Input() public readonly params: EditRevisionComponentParameters;
  private errorMessage: string | undefined;

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>,
    private readonly libService: LibService) {
  }

  public ngOnInit() {
    this.setInitialAssignments();
  }

  public availableSections(): SectionI[] {
    return this.params.assigner.availableSections();
  }

  public isSectionSelected(section: SectionI): boolean {
    return this.params.assigner.isSectionSelected(section);
  }

  public getAvailableUsers(): User[] {
    return this.params.getAvailableUsers();
  }

  public userForSection(section: SectionI): User {
    return this.params.assigner.userFor(section);
  }

  public handleUserSectionChange(event, section: SectionI) {
    this.updateUserForSection(this.extractUserIdFromEvent(event), section);
  }

  private updateUserForSection(userId: number | undefined, section: SectionI) {
    const user = this.params.assigner.getUserForId(userId);
    if (user) {
      this.params.assigner.add(user, section);
    } else {
      this.params.assigner.remove(section);
    }
  }

  private extractUserIdFromEvent(event): number | undefined {
    const { value } = event.target;
    const maybeNumber = Number(value);
    // Both 0 and NaN will be falsey, so we'd rather deal with an actual number or undefined
    return maybeNumber || undefined;
  }

  public handleSubmitRequest() {
    if (!this.params.isValid()) {
      this.temporarilyDisplayError(this.params.currentErrorMessage());
      return;
    }

    this.dispatchSubmit();
  }

  public getErrorMessage(): string | undefined {
    return this.errorMessage;
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  private setInitialAssignments() {
    const lib$ = this.libService.get();
    const users$ = this.store.pipe(map(state => Object.values(state.users.entities)));
    const callThroughToHelper = ([lib, users]: [Lib, User[]]) => this.setInitialAssignmentsHelper(lib, users);
    combineLatest([lib$, users$]).pipe(first()).subscribe(callThroughToHelper);
  }

  private setInitialAssignmentsHelper(lib: Lib, users: User[]) {
    this.params.setInitialAssignments(lib.makeSurvey(), users);
  }

  private temporarilyDisplayError(message: string): void {
    this.errorMessage = message;
    this.removeErrorIn(3000);
  }

  private removeErrorIn(milliseconds: number) {
    setTimeout(() => {
      this.errorMessage = undefined;
    }, milliseconds);
  }

  private dispatchSubmit() {
    this.store.dispatch(this.params.getActionToDispatch());
    this.activeModal.close();
  }
}
