export abstract class EditLocationText {
  public abstract getTitle(): string;
  public abstract getSuccessNotification(...args): string;
}

export class EditLocationTextEditting<T extends { name: string }> extends EditLocationText {
  constructor(private readonly model: T) {
    super();
  }

  public getTitle(): string {
    return `Edit - ${this.model.name}`;
  }

  public getSuccessNotification(): string {
    return `Successfully updated ${this.model.name}`;
  }
}

abstract class EditLocationTextCreating extends EditLocationText {
  protected abstract modelString: string;

  public getTitle(): string {
    return `New - ${this.modelString}`;
  }

  public getSuccessNotification(name: string): string {
    return `Successfully created ${this.modelString}: ${name}`;
  }
}

export class EditOrganizationTextCreating extends EditLocationTextCreating {
  protected modelString = 'Organization';
}

export class EditRegionTextCreating extends EditLocationTextCreating {
  protected modelString = 'Region';
}

export class EditStationTextCreating extends EditLocationTextCreating {
  protected modelString = 'Station';
}
