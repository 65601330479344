import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Lib } from '../core/lib/models';
import { LibService } from '../core/lib/service';
import { ReportArgsCreator } from '../core/models/reporting/report-args-creator';
import { ReportLocationsCreator } from '../core/models/reporting/report-locations-creator';
import { ReportPeopleCreator } from '../core/models/reporting/report-people-creator';
import { ReportRevisionsCreator } from '../core/models/reporting/report-revisions-creator';
import { ReportLocationsCreatorService } from './report-locations-creator.service';
import { ReportPeopleCreatorService } from './report-people-creator.service';
import { ReportRevisionsCreatorService } from './report-revisions-creator.service';
import { CachedCreator } from '../core/models/cached-creator';

@Injectable({
  providedIn: 'root'
})
export class ReportArgsCreatorService extends CachedCreator<ReportArgsCreator> {

  constructor(
    private readonly libService: LibService,
    private readonly reportPeopleCreatorService: ReportPeopleCreatorService,
    private readonly reportAssessmentsCreatorService: ReportRevisionsCreatorService,
    private readonly reportLocationsCreatorService: ReportLocationsCreatorService,
  ) {
    super();
  }

  make$(): Observable<ReportArgsCreator> {
    const helper = (
      lib: Lib,
      reportPeopleCreator: ReportPeopleCreator,
      reportAssessmentsCreator: ReportRevisionsCreator,
      reportLocationsCreator: ReportLocationsCreator
    ) =>
      new ReportArgsCreator(lib.genReport, reportPeopleCreator, reportAssessmentsCreator, reportLocationsCreator);

    const lib$ = this.libService
      .get()
      .pipe(distinctUntilChanged());
    const reportPeopleCreator$ = this.reportPeopleCreatorService.get$();
    const reportAssessmentsCreator$ = this.reportAssessmentsCreatorService.get$();
    const reportLocationsCreator$ = this.reportLocationsCreatorService.get$();

    return combineLatest([
      lib$,
      reportPeopleCreator$,
      reportAssessmentsCreator$,
      reportLocationsCreator$
    ]).pipe(map(([
      lib,
      reportPeopleCreator,
      reportAssessmentsCreator,
      reportLocationsCreator
    ]) => helper(lib, reportPeopleCreator, reportAssessmentsCreator, reportLocationsCreator)));
  }
}
