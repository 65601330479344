import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import { selectedJob } from 'src/app/core/jobs/selectors';
import { LoadFromJobStations } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { LoadRevisionsCache } from './load-revisions-cache';
import { stationsForSelectedJob } from 'src/app/opensource-survey/rx/selectors';

// HACK
export class RevisionsLoader {
  private readonly loadRevisionsCache = new LoadRevisionsCache();

  private readonly stations$: Observable<Station[]>
    = this.store.pipe(select(stationsForSelectedJob));

  constructor(private readonly store: Store<any>, private readonly unsubscribe: Observable<any>) {
  }

  public startSubscription() {
    this.subscribeToStations();
  }

  private subscribeToStations() {
    const helper = (job: Job | undefined, stations: Station[]) => {
      if (job && stations.length > 0) {
        this.dispatchLoadRevisions(job, stations);
      }
    };

    combineLatest(
      this.store.pipe(select(selectedJob)),
      this.stations$
    ).pipe(takeUntil(this.unsubscribe))
      .subscribe(([job, stations]) => helper(job, stations));
  }

  private dispatchLoadRevisions(job: Job, stations: Station[]) {
    this.loadRevisionsCache.doRequest(job, stations, (stationsForRequest: Station[]) => {
      if (stationsForRequest.length > 0) {
        this.store.dispatch(new LoadFromJobStations({ job, stations: stationsForRequest }));
      }
    });
  }
}
