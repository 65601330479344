import { Injectable } from '@angular/core';
import { partial } from 'lodash';
import * as _ from 'lodash/fp';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Update } from '../lib/models';
import { Revision } from '../revisions';
import { ApiV2Service } from '../services/api-v2.service';
import { UserService } from '../services/user.service';
import { User } from '../User';
import { CacheWrapper } from './cache-wrapper';
import { SurveyUpdate } from './model';
import { NonLocalStorageSurveyUpdateCache } from './survey-update-cache';
import { UpdatesQueueImpl } from './updates-queue-impl';

@Injectable({
  providedIn: 'root'
})
export class SurveyUpdateService {
  private readonly apiExtension = 'survey_updates';
  private readonly updatesQueue = new UpdatesQueueImpl(this.api, this.apiExtension);
  private readonly surveyUpdatesShim = new CacheWrapper(
    this.api,
    this.apiExtension,
    new NonLocalStorageSurveyUpdateCache());

  constructor(private readonly api: ApiV2Service,
    private readonly userService: UserService) {
  }

  public read(revisionId: number): Observable<SurveyUpdate[]> {
    return this.surveyUpdatesShim.get(revisionId);
  }

  // We greedily assume that updates succeed
  public queueUpdates(revision: Revision, updates: Update[]) {
    if (updates.length > 0) {
      this.updatesQueue.add(revision, updates);

      this.userService.getLoggedIn().pipe(first()).subscribe((user: User) => {
        const addData = partial(this.surveyUpdatesShim.addData.bind(this.surveyUpdatesShim), user.id, revision.id, _);
        _.map(addData, updates);
      });
    }
  }
}
