import { Action } from '@ngrx/store';
import { User } from 'src/app/core/User';
import { UserCopy } from 'src/app/core/users/user-copy';

export abstract class EditUserComponentParams {
  protected readonly parentUser: User;
  protected readonly user?: User;
  public abstract getSuccessNotificationMessage(copy: UserCopy): string;
  public abstract getTitle(): string;
  public abstract getCopy(): UserCopy;
  public abstract getAction(): Action;
}
