import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminModuleComponent } from './admin-module/admin-module.component';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { UsersComponent } from './users/users.component';
import {
  EditLocationPermissionsComponent
} from './edit-location-permissions/edit-location-permissions.component';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { RegionsComponent } from './regions/regions.component';
import { StationsComponent } from './stations/stations.component';
import { MapFileUploadComponent } from './map-file-upload/map-file-upload.component';
import { JobsComponent } from './jobs/jobs.component';
import { AdminJobSelectionComponent } from './admin-job-selection/admin-job.selection.component';
import { RevisionsComponent } from './revisions/revisions.component';
import { EditJobComponent } from './edit-job/edit-job.component';
import { StationsForAJobComponent } from './stations-for-a-job/stations-for-a-job.component';
import { RequestReviewComponent } from './request-review/request-review.component';
import { EditRevisionComponent } from './edit-revision/edit-revision.component';
import { NewModelButtonComponent } from './new-model-button/new-model-button.component';
import { NewUserButtonComponent } from './new-user-button/new-user-button.component';
import { NewOrganizationButtonComponent } from './new-organization-button/new-organization-button.component';
import { NewRegionButtonComponent } from './new-region-button/new-region-button.component';
import { NewStationButtonComponent } from './new-station-button/new-station-button.component';
import { NewJobButtonComponent } from './new-job-button/new-job-button.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './rx';
import { RevisionActionsComponent } from './revision-actions/revision-actions.component';
import { UsersForRevisionComponent } from './users-for-revision/users-for-revision.component';
import { ReviewsForRevisionComponent } from './reviews-for-revision/reviews-for-revision.component';
import { ParameterizedRevisionDirective } from './parameterized-revision.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule.forRoot(),
    SharedModule,
    AngularFontAwesomeModule,
    StoreModule.forFeature('admin', reducers)
  ],
  declarations: [
    AdminModuleComponent,
    EditLocationComponent,
    EditUserComponent,
    PictureUploadComponent,
    MapFileUploadComponent,
    UsersComponent,
    EditLocationPermissionsComponent,
    OrganizationsComponent,
    RegionsComponent,
    StationsComponent,
    JobsComponent,
    AdminJobSelectionComponent,
    RevisionsComponent,
    EditJobComponent,
    StationsForAJobComponent,
    RequestReviewComponent,
    EditRevisionComponent,
    NewModelButtonComponent,
    NewUserButtonComponent,
    NewOrganizationButtonComponent,
    NewRegionButtonComponent,
    NewStationButtonComponent,
    NewJobButtonComponent,
    RevisionActionsComponent,
    UsersForRevisionComponent,
    ReviewsForRevisionComponent,
    ParameterizedRevisionDirective
  ],
  entryComponents: [
    EditLocationComponent,
    EditUserComponent,
    PictureUploadComponent,
    MapFileUploadComponent,
    EditLocationPermissionsComponent,
    RevisionsComponent,
    RequestReviewComponent,
    EditRevisionComponent,
    EditJobComponent,
  ],
  exports: [
    AdminModuleComponent,
  ],
  providers: [
    NgbActiveModal,
  ]
})
export class AdminModule { } 
