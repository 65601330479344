export abstract class RankDataResult {
  public abstract asNumber(): number;
  public abstract asLevel(): string;
}

export abstract class RankData {
  public abstract getName(): string | undefined;
  public abstract getNick(): string | undefined;
  public abstract getColor(): string;
  public abstract getResult(): RankDataResult;
}
