import { Injectable } from '@angular/core';
import { ApiTokenService } from './api-token.service';
import { State } from 'src/app/reducers';
import { LibService } from '../lib/service';
import { Store } from '@ngrx/store';
import { LoadLoggedIn } from '../users';
import { first } from 'rxjs/operators';
import * as Chart from 'chart.js';
import { TimeoutService } from './timeout.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationSetupService {
  constructor(private readonly apiTokenService: ApiTokenService,
    private readonly store: Store<State>,
              private readonly libService: LibService,
              private readonly timeoutService: TimeoutService
  ) {
  }

  public setupApplication() {
    this.checkForApiTokenInUrl();
    this.dispatchLoadLoggedInUser();
    this.addCustomChartTypes();
    this.timeoutService.run();
  }

  private checkForApiTokenInUrl() {
    const searchString = window.location.search;

    if (searchString.includes('api_token')) {
      const apiToken = searchString.split('api_token')[1].split('=')[1];
      this.apiTokenService.apiToken = apiToken;
      history.replaceState({}, '', '/');
    }

  }

  private dispatchLoadLoggedInUser() {
    this.store.dispatch(new LoadLoggedIn());
  }

  private addCustomChartTypes() {
    this.libService.get().pipe(first()).subscribe(lib => {
      lib.addToChart(Chart);
    });
  }
}
