import { QuestionMetadata } from '../lib/models';
import { Action } from '@ngrx/store';
import { Review } from '../reviews';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { Injectable } from '@angular/core';
import { LoadEffect } from '../effects/load';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Model } from '../models';
import { ReviewCompletionService } from './service';
import { ModelCopy } from '../models/model-copy';

export type ReviewCompletionKindType = 'ReviewCompletion';
export const ReviewCompletionKind: ReviewCompletionKindType = 'ReviewCompletion';

export interface ReviewCompletion extends Model {
  id: number;
  kind: ReviewCompletionKindType;
  review_id: number;
  metadata_key: QuestionMetadata;
}

export class ReviewCompletionCopy extends ModelCopy<ReviewCompletion> {

  metadata_key: QuestionMetadata;
  review_id: number;

  protected validators: (() => any)[] = [];

  protected copyOverFields(model: ReviewCompletion): void {
    this.metadata_key = model.metadata_key;
    this.review_id = model.review_id;
  }

  protected createJSON() {
    return {
      metadata_key: this.metadata_key,
      review_id: this.review_id
    };
  }
}

export enum Types {
  LoadAll = '[ReviewCompletion] LoadAll',
  AddAll = '[ReviewCompletion] AddAll',
  UpsertOne = '[ReviewCompletion] UpsertOne',
  RemoveOne = '[ReviewCompletion] RemoveOne'
}

interface ReviewCompletionAction extends Action {
  type: Types;
}

export class UpsertOneReviewCompletion implements ReviewCompletionAction {
  public readonly type = Types.UpsertOne;

  constructor(public readonly payload: { reviewCompletion: ReviewCompletion }) {
  }
}

export class RemoveOneReviewCompletion implements ReviewCompletionAction {
  public readonly type = Types.RemoveOne;

  constructor(public readonly payload: { reviewCompletion: ReviewCompletion }) {
  }
}

export class AddAllReviewCompletion implements ReviewCompletionAction {
  public readonly type = Types.AddAll;

  constructor(public readonly payload: { reviewCompletions: ReviewCompletion[] }) {
  }
}

export class LoadAllReviewCompletion implements ReviewCompletionAction {
  public readonly type = Types.LoadAll;

  constructor(public readonly payload: { review: Review }) {
  }
}

const adapter: EntityAdapter<ReviewCompletion> = createEntityAdapter<ReviewCompletion>({});

export interface State extends EntityState<ReviewCompletion> { }

type Union = AddAllReviewCompletion | UpsertOneReviewCompletion | RemoveOneReviewCompletion;

const initial: State = adapter.getInitialState({});

export function reducer(state = initial, action: Union) {
  switch (action.type) {
    case (Types.AddAll): {
      return adapter.addMany(action.payload.reviewCompletions, state);
    }
    case (Types.UpsertOne): {
      return adapter.upsertOne(action.payload.reviewCompletion, state);
    }
    case (Types.RemoveOne): {
      return adapter.removeOne(action.payload.reviewCompletion.id, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class LoadAllReviewCompletionEffect extends LoadEffect {
  @Effect()
  public readonly observing$ = this.parentObserver$;

  constructor(actions$: Actions, private readonly reviewCompletionService: ReviewCompletionService) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAll];
  }

  protected handleResponse(reviewCompletions: ReviewCompletion[]) {
    return new AddAllReviewCompletion({ reviewCompletions: reviewCompletions });
  }

  protected handleRequest({ payload: { review } }: LoadAllReviewCompletion) {
    return this.reviewCompletionService.getFromReview(review);
  }
}
