import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { State } from '../../../opensource-survey/rx/index';
import { Store, select } from '@ngrx/store';
import { Toggle } from '../../../opensource-survey/rx/view-selections';


@Component({
  selector: 'app-toggle-view-selections',
  templateUrl: './toggle-view-selections.component.html',
  styleUrls: ['./toggle-view-selections.component.css']
})
export class ToggleViewSelectionsComponent implements OnInit {

  viewSelections$: Observable<boolean> = this.store.pipe(
    select((store) => store.surveys.viewSelections.viewSelections)
  );

  constructor(private readonly store: Store<State>) { }

  ngOnInit() {
  }

  handleToggleViewSelections() {
    this.store.dispatch(new Toggle());
  }
}
