import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FullReportArgs, PostReport, ReportResponse } from '../core/models/reporting';
import { ConstrainedReportGeneratorFactory } from '../core/models/reporting/constrained-report-generator';
import { NewTabOpener, NewTabOpenerImpl } from '../core/models/reporting/new-tab';
import { ReportArgsCreator } from '../core/models/reporting/report-args-creator';
import { ReportGeneratorImpl } from '../core/models/reporting/report-generator';
import { ApiTokenService } from '../core/services/api-token.service';
import { NotificationsService, AddNotification } from '../core/services/notifications.service';
import { toReplaySubject } from '../shared/utils';
import { ReportArgsCreatorService } from './report-args-creator.service';
import { CachedCreator } from '../core/models/cached-creator';

type RT = (successCallback: (arg: string) => void, additionalArgs?: any) => ConstrainedReportGeneratorFactory;

@Injectable({
  providedIn: 'root'
})
export class ConstrainedReportGeneratorFactoryService extends CachedCreator<RT> {

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly http: HttpClient,
    private readonly apiTokenService: ApiTokenService,
    private readonly reportArgsCreatorService: ReportArgsCreatorService
  ) {
    super();
  }

  protected make$(): Observable<RT> {
    const helper = (reportArgsCreator: ReportArgsCreator) => {
      return (successfullyOpened: (arg: string) => void, reportArgs: any = {}) => {
        const addNotification = this.makeAddNotification();
        const newTabOpener = this.makeNewTabOpener(successfullyOpened);
        const post = this.makeReportPost();
        const reportGenerator = new ReportGeneratorImpl(reportArgsCreator, addNotification, newTabOpener, post);
        return new ConstrainedReportGeneratorFactory(reportGenerator, reportArgs);
      };
    };

    const reportArgsCreator$ = this.reportArgsCreatorService.get$();

    const observable = combineLatest([
      reportArgsCreator$,
    ]).pipe(map(([
      reportArgsCreator,
    ]) => helper(reportArgsCreator)));

    return toReplaySubject(observable);
  }

  private makeAddNotification(): AddNotification {
    return this.notificationsService.addNotification.bind(this.notificationsService);
  }

  private makeNewTabOpener(successfullyOpened: (url: string) => void): NewTabOpener {
    const failedToOpen = (_: string) => {
      this.notificationsService.addNotification({
        message: 'Failed to open report. Are popups enabled?',
        type: 'warning'
      });
    };

    return new NewTabOpenerImpl(successfullyOpened, failedToOpen);
  }

  private makeReportPost(): PostReport {
    return (url: string, data: FullReportArgs) => {
      return this.http.post<ReportResponse>(url, { data }, { headers: this.apiTokenService.headers() }).toPromise();
    };
  }
}
