import { ModelServiceDispatcherService } from './model-service-dispatcher.service';
import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { LocationI } from '../locations';

@Injectable({
  providedIn: 'root'
})
export class LocationServiceDispatcherService {
  constructor(private readonly modelServiceDispatcher: ModelServiceDispatcherService) {
  }

  public getService<T extends LocationI>(model: T): LocationService<T> {
    return <LocationService<T>>this.modelServiceDispatcher.getService(model);
  }
}
