export class UniqueCollection<T> {
  private collection: T[] = [];

  constructor(private readonly areSame: (a: T, b: T) => boolean) {
  }

  public add(item: T) {
    if (!this.contains(item)) {
      this.collection.push(item);
    }
  }

  public remove(item: T) {
    this.collection = this.collection.filter(otherItem => !this.areSame(item, otherItem));
  }

  public contains(item: T): boolean {
    return !!this.collection.find((otherItem: T) => this.areSame(item, otherItem));
  }

  public length(): number {
    return this.collection.length;
  }

  public reset() {
    this.collection = [];
  }
}
