import { concat, last } from 'lodash/fp';
import { LocalStorageMapParseStrings } from '../models/local-storage/local-storage-map';
import { DummyUpdate } from './dummy-update';
import { SurveyUpdate } from './model';
import { TimestampSorter } from '../models/timestamp-sorter';

export abstract class SurveyUpdateCache {
  protected abstract readonly cache: Map<number | string, SurveyUpdate[]>;
  protected abstract internalAdd(revisionId: number, surveyUpdate: SurveyUpdate, skipLocalStorage: boolean): void;

  public addSurveyUpdate(revisionId: number, surveyUpdate: SurveyUpdate, skipLocalStorage = false) {
    this.ensurePresent(revisionId);
    this.internalAdd(revisionId, surveyUpdate, skipLocalStorage);
  }

  public addData(userId: number, revisionId: number, data: any) {
    this.addSurveyUpdate(revisionId, this.makeDummyUpdate(userId, revisionId, data), true);
  }

  public get(revisionId: number): SurveyUpdate[] {
    this.ensurePresent(revisionId);
    return this.cache.get(revisionId);
  }

  public has(revisionId: number): boolean {
    return this.cache.has(revisionId);
  }

  public clear() {
    this.cache.clear();
  }

  public getLastTimestamp(revisionId: number): number | null {
    const latestSurveyUpdate = last((new TimestampSorter(this.get(revisionId))).getSorted());
    return latestSurveyUpdate ? latestSurveyUpdate.timestamp : null;
  }

  private ensurePresent(revisionId: number) {
    if (!this.cache.has(revisionId)) {
      this.cache.set(revisionId, []);
    }
  }

  private makeDummyUpdate(userId: number, revisionId: number, data: any): SurveyUpdate {
    return (new DummyUpdate(userId, revisionId, data, this.cache)).get();
  }
}

export class LocalStorageSurveyUpdateCache extends SurveyUpdateCache {
  protected readonly cache = new LocalStorageMapParseStrings<SurveyUpdate[]>('cached-survey-updates');

  protected internalAdd(revisionId: number, surveyUpdate: SurveyUpdate, skipLocalStorage: boolean) {
    this.cache.set(revisionId, concat(this.cache.get(revisionId), [surveyUpdate]), skipLocalStorage);
  }
}

export class NonLocalStorageSurveyUpdateCache extends SurveyUpdateCache {
  protected readonly cache = new Map<string | number, SurveyUpdate[]>();
  protected internalAdd(revisionId: number, surveyUpdate: SurveyUpdate, skipLocalStorage: boolean) {
    this.cache.set(revisionId, concat(this.cache.get(revisionId), [surveyUpdate]));
  }
}
