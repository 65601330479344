import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Organizations from 'src/app/core/organizations';
import { Organization } from "src/app/core/organizations/Organization";
import * as Regions from 'src/app/core/regions';
import { Region } from "src/app/core/Region";
import { NotificationsService } from 'src/app/core/services/notifications.service';
import * as Stations from 'src/app/core/stations';
import { Station } from 'src/app/core/stations';
import { User } from "src/app/core/User";
import { LocationPermissionsDiff, UpdateLocationPermissionsAction, UpdateOrganizationPermissionsAction, UpdateRegionPermissionsAction, UpdateStationPermissionsAction } from 'src/app/core/users/location-diff-actions';
import { State } from '../rx';
import { AllLocationPermissionsDifferences, LocationPermissionSelections } from './LocationPermissionSelections';

@Component({
  templateUrl: './edit-location-permissions.component.html'
})
export class EditLocationPermissionsComponent implements OnInit {

  @Input() private readonly user: User;

  public readonly availableOrganizations$: Observable<Organization[]>;
  public readonly availableRegions$: Observable<Region[]>;
  public readonly availableStations$: Observable<Station[]>;

  public readonly locationPermissionSelections: LocationPermissionSelections;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly store: Store<State>,
    private readonly notificationsService: NotificationsService
  ) {
    this.locationPermissionSelections = new LocationPermissionSelections(
      this.makeOrganizations$(),
      this.makeRegions$(),
      this.makeStations$()
    );

    this.availableOrganizations$ = this.locationPermissionSelections.availableOrganizations$;
    this.availableRegions$ = this.locationPermissionSelections.availableRegions$;
    this.availableStations$ = this.locationPermissionSelections.availableStations$;
  }

  ngOnInit() {
    this.dispatchLocationLoads();
    this.setSelectedLocationIds();
  }

  public handleDismiss() {
    this.activeModal.dismiss();
  }

  public handleSubmit() {
    this.locationPermissionSelections.getDifferences(this.user).subscribe((allDifferences) => {
      this.dispatchDifferences(allDifferences);
      this.handleDifferenceResults();
    });
  }

  private dispatchDifferences({ stations, regions, organizations }: AllLocationPermissionsDifferences) {
    this.dispatchStationDifferences(stations);
    this.dispatchRegionDifferences(regions);
    this.dispatchOrganizationDifferences(organizations);
  }

  private handleDifferenceResults() {
    this.notificationsService.addNotification({ message: `Successfully updated location permissions for ${this.user.name}` });
    this.activeModal.close();
  }

  private dispatchStationDifferences(diff: LocationPermissionsDiff<Station>) {
    this.dispatchLocationPermissionsAction<Station>(UpdateStationPermissionsAction, diff);
  }

  private dispatchRegionDifferences(diff: LocationPermissionsDiff<Region>) {
    this.dispatchLocationPermissionsAction<Region>(UpdateRegionPermissionsAction, diff);
  }

  private dispatchOrganizationDifferences(diff: LocationPermissionsDiff<Organization>) {
    this.dispatchLocationPermissionsAction<Organization>(UpdateOrganizationPermissionsAction, diff);
  }

  private dispatchLocationPermissionsAction<T>(
    actionConstructor: (new (...args) => UpdateLocationPermissionsAction<T>),
    diff: LocationPermissionsDiff<T>) {
    this.store.dispatch(new actionConstructor({ user: this.user, diff }));
  }

  private dispatchLocationLoads() {
    this.dispatchLoadOrganizations();
    this.dispatchLoadRegions();
    this.dispatchLoadStations();
  }

  private setSelectedLocationIds() {
    this.locationPermissionSelections.setSelectedOrganizationIds(this.user.organization_ids);
    this.locationPermissionSelections.setSelectedRegionIds(this.user.region_ids);
    this.locationPermissionSelections.setSelectedStationIds(this.user.station_ids);
  }

  private dispatchLoadOrganizations() {
    this.store.dispatch(new Organizations.LoadAll());
  }

  private dispatchLoadRegions() {
    this.store.dispatch(new Regions.LoadAll());
  }

  private dispatchLoadStations() {
    this.store.dispatch(new Stations.LoadAll());
  }

  private makeOrganizations$(): Observable<Organization[]> {
    return this.store.pipe(map(state => Object.values(state.organizations.entities)));
  }

  private makeRegions$(): Observable<Region[]> {
    return this.store.pipe(map(state => Object.values(state.regions.entities)));
  }

  private makeStations$(): Observable<Station[]> {
    return this.store.pipe(map(state => Object.values(state.stations.entities)));
  }
}
