import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import * as Stations from 'src/app/core/stations';
import { Station } from 'src/app/core/stations';
import { stationSrc } from '../../shared/utils';
import { RevisionsComponent } from '../revisions/revisions.component';
import { State } from '../rx';

@Component({
  selector: 'app-stations-for-a-job',
  templateUrl: './stations-for-a-job.component.html',
  styleUrls: ['./stations-for-a-job.component.css']
})
export class StationsForAJobComponent implements OnInit, OnChanges {
  public readonly src = stationSrc;
  private readonly jobSubscription$: BehaviorSubject<Job | undefined> =
    new BehaviorSubject<Job | undefined>(undefined);
  public readonly stations$: Observable<Station[]> = this.makeStations$();

  @Input() private readonly job: Job;

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store<State>
  ) {
  }

  ngOnChanges() {
    this.jobSubscription$.next(this.job);
  }

  private makeStations$(): Observable<Station[]> {
    return combineLatest(
      this.store,
      this.jobSubscription$
    ).pipe(map(([state, job]) => this.getCurrentJobs(state, job)));
  }

  handleRequestOpenRevisions(station) {
    const modalRef = this.modalService.open(RevisionsComponent);
    modalRef.componentInstance.station = station;
    modalRef.componentInstance.job = this.job;
  }

  ngOnInit() {
    this.dispatchLoadStations();
  }

  private getCurrentJobs(state: State, job: Job | undefined): Station[] {
    const stationIds = this.job ? this.job.station_ids : [];
    return stationIds.map(stationId => state.stations.entities[stationId]);
  }

  private dispatchLoadStations() {
    this.store.dispatch(new Stations.LoadAll());
  }
}

