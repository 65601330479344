import { Injectable } from '@angular/core';
import { concat, Observable, onErrorResumeNext } from 'rxjs';
import { first } from 'rxjs/operators';
import { Revision } from '.';
import { Job } from '../jobs';
import { Review } from '../reviews';
import { ApiV2Service } from '../services/api-v2.service';
import { Station } from '../stations';
import { PostRevisionParams } from './PostRevisionParams';
import { get, reduce } from 'lodash/fp';
import { HttpParams } from '@angular/common/http';
import { urlEncodeArray } from 'src/app/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class RevisionsService {

  private readonly urlExtension = 'revisions';
  private readonly noChangeExtension = 'no_change';
  private readonly nonActiveExtension = 'non_active';
  private readonly finalizeExtension = 'final';
  private readonly unFinalizeExtension = 'un_finalize';

  constructor(private readonly api: ApiV2Service) {
  }

  public create(params: PostRevisionParams): Observable<Revision> {
    // Until we have time to build out the flow from noChange to nonActive to posting
    // in order to create, we will do all three in order here.
    return onErrorResumeNext(
      this.noChange(params),
      this.nonActive(params),
      this.api.post(this.urlExtension, params)
    );
  }

  public update(params: PostRevisionParams): Observable<Revision> {
    return this.api.put(this.urlExtension, params);
  }

  public finalize(revision: Revision): Observable<Revision> {
    const params = {
      revision: {
        station_id: revision.station_id,
        job_id: revision.job_id
      }
    };

    // We use the same shortcut as in create to mark as no change and non active first
    return concat(
      this.noChange(params),
      this.nonActive(params),
      this.api.put(this.makeUrl(this.finalizeExtension), params)
    );
  }

  public unFinalize(revision: Revision): Observable<Revision> {
    const params = {
      revision: {
        station_id: revision.station_id,
        job_id: revision.job_id
      }
    };

    return this.api.put(this.makeUrl(this.unFinalizeExtension), params);
  }

  public getFromId(id: number) {
    return this.api.get(this.makeRevisionUrlExtension(id));
  }

  public getFromReview(review: Review) {
    return this.api.get(this.makeReviewUrlExtension(review.id));
  }

  public getFromJobStation(job: Job, station: Station) {
    return this.api.get(this.makeJobStationUrlExtension(job.id, station.id)).pipe(first());
  }

  public getFromJobStations(job: Job, stations: Station[]) {
    return this.api.get(this.makeJobStationsUrlExtension(job.id, stations.map(get('id'))));
  }

  public getChain(revision: Revision) {
    return this.api.get(this.makeJobStationUrlExtension(revision.job_id, revision.station_id));
  }

  // Will eventually be publically exposed
  private noChange(params: PostRevisionParams) {
    return this.api.put(this.makeUrl(this.noChangeExtension), params);
  }

  // Will eventually be publically exposed
  private nonActive(params: PostRevisionParams) {
    return this.api.put(this.makeUrl(this.nonActiveExtension), params);
  }

  private makeUrl(extension: string): string {
    return this.urlExtension + '/' + extension;
  }

  private makeRevisionUrlExtension(revisionId: number) {
    return `${this.urlExtension}?id=${revisionId}`;
  }

  private makeReviewUrlExtension(reviewId: number) {
    return `${this.urlExtension}?review_id=${reviewId}`;
  }

  private makeJobStationUrlExtension(jobId: number, stationId: number): string {
    return `${this.urlExtension}?job_id=${jobId}&station_id=${stationId}`;
  }

  private makeJobStationsUrlExtension(jobId: number, stationIds: number[]): string {
    const encodedStationIds = urlEncodeArray('station_ids', stationIds);
    return `${this.urlExtension}?job_id=${jobId}&${encodedStationIds}`;
  }

  private futureMakeJobStationUrlExtension(job: Job, station: Station): string {
    return `jobs/${job.id}/stations/${station.id}/revisions`;
  }
}
