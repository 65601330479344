import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonadicAction, MonadicActionType } from './monadic-action';

export class ActionProcessorFactory {
  constructor(
    protected readonly handleRequest: (action: Action) => Observable<any>,
    protected readonly handleResponse: (action: Action, extra?: any) => Action) {
  }

  get(action: Action): ActionProcessor {
    return action.type === MonadicActionType ?
      new MonadicActionProcessor(
        this.handleRequest, this.handleResponse) :
      new ActionProcessorImpl(
        this.handleRequest, this.handleResponse);
  }
}

export abstract class ActionProcessor {
  constructor(
    protected readonly handleRequest: (action: Action) => Observable<any>,
    protected readonly handleResponse: (action: Action, extra?: any) => any) {
  }

  abstract processAction(action: Action): Observable<any>;

}

export class ActionProcessorImpl extends ActionProcessor {
  processAction(action: Action): Observable<any> {
    return this.handleRequest(action)
      .pipe(map(this.handleResponse));
  }
}

export class MonadicActionProcessor extends ActionProcessor {
  processAction(monadicAction: MonadicAction<any>): Observable<any> {
    return this.handleRequest(monadicAction.action)
      .pipe(map(result => {
        monadicAction.sideEffect(result);
        return this.handleResponse(result);
      }));
  }
}
