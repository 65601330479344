import { Component } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.css']
})
export class WarningComponent {
  constructor(private readonly loginStateService: LoginStateService) {
  }

  public handleDecline() {
    this.loginStateService.decline();
  }
}
