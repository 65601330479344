import { QuestionI, SectionI } from '../lib/models';
import { CompletionResult } from '../models/completion-result';

export class Counter {
  static Count(sections: SectionI[]): CompletionResult {
    const counter = new Counter(sections);
    return new CompletionResult(
      counter.totalCompletedQuestions(),
      counter.totalQuestions()
    );
  }

  private constructor(private readonly sections: SectionI[]) {
  }

  private totalQuestions(): number {
    return this.countQuestions(() => true);
  }

  private totalCompletedQuestions(): number {
    return this.countQuestions((question) => question.completed);
  }

  private countQuestions(incIf: (question: QuestionI) => boolean): number {
    let count = 0;

    this.onAllQuestions(question => { if (incIf(question)) { (++count); } });

    return count;
  }

  private onAllQuestions(callback: (question: QuestionI) => any) {
    this.onQuestions(this.sections, callback);
  }

  private onQuestions(sections: SectionI[], callback: (question: QuestionI) => any) {
    sections.forEach(section => {
      this.getQuestions(section).forEach(question => {
        callback(question);
      });
    });
  }

  private getQuestions(section: SectionI): QuestionI[] {
    const { question } = section;

    let questions: QuestionI[];

    if (question) {
      questions = [question];
    } else {
      questions = section.questions;
    }

    return questions;
  }
}
