import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionI, SurveyI } from 'src/app/core/lib/models';
import { LibService } from 'src/app/core/lib/service';
import { ReadReviewFromRevisionAction, Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { User } from 'src/app/core/User';
import { State, selectReviewsForRevision } from '../rx';
import { ReviewTypeTag } from 'src/app/core/models/reporting/review-type-tag';
import { concat } from 'lodash';

@Component({
  selector: 'app-reviews-for-revision',
  templateUrl: './reviews-for-revision.component.html',
  styleUrls: ['./reviews-for-revision.component.css']
})
export class ReviewsForRevisionComponent implements OnInit, OnChanges {
  public reviews$: Observable<Review[]>;

  @Input() private readonly revision: Revision;
  private readonly survey$: Observable<SurveyI> = this.makeSurvey$();

  constructor(private readonly store: Store<State>,
    private readonly libService: LibService) {
  }

  ngOnInit() {
    this.dispatchLoadReviews();
    this.reviews$ = this.makeReviews$();
  }

  ngOnChanges() {
    this.dispatchLoadReviews();
    this.reviews$ = this.makeReviews$();
  }

  public formatUnixTime(timestamp: number): string {
    return (new Date(timestamp)).toDateString();
  }

  public isReviewComplete(review: Review): boolean {
    return !!review.complete_at;
  }

  public shouldDisplayCompletion(review: Review): boolean {
    return !!!review.response;
  }

  public userForReview$(review: Review): Observable<User> {
    return this.store.pipe(map(state => state.users.entities[review.user_id]));
  }

  public sectionsForReview$(review: Review): Observable<SectionI[]> {
    return this.survey$.pipe(map(survey => review.section_keys.map(key => survey.sections[key])));
  }

  private makeSurvey$(): Observable<SurveyI> {
    return this.libService.get().pipe(map(lib => lib.makeSurvey()));
  }

  private dispatchLoadReviews() {
    this.store.dispatch(new ReadReviewFromRevisionAction({ revision: this.revision }));
  }

  private makeReviews$(): Observable<Review[]> {
    return this.store.pipe(select(selectReviewsForRevision(), { revision: this.revision }),
                           map(reviews => {
                             // Put the classification review first
                             // Put the optional acceptance review second
                             // Put all other optional reviews after

                             const classificationReviews = reviews.filter(
                               r => r.review_type.name === ReviewTypeTag.Classification);
                             const approvalReviews = reviews.filter(
                               r => r.review_type.name === ReviewTypeTag.Approval);
                             const otherReviews = reviews.filter(
                               r => !classificationReviews.includes(r) &&
                                 !approvalReviews.includes(r));

                             return concat(classificationReviews, approvalReviews, otherReviews);
                           }));
  }
}
