import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapComponent } from './map/map.component';
import { LocationsComponent
       } from './locations/locations.component';
import { SortStationsPipe
       } from './sort-stations.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ChartsModule,
    AngularFontAwesomeModule,
    LeafletModule,
  ],
  declarations: [
    DashboardComponent,
    MapComponent,
    LocationsComponent,
    SortStationsPipe
  ],
})
export class DashboardModule { }
