import { memoize } from 'lodash';
import * as _ from 'lodash/fp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemoizedFunctionGeneric, toReplaySubject } from 'src/app/shared/utils';
import { SurveyUpdate } from './model';
import { SurveyUpdateCache } from './survey-update-cache';
import { SurveyUpdatesShim } from './survey-updates-shim';
import { ApiV2Service } from '../services/api-v2.service';

export class CacheWrapper extends SurveyUpdatesShim {
  constructor(api: ApiV2Service,
    apiExtension: string,
    private readonly cache: SurveyUpdateCache) {
    super(api, apiExtension);
  }

  // Memoize to only call once for every revisionId
  private ensure: MemoizedFunctionGeneric<(revisionId: number) => Observable<any>> = memoize((revisionId: number) => {
    try {
      return this.internalEnsure(revisionId);
    } catch (e) {
      this.handleCacheError(e, revisionId);
      return this.internalEnsure(revisionId);
    }
  });

  public get(revisionId: number): Observable<SurveyUpdate[]> {
    return this.ensure(revisionId)
      .pipe(map(() => this.cache.get(revisionId)));
  }

  public addData(userId: number, revisionId: number, data: any) {
    try {
      this.internalAddData(userId, revisionId, data);
    } catch (e) {
      this.handleCacheError(e, revisionId);
      this.internalAddData(userId, revisionId, data);
    }
  }

  private handleCacheError(e: Error, revisionId: number) {
    this.cache.clear();
    this.ensure.cache.clear();
  }

  private internalAddData(userId: number, revisionId: number, data: any) {
    this.cache.addData(userId, revisionId, data);
  }

  private internalEnsure(revisionId: number): Observable<any> {
    const addToCache = (surveyUpdate: SurveyUpdate) => this.cache.addSurveyUpdate(revisionId, surveyUpdate);
    const helper = (surveyUpdates: SurveyUpdate[]) => _.map(addToCache, surveyUpdates);

    const request = this.makeRequest(revisionId).pipe(map(helper));

    return toReplaySubject(request);
  }

  protected makeRequestUrl(revisionId: number): string {
    const url = super.makeRequestUrl(revisionId);
    const maybeLastTimestamp = this.cache.getLastTimestamp(revisionId);
    return maybeLastTimestamp ?
      `${url}&last_timestamp=${maybeLastTimestamp}` :
      url;
  }
}
