import { SimpleMockApiInterceptor } from '../interceptors/simple-mock-api';
import { Revision, revisionGen } from '.';
import { sampleOne } from 'testcheck';
import { MultipleMockApiInterceptor } from '../interceptors/multiple-mock-api';

abstract class RevisionsInterceptor extends SimpleMockApiInterceptor<Revision[]> {
  protected abstract readonly jobId;
  protected abstract readonly stationId;
  protected abstract readonly revisionIds;

  protected readonly correctMethod: string = 'GET';

  protected get correctExtension(): string {
    return `apiv2/jobs/${this.jobId}/stations/${this.stationId}/revisions`;
  }

  private revisions;

  protected getBody(): Revision[] {
    if (!this.revisions) {
      this.setRevisions();
    }

    return this.revisions;
  }

  private setRevisions() {
    this.revisions = this.revisionIds.map(revisionId => {
      const revision = sampleOne(revisionGen)
      revision.id = revisionId;
      revision.job_id = this.jobId;
      revision.station_id = this.stationId;
      return revision;
    });
  }
}

export class RevisionsInterceptor1 extends RevisionsInterceptor {
  protected readonly jobId = 1;
  protected readonly stationId = 1;
  protected readonly revisionIds = [1, 2, 3, 4, 5];
}

export class RevisionsInterceptor2 extends RevisionsInterceptor {
  protected readonly jobId = 1;
  protected readonly stationId = 2;
  protected readonly revisionIds = [6, 7];
}

export class RevisionsInterceptor3 extends RevisionsInterceptor {
  protected readonly jobId = 1;
  protected readonly stationId = 3;
  protected readonly revisionIds = [8, 9, 10];
}

export class EmptyRevisions extends MultipleMockApiInterceptor<Revision[]> {
  private readonly matchesRevisionUrl = /.*\/apiv2\/jobs\/\d\/stations\/\d\/revisions/

  protected getBody() {
    return [];
  }

  protected isCorrectExtension(url: string): boolean {
    return this.matchesRevisionUrl.test(url);
  }

}
