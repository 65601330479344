import { Directive, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Revisions from 'src/app/core/revisions';
import { Job } from '../core/jobs';
import { Revision } from '../core/revisions';
import { Station } from '../core/stations';
import { State } from './rx';

@Directive({
  selector: 'only-extend-parameterized-revision'
})
export class ParameterizedRevisionDirective implements OnInit {

  @Input() protected readonly job: Job;
  @Input() protected readonly station: Station;

  protected readonly revisions$: Observable<Revision[]> = this.makeRevisions$();

  constructor(protected readonly store: Store<State>) {
  }

  ngOnInit() {
    this.dispatchLoadRevisions();
  }

  private dispatchLoadRevisions() {
    this.store.dispatch(new Revisions.LoadFromJobStation({ job: this.job, station: this.station }));
  }

  private makeRevisions$(): Observable<Revision[]> {
    return this.store.pipe(
      map(state => this.revisionsForCurrentInputs(Object.values(state.revisions.entities))));
  }

  private revisionsForCurrentInputs(revisions: Revision[]): Revision[] {
    return revisions.filter(revision =>
      revision.station_id === this.station.id &&
      revision.job_id === this.job.id);
  }
}
