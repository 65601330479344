import { flow } from 'lodash';
import { UpdateBelongsToSection, SectionI, Update } from '../lib/models';
import { TimestampSorter } from '../models/timestamp-sorter';
import { User } from '../User';
import { last } from 'lodash/fp';

export class LastModificationToSection {
  constructor(private readonly updateBelongsToSection: UpdateBelongsToSection,
    private readonly updates: Update[]
  ) {
  }

  get(section: SectionI, user: User): number | null {
    return flow([
      this.updatesForSection(section),
      this.updatesForUser(user),
      this.sortedUpdates.bind(this),
      this.getLastUpdate.bind(this),
      this.getTimestamp.bind(this)
    ])(this.updates);
  }

  private updatesForSection(section: SectionI): (updates: Update[]) => Update[] {
    return (updates: Update[]) => {
      return updates.filter(update => this.updateBelongsToSection({ update, section }));
    };
  }

  private updatesForUser(user: User): (updates: Update[]) => Update[] {
    return (updates: Update[]) => {
      return updates.filter(update => update.user_id === user.id);
    };
  }

  private sortedUpdates(updates: Update[]): Update[] {
    return (new TimestampSorter<Update>(updates)).getSorted();
  }

  private getLastUpdate(updates: Update[]): Update | null {
    return last(updates) || null;
  }

  private getTimestamp(update: Update | null): number | null {
    return update ?
      update.timestamp :
      null;
  }
}
