import { QuestionI, SurveyI } from 'src/app/core/lib/models';
import { CompletionResult } from 'src/app/core/models/completion-result';
import { UniqueCollection } from '../unique-collection';
import { ReviewCompletionDataRepresentation } from './review-completion-data-representation';

export class ReviewCompletionManager<InternalRepresentation> {
  private totalQuestionsNeeded: number;
  private readonly internalReps: UniqueCollection<InternalRepresentation>;

  constructor(
    private readonly dataRepresentation: ReviewCompletionDataRepresentation<InternalRepresentation>
  ) {
    this.internalReps = new UniqueCollection<InternalRepresentation>(
      this.dataRepresentation.areSameRepresentation.bind(this.dataRepresentation));
  }

  addCompletion(question: QuestionI, survey: SurveyI): void {
    const internalRep = this.dataRepresentation.getInternalRepresentation(question, survey);
    this.addCompletionByInternalRepresentation(internalRep);
  }

  setCompletions(questions: QuestionI[], survey: SurveyI): void {
    const internalReps = questions.map(question => this.getRepresentation(question, survey));
    this.setCompletionsByInternalRepresentations(internalReps);
  }

  setCompletionsByInternalRepresentations(reps: InternalRepresentation[]): void {
    this.resetInternalReps();
    reps.forEach(this.addCompletionByInternalRepresentation.bind(this));
  }

  setTotalQuestionsNeeded(totalQuestionsNeeded: number) {
    this.totalQuestionsNeeded = totalQuestionsNeeded;
  }

  getTotalCompletion(): CompletionResult {
    if (!this.totalQuestionsNeeded) {
      throw new Error('call setTotalQuestionsNeeded(number) before requesting the totalCompletion');
    }

    return new CompletionResult(this.internalReps.length(), this.totalQuestionsNeeded);
  }

  mayCompleteReview(): boolean {
    return this.getTotalCompletion().asNumber() === 1;
  }

  addCompletionByInternalRepresentation(internalRep: InternalRepresentation) {
    this.internalReps.add(internalRep);
  }

  isCompleted(question: QuestionI, survey: SurveyI): boolean {
    const internalRep = this.dataRepresentation.getInternalRepresentation(question, survey);
    return this.internalReps.contains(internalRep);
  }

  removeCompletion(question: QuestionI, survey: SurveyI) {
    const internalRep = this.dataRepresentation.getInternalRepresentation(question, survey);
    this.internalReps.remove(internalRep);
  }

  toggleCompletion(question: QuestionI, survey: SurveyI) {
    if (this.isCompleted(question, survey)) {
      this.removeCompletion(question, survey);
    } else {
      this.addCompletion(question, survey);
    }
  }

  getRepresentation(question: QuestionI, survey: SurveyI): InternalRepresentation {
    return this.dataRepresentation.getInternalRepresentation(question, survey);
  }

  areSameRepresentation(rep1: InternalRepresentation, rep2: InternalRepresentation): boolean {
    return this.dataRepresentation.areSameRepresentation(rep1, rep2);
  }

  private resetInternalReps(): void {
    this.internalReps.reset();
  }
}
