import { filter } from 'lodash/fp';
import { SurveyWrapper } from '.';
import { Job } from '../jobs';
import { QuestionI, SectionI } from '../lib/models';
import { Revision } from '../revisions';
import { User } from '../User';
import { SurveyAccessControl } from './survey-access-control';

export class RevisionAccessControl extends SurveyAccessControl {
  constructor(surveyWrapper: SurveyWrapper,
    user: User,
    private readonly job: Job,
    private readonly revision: Revision
  ) {
    super(surveyWrapper, user);
  }

  public hasAccessToSection(section: SectionI): boolean {
    return this.mayChangeRevision() &&
      (this.isAssignedSection(section) || this.createdJobForRevision());
  }

  public isAssignedAnySections(): boolean {
    return this.getAssignedSections().length > 0;
  }

  public getAssignedSections(): SectionI[] {
    return <SectionI[]>filter(this.isAssignedSection.bind(this))(this.getSections());
  }

  public mayAnswer(question: QuestionI, section: SectionI): boolean {
    return this.mayChangeRevision() && this.hasAccessToSection(section);
  }

  private mayChangeRevision(): boolean {
    return !this.revision.no_change && this.revision.active && !this.revision.final;
  }

  private isAssignedSection(section: SectionI): boolean {
    const sectionKey = this.sectionKeyFor(section);
    if (sectionKey) {
      return this.revision.user_sections[sectionKey] === this.user.id;
    } else {
      return false;
    }
  }

  private createdJobForRevision(): boolean {
    return this.job.user_id === this.user.id;
  }
}

export class RevisionAccessControlFactory {
  constructor(
    private readonly jobs: Job[],
  ) {
  }

  get(surveyWrapper: SurveyWrapper, revision: Revision, user: User): RevisionAccessControl {
    return new RevisionAccessControl(surveyWrapper, user, this.getJobForRevision(revision), revision);
  }

  private getJobForRevision(revision: Revision): Job {
    const maybeJob = this.jobs.find(this.jobBelongsToRevision(revision));
    this.assertJob(revision, maybeJob);
    return maybeJob;
  }

  private jobBelongsToRevision(revision: Revision): (job: Job) => boolean {
    return (job: Job) => revision.job_id === job.id;
  }

  private assertJob(revision: Revision, job: Job | undefined) {
    if (!job) {
      throw new Error(`No job found for Revision: ${JSON.stringify(revision)}`);
    }
  }
}
