import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { AddSurveyWrappersToFinalizedSurveys } from './add-survey-wrappers-to-finalized-surveys';
import { SurveyWrappersForRevisions } from './survey-wrappers-for-revisions';
import { Revision } from 'src/app/core/revisions';
import { RevisionSurveyWrapperService } from 'src/app/core/survey-wrapper/survey-wrapper.service';
import { FinalizedSurveys } from 'src/app/core/models/finalized-surveys';

@Injectable({
  providedIn: 'root'
})
export class AddSurveyWrappersToFinalizedSurveysService {
  constructor(private readonly surveyWrapperService: RevisionSurveyWrapperService) {
  }

  public addSurveyWrappers(finalizedSurveys: FinalizedSurveys): Observable<any> {
    const revisions = finalizedSurveys.getAvailableRevisions();

    if (revisions.length === 0) {
      // There are no `SurveyWrappers` to add.
      // Just indicate completion.
      return of(null);
    } else {
      return this.getSurveyWrappersForRevisions(revisions)
        .pipe(
          tap(this.internalAddSurveyWrappers(finalizedSurveys, revisions)));
    }
  }

  private getSurveyWrappersForRevisions(revisions: Revision[]): Observable<(revision: Revision) => SurveyWrapper> {
    return (new SurveyWrappersForRevisions(
      this.getSurveyWrapper.bind(this)))
      .get(revisions);
  }

  private internalAddSurveyWrappers(
    finalizedSurveys: FinalizedSurveys,
    revisions: Revision[]
  ): (getSurveyWrapper: (revision: Revision) => SurveyWrapper) => void {
    return (getSurveyWrapper: (revision: Revision) => SurveyWrapper) => {
      (new AddSurveyWrappersToFinalizedSurveys(finalizedSurveys, getSurveyWrapper)).addSurveyWrappers(revisions);
    };
  }

  private getSurveyWrapper(revision: Revision): Observable<SurveyWrapper> {
    return this.surveyWrapperService.get(revision);
  }
}
