import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadEffect } from '../../effects/load';
import * as JobActions from '../actions';
import { Job } from '../model';
import { JobsService } from './../service';

@Injectable()
export class LoadAll extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly jobsService: JobsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [JobActions.Types.LoadAll];
  }

  protected handleResponse(jobs: Job[]): JobActions.AddAll {
    return new JobActions.AddAll({ jobs });
  }

  protected handleRequest(action: any) {
    return this.jobsService.get();
  }

}
