import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import { ApiTokenService } from '../../services/api-token.service';
import { LoadLoggedIn } from '../../users';
import { LoginStateController } from './login-state-controller';
import { PasswordLoginState } from './password-login-state';
import { AuthenticationFailedLoginState } from './authentication-failed-login-state';

interface LoginResponse {
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginStateService extends LoginStateController {
  private static readonly LOGIN_SUCCESSFUL_MESSAGE = 'Login successful';
  private static readonly LOGIN_FAILURE_MESSAGE = 'Login failed';

  constructor(
    private readonly http: HttpClient,
    private readonly apiTokenService: ApiTokenService,
    private readonly store: Store<State>
  ) {
    super(environment.useSaml);
    this.checkAuthenticationFailed();
  }

  public nextLoginState() {
    if (this.isCurrentLoginState(PasswordLoginState)) {
      this.doLogin();
    } else {
      super.nextLoginState();
    }
  }

  private checkAuthenticationFailed() {
    if (window.location.pathname === '/authentication_failed') {
      this.currentLoginState = this.getLoginState(AuthenticationFailedLoginState);
    }
  }

  private doLogin() {
    this.makeLoginRequest()
      .then(this.processLoginResponse.bind(this))
      .catch(this.processLoginFailure.bind(this));
  }

  private makeLoginRequest(): Promise<LoginResponse | undefined> {
    return this.http.post(
      environment.apiUri.toString() + '/api/authentication/login',
      { email: this.getEmail(), password: this.getPassword() }
    ).toPromise();
  }

  private processLoginResponse(response: LoginResponse | undefined) {
    if (response && response.token) {
      this.setSuccessMessage(LoginStateService.LOGIN_SUCCESSFUL_MESSAGE);

      setTimeout(() => {
        this.apiTokenService.apiToken = response.token;
        this.store.dispatch(new LoadLoggedIn());
        this.reset();
      }, 2000);

    } else {
      throw new Error('login failed');
    }
  }

  private processLoginFailure(error: Error) {
    console.warn(error);
    this.setErrorMessage(LoginStateService.LOGIN_FAILURE_MESSAGE);
  }
}
