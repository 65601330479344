import { Subject, BehaviorSubject } from 'rxjs';

class SubscriptionMap<K, V> {
  public readonly changed = new BehaviorSubject(null);

  constructor(public readonly map: Map<K, V>) {
  }

  set(key: K, value: V): this {
    this.map.set(key, value);
    this.changed.next([key, value]);
    return this;
  }

  get(key: K): V {
    return this.map.get(key);
  }
}

export class Toggler<T extends { id: number }> {
  private readonly selections: SubscriptionMap<number, boolean> =
    new SubscriptionMap<number, boolean>(new Map<number, boolean>());

  public readonly changed = this.selections.changed;

  static Empty<T extends { id: number }>(): Toggler<T> {
    return new Toggler<T>([]);
  }

  constructor(initialSelections: number[], onChange?: (any) => any) {
    initialSelections.forEach(this.ensureSelected.bind(this));
    if (onChange) {
      this.changed.subscribe((emitted) => onChange(emitted));
    }
  }

  public toggle(model: T) {
    const current = this.selections.get(model.id) || false;
    this.selections.set(model.id, !current);
  }

  public isSelected(model: T): boolean {
    return this.selections.get(model.id);
  }

  public ensureOnlyAvailable(models: T[]) {
    this.unselectIf(id => !!!models.find(model => model.id === id));
  }

  public unselectIf(predicate: (id: number) => boolean) {
    for (let id of this.selections.map.keys()) {
      if (predicate(id)) {
        this.ensureUnselected(id);
      }
    }
  }

  private ensureSelected(id: number) {
    this.selections.set(id, true);
  }

  private ensureUnselected(id: number) {
    this.selections.set(id, false);
  }
}
