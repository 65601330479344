import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { RevisionsLoader } from 'src/app/core/models/revisions-loader/revisions-loader';
import * as Organizations from 'src/app/core/organizations';
import * as Regions from 'src/app/core/regions';
import * as Stations from 'src/app/core/stations';
import * as Users from 'src/app/core/users';
import { State } from 'src/app/reducers';
import { UnsubscribeOnDestroyComponent } from 'src/app/shared/components/unsubscribe-on-destroy.component';
import { AnalysisSelectionService } from '../analysis-selection.service';
import { ConstrainedReportGeneratorFactoryService } from '../constrained-report-generator-factory.service';
import { FinalizedSurveysService } from '../finalized-surveys.service';
import { LocationHierarchyService } from '../location-hierarchy.service';
import { map } from 'rxjs/operators';
import { LocationI } from 'src/app/core/locations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends UnsubscribeOnDestroyComponent {
  public readonly analysisSelection$ = this.analysisSelectionService.get$();
  public readonly locationHierarchy$ = this.locationHierarchyService.get$();
  public readonly finalizedSurveys$ = this.finalizedSurveysService.get$();
  public readonly constrainedReportGeneratorFactory$ =
    this.constrainedReportGeneratorFactoryService.get$()
    .pipe(map(callForFactory => callForFactory(() => undefined)));
  public zoomToLocation: LocationI;

  private revisionsLoader = new RevisionsLoader(this.store, this.ngUnsubscribe);
  // HACK
  private loaded = false;

  constructor(
    private readonly store: Store<State>,
    private readonly locationHierarchyService: LocationHierarchyService,
    private readonly analysisSelectionService: AnalysisSelectionService,
    private readonly constrainedReportGeneratorFactoryService: ConstrainedReportGeneratorFactoryService,
    private readonly finalizedSurveysService: FinalizedSurveysService
  ) {
    super();
    this.dispatchLoads();
    this.revisionsLoader.startSubscription();

  }

  private dispatchLoads() {
    if (!this.loaded) {
      this.loaded = true;
      this.store.dispatch(new Organizations.LoadAll());
      this.store.dispatch(new Regions.LoadAll());
      this.store.dispatch(new Stations.LoadAll());
      this.store.dispatch(new Users.LoadAll());
    }
  }
}
