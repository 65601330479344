import { Action } from '@ngrx/store';
import { SurveyWrapper } from '../../core/survey-wrapper';

export interface State {
  selectedRevisionId: number | null;
  surveyWrapper: SurveyWrapper | null;
}

const initial = {
  selectedRevisionId: null,
  surveyWrapper: null
};

enum Types {
  SelectById = '[Surveys Revision] Select By Id'
}

interface SelectedRevisionAction extends Action {
  type: Types;
}

export class SelectRevisionById implements SelectedRevisionAction {
  type = Types.SelectById;
  constructor(public readonly payload: { id: number }) {
  }
}

export function reducer(state = initial, action: SelectedRevisionAction) {
  switch (action.type) {
    case Types.SelectById: {
      return {
        ...state,
        selectedRevisionId: (<SelectRevisionById>action).payload.id
      };
    }

    default: {
      return state;
    }
  }
}

