import { createSelector, Selector } from '@ngrx/store';
import { LocationI } from 'src/app/core/locations';
import { User } from 'src/app/core/User';
import { loggedInUserSelector } from 'src/app/core/users';
import { State } from '.';
import { Organization } from 'src/app/core/organizations/Organization';
import { Station } from 'src/app/core/stations';
import { Region } from 'src/app/core/Region';

abstract class AdminLocationSelectorCreator<T extends LocationI> {
  abstract getAllLocations(state: State): T[];
  abstract getIdsToFilterOn(user: User): number[];

  getSelector(): Selector<State, T[]> {
    return createSelector(
      loggedInUserSelector,
      this.getAllLocations.bind(this),
      this.locationsForUser.bind(this)
    );
  }

  private locationsForUser(user: User, locations: T[]) {
    return locations.filter(location => this.getIdsToFilterOn(user).includes(location.id));
  }
}

class AdminOrganizationsSelectorCreator extends AdminLocationSelectorCreator<Organization> {
  getAllLocations(state: State): Organization[] {
    return Object.values(state.organizations.entities);
  }

  getIdsToFilterOn(user: User): number[] {
    return user.edit_organization_ids;
  }
}

class AdminRegionsSelectorCreator extends AdminLocationSelectorCreator<Region> {
  getAllLocations(state: State): Region[] {
    return Object.values(state.regions.entities);
  }

  getIdsToFilterOn(user: User): number[] {
    return user.edit_region_ids;
  }
}

class AdminStationsSelectorCreator extends AdminLocationSelectorCreator<Station> {
  getAllLocations(state: State): Station[] {
    return Object.values(state.stations.entities);
  }

  getIdsToFilterOn(user: User): number[] {
    return user.edit_station_ids;
  }
}

export const adminOrganizationsSelector =
  (new AdminOrganizationsSelectorCreator).getSelector();
export const adminRegionsSelector =
  (new AdminRegionsSelectorCreator()).getSelector();
export const adminStationsSelector =
  (new AdminStationsSelectorCreator()).getSelector();
