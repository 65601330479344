import { InternalRankData } from '../../lib/models';
import { RankData, RankDataResult } from './rank-data';

class LibRankDataResult extends RankDataResult {
  constructor(private readonly value: number, private readonly level: string) {
    super();
  }

  public asNumber(): number {
    return this.value;
  }

  public asLevel(): string {
    return this.level;
  }
}

export class LibRankData extends RankData {
  private readonly result: RankDataResult;

  constructor(private readonly internalRankData: InternalRankData) {
    super();
    this.result = new LibRankDataResult(internalRankData.value, internalRankData.level);
  }

  public getName(): string | undefined {
    return this.internalRankData.name;
  }

  public getNick(): string | undefined {
    return this.internalRankData.nick;
  }
  public getColor(): string {
    return this.internalRankData.color;
  }

  public getResult(): RankDataResult {
    return this.result;
  }
}
