import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { environment } from '../environments/environment';
import { AdminModuleComponent } from './admin/admin-module/admin-module.component';
import { AdminModule } from './admin/admin.module';
import { AppModuleComponent } from './app-module/app-module.component';
import { AppEffects } from './app.effects';
import * as Feedbacks from './core/feedbacks';
import * as Jobs from './core/jobs';
import { Interceptors as JobInterceptors } from './core/jobs/';
import { AddMapFileEffect, RemoveMapFileEffect } from './core/locations/map-file';
import { CreateModelEffect, DeleteModelEffect, RemovePictureEffect, UpdateModelEffect, UploadPictureEffect } from './core/models';
import { OrganizationsLoadAllEffect } from './core/organizations/LoadAllEffect';
import * as Regions from './core/regions';
import * as ReviewTypes from './core/review-types';
import { ReadReviewFromRevisionEffect, CreateReviewEffect, GetReviewsEffect, MarkAsCompleteEffect } from './core/reviews';
import * as Revisions from './core/revisions';
import * as RevisionInterceptors from './core/revisions/interceptors';
import * as Stations from './core/stations';
import { FirstJobStationsInterceptor, SecondJobStationsInterceptor } from './core/stations/interceptors';
import * as Users from './core/users';
import { UpdateOrganizationsDiffEffect, UpdateRegionsDiffEffect, UpdateStationsDiffEffect } from './core/users/location-diff-actions';
import { DashboardModule } from './dashboard/dashboard.module';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { OpensourceSurveyModuleComponent } from './opensource-survey/opensource-survey-module/opensource-survey-module.component';
import { OpensourceSurveyModule } from './opensource-survey/opensource-survey.module';
import { metaReducers, reducers } from './reducers';
import { SharedModule } from './shared/shared.module';
import { LoadAllReviewCompletionEffect } from './core/review-completion/review-completion';
import { ReviewNavSelectionComponent } from './review-nav-selection/review-nav-selection.component';
import { LoginStatusComponent } from './login-status/login-status.component';
import { SiteNavSelectionComponent } from './site-nav-selection/site-nav-selection.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { LoginModule } from './login/login.module';
import { ReviewsReportModalComponent, ReviewsReportComponent } from './classification-review/classification-review.component';

export const REVIEW_ID_KEY = 'reviewId';
export const REVISION_ID_KEY = 'revisionId';

@NgModule({
  declarations: [
    AppModuleComponent,
    ReviewNavSelectionComponent,
    LoginStatusComponent,
    SiteNavSelectionComponent,
    FooterComponent,
    NavComponent,
    ReviewsReportComponent,
    ReviewsReportModalComponent
  ],
  entryComponents: [
    ReviewsReportModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AngularFontAwesomeModule,
    NgbModule.forRoot(),
    LeafletModule.forRoot(),
    AdminModule,
    SharedModule,
    LoginModule,
    BrowserAnimationsModule,
    DashboardModule,
    OpensourceSurveyModule,
    RouterModule.forRoot([
      {
        path: 'survey',
        component: OpensourceSurveyModuleComponent,
      },
      {
        path: `survey/revision/:${REVISION_ID_KEY}`,
        component: OpensourceSurveyModuleComponent,
      },
      {
        path: `survey/review/:${REVIEW_ID_KEY}`,
        component: OpensourceSurveyModuleComponent,
        data: { reviewMode: true }
      },
      {
        path: 'admin',
        component: AdminModuleComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: '**',
        component: DashboardComponent,
      },
    ]),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AppEffects,
      Jobs.Effects.LoadAll,
      Jobs.Effects.Create,
      Stations.LoadFromJobEffect,
      Stations.LoadAllEffect,
      OrganizationsLoadAllEffect,
      Regions.LoadAllEffect,
      Revisions.LoadFromJobStationEffect,
      Users.LoadAllEffect,
      Users.LoadLoggedInEffect,
      Users.LoadOneEffect,
      ReviewTypes.LoadAllEffect,
      Feedbacks.LoadAllForRevisionEffect,
      UpdateOrganizationsDiffEffect,
      UpdateRegionsDiffEffect,
      UpdateStationsDiffEffect,
      UpdateModelEffect,
      CreateModelEffect,
      AddMapFileEffect,
      RemoveMapFileEffect,
      DeleteModelEffect,
      Users.CreateEffect,
      Users.UpdateEffect,
      Users.DeleteEffect,
      UploadPictureEffect,
      RemovePictureEffect,
      ReadReviewFromRevisionEffect,
      CreateReviewEffect,
      Revisions.UpdateRevisionEffect,
      Revisions.CreateRevisionEffect,
      Revisions.FinalizeRevisionEffect,
      GetReviewsEffect,
      Revisions.LoadFromReviewEffect,
      Feedbacks.CreateFeedbackEffect,
      LoadAllReviewCompletionEffect,
      MarkAsCompleteEffect,
      Revisions.UnFinalizeRevisionEffect,
      Revisions.LoadFromIdEffect,
      Revisions.LoadFromJobStationsEffect,
      Users.LogoutEffect,
      Feedbacks.UpdateFeedbackEffect,
      Feedbacks.DeleteFeedbackEffect,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JobInterceptors.Get, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FirstJobStationsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecondJobStationsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RevisionInterceptors.RevisionsInterceptor1, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RevisionInterceptors.RevisionsInterceptor2, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RevisionInterceptors.RevisionsInterceptor3, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RevisionInterceptors.EmptyRevisions, multi: true },
  ],
  bootstrap: [AppModuleComponent],
})
export class AppModule {
}
