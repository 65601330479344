import { Component, OnInit } from '@angular/core';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';

@Component({
  selector: 'app-next-login-state-button',
  templateUrl: './next-login-state-button.component.html',
  styleUrls: ['./next-login-state-button.component.css']
})
export class NextLoginStateButtonComponent implements OnInit {

  constructor(private readonly loginStateService: LoginStateService) { }

  ngOnInit() {
  }

  handleNext() {
    this.loginStateService.nextLoginState();
  }
}
