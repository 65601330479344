import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadEffect } from '../effects/load';
import { AddAll, LoadAll, Types } from './index';
import { Organization } from './Organization';
import { OrganizationsService } from './service';

@Injectable()
export class OrganizationsLoadAllEffect extends LoadEffect {
  @Effect()
  observing = this.parentObserver$;
  constructor(actions$: Actions, private readonly organizationsService: OrganizationsService) {
    super(actions$);
  }
  protected typeFilters() {
    return [Types.LoadAll];
  }
  protected handleResponse(organizations: Organization[]) {
    return new AddAll({ organizations });
  }
  protected handleRequest(action: LoadAll) {
    return this.organizationsService.get();
  }
}
