import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feedback } from '.';
import { Revision } from '../revisions';
import { ApiV2Service } from './../services/api-v2.service';
import { ApiTokenService } from '../services/api-token.service';
import { HttpClient } from '@angular/common/http';
import { makeUrlForInstance, processDeleteResponse } from '../services/model-service';
import { pickBy } from 'lodash/fp';

interface CreateRequestParameters {
  feedback: {
    review_id: number;
    text: string;
    metadata_key: any
  };
}

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {
  private readonly urlExtension = 'feedbacks';

  constructor(private readonly api: ApiV2Service,
              private readonly http: HttpClient,
              private readonly apiTokenService: ApiTokenService) {
  }

  public getForRevision(revision: Revision): Observable<Feedback[]> {
    return this.api.get(`${this.urlExtension}?revision_id=${revision.id}`);
  }

  public create(feedback: Feedback): Observable<{ oldFeedback: Feedback, newFeedback: Feedback }> {
    const requestParams: CreateRequestParameters = {
      feedback: {
        review_id: feedback.review_id,
        text: feedback.text,
        metadata_key: feedback.metadata_key
      }
    };

    const helper = (newFeedback: Feedback) => {
      return {
        oldFeedback: feedback,
        newFeedback: newFeedback
      };
    };

    return this.api.post(this.urlExtension, requestParams).pipe(map(helper));
  }

  public update(model: Feedback): Observable<Feedback> {
    const { id } = model;

    const updatedModel = pickBy((v, k) => k !== 'revision_id', model);

    return this.http.post<Feedback>(
      this.makeUrlForInstance(<number>id),
      updatedModel,
      { headers: this.apiTokenService.headers() }
    );
  }

  public delete(model: Feedback): Observable<Feedback> {
    return this.http.delete<{ success: boolean }>(
      this.makeUrlForInstance(<number>model.id),
      { headers: this.apiTokenService.headers() }
    ).pipe(
      map(processDeleteResponse(model)));
  }

  private makeUrlForInstance(id: number) {
    return makeUrlForInstance('/api/feedbacks', id);
  }
}
