import { FinalizedSurveys } from 'src/app/core/models/finalized-surveys';
import { Revision } from 'src/app/core/revisions';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';

export class AddSurveyWrappersToFinalizedSurveys {
  constructor(private readonly finalizedSurveys: FinalizedSurveys,
    private readonly getSurveyWrapper: (revision: Revision) => SurveyWrapper) {
  }

  public addSurveyWrappers(revisions: Revision[]): void {
    revisions.forEach(this.addSurveyWrapperForRevision.bind(this));
  }

  private addSurveyWrapperForRevision(revision: Revision): void {
    this.addSurveyWrapper(revision, this.getSurveyWrapper(revision));
  }

  private addSurveyWrapper(revision: Revision, surveyWrapper: SurveyWrapper): void {
    this.finalizedSurveys.addSurveyWrapper(revision, surveyWrapper);
  }
}
