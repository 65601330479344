import { Component, OnInit, Input } from '@angular/core';
import { Review, MarkAsComplete } from '../core/reviews';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from '../reducers';
import { Store } from '@ngrx/store';
import { partial } from 'lodash';
import { displayReview$ } from '../review-nav-selection/review-nav-selection.component';
import { Station } from '../core/stations';
import { Job } from '../core/jobs';
import { first, map, tap } from 'rxjs/operators';
import { Revision } from '../core/revisions';
import { ConstrainedReportGeneratorFactoryService } from '../dashboard/constrained-report-generator-factory.service';
import { ReviewSurveyWrapperService } from '../core/survey-wrapper/survey-wrapper.service';
import { combineLatest, Observable } from 'rxjs';
import { SurveyWrapper } from '../core/survey-wrapper';
import { Completion } from '../core/survey-wrapper/completion';
import { NotificationsService } from '../core/services/notifications.service';
import { MonadicAction } from '../core/effects/monadic-action';
import { DRAFT_TEXT } from '../core/models/reporting/report-args-creator';
import * as Organizations from 'src/app/core/organizations';
import * as Regions from 'src/app/core/regions';
import * as Stations from 'src/app/core/stations';
import * as Users from 'src/app/core/users';

@Component({
  templateUrl: './classification-review-modal.component.html'
})
export class ReviewsReportModalComponent implements OnInit {
  @Input() public readonly review: Review;
  @Input() public readonly revision: Revision;
  @Input() public readonly station: Station;
  @Input() public readonly job: Job;

  public response: string;
  public hasDownloadedDraft = false;
  private surveyWrapper: SurveyWrapper | undefined;
  public reportIsComplete = false;
  public downloading = false;

  public readonly availableResponses = () =>
    this.review.review_type.available_responses

  public readonly canSubmit = () =>
    this.hasDownloadedDraft && !!this.response

  public readonly handleCancel = () =>
    this.activeModal.dismiss()

  private loaded = false;

  constructor(private readonly activeModal: NgbActiveModal,
              // death by design patterns
              private readonly dbdp: ConstrainedReportGeneratorFactoryService,
              private readonly reviewSurveyWrapperService: ReviewSurveyWrapperService,
              private readonly notifications: NotificationsService,
              private readonly store: Store<State>
             ) {
    this.dispatchLoads();
  }

  private dispatchLoads() {
    if (!this.loaded) {
      this.loaded = true;
      this.store.dispatch(new Organizations.LoadAll());
      this.store.dispatch(new Regions.LoadAll());
      this.store.dispatch(new Stations.LoadAll());
      this.store.dispatch(new Users.LoadAll());
    }
  }

  ngOnInit() {
    this.reviewSurveyWrapperService
      .get(this.review)
      .pipe(first())
      .subscribe(surveyWrapper => {
        this.surveyWrapper = surveyWrapper;
        this.reportIsComplete = (new Completion(this.surveyWrapper)).total().asNumber() === 1;
      });
  }

  public submit() {
    const action = new MonadicAction(
      new MarkAsComplete({ review: this.review, response: this.response }),
      () => {
        this.activeModal.dismiss();
        this.notifications.addNotification({ message: `Successfully completed review` });
      }
    );

    this.store.dispatch(action);
  }

  public downloadReportDraft() {
    // Don't download more than one at a time
    if (this.downloading) {
      return;
    }

    combineLatest([
      this.dbdp.get$(),
    ])
      .pipe(first())
      .subscribe(([factory]) => {
        this.downloading = true;

        const onSuccess = () => {
          this.hasDownloadedDraft = true;
          this.downloading = false;
        };

        // Force the header to note that this is a draft
        factory(onSuccess, { header: DRAFT_TEXT })
          .getConstrainedReportGenerator(this.station, this.revision, this.surveyWrapper)
          .generateReport();
      });
  }
}


@Component({
  selector: 'app-reviews-report',
  templateUrl: './classification-review.component.html',
  styleUrls: ['./classification-review.component.css']
})
export class ReviewsReportComponent {
  @Input() public readonly review: Review;
  public readonly displayReview$ = partial(displayReview$, this.store);

  constructor(private readonly store: Store<State>,
    private readonly modalService: NgbModal) {
  }

  public openClassificationModal(review: Review): void {
    this.store.pipe(first()).subscribe(state => {
      const revision = state.revisions.entities[review.revision_id];
      const station = state.stations.entities[revision.station_id];
      const job = state.jobs.entities[revision.job_id];

      const modalRef = this.modalService.open(ReviewsReportModalComponent, {
        backdrop: 'static'
      });

      modalRef.componentInstance.review = review;
      modalRef.componentInstance.station = station;
      modalRef.componentInstance.revision = revision;
      modalRef.componentInstance.job = job;
    });
  }
}
