import { Component } from '@angular/core';
import { EmailLoginState } from 'src/app/core/models/login-states/email-login-state';
import { LoginStateService } from 'src/app/core/models/login-states/login-state.service';
import { PasswordLoginState } from 'src/app/core/models/login-states/password-login-state';
import { RequestAccessLoginState } from 'src/app/core/models/login-states/request-access-login-state';
import { RequestAccessRequestMadeLoginState } from 'src/app/core/models/login-states/request-access-request-made-login-state';
import { SamlLoginState } from 'src/app/core/models/login-states/saml-login-state';
import { SelectOrganizationLoginState } from 'src/app/core/models/login-states/select-organization-login-state';
import { WarningLoginState } from 'src/app/core/models/login-states/warning-login-state';
import { AuthenticationFailedLoginState } from 'src/app/core/models/login-states/authentication-failed-login-state';
import { LoginStateConstructor } from 'src/app/core/models/login-states/login-state-controller';
import { DeclineLoginState } from 'src/app/core/models/login-states/decline-login-state';

@Component({
  selector: 'app-current-login-state',
  templateUrl: './current-login-state.component.html',
  styleUrls: ['./current-login-state.component.css']
})
export class CurrentLoginStateComponent {

  constructor(private readonly loginStateService: LoginStateService) {
  }

  public readonly DeclineLoginState = DeclineLoginState;
  public readonly AuthenticationFailedLoginState = AuthenticationFailedLoginState;
  public readonly WarningLoginState = WarningLoginState;
  public readonly EmailLoginState = EmailLoginState;
  public readonly SamlLoginState = SamlLoginState;
  public readonly PasswordLoginState = PasswordLoginState;
  public readonly RequestAccessLoginState = RequestAccessLoginState;
  public readonly SelectOrganizationLoginState = SelectOrganizationLoginState;
  public readonly RequestAccessRequestMadeLoginState = RequestAccessRequestMadeLoginState;

  public shouldDisplay(constructor_: LoginStateConstructor): boolean {
    return this.loginStateService.isCurrentLoginState(constructor_);
  }
}
