import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SurveyI } from 'src/app/core/lib/models';
import { AnalysisSelection } from 'src/app/core/models/analysis';
import { FinalizedSurveys } from 'src/app/core/models/finalized-surveys';
import { CurrentLocationType, LocationHierarchy } from 'src/app/core/models/location-hierarchy';
import { ConstrainedReportGenerator, ConstrainedReportGeneratorFactory } from 'src/app/core/models/reporting/constrained-report-generator';
import { Organization } from 'src/app/core/organizations/Organization';
import { Region } from 'src/app/core/Region';
import { Station } from 'src/app/core/stations';
import { organizationSrc, regionSrc } from 'src/app/shared/utils';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { Revision } from 'src/app/core/revisions';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html'
})
export class LocationsComponent {

  public readonly organizationSrc = organizationSrc;
  public readonly regionSrc = regionSrc;

  @Input() public readonly analysisSelection: AnalysisSelection;
  @Input() private readonly locationHierarchy: LocationHierarchy;
  @Input() private readonly finalizedSurveys: FinalizedSurveys;
  @Input() private readonly constrainedReportGeneratorFactory: ConstrainedReportGeneratorFactory;

  @Output() public readonly locationClicked = new EventEmitter();

  public canGoBack(): boolean {
    return this.locationHierarchy.canGoBack();
  }

  public back(): void {
    return this.locationHierarchy.back();
  }

  public shouldListOrganizations(): boolean {
    return this.locationHierarchy.shouldListOrganizations();
  }

  public shouldListRegions(): boolean {
    return this.locationHierarchy.shouldListRegions();
  }

  public shouldListStations(): boolean {
    return this.locationHierarchy.shouldListStations();
  }

  public currentOrganizations(): Organization[] {
    return this.locationHierarchy.currentOrganizations();
  }

  public currentRegions(): Region[] {
    return this.locationHierarchy.currentRegions();
  }

  public currentStations(): Station[] {
    return this.locationHierarchy.currentStations();
  }

  public setCurrentLocation(location: CurrentLocationType) {
    this.locationHierarchy.setCurrentLocation(location);
  }

  public surveyFor(station: Station): SurveyI | undefined {
    return this.finalizedSurveys ?
      this.finalizedSurveys.surveyFor(station) :
      undefined;
  }

  public reportGeneratorFor(station: Station): ConstrainedReportGenerator | undefined {
    const revision = this.revisionFor(station);
    const surveyWrapper = this.surveyWrapperFor(station);

    return revision && surveyWrapper ?
      this.constrainedReportGeneratorFactory.getConstrainedReportGenerator(
        station,
        this.revisionFor(station),
        this.surveyWrapperFor(station)
      ) :
      undefined;
  }

  private surveyWrapperFor(station: Station): SurveyWrapper | undefined {
    return this.finalizedSurveys ?
      this.finalizedSurveys.surveyWrapperFor(station) :
      undefined;
  }

  private revisionFor(station: Station): Revision | undefined {
    return this.finalizedSurveys ?
      this.finalizedSurveys.revisionFor(station) :
      undefined;
  }
}
