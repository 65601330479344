import { combineLatest, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { Revision } from 'src/app/core/revisions';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';

export class SurveyWrappersForRevisions {
  constructor(private readonly getSurveyWrapperFunc: (revision: Revision) => Observable<SurveyWrapper>) {
  }

  public get(revisions: Revision[]): Observable<(revision: Revision) => SurveyWrapper> {
    const revisionMap = new Map<Revision, SurveyWrapper>();

    return combineLatest(
      revisions.map(this.addSurveyWrapperForRevisionToMap(revisionMap)))
      .pipe(map(() => this.makeFunction(revisionMap)));
  }

  private addSurveyWrapperForRevisionToMap(revisionMap: Map<Revision, SurveyWrapper>): (revision: Revision) => Observable<any> {
    return (revision: Revision) =>
      this.getSurveyWrapper(revision)
        .pipe(tap(this.addToMap(revisionMap, revision)));
  }

  private makeFunction(revisionMap: Map<Revision, SurveyWrapper>): (revision: Revision) => SurveyWrapper {
    return (revision: Revision) => revisionMap.get(revision);
  }

  private getSurveyWrapper(revision: Revision): Observable<SurveyWrapper> {
    return this.getSurveyWrapperFunc(revision).pipe(first());
  }

  private addToMap(revisionMap: Map<Revision, SurveyWrapper>, revision: Revision): (surveyWrapper: SurveyWrapper) => void {
    return (surveyWrapper: SurveyWrapper) => {
      revisionMap.set(revision, surveyWrapper);
    };
  }
}
