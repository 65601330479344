import { userSrc } from 'src/app/shared/utils';
import { ReportOrganization, ReportSection, ReportUser } from '.';
import { SectionI } from '../../lib/models';
import { Revision } from '../../revisions';
import { SurveyWrapper } from '../../survey-wrapper';
import { RevisionAccessControlFactory, RevisionAccessControl } from '../../survey-wrapper/revision-access-control';
import { User } from '../../User';


export abstract class ReportPeopleCreator {
  abstract createReportPeople(
    revision: Revision,
    surveyWrapper: SurveyWrapper,
    organization: ReportOrganization
  ): ReportUser[];
}

export class ReportPeopleCreatorImpl extends ReportPeopleCreator {
  constructor(
    private readonly users: User[],
    private readonly revisionAccessControlFactory: RevisionAccessControlFactory
  ) {
    super();
  }

  createReportPeople(
    revision: Revision,
    surveyWrapper: SurveyWrapper,
    organization: ReportOrganization
  ): ReportUser[] {
    return this.users
      .filter(user => this
        .getRevisionAccessControl(revision, surveyWrapper, user)
        .isAssignedAnySections())
      .map(this.getReportUser(revision, surveyWrapper, organization));
  }

  private getReportUser(
    revision: Revision,
    surveyWrapper: SurveyWrapper,
    organization: ReportOrganization
  ): (user: User) => ReportUser {
    return (user: User) => Object.assign({}, user, {
      img: userSrc(user),
      sections: this.getSections(revision, surveyWrapper, user),
      organization: this.getOrganization(user, organization)
    });
  }

  private getSections(revision: Revision, surveyWrapper: SurveyWrapper, user: User): ReportSection[] {
    return this
      .getRevisionAccessControl(revision, surveyWrapper, user)
      .getAssignedSections()
      .map(this.getReportSection(surveyWrapper, user));
  }

  private getReportSection(surveyWrapper: SurveyWrapper, user: User): (section: SectionI) => ReportSection {
    return (section: SectionI) => {
      return {
        name: section.title,
        last_modification: this.getLastModificationToSection(surveyWrapper, section, user)
      };
    };
  }

  // HACK. This does not actually get the organization that the user belongs to.
  private getOrganization(user: User, organization: ReportOrganization): ReportOrganization {
    return organization;
  }

  private getLastModificationToSection(surveyWrapper: SurveyWrapper, section: SectionI, user: User): number | null {
    return surveyWrapper.getLastModificationToSection(section, user);
  }

  private getRevisionAccessControl(revision: Revision, surveyWrapper: SurveyWrapper, user: User): RevisionAccessControl {
    return this.revisionAccessControlFactory.get(surveyWrapper, revision, user);
  }
}
