import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { UserCopy } from 'src/app/core/users/user-copy'

import { NotificationsService } from '../../core/services/notifications.service'
import { State } from '../rx'
import { EditUserComponentParams } from './edit-user-component-params/edit-user-component-params'

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  public readonly availableRoles = [
    {
      text: 'admin',
      title: 'Not available yet',
      disabled: true,
    },
    {
      text: 'analyst',
    },
    {
      text: 'user'
    }
  ];

  public copy: UserCopy;

  private errorMessage: string;
  @Input() public readonly params: EditUserComponentParams;

  constructor(private readonly activeModal: NgbActiveModal,
    private readonly notifications: NotificationsService,
    private readonly store: Store<State>
  ) {
  }

  ngOnInit() {
    this.copy = this.params.getCopy();
  }

  public handleCancel() {
    this.activeModal.dismiss();
  }

  public handleSave() {
    if (!this.copy.isValid()) {
      this.temporarilySetErrorMessage(this.copy.currentError());
      return;
    }

    this.save();
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  private temporarilySetErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.removeErrorMessageIn(3000);
  }

  private removeErrorMessageIn(milliseconds: number) {
    setTimeout(() => this.errorMessage = undefined, milliseconds);
  }

  private save() {
    this.store.dispatch(this.params.getAction());
    this.handleResponse();
  }

  private handleResponse() {
    this.notifications.addNotification({
      message: this.params.getSuccessNotificationMessage(this.copy)
    });
    this.activeModal.close();
  }
}
