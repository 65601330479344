import { SectionI, SurveyI } from './../../core/lib/models';

export class SectionSelector {

  private readonly sections: { [key: string]: SectionI };
  private readonly selected: { [key: string]: boolean } = {};

  constructor(survey: SurveyI) {
    this.sections = survey.sections;
  }

  public atLeastOneSelected(): boolean {
    return Object.values(this.selected).reduce((a, b) => a || b, false) ?
      true :
      false;
  }

  public sectionKeys(): string[] {
    return Object.keys(this.selected).filter(key => this.selected[key]);
  }

  public availableSections(): SectionI[] {
    return Object.values(this.sections);
  }

  public toggle(section: SectionI) {
    if (this.isSelected(section)) {
      this.remove(section);
    } else {
      this.select(section);
    }
  }

  private select(section: SectionI) {
    const key = this.getSectionKey(section);
    this.selected[key] = true;
  }

  private remove(section: SectionI) {
    const key = this.getSectionKey(section);
    this.selected[key] = false;
  }

  private isSelected(section: SectionI): boolean {
    const key = this.getSectionKey(section);
    return this.selected[key];
  }

  private getSectionKey(section: SectionI) {
    const foundSectionKey = Object.keys(this.sections).find(sectionKey => {
      return this.sections[sectionKey] === section;
    });
    if (!foundSectionKey) {
      throw new Error('The given section was not produced by this assigner');
    }
    return foundSectionKey;
  }
}
