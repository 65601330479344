export abstract class URLToCachedResponse {
  abstract get<T>(url: string): T;
  abstract set<T>(url: string, responseResult: T): void;
  abstract has(url: string): boolean;
}

export class URLToCachedResponseImpl extends URLToCachedResponse {

  get<T>(url: string): T {
    this.assertInCache(url);

    let cached: T;

    try {
      cached = JSON.parse(this.getRaw(url));
    } catch (error) {
      throw new Error(`Failed to parse cached value for ${url}`);
    }

    return cached;
  }

  set<T>(url: string, responseResult: T): void {
    localStorage.setItem(this.makeLocalStorageString(url), JSON.stringify(responseResult));
  }

  has(url: string): boolean {
    return !!this.getRaw(url);
  }

  private getRaw(url: string): string | undefined {
    return localStorage.getItem(this.makeLocalStorageString(url));
  }

  // HACK
  private makeLocalStorageString(url: string): string {
    return `RESPONSE_FOR: ${url}`;
  }

  private assertInCache(url: string) {
    if (!this.has(url)) {
      throw new Error(`Nothing in cache for ${url}`);
    }
  }
}
