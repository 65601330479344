import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { SectionI } from '../lib/models';
import { CompletionResult } from '../models/completion-result';
import { Counter } from './counter';

export class Completion {
  constructor(private readonly surveyWrapper: SurveyWrapper) {
  }

  public total(): CompletionResult {
    return Counter.Count(this.getSections());
  }

  public forSection(section: SectionI): CompletionResult {
    return Counter.Count([section]);
  }


  private getSections(): SectionI[] {
    return Object.values(this.getSurvey().sections);
  }

  private getSurvey() {
    return this.surveyWrapper.getSurvey();
  }
}
