import { Directive, ChangeDetectorRef } from '@angular/core';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';
import { ActiveSurveyFactoryService } from '../active-survey/active-survey-factory.service';
import { State } from '../rx';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ReviewCompletionManager } from 'src/app/core/models/review-completion-manager/review-completion-manager';
import { QuestionMetadata } from 'src/app/core/lib/models';
import { UpdatingReviewCompletionMangerService } from './updating-review-completion-manager.service';
import { toReplaySubject } from 'src/app/shared/utils';
import { SurveyAccessControl } from 'src/app/core/survey-wrapper/survey-access-control';
import { flatMap } from 'rxjs/operators';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';

@Directive({
  selector: 'only-extend-updating-review-completion-manager-directive'
})
export class UpdatingReviewCompletionManagerDirective extends ActiveSurveyDirective {
  protected readonly reviewCompletionManager$: Observable<ReviewCompletionManager<QuestionMetadata>> =
    this.makeReviewCompletion$();

  constructor(
    activeSurveyFactoryService: ActiveSurveyFactoryService,
    store: Store<State>,
    ref: ChangeDetectorRef,
    reviewSurveyActionsService: ReviewSurveyActionsService,
    private readonly updatingReviewCompletionManagerService: UpdatingReviewCompletionMangerService
  ) {
    super(activeSurveyFactoryService, store, ref, reviewSurveyActionsService);
  }

  private makeReviewCompletion$(): Observable<ReviewCompletionManager<QuestionMetadata>> {
    const helper = (accessControl: SurveyAccessControl) => {
      return this.updatingReviewCompletionManagerService.get$(
        accessControl.getTotalQuestionsNeededToReview());
    };

    const observable = this.accessControl$.pipe(flatMap(helper));

    return toReplaySubject(observable);
  }
}
