import { Injectable } from '@angular/core';
import { RemoveOne, UpsertOne } from '.';
import { LocationService } from '../services/location.service';
import { Organization, OrganizationKind } from './Organization';
import { OrganizationCopy } from './organization-copy';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService extends LocationService<Organization> {
  protected readonly uploadExtensionName = 'organization';
  protected readonly mapFileExtensionName = 'organization';
  protected readonly urlExtension = '/api/organizations';
  protected readonly kind = OrganizationKind;
  protected readonly copyConstructor = OrganizationCopy;

  public makeUpsertAction(organization: Organization): UpsertOne {
    return new UpsertOne({ organization });
  }

  public makeRemoveOneAction(organization: Organization): RemoveOne {
    return new RemoveOne({ organization });
  }
}
