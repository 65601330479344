import { AvailableModelTypes } from './../rx/index';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { State } from '../rx';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-model-button',
  templateUrl: './new-model-button.component.html',
  styleUrls: ['./new-model-button.component.css']
})
export class NewModelButtonComponent implements OnInit {

  public readonly AvailableModelTypes = AvailableModelTypes;

  public readonly currentModelType$: Observable<AvailableModelTypes> =
    this.store.pipe(map(state => state.admin.currentModelType));

  constructor(private readonly store: Store<State>) {
  }

  ngOnInit() {
  }

}
