import { Injectable, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeleteModelAction, PictureModel } from 'src/app/core/models';
import { ModelCopyConstructor } from 'src/app/core/models/model-copy';
import { User } from 'src/app/core/User';
import { UserKind } from 'src/app/core/users';
import { DeleteModelComponent } from 'src/app/shared/components/delete-model/delete-model.component';
import { EditLocationPermissionsComponent } from '../edit-location-permissions/edit-location-permissions.component';
import { MapFileUploadComponent } from '../map-file-upload/map-file-upload.component';
import { PictureUploadComponent } from '../picture-upload/picture-upload.component';
import { State } from '../rx';

// Must set the template url in the derived component
@Injectable()
export abstract class ModelsComponent<T extends PictureModel> {
  @Input() public readonly searchText = '';
  public hasMapFiles = false;
  public hasLocationPermissions = false;

  public readonly models$: Observable<T[]> = this.makeModels$();
  public abstract readonly src;

  protected abstract readonly kind: string;
  protected abstract readonly copyConstructor: ModelCopyConstructor<T>;

  protected abstract makeModels$(): Observable<T[]>;

  constructor(
    protected readonly modalService: NgbModal,
    protected readonly store: Store<State>
  ) {
  }

  public abstract handleRequestModelEdit(model: T): void;

  public handleRequestMapFileEdit(model: T) {
    const modalRef = this.modalService.open(MapFileUploadComponent);
    modalRef.componentInstance.model = model;
  }

  public handleRequestLocationPermissionsEdit(model: T) {
    this.assertUser(model);

    const modalRef = this.modalService.open(EditLocationPermissionsComponent);
    modalRef.componentInstance.user = <User><unknown>model;
  }

  public handleRequestPictureEdit(model: T) {
    const modalRef = this.modalService.open(PictureUploadComponent);
    modalRef.componentInstance.model = model;
  }

  public handleRequestModelDelete(model: T) {
    const modalRef = this.modalService.open(DeleteModelComponent);
    modalRef.componentInstance.model = model;
    modalRef.componentInstance.actionToDispatch = new DeleteModelAction({ model });
  }

  private assertUser(model: T) {
    if (model.kind !== UserKind) {
      throw new Error('May only request location permission edits on users');
    }
  }
}
