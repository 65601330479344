import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditOrganizationTextCreating } from '../edit-location/edit-location-text';
import { EditLocationComponent } from '../edit-location/edit-location.component';
import { OrganizationKind } from 'src/app/core/organizations/Organization';
import { OrganizationCopy } from 'src/app/core/organizations/organization-copy';
import { noCloseModalOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-new-organization-button',
  templateUrl: './new-organization-button.component.html',
  styleUrls: ['./new-organization-button.component.css']
})
export class NewOrganizationButtonComponent {

  constructor(private readonly modalService: NgbModal) { }

  public handleClick() {
    const modalRef = this.modalService.open(EditLocationComponent, noCloseModalOptions);
    modalRef.componentInstance.editLocationText = new EditOrganizationTextCreating();
    modalRef.componentInstance.copy = new OrganizationCopy({ kind: OrganizationKind });
  }
}
