import { CurrentLocationType, isRegion, isOrganization } from "./location-hierarchy";
import { kml } from '@mapbox/leaflet-omnivore';
import { environment } from 'src/environments/environment';
import * as L from '@mapbox/leaflet-omnivore';
import { Layer } from 'leaflet';

// HACK
// The import has a side effect of modifying the global `L`.
// In order to maintain the import in the compiled code, we must
// use the import which we accomplish via the assignment
import * as foo from 'leaflet-kmz/libs/KMZParser';
const foo_ = foo;

// HACK
// Same as KMZParser.
import * as bar from 'leaflet-kmz/libs/GridLayer.GeoJSON';
const bar_ = bar;

// HACK
// The KMZParser requires the following three values
// to be globally accessible with the shown bindings.
import * as JSZip from 'jszip';
import * as togeojson from '@mapbox/togeojson';
import geojsonvt from 'geojson-vt';
window['JSZip'] = JSZip;
window['toGeoJSON'] = togeojson;
window['geojsonvt'] = geojsonvt;

export class OverlayMaker {
  constructor(private readonly overlayLocation: CurrentLocationType) {
  }

  public makeOverlay(): Promise<Layer | undefined> {
    if (!this.overlayLocation || !this.overlayLocation.map_file_uri) {
      return Promise.resolve(undefined);
    } else {
      const url = this.getOverlayUrl(this.overlayLocation);
      return this.convertUrlToLayer(url);
    }
  }

  private convertUrlToLayer(url: string): Promise<Layer> {
    let res, rej;
    const promise = new Promise<Layer>((res_, rej_) => {
      res = res_;
      rej = rej_;
    });

    if (this.isKmz(url)) {
      const kmzParser = new window['L'].KMZParser({
        onKMZLoaded: function(layer, name) {
          res(layer);
        }
      });

      kmzParser.load(url);
    } else if (this.isKml(url)) {
      res(kml(url));
    } else {
      rej(new Error(`Do not know how to convert ${url} to layer`));
    }

    return promise;
  }

  private isKml(url: string): boolean {
    return this.getFileExtension(url) === 'kml';
  }

  private isKmz(url: string): boolean {
    return this.getFileExtension(url) === 'kmz';
  }

  private getFileExtension(url: string): string {
    return url.split('.').pop();
  }

  private getOverlayUrl(overlayLocation: CurrentLocationType) {
    const { id, map_file_uri } = overlayLocation;
    const modelStr = this.getModelString(overlayLocation);
    const { apiUri } = environment;
    return apiUri.toString() + '/uploaded_files/map_file/' + modelStr + '/' + id + '/' + map_file_uri;
  }

  private getModelString(overlayLocation: CurrentLocationType) {
    if (isRegion(overlayLocation)) {
      return 'region';
    } else if (isOrganization(overlayLocation)) {
      return 'organization';
    } else {
      throw new Error(`Could not identify overlayLocation ${JSON.stringify(overlayLocation)}`);
    }
  }
}
