import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActiveSurveyDirective } from '../active-survey/active-survey.component';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent extends ActiveSurveyDirective {
  @ViewChild('questionContainer') elRef: ElementRef;

  checkOverflow() {
    if (!this.elRef) {
      return false;
    }

    const { nativeElement: element } = this.elRef;

    if (element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }
}
