import { SurveyWrapper } from '.';
import { Lib, SurveyI } from '../lib/models';
import { Revision } from '../revisions';
import { assign } from 'lodash/fp';
import { SetSurveyCache } from './model';

export class RevisionSurveyWrapper extends SurveyWrapper {
  constructor(
    lib: Lib,
    survey: SurveyI,
    setSurveyCache: SetSurveyCache,
    public readonly revision: Revision
  ) {
    super(lib, survey, revision.id, setSurveyCache);
  }

  public makeSelection(args: any): void {
    this.doSurveyTransition(
      this.lib.makeSelection(
        assign(args, { survey: this.survey })));
  }

  public addAdditionalInformation(args: any) {
    this.doSurveyTransition(
      this.lib.addAdditionalInformation(
        assign(args, { survey: this.survey })));
  }

  public removeAdditionalInformation(args: any) {
    this.doSurveyTransition(
      this.lib.removeAdditionalInformation(
        assign(args, { survey: this.survey })));
  }

  public belongsToRevision(revision: Revision): boolean {
    // Only need to check the id in this case. Object equality is too strict
    return this.revision.id === revision.id;
  }
}
