import { Component, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Organizations from 'src/app/core/organizations';
import { Organization, OrganizationKind } from 'src/app/core/organizations/Organization';
import { OrganizationCopy } from 'src/app/core/organizations/organization-copy';
import { organizationSrc } from '../../shared/utils';
import { LocationsComponent } from '../locations/locations.component';
import { adminOrganizationsSelector } from '../rx/admin-location-selectors';

@Component({
  templateUrl: '../models/models.component.html',
  selector: 'app-organizations'
})
export class OrganizationsComponent
  extends LocationsComponent<Organization>
  implements OnInit {
  public readonly src = organizationSrc;
  protected readonly kind = OrganizationKind;
  protected readonly copyConstructor = OrganizationCopy;

  public ngOnInit() {
    this.dispatchLoadOrganizations();
  }

  protected makeModels$(): Observable<Organization[]> {
    return this.store.pipe(select(adminOrganizationsSelector));
  }

  private dispatchLoadOrganizations() {
    this.store.dispatch(new Organizations.LoadAll());
  }
}
