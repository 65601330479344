import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as Regions from 'src/app/core/regions';
import { Region } from "src/app/core/Region";
import { RegionCopy } from 'src/app/core/regions/region-copy';
import { regionSrc } from '../../shared/utils';
import { LocationsComponent } from '../locations/locations.component';
import { adminRegionsSelector } from '../rx/admin-location-selectors';
import { select } from '@ngrx/store';

@Component({
  selector: 'app-regions',
  templateUrl: '../models/models.component.html'
})
export class RegionsComponent extends LocationsComponent<Region> implements OnInit {
  public readonly src = regionSrc;
  protected readonly kind = Regions.RegionKind;
  protected readonly copyConstructor = RegionCopy;

  public ngOnInit() {
    this.dispatchLoadRegions();
  }

  private dispatchLoadRegions(): void {
    this.store.dispatch(new Regions.LoadAll());
  }

  protected makeModels$(): Observable<Region[]> {
    return this.store.pipe(select(adminRegionsSelector));
  }
}
