import { ParamMap } from '@angular/router';

export class NumericParamMapParser {
  constructor(private readonly key: string) {
  }

  parse(paramMap: ParamMap): number | undefined {
    // As far as I know, all of the results when Number fails to parse
    // are falsey
    return Number(paramMap.get(this.key)) || undefined;
  }
}
