import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadEffect } from '../../effects/load';
import * as JobActions from '../actions';
import { Job } from '../model';
import { JobsService } from '../service';

@Injectable()
export class Create extends LoadEffect {
  @Effect()
  observing$ = this.parentObserver$;

  constructor(
    actions$: Actions,
    private readonly jobsService: JobsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [JobActions.Types.Create];
  }

  protected handleResponse(job: Job): JobActions.AddAll {
    return new JobActions.AddAll({ jobs: [job] });
  }

  protected handleRequest(createAction: JobActions.Create) {
    const { name, stationIds } = createAction.payload;
    return this.jobsService.create(name, stationIds);
  }
}
