import { Component, Input, OnInit } from '@angular/core';
import { Feedback, DeleteFeedback, UpdateFeedback } from 'src/app/core/feedbacks';
import { QuestionI, SurveyI } from 'src/app/core/lib/models';
import { FeedbackManager } from '../question/feedback-manager';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserService } from 'src/app/core/services/user.service';
import { map } from 'rxjs/operators';
import { State } from 'src/app/reducers';
import { assign } from 'lodash/fp';
import { MonadicAction } from 'src/app/core/effects/monadic-action';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @Input() private readonly feedbackManager: FeedbackManager;
  @Input() private readonly question: QuestionI;
  @Input() private readonly survey: SurveyI;

  // <feedback id> -> string
  readonly feedbackTextCopy = new Map();

  constructor(private readonly store: Store<State>,
              private readonly userService: UserService,
              private readonly notificationsService: NotificationsService,
              private readonly activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getFeedbacks().forEach(feedback => {
      this.feedbackTextCopy.set(feedback.id, feedback.text);
    });
  }

  public getFeedbacks(): Feedback[] {
    return this.feedbackManager.getFeedbacksFor(this.question, this.survey);
  }

  public userName(feedback: Feedback): string {
    return this.feedbackManager.userNameForFeedback(feedback);
  }

  public mayEdit$(fb: Feedback): Observable<boolean> {
    return combineLatest([this.store, this.userService.getLoggedIn()])
      .pipe(map(([state, user]) => {
        const review = state.reviews.entities[fb.review_id];
        const fbUser = state.users.entities[review.user_id];
        return user.id === fbUser.id;
      }));
  }

  handleDeleteRequest(fb : Feedback) {
    const action = new MonadicAction(new DeleteFeedback({ feedback: fb }), () => {
      this.notificationsService.addNotification({
        message: 'Successfully removed feedback'
      });

      this.feedbackManager.removeFeedback(fb.id);

      if (this.getFeedbacks().length === 0) {
        this.activeModal.close();
      }
    });

    this.store.dispatch(action);
  }

  canSubmit(fb: Feedback) : boolean {
    return fb.text !== this.feedbackTextCopy.get(fb.id);
  }

  submit(fb: Feedback) {
    const newFeedback = assign(fb, { text: this.feedbackTextCopy.get(fb.id)});
    const action = new MonadicAction(new UpdateFeedback({ feedback: newFeedback }), () => {
      this.notificationsService.addNotification({
        message: 'Successfully updated feedback'
      });
    });
    this.store.dispatch(action);
  }
}
