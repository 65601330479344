import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionI, SurveyI } from 'src/app/core/lib/models';
import { LibService } from 'src/app/core/lib/service';
import { Revision } from 'src/app/core/revisions';
import { User } from 'src/app/core/User';
import * as Users from 'src/app/core/users';
import { State } from '../rx';

@Component({
  selector: 'app-users-for-revision',
  templateUrl: './users-for-revision.component.html',
  styleUrls: ['./users-for-revision.component.css']
})
export class UsersForRevisionComponent implements OnInit, OnChanges {
  @Input() private readonly revision: Revision;
  public users$: Observable<User[]> = this.makeUsers$();
  private survey$: Observable<SurveyI> = this.makeSurvey$();

  constructor(private readonly store: Store<State>, private readonly libService: LibService) {
  }

  public ngOnInit() {
    this.dispatchLoadUsers();
    this.users$ = this.makeUsers$();
    this.survey$ = this.makeSurvey$();
  }

  public ngOnChanges() {
    this.dispatchLoadUsers();
    this.users$ = this.makeUsers$();
    this.survey$ = this.makeSurvey$();
  }

  public sectionsForUser$(user: User): Observable<SectionI[]> {
    return combineLatest([
      this.survey$,
    ]).pipe(map(([survey]) => this.sectionsForUser(survey, user)));
  }

  private sectionsForUser(survey: SurveyI, user: User) {
    const sections: SectionI[] = [];

    Object.entries(this.revision.user_sections).forEach(([sectionKey, userId]) => {
      if (userId === user.id) {
        sections.push(survey.sections[sectionKey]);
      }
    });

    return sections;
  }

  private dispatchLoadUsers() {
    this.store.dispatch(new Users.LoadAll());
  }

  private makeSurvey$(): Observable<SurveyI> {
    return this.libService.get().pipe(map(lib => lib.makeSurvey()));
  }

  private makeUsers$(): Observable<User[]> {
    const users$ = this.store.pipe(map(state => state.users.entities));
    return combineLatest([
      users$,
    ]).pipe(map(([users]) => this.usersForRevision(users)));
  }

  private usersForRevision(users: { [id: number]: User }): User[] {
    return Object.values(this.revision.user_sections)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(userId => users[userId]);
  }

}
