import { environment } from 'src/environments/environment';
import { FullReportArgs, PostReport, ReportSessionId } from '.';
import { Revision } from '../../revisions';
import { AddNotification } from '../../services/notifications.service';
import { Station } from '../../stations';
import { SurveyWrapper } from '../../survey-wrapper';
import { NewTabOpener } from './new-tab';
import { ReportArgsCreator } from './report-args-creator';

export abstract class ReportGenerator {
  public abstract generateReport(station: Station, revision: Revision, surveyWrapper: SurveyWrapper, reportArgs: any): void;
}

export class ReportGeneratorImpl extends ReportGenerator {
  constructor(
    private readonly reportArgsCreator: ReportArgsCreator,
    private readonly addNotification: AddNotification,
    private readonly newTabOpener: NewTabOpener,
    private readonly post: PostReport,
  ) {
    super();
  }

  public async generateReport(station: Station, revision: Revision, surveyWrapper: SurveyWrapper, additionalReportArgs: any): Promise<void> {
    const reportArgs = await this.reportArgsCreator.createReportArgs(station, revision, surveyWrapper, additionalReportArgs);
    const removeNotification = this.notifyReportIsGenerating(station);
    const sessionId = await this.makeRequest(station, reportArgs);

    removeNotification();

    if (sessionId) {
      this.openReportInNewTab(sessionId);
    }
  }

  private openReportInNewTab(sessionId: ReportSessionId) {
    const url = environment.reportUrl + '/reports/' + sessionId + '.pdf';
    this.newTabOpener.openTab(url);
  }

  private async makeRequest(station: Station, reportArgs: FullReportArgs): Promise<ReportSessionId | undefined> {
    const url = environment.reportUrl + '/reports';
    let sessionId: ReportSessionId;

    try {
      const { session_id } = await this.post(url, reportArgs);
      sessionId = session_id;
    } catch (e) {
      console.warn(e);
      this.addNotification({
        message: `Could not generate report for: ${station.name}`,
        type: 'warning'
      });
    }

    return sessionId;
  }

  private notifyReportIsGenerating(station: Station): () => {} {
    let removeNotification: () => {};

    const promise = new Promise((res) => removeNotification = <() => {}>res);

    const message = `Generating report for ${station.name}. This might take a while. Do not close the tab.`;

    this.addNotification(
      { message, spinner: true },
      promise
    );

    return removeNotification;

  }
}
