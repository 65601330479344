import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Job } from 'src/app/core/jobs';
import { Review } from 'src/app/core/reviews';
import { Revision } from 'src/app/core/revisions';
import { Station } from 'src/app/core/stations';
import { SurveyWrapper } from 'src/app/core/survey-wrapper';
import { ReviewSurveyActionsService } from 'src/app/core/survey-wrapper/review-survey-wrapper-service';
import { AdditionalAccessControlModels } from 'src/app/core/survey-wrapper/survey-access-control';
import { SurveyActions } from 'src/app/core/survey-wrapper/survey-actions';
import { State } from '../rx';
import { ActiveSurveyService } from './active-survey.service';
import { stationForCurrentReviewSelector, revisionForCurrentReviewSelector, jobForCurrentReviewSelector, currentReviewSelector } from '../rx/selectors';

@Injectable({
  providedIn: 'root'
})
export class ReviewActiveSurveyService extends ActiveSurveyService {
  public readonly review$: Observable<Review | null> = this.makeReview$();

  constructor(
    store: Store<State>,
    updates$: Actions,
    private readonly reviewSurveyWrapperService: ReviewSurveyActionsService
  ) {
    super(store, updates$);
  }

  public isInReviewMode(): boolean {
    return true;
  }

  protected makeStation$(): Observable<Station | null> {
    return this.store.pipe(select(stationForCurrentReviewSelector));
  }

  protected makeRevision$(): Observable<Revision | null> {
    return this.store.pipe(select(revisionForCurrentReviewSelector));
  }

  protected makeJob$(): Observable<Job | null> {
    return this.store.pipe(select(jobForCurrentReviewSelector));
  }

  protected makeSurveyActions$(): Observable<SurveyActions | null> {
    const revision$: Observable<Revision> = this.store.pipe(select(revisionForCurrentReviewSelector));
    const review$ = this.makeReview$();

    const getActions = (additionalModels: AdditionalAccessControlModels) => {
      if (additionalModels.review) {
        return this.reviewSurveyWrapperService.get(additionalModels, additionalModels.review);
      } else {
        return undefined;
      }
    };

    return this.makeSurveyActionsHelper$(revision$, review$, getActions);
  }

  protected savePositionData() {
    const helper = (review: Review, surveyWrapper: SurveyWrapper) => {
      if (review && surveyWrapper) {
        this.reviewSurveyWrapperService.savePositionData(review, surveyWrapper);
      }
    };

    this.onReviewAndSurveyWrapper(helper);
  }

  private makeReview$(): Observable<Review | null> {
    return this.store.pipe(select(currentReviewSelector));
  }

  private onReviewAndSurveyWrapper(callback: (arg0: Review, arg1: SurveyWrapper) => any) {
    const review$: Observable<Review> = this.review$.pipe(first());
    const surveyActions$ = this.surveyActions$.pipe(first());

    forkJoin(
      review$,
      surveyActions$
    ).subscribe(([revision, surveyActions]) =>
      callback(revision, surveyActions.surveyWrapper));
  }
}
