import { ApiV2Service } from '../services/api-v2.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Job } from './model';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private readonly urlExtension = 'jobs';

  constructor(private readonly api: ApiV2Service) {
  }

  get(): Observable<Job[]> {
    return this.api.get(this.urlExtension);
  }

  create(name: string, stationIds: number[]): Observable<Job> {
    const newJobArgs = {
      job: { name },
      station_ids: stationIds
    };

    return this.api.post(this.urlExtension, newJobArgs);
  }
}

