import { Subject } from 'rxjs';

export function clickTimeout() {
  const subject = new Subject();

  document.addEventListener('click', () => {
    subject.next();
  });

  return subject;
}
