import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { pipe, EMPTY } from 'rxjs';
import { StationsService } from './service';
import { Job } from '../jobs';
import { gen } from 'testcheck';
import { LoadEffect } from '../effects/load';
import { LocationI } from '../locations/index';

type StationKindType = 'Station';
export const StationKind: StationKindType = 'Station';

export interface Station extends LocationI {
  id: number;
  region_id: number | null;
  organization_id: number | null;
  kind: StationKindType;
}

export const stationGen = gen.object({
  id: gen.sPosInt,
  name: gen.asciiString,
  nick: gen.asciiString,
  region_id: gen.sPosInt,
  organization_id: gen.sPosInt,
  latitude: gen.numberWithin(0, 180),
  longitude: gen.numberWithin(-90, 90),
  image_uri: null,
  city: gen.asciiString,
  state_abbrev: gen.asciiString,
  zip: gen.asciiString
});

export const adapter: EntityAdapter<Station> = createEntityAdapter<Station>({
});

enum Types {
  LoadFromJob = '[Stations] Load From Job',
  LoadAll = '[Stations] Load All',
  AddAll = '[Stations] Add All',
  UpsertOne = '[Stations] Upsert One',
  RemoveOne = '[Stations] Remove One'
}

interface StationAction extends Action {
  type: Types
}

export class AddAll implements StationAction {
  public readonly type = Types.AddAll;

  constructor(public readonly payload: { stations: Station[] }) {
  }
}

export class LoadFromJob implements StationAction {
  public readonly type = Types.LoadFromJob;

  constructor(public readonly payload: { job: Job }) {
  }
}

export class LoadAll implements StationAction {
  public readonly type = Types.LoadAll;
}

export class UpsertOne implements StationAction {
  public readonly type = Types.UpsertOne;

  constructor(public readonly payload: { station: Station }) {
  }
}

export class RemoveOne implements StationAction {
  public readonly type = Types.RemoveOne;

  constructor(public readonly payload: { station: Station }) {
  }
}

export interface State extends EntityState<Station> {
}

const initial: State = adapter.getInitialState({});

type Union = AddAll | LoadFromJob | UpsertOne | RemoveOne;

export function reducer(state = initial, action: Union): State {
  switch (action.type) {
    case Types.AddAll: {
      return adapter.addMany(action.payload.stations, state);
    }
    case Types.UpsertOne: {
      return adapter.upsertOne(action.payload.station, state);
    }
    case Types.RemoveOne: {
      return adapter.removeOne(action.payload.station.id, state);
    }
    default: {
      return state;
    }
  }
}

@Injectable()
export class LoadFromJobEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$

  constructor(
    actions$: Actions,
    private readonly stationsService: StationsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadFromJob];
  }

  protected handleResponse(stations: Station[]) {
    return new AddAll({ stations });
  }

  protected handleRequest({ payload: { job } }) {
    return this.stationsService.getFromJob(job);
  }
}

@Injectable()
export class LoadAllEffect extends LoadEffect {

  @Effect()
  observing$ = this.parentObserver$

  constructor(
    actions$: Actions,
    private readonly stationsService: StationsService
  ) {
    super(actions$);
  }

  protected typeFilters() {
    return [Types.LoadAll];
  }

  protected handleResponse(stations: Station[]) {
    return new AddAll({ stations });
  }

  protected handleRequest(action: LoadAll) {
    return this.stationsService.get();
  }
}
