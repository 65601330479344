import { LoginState } from './login-state';
import { WarningLoginState } from './warning-login-state';
import { PasswordLoginState } from './password-login-state';
import { emailRegex } from 'src/app/shared/utils';
import { GetLoginState } from './login-state-controller';

export class EmailLoginState extends LoginState {
  constructor(
    getLoginState: GetLoginState,
    email: string,
  ) {
    super(getLoginState);
    this.setEmail(email);
  }

  back() {
    return this.getLoginState(WarningLoginState);
  }

  next() {
    return this.getLoginState(PasswordLoginState);
  }

  canMoveNext() {
    return this.validEmail();
  }

  getErrorMessage() {
    return 'Not valid email';
  }

  private validEmail(): boolean {
    return emailRegex.test(this.email);
  }
}
