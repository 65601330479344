import { Injectable } from '@angular/core';
import { SurveyActionsService, SurveyWrapper } from '.';
import { Revision } from '../revisions';
import { MarkAsCompleteOnNextPositionInSurvey } from './position-in-survey';
import { RevisionAccessControl } from './revision-access-control';
import { AdditionalAccessControlModels } from './survey-access-control';
import { RevisionSurveyWrapperService } from './survey-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class RevisionSurveyActionsService extends SurveyActionsService<Revision>  {
  constructor(revisionSurveyWrapperService: RevisionSurveyWrapperService
  ) {
    super(revisionSurveyWrapperService);
  }

  protected createAccessControl(
    surveyWrapper: SurveyWrapper,
    models: AdditionalAccessControlModels
  ): RevisionAccessControl {
    return new RevisionAccessControl(surveyWrapper, models.user, models.job, models.revision);
  }

  protected createPositionInSurvey(surveyWrapper: SurveyWrapper): MarkAsCompleteOnNextPositionInSurvey {
    return new MarkAsCompleteOnNextPositionInSurvey(surveyWrapper);
  }
}
