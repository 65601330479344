import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ReportRevisionCreatorImpl, ReportRevisionsCreator } from '../core/models/reporting/report-revisions-creator';
import { Review } from '../core/reviews';
import { Revision } from '../core/revisions';
import { State } from '../reducers';
import { sameIds } from '../shared/utils';
import { User } from '../core/User';

@Injectable({
  providedIn: 'root'
})
export class ReportRevisionsCreatorService {
  constructor(private readonly store: Store<State>) {
  }

  get$(): Observable<ReportRevisionsCreator> {
    const helper = (revisions: Revision[], reviews: Review[], users: User[]) =>
      new ReportRevisionCreatorImpl(revisions, reviews, users);

    const revisions$ = this.store.pipe(
      map(state => Object.values(state.revisions.entities)),
      distinctUntilChanged(sameIds)
    );

    const reviews$ = this.store.pipe(
      map(state => Object.values(state.reviews.entities)),
      distinctUntilChanged(sameIds)
    );

    const users$ = this.store.pipe(
      map(state => Object.values(state.users.entities)),
      distinctUntilChanged(sameIds)
    );

    return combineLatest([
      revisions$,
      reviews$,
      users$
    ]).pipe(map(([revisions, reviews, users]) => helper(revisions, reviews, users)));
  }
}
