import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { Job } from '.';
import { State } from '../../reducers';
import { adapter } from './adapter';
import { State as JobState } from './state';

export const slice = createFeatureSelector<JobState>('jobs');

export const { selectAll: all } = adapter.getSelectors();

export const allFromApplicationState: Selector<State, Job[]> = createSelector(
  slice,
  all
);

export const selectedJob: Selector<State, Job | null> = createSelector(
  (state: State) => {
    return state.jobs.entities;
  },
  (state: State) => {
    return state.jobs.selectedId;
  },
  (jobs, selectedId) => {
    return selectedId ? jobs[selectedId] : null;
  }
);
