import { Component, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as Stations from 'src/app/core/stations';
import { Station, StationKind } from 'src/app/core/stations';
import { StationCopy } from 'src/app/core/stations/station-copy';
import { stationSrc } from 'src/app/shared/utils';
import { LocationsComponent } from '../locations/locations.component';
import { adminStationsSelector } from '../rx/admin-location-selectors';

@Component({
  selector: 'app-stations',
  templateUrl: '../models/models.component.html'
})
export class StationsComponent extends LocationsComponent<Station> implements OnInit {
  public readonly src = stationSrc;
  public readonly hasMapFiles = false;
  protected readonly kind = StationKind;
  protected readonly copyConstructor = StationCopy;

  public ngOnInit() {
    this.dispatchLoadStations();
  }

  private dispatchLoadStations(): void {
    this.store.dispatch(new Stations.LoadAll());
  }

  protected makeModels$(): Observable<Station[]> {
    return this.store.pipe(select(adminStationsSelector));
  }
}
