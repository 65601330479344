import { Subject } from 'rxjs';

export function keydownTimeout() {
  const subject = new Subject();

  document.addEventListener('keydown', () => {
    subject.next();
  });

  return subject;
}
